// import React ,{ useState, useEffect} from "react";
// import  Chart  from "react-apexcharts";
// import { API_HEADER, dasboard_counter_url } from "../../config";
// import axios from "axios";
// import { toast } from 'react-toastify';

// function ContractStatus({search,startDate,endDate})
// {
//     const [signedContractCount, setSignedContractCount] = useState(0);
//     const [pendingContractCount, setPendingContractCount] = useState(0);
//     const [dealLostCount, setDealLostCount] = useState(0);


//    useEffect(() => {
//     const fetchData = async () => {
//       try {

//         const payload = { 
//           "fy": "",
//           'start_date': startDate ? startDate.format('YYYY-MM-DD') : null,
//           'end_date': endDate ? endDate.format('YYYY-MM-DD') : null,
//         };

//         const response = await axios.post(`${dasboard_counter_url}`,payload, API_HEADER);

//         setSignedContractCount(response.data.data.project_signed_count);
//         setPendingContractCount(response.data.data.project_pending_count);
//         setDealLostCount(response.data.data.project_lost_count)
        
//       } catch (error) {
//         toast.error(error)
//       }
//     }
//     fetchData()
// },[search]);

// const customColors = [
//   "#c7522a", // Red open
//   "#d68a58", // Blue won
//   "#a4d658", // Green lost
 
// ];

//     return(
//         <React.Fragment>
//             <div className="container-fluid">
               
//                 <Chart 
//                 type="pie"
//                 width={"100%"}
//                 height={300}

//                 series={ [pendingContractCount, signedContractCount, dealLostCount] }                

//                 options={{
                       
//                        noData:{text:"Empty Data"},                        
                      
//                       labels: ["Open", 'Won', 'Lost']  ,
//                       colors: customColors,
//                       legend:{
//                         position: 'bottom'
//                     },

//                  }}
//                 >
//                 </Chart>
//             </div>
//         </React.Fragment>
//     );
// }
// export default ContractStatus;

import React from "react";


function ContractStatus({ startDate, endDate }) {
  const grafanaBaseUrl = "https://grafana1.intileotech.xyz:3000/d-solo/cebnhxngazxtsd/sales-dashboard";
  const orgId = 1;
  const timezone = "browser";
  // const panelId = 5;
  const panelId = 21;
  const theme = "light";
 


    const role = localStorage.getItem("designation_id");
    const userData = localStorage.getItem("user");
    const userId = JSON.parse(userData);
  
    // Access the 'id' field
    const user_id = userId.id;
    console.log("Extracted ID:", user_id);


  // Convert start and end date to UNIX timestamps (milliseconds)
  const from = startDate ? startDate.format('YYYY-MM-DD') : '2024-04-01' 
  const to = endDate ? endDate.format('YYYY-MM-DD') : '2025-03-31' 
  
  
  // const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&from=${from}&to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-user_id=${user_id}`;
  
  
  const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-user_id=${user_id}`;


  console.log("from",from,"to",to)

  // Construct Grafana iframe URL dynamically
  // const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&var-from=${from}&var-to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}`;

  return (
    <div className="container-fluid">
      <iframe
        src={grafanaUrl}
        width="100%"
        height="400"
        frameBorder="0"
        title="Program Wise Projects"
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
}

export default ContractStatus;



