import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { toast } from "react-toastify";
import { Alert } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import FileUploaderComponent from "../../utlis/FileUploaderComponent";
import LoaderComponent from "../../utlis/LoderComponent";

import {
  API_HEADER,
  tl_remotesitevisit_url,
  get_project_details_url,
  BASE_DOCUMENT,
} from "../../config";

const RemoteSiteVisitTL = ({ visitType }) => {
  const { id } = useParams();
  const sitevisittype = { visitType }.visitType;

  const [siteVisitStatus, setSiteVisitStatus] = useState("");
  const [remoteSiteVisitRemarks, setRemoteSiteVisitRemarks] = useState("");
  const [remoteSiteVisitReasons, setRemoteSiteVisitReasons] = useState("");
  const [remarkTMS, setRemarkTMS] = useState("");
  const [remarkTM, setRemarkTM] = useState("");
  const [siteVisitDateFrom, setSiteVisitDateFrom] = useState("");
  const [siteVisitDateTo, setSiteVisitDateTo] = useState("");
  const [error, setError] = useState("");
  const [siteVisitType, setSiteVisitType] = useState("");
  const [loading, setLoading] = useState(false);
  const [file1, setFile1] = useState("");
  const [osbExemptionName, setosbExemptionName] = useState(null);
  const [osbExemption, setosbExemption] = useState("");
  const [action, setAction] = useState();
  const [remoteSiteVisitATRemarks, setRemoteSiteVisitATRemarks] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_project_details_url}/${ids}`,
        API_HEADER
      );
      const { record } = response.data;

      setRemarkTMS(record.tms_remote_remarks);
      setRemarkTM(record.tm_remote_remarks);
      // setRemoteSiteVisitRemarks(record.sbu_tl_remarks);
      setRemoteSiteVisitRemarks(
        record.site_visit_status == 0 ||
          record.site_visit_status == 1 ||
          record.site_visit_status == 4
          ? (record.sbu_tl_remarks = "")
          : record.sbu_tl_remarks
      );
      setRemoteSiteVisitATRemarks(record.sbu_at_remarks);

      setSiteVisitStatus(record.site_visit_status);
      setSiteVisitDateFrom(record.site_visit_date_from);
      setSiteVisitDateTo(record.site_visit_date_to);
      setRemoteSiteVisitReasons(record.remote_visit_reason);
      setSiteVisitType(record.site_visit_type);
      setAction(record.site_visit_status);
      setFile1(record.osb_exemption);

      let url1 = `${BASE_DOCUMENT}/projects/${record.id}/${record.osb_exemption}`;
      setosbExemptionName(url1);
      setosbExemption(record.osb_exemption);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setPercent(percentCompleted);
    },
  };

  const handleRemarksChange = (content) => {
    setRemoteSiteVisitRemarks(content);
  };

  const navigate = useNavigate();

  const handleRemoteSiteVisit = async (e) => {
    e.preventDefault();
    setIsSubmitting(false);

    if (
      remoteSiteVisitRemarks == null ||
      remoteSiteVisitRemarks == "<p><br></p>" ||
      remoteSiteVisitRemarks == "" ||
      file1 == null
    ) {
      toast.error("Please upload the document and remarks.");
    } else {
      try {
        // setLoading(true); // Show loader
        setIsSubmitting(true);

        const formDataToSend = new FormData();

        formDataToSend.append("project_id", id);
        formDataToSend.append("site_visit_type", sitevisittype);
        formDataToSend.append("sbu_tl_remarks", remoteSiteVisitRemarks);
        formDataToSend.append("remote_visit_reason", remoteSiteVisitReasons);
        formDataToSend.append("site_visit_date_from", siteVisitDateFrom);
        formDataToSend.append("site_visit_date_to", siteVisitDateTo);
        formDataToSend.append("osb_exemption", file1);
        formDataToSend.append("site_visit_status", action);

        const response = await axios.post(
          `${tl_remotesitevisit_url}`,
          formDataToSend,
          CONFIG_Token2
        );
        if (response.status === 200 && response.data.status == 1) {
          setRemoteSiteVisitRemarks("");
          toast.success("Reason and Remark added successfully");
          navigate("/dashboard");
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
      } finally {
        // setLoading(false); // Hide loader
        setIsSubmitting(false);
      }
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    const year = today.getFullYear();

    // Add leading zero if month or day is less than 10
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    // Return the date in the format yyyy-mm-dd
    return `${year}-${month}-${day}`;
  };

  const handleSiteVisitReason = (e) => {
    setRemoteSiteVisitReasons(e.target.value);
  };

  const handleToDateChange = (e) => {
    const value = e.target.value;
    setSiteVisitDateTo(value);
  };
  const handleFromDateChange = (e) => {
    const value = e.target.value;
    setSiteVisitDateFrom(value);
  };

  return (
    <>
      {siteVisitStatus == 0 || siteVisitStatus == 1 || siteVisitStatus == 4 ? (
        <>
          <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
          <form
            method="post"
            onSubmit={handleRemoteSiteVisit}
            encType="multipart/form-data"
          >
            <div className="container my-4">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <label
                    htmlFor="remotesitevisitreasons"
                    className="form-label lightgreen fs-6"
                  >
                    Reasons For Remote Site Visit
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <textarea
                    rows="4"
                    cols="50"
                    type="text"
                    className={`form-control borderlightgreen`}
                    id="remote_site_visit_reasons"
                    required
                    name="remote_site_visit_reasons"
                    value={remoteSiteVisitReasons}
                    onChange={handleSiteVisitReason}
                  />
                </div>

                <div className="col-lg-6 col-md-6 col-12 ">
                  <label
                    htmlFor="remotesitevisitreasons"
                    className="form-label lightgreen fs-6"
                  >
                    OSV Exemption
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <FileUploaderComponent file={file1} setFile={setFile1} />

                  <div>
                    {/* {siteVisitStatus == 2 ? ( */}
                    <td>
                      <a href={osbExemptionName} target="_blank">
                        {osbExemption}
                      </a>
                    </td>
                    {/* ) : (
                  ""
                )} */}
                  </div>
                </div>
              </div>

              <div className="row mb-2 mt-4 ">
                <div className="col-6 mb-3">
                  <label
                    htmlFor="visit Date"
                    className="form-label lightgreen fs-6"
                  >
                    Site Visit Date From<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    className={`form-control borderlightgreen`}
                    id="visitdatefrom"
                    required
                    name="visit_date_from"
                    value={siteVisitDateFrom}
                    onChange={handleFromDateChange}
                    min={getCurrentDate()}
                  />
                </div>
                <div className="col-6 mb-3">
                  <label
                    htmlFor="visit Date"
                    className="form-label lightgreen fs-6"
                  >
                    Site Visit Date To<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    className={`form-control borderlightgreen`}
                    id="visitdateto"
                    required
                    name="visit_date_to"
                    value={siteVisitDateTo}
                    // onChange={handleToDateChange}
                    // min={siteVisitDateFrom}

                    onChange={
                      siteVisitDateFrom ? handleToDateChange : undefined
                    } // Only allow changing when startDate is set
                    min={siteVisitDateFrom ? siteVisitDateFrom : undefined} // Set min to startDate if startDate is set
                    disabled={!siteVisitDateFrom}
                  />
                  {/* {error && <div className="text-danger">{error}</div>} */}
                </div>
              </div>

              <div className="row " style={{ width: "80vw" }}>
                <div className="col-3 my-4">
                  <label
                    htmlFor="Member"
                    className="form-label lightgreen mb-4 fs-6"
                  >
                    Send To
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="member"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="status"
                    onChange={(e) => {
                      setAction(e.target.value);
                    }}
                    required
                    style={{ width: "200px" }}
                    value={action}
                  >
                    <option value="">Select</option>

                    <option value={2}>Send to team Member</option>
                    <option value={3}>Forward to TM support</option>
                  </select>
                </div>
              </div>

              <div className="row my-4">
                <div className="col-12">
                  <label
                    htmlFor="remotesitevisitremarkstl"
                    className="form-label lightgreen fs-6"
                  >
                    Team Leader Remarks<span style={{ color: "red" }}>*</span>
                  </label>

                  <ReactQuill
                    theme="snow"
                    value={remoteSiteVisitRemarks}
                    name="remotesitevisitremarkstl"
                    dangerouslySetInnerHTML={{ __html: remoteSiteVisitRemarks }}
                    onChange={handleRemarksChange}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>

              {remoteSiteVisitATRemarks && (
                <div className="row my-4">
                  <div className="col-12">
                    <label
                      htmlFor="remotesitevisitremarkstl"
                      className="form-label lightgreen fs-6"
                    >
                      Team Member Remarks
                    </label>

                    <ReactQuill
                      theme="snow"
                      value={remoteSiteVisitATRemarks}
                      name="remotesitevisitremarkstl"
                      dangerouslySetInnerHTML={{
                        __html: remoteSiteVisitATRemarks,
                      }}
                      readOnly={true}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              )}

              {/* {siteVisitStatus === 4   ? ( */}
              {remarkTMS && (
                <div className="row my-4">
                  <div className="col-12">
                    <label
                      htmlFor="remotesitevisitremarkstms"
                      className="form-label lightgreen fs-6"
                    >
                      TM Support Remarks
                    </label>
                    {siteVisitStatus === 4 ? (
                      <div className="row mt-4 mb-4">
                        <span>
                          {siteVisitStatus === 4 ? (
                            <Alert
                              className="col-12"
                              message="Clarification Required"
                              banner
                            />
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <ReactQuill
                      theme="snow"
                      value={remarkTMS}
                      name="remotesitevisitremarkstms"
                      readOnly={true}
                      dangerouslySetInnerHTML={{ __html: remarkTMS }}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              )}

              <div className="col-12 mt-3 d-flex justify-content-end gap-3 pr-0">
                <button type="submit" className="btn btn-success ">
                  Submit
                </button>
              </div>
            </div>
            {loading && <div className="loader"></div>}
          </form>
        </>
      ) : (
        <form
          method="post"
          onSubmit={handleRemoteSiteVisit}
          encType="json/form-data"
        >
          <div className="container my-4">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12 mb-3 ">
                <label
                  htmlFor="remotesitevisitreasons"
                  className="form-label lightgreen fs-6"
                >
                  Reasons For Remote Site Visit
                  <span style={{ color: "red" }}>*</span>
                </label>

                <textarea
                  rows="4"
                  cols="50"
                  type="text"
                  className={`form-control borderlightgreen`}
                  id="remote_site_visit_reasons"
                  required
                  name="remote_site_visit_reasons"
                  value={remoteSiteVisitReasons}
                  // onChange={handleSiteVisitReason}
                  disabled
                />
              </div>

              <div className="col-lg-6 col-md-6 col-12 mb-3">
                <label
                  htmlFor="remotesitevisitreasons"
                  className="form-label lightgreen fs-6"
                >
                  OSV Exemption
                  <span style={{ color: "red" }}>*</span>
                </label>

                <div>
                  <a href={osbExemptionName} target="_blank">
                    {osbExemption}
                  </a>
                </div>
              </div>
            </div>

            <div className="row mb-2 mt-4 ">
              <div className="col-6 mb-3">
                <label
                  htmlFor="visit Date"
                  className="form-label lightgreen fs-6"
                >
                  Site Visit Date From<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  className={`form-control borderlightgreen`}
                  id="visitdatefrom"
                  required
                  disabled
                  name="visit_date_from"
                  value={siteVisitDateFrom}
                  onChange={handleFromDateChange}
                  min={getCurrentDate()}
                />
              </div>
              <div className="col-6 mb-3">
                <label
                  htmlFor="visit Date"
                  className="form-label lightgreen fs-6"
                >
                  Site Visit Date To<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  className={`form-control borderlightgreen`}
                  id="visitdateto"
                  required
                  disabled
                  name="visit_date_to"
                  value={siteVisitDateTo}
                  onChange={handleToDateChange}
                  min={getCurrentDate()}
                />
                {error && <div className="text-danger">{error}</div>}
              </div>
            </div>

            <div className="row " style={{ width: "80vw" }}>
              <div className="col-3 my-4">
                <label
                  htmlFor="Member"
                  className="form-label lightgreen mb-4 fs-6"
                >
                  Send To
                  <span style={{ color: "red" }}>*</span>
                </label>

                <select
                  id="member"
                  className="form-select borderlightgreen form-select-sm"
                  aria-label="Default select example"
                  name="status"
                  // onChange={(e) => {
                  //   setAction(e.target.value);
                  // }}
                  disabled
                  style={{ width: "200px" }}
                  value={action}
                >
                  <option value="">Select</option>

                  <option value={2}>Send to team Member</option>
                  <option value={3}>Forward to TM support</option>
                  <option value={5}>TM support Send to TM</option>
                  <option value={6}>TM Send to TM Support</option>
                  <option value={7}>TM Approved</option>
                </select>
              </div>
            </div>

            {remoteSiteVisitRemarks && (
              <div className="row my-4">
                <div className="col-12">
                  <label
                    htmlFor="remotesitevisitremarkstl"
                    className="form-label lightgreen fs-6"
                  >
                    Team Leader Remarks<span style={{ color: "red" }}>*</span>
                  </label>

                  <ReactQuill
                    theme="snow"
                    value={remoteSiteVisitRemarks}
                    name="remotesitevisitremarkstl"
                    dangerouslySetInnerHTML={{ __html: remoteSiteVisitRemarks }}
                    readOnly={true}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            )}

            {remoteSiteVisitATRemarks && (
              <div className="row my-4">
                <div className="col-12">
                  <label
                    htmlFor="remotesitevisitremarkstl"
                    className="form-label lightgreen fs-6"
                  >
                    Team Member Remarks
                  </label>

                  <ReactQuill
                    theme="snow"
                    value={remoteSiteVisitATRemarks}
                    name="remotesitevisitremarkstl"
                    dangerouslySetInnerHTML={{
                      __html: remoteSiteVisitATRemarks,
                    }}
                    readOnly={true}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            )}

            {siteVisitStatus === 6 || siteVisitStatus === 7 ? (
              <div className="row my-4">
                <div className="col-12">
                  <label
                    htmlFor="remotesitevisitremarkstm"
                    className="form-label lightgreen fs-6"
                  >
                    TM Remarks
                  </label>
                  {siteVisitStatus === 6 ? (
                    <div className="row mt-4 mb-4">
                      <span>
                        {siteVisitStatus === 6 ? (
                          <Alert
                            className="col-12"
                            message="Clarification Required"
                            banner
                          />
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  <ReactQuill
                    theme="snow"
                    value={remarkTM}
                    name="remotesitevisitremarkstm"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: remarkTM }}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            {remarkTMS && (
              <div className="row my-4">
                <div className="col-12">
                  <label
                    htmlFor="remotesitevisitremarkstms"
                    className="form-label lightgreen fs-6"
                  >
                    TM Support Remarks
                  </label>
                  {siteVisitStatus === 4 ? (
                    <div className="row mt-4 mb-4">
                      <span>
                        {siteVisitStatus === 4 ? (
                          <Alert
                            className="col-12"
                            message="Clarification Required"
                            banner
                          />
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  <ReactQuill
                    theme="snow"
                    value={remarkTMS}
                    name="remotesitevisitremarkstms"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: remarkTMS }}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            )}
          </div>
          {loading && <div className="loader"></div>}
        </form>
      )}
    </>
  );
};

export default RemoteSiteVisitTL;
