// import React, { useState, useEffect } from "react";
// import Chart from "react-apexcharts";
// import { API_HEADER,project_stage_wise_url ,client_project_stage_wise_graph_url} from "../../../config";
// import axios from "axios";
// import { toast } from 'react-toastify';
// import { useNavigate } from "react-router-dom";

// function ClientProjectStageWiseGraph({search,startDate,endDate}) {
//     const [projectwisestage, setProjectWiseStage] = useState({});
//     const [programWiseData, setProgramWiseData] = useState([]); // State to store MyprogramwiseData
//     const navigate = useNavigate();

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
                
//                 const payload = { 
//                     "fy": "",
//                     'start_date': startDate ? startDate.format('YYYY-MM-DD') : null,
//                     'end_date': endDate ? endDate.format('YYYY-MM-DD') : null,
//                 };

//                 const response = await axios.post(`${client_project_stage_wise_graph_url}`, payload, API_HEADER);
//                 setProjectWiseStage(response.data.data);
//                 setProgramWiseData(response.data.data); // Save the data
//             } catch (error) {
//                 toast.error(error);
//             }
//         };
//         fetchData();
//     }, [search]);

//     const customColors = [
//         "#fe705d", "#ffb7c5", "#6b59cd", "#00cc99",
//         "#008081", "#edce7a", "#ee7036", "#f3c262",
//         "#a71666", "#d31638", "#4fb19d", "#823772"
//     ];

//     const formatLabel = (label) => {
//         return label.split('_')
//             .map(word => word.charAt(0).toUpperCase() + word.slice(1))
//             .join(' ');
//     };

//     const formattedLabels = Object.keys(projectwisestage).map(formatLabel);

//     const handleChartClick = (event, chartContext, { seriesIndex, dataPointIndex, w }) => {
        
//         const project_stage = w.globals.labels[dataPointIndex];
     
//         const title='Project Stage Wise';
//         if (project_stage) {
//             navigate('/programwiseprojecttable', { state: { title,project_stage } });
//         }

//     };

//     return (
//         <React.Fragment>
//             <div className="container-fluid my-3">
//                 <Chart
//                     type="pie"
//                     width={"100%"}
//                     height={450}
//                     series={Object.values(projectwisestage)}
//                     options={{
//                         chart: {
//                             toolbar: {
//                                 show: false,  // This will hide the icons on the top right corner
//                               },
//                             events: {
//                                 dataPointSelection: handleChartClick,
//                             },
//                         },
//                         noData: { text: "Empty Data" },
//                         labels: formattedLabels,
//                         colors: customColors,
//                         legend: { 
//                             position: "right", // Keeps the legend on the right side
//                             fontSize: '14px',  // Adjust font size if necessary
//                             offsetY: -50,        // Move the legend vertically upwards (adjust the value as needed)
//                             itemMargin: {
//                               vertical: 5,      
//                             },
//                          },

//                     }}
//                 />
//             </div>
//         </React.Fragment>
//     );
// }

// export default ClientProjectStageWiseGraph;

import React from "react";


function ClientProjectStageWiseGraph({ startDate, endDate }) {
  const grafanaBaseUrl = "https://grafana1.intileotech.xyz:3000/d-solo/eebqpgmaf99mof/client-dashboard";
  const orgId = 1;
  const timezone = "browser";
  // const panelId = 2;
  const panelId = 6;
  const theme = "light";
 


    const role = localStorage.getItem("designation_id");
    console.log("roleee",role)
    const userData = localStorage.getItem("user");
    const userId = JSON.parse(userData);
  
    // Access the 'id' field
    const client_Id = userId.client_id;
    console.log("Extracted ID:", client_Id);


  // Convert start and end date to UNIX timestamps (milliseconds)
  const from = startDate ? startDate.format('YYYY-MM-DD') : '2024-04-01' 
  const to = endDate ? endDate.format('YYYY-MM-DD') : '2025-03-31' 


// const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&from=${from}&to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-user_id=${user_id}`;


const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-client_id=${client_Id}`;


  console.log("from",from,"to",to)

  // Construct Grafana iframe URL dynamically
  // const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&var-from=${from}&var-to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}`;

  return (
    <div className="container-fluid">
      <iframe
        src={grafanaUrl}
        width="100%"
        height="400"
        frameBorder="0"
        title="Program Wise Projects"
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
}

export default ClientProjectStageWiseGraph;





