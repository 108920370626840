// import React from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { ErrorMessage, Field, Formik } from 'formik';
// import loginSchema from '../Schema/LoginSchema';
// import axios from 'axios';
// import { login_url,mail_reminder_url } from '../config';
// import { toast } from 'react-toastify';
// import logo from '../assets/logo.png'

// export default function Login({token}) {
//     const navigate=useNavigate();

//     const loginform = {
//         user_id: '',
//         password: '',
//         firebase_token:token
//     };

//     localStorage.setItem('firebase_token',token);
//     const handleSubmit = async (payload, { setSubmitting }) => {
//         try {
//             const result = await axios.post(login_url, payload);
            
//             if(result.status===200 && result.data.status===true){
                
//                 toast.success('Login Successfully');
//                 const mail_reminder = await axios.get(`${mail_reminder_url}`);
//                 localStorage.setItem('mail_reminder', JSON.stringify(mail_reminder.data.data));
//                 localStorage.setItem("token", result.data.user.token);
//                 localStorage.setItem("designation_id", Number(result.data.user.designation_id));
//                 localStorage.setItem("name", result.data.user.name);
//                 localStorage.setItem('user', JSON.stringify(result.data.user));
//                 localStorage.setItem("multi_user", result.data.user.multi_user);

//                 navigate('/dashboard')
//                 window.location.reload()

//             }else{

//                 toast.error(result.data.error);

//             }

            
//         } catch (error) {
//             toast.error(error);
//         }
//         setSubmitting(false);
//     };

//     return (
//         <div className="container-fluid" style={{ height: '100vh' }}>
//             <div className="row h-100">

//                 <div className='col-lg-6 col-xl-4 col-12 col-md-8 p-5 rounded-4 blurloginbox shadow-lg mx-auto my-auto border border-2 border-light'>
//                 <img className='d-flex justify-content-center mx-auto' width='205px' height='89px' src={logo} alt="earthoodlogo" />
//                     <p className='text-center fw-bolder' style={{ fontSize: '30px', fontWeight: '700', lineHeight: '60.51px' }}>
//                         <span className='fw-bolder textcolorblue'>Welcome</span><br />
//                     </p>
//                     <h5 className='text-black text-center'>Login to your account</h5>
//                     <Formik
//                         initialValues={loginform}
//                         validationSchema={loginSchema}
//                         onSubmit={handleSubmit}
//                         validateOnChange={true}
//                         validateOnBlur={true}
//                     >
//                         {({ handleSubmit, isSubmitting }) => (
//                             <form onSubmit={handleSubmit}>
//                                 <div className="mb-3">
//                                     <label htmlFor="user_id" className="form-label text-black">Username</label>
//                                     <Field type="text" name="user_id" id="user_id" className="form-control" />
//                                     <ErrorMessage name="user_id" component="div" className="error" />
//                                 </div>
//                                 <div className="mb-3">
//                                     <label htmlFor="password" className="form-label text-black">Password</label>
//                                     <Field type="password" name="password" id="password" className="form-control" />
//                                     <ErrorMessage name="password" component="div" className="error" />
//                                 </div>
//                                 <div className="d-flex justify-content-end">
//                                     <Link to="/forgotpassword" className="text-black text-end text-decoration-underline mb-3">Forgot Password?</Link>
//                                 </div>
//                                 <div className="d-grid">
//                                     <button className="btn btn-success border-0 bg_green text-white" type="submit" disabled={isSubmitting}>
//                                         {isSubmitting ? 'Signing...' : 'Login'}
//                                     </button>
//                                 </div>
//                             </form>
//                         )}
//                     </Formik>
//                 </div>
//             </div>
//         </div>
//     );
// }




import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ErrorMessage, Field, Formik } from 'formik';
import loginSchema from '../Schema/LoginSchema';
import axios from 'axios';
import { login_url,mail_reminder_url } from '../config';
import { toast } from 'react-toastify';
import logo from '../assets/logo.png'

export default function Login({token}) {
    const navigate=useNavigate();

    const loginform = {
        user_id: '',
        password: '',
        firebase_token:token
    };

    localStorage.setItem('firebase_token',token);
    const handleSubmit = async (payload, { setSubmitting }) => {
        try {
            const result = await axios.post(login_url, payload);
            
            if(result.status===200 && result.data.status===true){
                
                toast.success('Login Successfully');
                const mail_reminder = await axios.get(`${mail_reminder_url}`);
                localStorage.setItem('mail_reminder', JSON.stringify(mail_reminder.data.data));
                localStorage.setItem("token", result.data.user.token);
                localStorage.setItem("designation_id", Number(result.data.user.designation_id));
                localStorage.setItem("name", result.data.user.name);
                localStorage.setItem('user', JSON.stringify(result.data.user));
                localStorage.setItem("multi_user", result.data.user.multi_user);

                navigate('/dashboard')
                window.location.reload()

            }else{

                toast.error(result.data.error);

            }

            
        } catch (error) {
            toast.error(error);
        }
        setSubmitting(false);
    };

    return (
        <div className="container-fluid" style={{ height: '100vh' }}>
            <div className="row h-100">

                <div className='col-lg-6 col-xl-4 col-12 col-md-8 p-5 rounded-4 blurloginbox shadow-lg mx-auto my-auto border border-2 border-light'>
                <img className='d-flex justify-content-center mx-auto' width='205px' height='89px' src={logo} alt="earthoodlogo" />
                    <p className='text-center fw-bolder' style={{ fontSize: '30px', fontWeight: '700', lineHeight: '60.51px' }}>
                        <span className='fw-bolder textcolorblue'>Welcome</span><br />
                    </p>
                    <h5 className='text-black text-center'>Login to your account</h5>
                    <Formik
                        initialValues={loginform}
                        validationSchema={loginSchema}
                        onSubmit={handleSubmit}
                        validateOnChange={true}
                        validateOnBlur={true}
                    >
                        {({ handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="user_id" className="form-label text-black">Username</label>
                                    <Field type="text" name="user_id" id="user_id" className="form-control" />
                                    <ErrorMessage name="user_id" component="div" className="error" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label text-black">Password</label>
                                    <Field type="password" name="password" id="password" className="form-control" />
                                    <ErrorMessage name="password" component="div" className="error" />
                                </div>
                                <div className="d-flex justify-content-end">
                                    <Link to="/forgotpassword" className="text-black text-end text-decoration-underline mb-3">Forgot Password?</Link>
                                </div>
                                <div className="d-grid">
                                    <button className="btn btn-success border-0 bg_green text-white" type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? 'Signing...' : 'Login'}
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}
