// import React, { useState, useEffect } from "react";
// import Chart from "react-apexcharts";
// import { API_HEADER, region_wise_projects_url } from "../../../config";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// function RegionWiseProjectGraph({search,startDate,endDate}) {
//     const [projectOngoing, setProjectOngoing] = useState([]);
//     const [projectKickout, setProjectKickout] = useState([]);
//     const [projectNotStarted, setProjectNotStarted] = useState([]);
//     const [projectCompleted, setProjectCompleted] = useState([]);
//     const [regionName, setRegionName] = useState([]);
//     const [regionWiseData, setRegionWiseData] = useState([]);

//     const navigate = useNavigate();

//     useEffect(() => {
//         const projectongoing = [];
//         const projectkickout = [];
//         const projectnotstarted = [];
//         const projectcompleted = [];
//         const regionname = [];

//         const getRegionWiseProjects = async () => {
            
//             const payload = { 
//                 "fy": "",
//                 'start_date': startDate ? startDate.format('YYYY-MM-DD') : null,
//                 'end_date': endDate ? endDate.format('YYYY-MM-DD') : null,
//             };
    
//             const reqData = await axios.post(`${region_wise_projects_url}`, payload,API_HEADER);
//             const myRegionwiseData = reqData.data.data;

//             for (let i = 0; i < myRegionwiseData.length; i++) {
//                 projectongoing.push(myRegionwiseData[i].data.project_ongoing);
//                 projectkickout.push(myRegionwiseData[i].data.project_kickout);
//                 projectnotstarted.push(myRegionwiseData[i].data.project_notstarted);
//                 projectcompleted.push(myRegionwiseData[i].data.project_completed);
//                 regionname.push(myRegionwiseData[i].description);
//             }

//             setProjectOngoing(projectongoing);
//             setProjectKickout(projectkickout);
//             setProjectNotStarted(projectnotstarted);
//             setProjectCompleted(projectcompleted);
//             setRegionName(regionname);
//             setRegionWiseData(myRegionwiseData);

//         };

//         getRegionWiseProjects();
//     }, [search]);

//     const handleChartClick = (event, chartContext, { seriesIndex, dataPointIndex, w }) => {
//         const region_status = w.globals.seriesNames[seriesIndex];
//         const seriesName = w.globals.labels[dataPointIndex];
//         const matchedRegion = regionWiseData.find((region) => region.description === seriesName);

//         const title = w.globals.labels[dataPointIndex];

//         const region_id = matchedRegion ? matchedRegion.region_id : null;

//         if (region_id) {
//             navigate('/programwiseprojecttable', { state: { title,region_status, region_id, seriesName } });
//         }
//     };

//     return (
//         <React.Fragment>
//             <div className="container-fluid">
//                 <Chart
//                     type="bar"
//                     width={"100%"}
//                     height={400}
//                     series={[
//                         {
//                             name:"Ongoing Projects",
//                             data: projectOngoing,
//                             color: '#2776ae'
//                         },
        
//                         {
//                             name:"RFI Completed",
//                             data: projectNotStarted,
//                             color: '#65ddf7'
//                         },
        
//                         {
//                             name:"Under Kickout",
//                             data: projectKickout,
//                             color: '#f37324'
//                         },
                        
//                         {
//                             name:"Issued/Registered",
//                             data: projectCompleted,
//                             color: '#08415C'
//                         }
//                     ]}
//                     options={{
//                         chart: {
//                             toolbar: {
//                                 show: false,  // This will hide the icons on the top right corner
//                               },
//                             stacked: false,
//                             events: {
//                                 dataPointSelection: handleChartClick,
//                             },
//                             margin: {
//                                 top: 20, // Add margin to the top of the chart
//                             },
//                         },
//                         plotOptions: {
//                             bar: {
//                                 horizontal: false,
//                                 columnWidth: '40%',
//                                 dataLabels: {
//                                   position: "top", // Display labels on top of the bars
//                                   offsetY: -10, // Slightly offset the labels above the bars
//                                 }
//                             }
//                         },
//                         stroke: {
//                             width: 1,
//                         },
//                         xaxis: {
//                             title: {
//                                 text: "Regions",
//                                 style: {
//                                     fontSize: '16px',
//                                     fontWeight: 'bold'
//                                 }
//                             },
//                             categories: regionName
//                         },
//                         yaxis: {
//                             title: {
//                                 text: "Number of Projects",
//                                 style: { fontSize: '16px', fontWeight: 'bold' }
//                             },
//                             max: Math.max(
//                                 ...projectOngoing,
//                                 ...projectNotStarted,
//                                 ...projectKickout,
//                                 ...projectCompleted
//                             ) + 3, 
//                         },
//                         legend: {
//                             position: 'bottom',
//                         },
//                         dataLabels: {
//                             enabled: true,
//                             position: "top", // Show the label on top of the bars
//                             style: {
//                               fontSize: "12px",
//                               fontWeight: "bold",
//                               colors: ["#304758"],
//                             },
//                             offsetY: -20, // Add padding to ensure it's outside the bar
//                             formatter: function (val) {
//                                 return val === 0 ? "" : val.toFixed(0); // Show empty string for 0
//                             },
//                         },
//                         grid: {
//                             show: true,
//                             xaxis: {
//                                 lines: {
//                                     show: false
//                                 }
//                             },
//                             yaxis: {
//                                 lines: {
//                                     show: false
//                                 }
//                             },
//                             borderColor: '#f1f1f1',
//                             row: {
//                                 colors: ['transparent'],
//                                 opacity: 1,
//                             },
//                         },
//                     }}
//                 />
//             </div>
//         </React.Fragment>
//     );
// }

// export default RegionWiseProjectGraph;


import React from "react";


function RegionWiseProjectGraph({ startDate, endDate }) {
  const grafanaBaseUrl = "https://grafana1.intileotech.xyz:3000/d-solo/aea99hs3003y8b/md-dashboard";
  const orgId = 1;
  const timezone = "browser";
  // const panelId = 25;
  const panelId = 47;
  const theme = "light";
 


    const role = localStorage.getItem("designation_id");
    const userData = localStorage.getItem("user");
    const userId = JSON.parse(userData);
    console.log("ppp",userId)
  
    // Access the 'id' field
    const user_id = userId.id;


  // Convert start and end date to UNIX timestamps (milliseconds)
  const from = startDate ? startDate.format('YYYY-MM-DD') : '2024-04-01' 
  const to = endDate ? endDate.format('YYYY-MM-DD') : '2025-03-31'

// const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&from=${from}&to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-user_id=${user_id}`;

const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-user_id=${user_id}`;



 

  // Construct Grafana iframe URL dynamically
  // const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&var-from=${from}&var-to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}`;

  return (
    <div className="container-fluid">
      <iframe
        src={grafanaUrl}
        width="100%"
        height="400"
        frameBorder="0"
        title="Program Wise Projects"
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
}

export default RegionWiseProjectGraph;