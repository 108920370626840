// import React ,{ useState, useEffect} from "react";
// import  Chart  from "react-apexcharts";
// import { API_HEADER, commonproject_graphs_url } from "../../config";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// function ProposalMonthWise()
// {
//     const navigate = useNavigate();

//    const [proposalSigned, setProposalSigned]= useState([]);
//    const [proposalLost, setproposalLost]= useState([]);
//    const [proposalOpen, setproposalOpen] = useState([]);
//    const [dateCreated, setDateCreated] = useState([]);
//    const [createdYear, setCreatedYear] = useState([]);
//    const [programWiseData, setProgramWiseData] = useState([]);


//    useEffect( ()=>{
//        const proposalsign=[];
//        const proposallost=[];
//        const proposalopen=[];
//        const datecreation = [];
//        const YearCreated = [];

//        const getproposalMonthWise= async()=>{
//        const reqData= await axios.get(`${commonproject_graphs_url}`, API_HEADER);
    
//        const Myproposalmonthwise = reqData.data.proposal_month_wise
       
//        for(let i=0; i< Myproposalmonthwise.length; i++)
//        {
//         proposalopen.push((Myproposalmonthwise[i].proposal_open));
//         proposallost.push((Myproposalmonthwise[i].proposal_lost));
//         proposalsign.push((Myproposalmonthwise[i].proposal_signed));
//         const dateString = Myproposalmonthwise[i].month_year_created;
//         const [month, year] = dateString.split('-');
//         const date = new Date(year, parseInt(month) - 1);
//         const formattedMonth = date.toLocaleString('en-US', { month: 'long' });
//         const formattedYear = date.getFullYear();
//         datecreation.push(formattedMonth);
//         YearCreated.push(formattedYear)
//        }
//        setProposalSigned(proposalsign);
//        setproposalOpen(proposalopen);
//        setproposalLost(proposallost);
//        setDateCreated(datecreation);
//        setCreatedYear(YearCreated)
//        setProgramWiseData(Myproposalmonthwise);

//         }

//        getproposalMonthWise();

//    },[]);

//    const handleChartClick = (event, chartContext, { seriesIndex, dataPointIndex, w }) => {

//     const program_status = w.globals.seriesNames[seriesIndex];
//     const programname = programWiseData[dataPointIndex].month_year_created;

//     const matchedProgram = programWiseData.find((programName) => programName?.month_year_created == programname); 

//     if (programname) {
//       navigate('/proposaltable', { state: {  program_status,title:program_status,month_year_created:programname} });
//     }
//   };


//    return(
//     <React.Fragment>
//         <div className="container-fluid">
           
//             <Chart
//             type="bar"
//             width={"100%"}
//             height={400}
//             series={[
//                 {
//                     name:"Signed",
//                     data: proposalSigned,
//                     color: '#c7522a'
//                 },
//                 {
//                     name:"Lost",
//                     data: proposalLost,
//                     color: '#a0c72a'
//                 },
//                 {
//                     name:"Open",
//                     data: proposalOpen,
//                     color: '#f0daa5'
//                 },

//             ]}

//             options={{
//                 chart:{
//                     toolbar: {
//                         show: false,  // This will hide the icons on the top right corner
//                       },
//                     stacked:true,
//                     events: {
//                         dataPointSelection: handleChartClick,
//                       },
//                 },
//                 plotOptions:{
//                     bar:{
//                         horizontal:false,
//                         columnWidth:'70%',
//                       dataLabels : {
//                         total: {
//                             enabled: true,
//                             style: {
//                               fontSize: '13px',
//                               fontWeight: 900
//                             },
                            
//                           }
//                       }
                        
//                       }
//                 },
//                 stroke: {
//                     width: 1,
//                 },
//                 xaxis:{
//                     title:{
//                         text: `Month`,
//                         style:{fontSize:'16px',fontWeight:'bold'}
//                     },
//                     categories: dateCreated
//                 },
//                 yaxis:{
//                     title:{
//                         text:"Number of Deals",
//                         style:{fontSize:'16px',fontWeight:'bold'}
//                     },
//                     labels:{
//                         style: { fontSize: "15"},

//                     }
//                 },
//                 legend:{
//                     position: 'bottom'
//                 },
//                 dataLabels:{
//                     enabled:false,
//                 },
//                 grid: {
//                     show:true,
//                     xaxis:{
//                         lines:{
//                             show:false
//                         }
//                     },
//                     yaxis:{
//                         lines:{
//                             show:false
//                         }
//                     }

//                 },
               
//                   grid: {
//                     borderColor: '#f1f1f1',
//                     row: {
//                       colors: ['transparent'], 
//                       opacity: 1,
      
//                     },
//                   },

//             }}

//             />
//         </div>
//     </React.Fragment>
// );
// }
// export default ProposalMonthWise;



import React from "react";


function ProposalMonthWise({ startDate, endDate }) {
  const grafanaBaseUrl = "https://grafana1.intileotech.xyz:3000/d-solo/cebnhxngazxtsd/sales-dashboard";
  const orgId = 1;
  const timezone = "browser";
  // const panelId = 13;
  const panelId = 19;
  const theme = "light";
 


    const role = localStorage.getItem("designation_id");
    const userData = localStorage.getItem("user");
    const userId = JSON.parse(userData);
  
    // Access the 'id' field
    const user_id = userId.id;
    console.log("Extracted ID:", user_id);


  // Convert start and end date to UNIX timestamps (milliseconds)
  const from = startDate ? startDate.format('YYYY-MM-DD') : '2024-04-01' 
  const to = endDate ? endDate.format('YYYY-MM-DD') : '2025-03-31' 
  
  
  // const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&from=${from}&to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-user_id=${user_id}`;
  
  
  const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-user_id=${user_id}`;


  console.log("from",from,"to",to)

  // Construct Grafana iframe URL dynamically
  // const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&var-from=${from}&var-to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}`;

  return (
    <div className="container-fluid">
      <iframe
        src={grafanaUrl}
        width="100%"
        height="400"
        frameBorder="0"
        title="Program Wise Projects"
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
}

export default ProposalMonthWise;



