import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ErrorMessage, Field, Formik } from 'formik';
import loginSchema from '../Schema/LoginSchema';
import { login_url, mail_reminder_url } from '../config';
import { toast } from 'react-toastify';
import { faHandSpock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../assets/logo.png'
import image1 from '../assets/carboncredit.png';
import image2 from '../assets/carboncredit1.png';
import image3 from '../assets/carboncredit2.png';
import image4 from '../assets/carboncredit3.png';
export default function Login() {

    const navigate = useNavigate();

    const loginform = {
        user_id: '',
        password: '',
    };

    const handleSubmit = async (payload, { setSubmitting }) => {
        try {
            const result = await axios.post(login_url, payload);

            if (result.status === 200 && result.data.status === true) {

                toast.success('Login Successfully');
                const mail_reminder = await axios.get(`${mail_reminder_url}`);
                localStorage.setItem('mail_reminder', JSON.stringify(mail_reminder.data.data));
                localStorage.setItem("token", result.data.user.token);
                localStorage.setItem("designation_id", result.data.user.designation_id);
                localStorage.setItem("multi_user", result.data.user.multi_user);
                localStorage.setItem("name", result.data.user.name);
                localStorage.setItem('user', JSON.stringify(result.data.user));

                navigate('/dashboard')
                window.location.reload()

            } else {

                toast.error(result.data.error);

            }


        } catch (error) {
            toast.error(error);
        }
        setSubmitting(false);
    };

    return (
        <>
            


         <div className="container-fluid " style={{ height: '100vh' }}>
              <div className="row h-100">

              <div className='col-lg-6 col-xl-4 col-12 col-md-8 p-5 rounded-4 blurloginbox shadow-lg mx-auto my-auto border border-2 border-light'>
              {/* <img className='mb-5 d-flex justify-content-center mx-auto' width='205px' height='40px' src={logo} alt="earthoodlogo" /> */}
              <img className='d-flex justify-content-center mx-auto' width='205px' height='89px' src={logo} alt="earthoodlogo" />

                    <p className='text-center fw-bolder' style={{ fontSize: '30px', fontWeight: '500', lineHeight: '60.51px' }}>
                        <span className='fw-bolder textcolorblue'>Client Login</span><br />
                    </p>
                    <h5 className='text-black text-center'>Login to your account</h5>
                    <Formik
                        initialValues={loginform}
                        validationSchema={loginSchema}
                        onSubmit={handleSubmit}
                        validateOnChange={true}
                        validateOnBlur={true}
                    >
                        {({ handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="user_id" className="form-label text-black">Username</label>
                                    <Field type="text" name="user_id" id="user_id" className="form-control" />
                                    <ErrorMessage name="user_id" component="div" className="error" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label text-black">Password</label>
                                    <Field type="password" name="password" id="password" className="form-control" />
                                    <ErrorMessage name="password" component="div" className="error" />
                                </div>
                                <div className="d-flex justify-content-end">
                                    <Link to="/forgotpassword" className="text-black text-end text-decoration-underline mb-3">Forgot Password?</Link>
                                </div>
                                <div className="d-grid">
                                    <button className="btn btn-success border-0 bg_green text-white" type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? 'Signing...' : 'Login'}
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>

        </>
    );
}
