// import React, {useState, useEffect } from "react";
// import Chart from "react-apexcharts";
// import { toast } from "react-toastify";
// import axios from "axios";
// import { target_analysis_url, API_HEADER } from "../../config";
// import { Tag } from "antd";

// function YearlyTargetAnalysis() {

//   const [yearlytarget, setYearlyTarget]= useState(0);
//   const [yearlyRealValue, setYearlyRealValue]= useState(0);
//   const [dateCreated, setDateCreated]= useState([]);
//   const [createdYear, setCreatedYear] = useState([]);

//   useEffect( ()=>{

//     const RealTargetAchieved=[];
//     const CreationDate = [];
//     const YearCreated = [];

//     const gettargetanalysisrecord= async()=>{
//         const dataReq= await axios.get(`${target_analysis_url}`, API_HEADER);
//         const MyTargetAnalysis = dataReq.data.contract_monthly
//         const MyTargetAnalysisYearly = dataReq.data
//         for(let i=0; i<MyTargetAnalysis.length; i++)
//         {
//           RealTargetAchieved.push(MyTargetAnalysis[i].target_achived);
//           const dateString = MyTargetAnalysis[i].month_year_created;
//           const [month, year] = dateString.split('-');
//           const date = new Date(year, parseInt(month) - 1);
//           const formattedMonth = date.toLocaleString('en-US', { month: 'long' });
//           const formattedYear = date.getFullYear();
//           CreationDate.push(formattedMonth);
//           YearCreated.push(formattedYear)
//         }

//         setYearlyTarget(MyTargetAnalysisYearly.yearly_target);
//         setYearlyRealValue(MyTargetAnalysisYearly.target_achived_yearly);
//         setDateCreated(CreationDate);
//         setCreatedYear(YearCreated);
//  }
//   gettargetanalysisrecord();

//   },[]);

//   return (
//     <React.Fragment>
//       <div className="container-fluid">

//         <Chart
//           type="bar"
//           width={"100%"}
//           height={300}

//           series= {[
//             {
//               name: 'Actual',
//               data: [
//                 {
//                   x: createdYear[0],
//                   y: yearlyRealValue,
//                   goals: [
//                     {
//                       name: 'Expected',
//                       value: yearlytarget,
//                       strokeHeight: 5,
//                       strokeColor: '#900c3f'
//                     }
//                   ]
//                 },

//               ]
//             }
//           ]}
//           options={{
//             chart:{
//               toolbar: {
//                 show: false,  // This will hide the icons on the top right corner
//               },
//             },

//             colors: ['#182b55'],
//             theme: { mode: "light" },
//             xaxis: {
//               tickPlacement: "on",
//               title: {
//                 text: ``,
//                 style: {  fontSize: "14px", fontWeight: "bold" },
//               },
//             },
//             yaxis: {
//                 labels: {
//                   formatter: (val) => {
//                   return `${val}`;
//                   },
//               },
//                  title: {
//                  text: "Value in Millions",
//                  style: { fontSize: '14px', fontWeight: 'bold' },
//               },
//             },

//             legend: {
//                 show: true,
//                 showForSingleSeries: true,
//                 customLegendItems: ['Actual', 'Expected'],
//                 markers: {
//                   fillColors: ['#182b55,', '#900c3f']
//             },

//             dataLabels: {
//               formatter: (val) => {
//                 return `${val}`;
//               },
//               style: {
//                 colors: ["#f4f4f4"],
//                 fontSize: 15,
//               },
//             },
//           }}
//         }
//         ></Chart>
//       </div>
//     </React.Fragment>
//   );
// }

// export default YearlyTargetAnalysis;

import React from "react";

function YearlyTargetAnalysis({ startDate, endDate }) {
  const grafanaBaseUrl =
    "https://grafana1.intileotech.xyz:3000/d-solo/cebnhxngazxtsd/sales-dashboard";
  const orgId = 1;
  const timezone = "browser";
  // const panelId = 9;
  const panelId = 24;
  const theme = "light";

  const role = localStorage.getItem("designation_id");
  const userData = localStorage.getItem("user");
  const userId = JSON.parse(userData);

  // Access the 'id' field
  const user_id = userId.id;

  const from = startDate ? startDate.format("YYYY-MM-DD") : "2024-04-01";
  const to = endDate ? endDate.format("YYYY-MM-DD") : "2025-03-31";

  const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-user_id=${user_id}`;

  return (
    <div className="container-fluid">
      <iframe
        src={grafanaUrl}
        width="100%"
        height="400"
        frameBorder="0"
        title="Program Wise Projects"
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
}

export default YearlyTargetAnalysis;
