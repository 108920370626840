// // please install npm install react-apexcharts apexcharts
// import React ,{ useState, useEffect} from "react";
// import  Chart  from "react-apexcharts";
// import { API_HEADER, scopewiseproject_graphs_url } from "../../config";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// function ScopeWiseDealCount({search,startDate,endDate})
// {
//     const navigate = useNavigate();

//    const [proposalSignedCount, setProposalSignedCount]= useState([]);
//    const [proposalLostCount, setProposalLostCount]= useState([]);
//    const [proposalOpenCount, setProposalOpenCount] = useState([]);
//    const [proposalScope, setProposalScope] = useState([])
//    const [programWiseData, setProgramWiseData] = useState([]);


//    useEffect( ()=>{
//        const proposalsigncounting=[];
//        const proposallostcounting=[];
//        const proposalopencounting=[];
//        const proposalscopenaming = [];


//        const getProposalScopeWise= async()=>{

//         const payload = { 
//             "fy": "",
//             'start_date': startDate ? startDate.format('YYYY-MM-DD') : null,
//             'end_date': endDate ? endDate.format('YYYY-MM-DD') : null,
//         };

//        const reqData= await axios.post(`${scopewiseproject_graphs_url}`, payload,API_HEADER);
    
//        const Myproposalscopewise = reqData.data.data
       
//        for(let i=0; i< Myproposalscopewise.length; i++)
//        {
        
//         proposalopencounting.push((Myproposalscopewise[i].data.project_pending_count));
//         proposallostcounting.push((Myproposalscopewise[i].data.project_lost_count));
//         proposalsigncounting.push((Myproposalscopewise[i].data.project_signed_count));
//         proposalscopenaming.push((Myproposalscopewise[i].description));

       
//        }
//        setProposalSignedCount(proposalsigncounting);
//        setProposalOpenCount(proposalopencounting);
//        setProposalLostCount(proposallostcounting);
//        setProposalScope(proposalscopenaming);
//        setProgramWiseData(Myproposalscopewise);


//     }

//        getProposalScopeWise();

//    },[search]);


//    const handleChartClick = (event, chartContext, { seriesIndex, dataPointIndex, w }) => {

//     const program_status = w.globals.seriesNames[seriesIndex];
//     console.log("program_status",program_status)


//     const programname = w.globals.labels[dataPointIndex];
//     console.log("programname",programname)

//     const matchedProgram = programWiseData.find((programName) => programName?.description == programname);
//     console.log("matchedProgram",matchedProgram)

 

//     const program_id = matchedProgram ? matchedProgram.program_id : null;
//     console.log("program_id",program_id)

    
  



//     if (program_id) {
//       navigate('/proposaltable', { state: {  program_status,title:program_status,program:program_id} });
//     }
//   };

//    return(
//     <React.Fragment>
//         <div className="container-fluid">
           
//             <Chart
//             type="bar"
//             width={"100%"}
//             height={400}
//             series={[
//                 {
//                     name:"Signed",
//                     data: proposalSignedCount,
//                     color: '#27AE60'
//                 },
//                 {
//                     name:"Lost",
//                     data: proposalLostCount,
//                     color: '#f37324'
//                 },
//                 {
//                     name:"Open",
//                     data: proposalOpenCount,
//                     color: '#2776ae'
//                 },

//             ]}

//             options={{
//                 // title:{
//                 //     text:"Proposal Month Wise"
//                 // },
                
//                 chart:{
//                     toolbar: {
//                         show: false,  // This will hide the icons on the top right corner
//                       },
//                     stacked:true,
//                     events: {
//                         dataPointSelection: handleChartClick,
//                       },
//                 },
//                 plotOptions:{
//                     bar:{
//                         horizontal:false,
//                         columnWidth:'40%',
//                         dataLabels : {
//                             total: {
//                                 enabled: true,
//                                 style: {
//                                   fontSize: '11px',
//                                   fontWeight: 900
//                                 },                          
//                               }
//                           }
//                       }
//                 },
//                 stroke: {
//                     width: 1,
//                 },
//                 xaxis:{
//                     title:{
//                         text:"Proposal in different Programs",
//                         style:{fontSize:'16px',fontWeight:'bold'}
//                     },
//                     categories : proposalScope
//                 },
//                 yaxis:{
//                     title:{
//                         text:"Number of Deals",
//                         style:{fontSize:'16px',fontWeight:'bold'}
//                     },
//                     labels:{
//                         style: { fontSize: "15"},

//                     }
//                 },
//                 legend:{
//                     position: 'bottom'
//                 },
//                 dataLabels:{
//                     enabled:false,
//                 },
//                 grid: {
//                     show:true,
//                     xaxis:{
//                         lines:{
//                             show:false
//                         }
//                     },
//                     yaxis:{
//                         lines:{
//                             show:false
//                         }
//                     }

//                 },
                
               
//               grid: {
//                 borderColor: '#f1f1f1',
//                 row: {
//                   colors: ['transparent'], 
//                   opacity: 1,
  
//                 },
//               },

//             }}

//             />
//         </div>
//     </React.Fragment>
// );
// }
// export default ScopeWiseDealCount;

import React from "react";


function ScopeWiseDealCount({ startDate, endDate }) {
  const grafanaBaseUrl = "https://grafana1.intileotech.xyz:3000/d-solo/cebnhxngazxtsd/sales-dashboard";
  const orgId = 1;
  const timezone = "browser";
  // const panelId = 2;
  const panelId = 17;
  const theme = "light";
 


    const role = localStorage.getItem("designation_id");
    const userData = localStorage.getItem("user");
    const userId = JSON.parse(userData);
  
    // Access the 'id' field
    const user_id = userId.id;
    console.log("Extracted ID:", user_id);


  // Convert start and end date to UNIX timestamps (milliseconds)
  const from = startDate ? startDate.format('YYYY-MM-DD') : '2024-04-01' 
  const to = endDate ? endDate.format('YYYY-MM-DD') : '2025-03-31' 
  
  
  // const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&from=${from}&to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-user_id=${user_id}`;
  
  
  const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-user_id=${user_id}`;



  // Construct Grafana iframe URL dynamically
  // const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&var-from=${from}&var-to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}`;

  return (
    <div className="container-fluid">
      <iframe
        src={grafanaUrl}
        width="100%"
        height="400"
        frameBorder="0"
        title="Program Wise Projects"
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
}

export default ScopeWiseDealCount;



