import { EyeOutlined } from "@ant-design/icons";
import { Tooltip as Tip } from "antd";
import { Table } from "antd";
import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_HEADER, getAllFeedbacks } from "../config";
import Header from "../Pages/Header";
import Footer from "./Footer";
import SideNavbar from "./SideNavbar";
import dayjs from "dayjs";
import { DatePicker, Spin } from "antd";
import moment from "moment";

const FeedbackTable = () => {
  const dateFormat = "DD/MM/YYYY";
  const [dataSource, setDataSource] = useState([]);
  const [loadData, setloadData] = useState(false);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState(false);

  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    setloadData(true);
  };

  const handleSearchByColumn = (value) => {
    if (startDate && endDate) {
      if (startDate.isAfter(endDate)) {
        alert("Start Date cannot be after End Date.");
        setendDate(null);
        setstartDate(null);
        return;
      }
    }
    setSearch(!search);
	allData()
  };

  const columns = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "proposal_id",
      fixed: "left",
      width: 70,
      render: (text, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      fixed: "left",
      width: 90,
      render: (text, record) => {
        return (
          <Tip title={record.client_name}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.client_name}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Validation & Verification Services
        </span>
      ),
      fixed: "left",
      width: 90,
      render: (text, record) => {
        return (
          <Tip title={record.verificationServices}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.verificationServices}
            </span>
          </Tip>
        );
      },
      // sorter: (record1, record2) => {
      // 	return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
      // },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Team Communication
        </span>
      ),
      width: 180,
      render: (text, record) => {
        return (
          <Tip title={record.communication}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.communication}
            </span>
          </Tip>
        );
      },
      // sorter: (record1, record2) => {
      // 	return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
      // }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Issue Resolution
        </span>
      ),
      width: 100,
      render: (text, record) => {
        return (
          <Tip title={record.effectively}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.effectively}
            </span>
          </Tip>
        );
      },
      // sorter: (record1, record2) => {
      // 	return (record1.program_name > record2.program_name) ? 1 : (record1.program_name === record2.program_name) ? 0 : -1
      // }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Feedback on Service Improvement
        </span>
      ),
      width: 100,
      render: (text, record) => {
        return (
          <Tip title={record.suggestion}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.suggestion}
            </span>
          </Tip>
        );
      },
      // sorter: (record1, record2) => {
      // 	return (record1.implemented_fees > record2.implemented_fees) ? 1 : (record1.implemented_fees === record2.implemented_fees) ? 0 : -1
      // }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Aspect of our service
        </span>
      ),
      width: 100,
      render: (text, record) => {
        return (
          <Tip title={record.feedbackComments}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.feedbackComments}
            </span>
          </Tip>
        );
      },
      // sorter: (record1, record2) => {
      // 	return (record1.implemented_fees > record2.implemented_fees) ? 1 : (record1.implemented_fees === record2.implemented_fees) ? 0 : -1
      // }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Feedback
        </span>
      ),
      width: 100,
      render: (text, record) => {
        return (
          <Tip title={record.feedbackComments}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.feedbackComments}
            </span>
          </Tip>
        );
      },
      // sorter: (record1, record2) => {
      // 	return (record1.implemented_fees > record2.implemented_fees) ? 1 : (record1.implemented_fees === record2.implemented_fees) ? 0 : -1
      // }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Overall Satisfaction
        </span>
      ),
      width: 100,
      render: (text, record) => {
        return (
          <Tip title={record.technical_expertise}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.technical_expertise}
            </span>
          </Tip>
        );
      },
      // sorter: (record1, record2) => {
      // 	return (record1.implemented_fees > record2.implemented_fees) ? 1 : (record1.implemented_fees === record2.implemented_fees) ? 0 : -1
      // }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Date
        </span>
      ),
      width: 100,
      render: (text, record) => {
        const formattedDate = dayjs(record.created_at).format("YYYY-MM-DD");
        return (
          <Tip title={formattedDate}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {formattedDate}
            </span>
          </Tip>
        );
      },
      // sorter: (record1, record2) => {
      // 	return (record1.implemented_fees > record2.implemented_fees) ? 1 : (record1.implemented_fees === record2.implemented_fees) ? 0 : -1
      // }
    },
  ];

  const allData = async () => {
    try {
      setLoader(true);
	  const payload = {
        'start_date': startDate ? startDate.format('YYYY-MM-DD') : null,
        'end_date': endDate ? endDate.format('YYYY-MM-DD') : null,
      };
      const response = await axios.post(`${getAllFeedbacks}`, payload, API_HEADER);
      setDataSource(response.data.data.data);
      setloadData(false);

    //   setPagination((prevPagination) => ({
    //   	...prevPagination,
    //   	total: response.data.data.total,
    //   }));
      setLoader(false);
    } catch (error) {}
  };

  useEffect(() => {
    allData();
  }, [loadData]);

  return (
    <>
      <Header />
      <SideNavbar />
      <div className="content-wrapper bg-white">
        <div className="content">
          <div className="container-fluid">
            <div
              className="row"
              style={{
                justifyContent: "center",
              }}
            >
              <div className="">
                <div className="mx-3 my-3 border-1 border border-light-subtle p-0 rounded-3 mt-5">
                  <div className="row my-3 mx-3">
                    <div className="row ">
                      <div className="col-sm-3 col-md-2">
                        <div className="mb-3">
                          <label className="text-capitalize textcolumntitle  fw-bold font12px">
                            Start Date
                          </label>
                          <DatePicker
                            onChange={(date, dateString) => setstartDate(date)}
                            placeholder="From Date"
                            style={{ width: "100%" }}
                            className="rounded-2"
                            format={dateFormat}
                            showTime={false}
                            value={startDate}
                            disabledDate={(current) =>
                              current && current > moment().endOf("day")
                            }
                          />
                        </div>
                      </div>

                      <div className="col-sm-3 col-md-2">
                        <div className="mb-3">
                          <label className="text-capitalize textcolumntitle  fw-bold font12px">
                            End Date
                          </label>
                          <DatePicker
                            onChange={(date, dateString) => setendDate(date)}
                            placeholder="To Date"
                            style={{ width: "100%" }}
                            className="rounded-2"
                            format={dateFormat}
                            showTime={false}
                            value={endDate}
                            disabledDate={(current) =>
                              current && current > moment().endOf("day")
                            }
                          />
                        </div>
                      </div>

                      <div className="col-sm-3 col-md-2 mt-4">
                        <button
                          className="btn btn-success btn-md rounded-2 me-2"
                            onClick={handleSearchByColumn}
                        >
                          Search
                        </button>
                      </div>
                    </div>

                    <Table
                      className="border-1 border ronded-0 border-subtle-light px-0"
                      columns={columns}
                      dataSource={dataSource}
                      rowKey="proposal_id"
                      pagination={pagination}
                      onChange={handleTableChange}
                      loading={loader}
                      tableLayout="fixed"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FeedbackTable;
