import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BASE_DOCUMENT, Registrar_kickoutAction_url, get_KickoutInfo_url } from "../../config";
import { useParams } from "react-router-dom";
import { API_HEADER } from "../../config";
import FileUploaderComponent from "../../utlis/FileUploaderComponent";
import LoaderComponent from "../../utlis/LoderComponent";




const RegistrarKickout = () => {

  const { id } = useParams();
  const designation_id = localStorage.getItem("designation_id");
  const userdata = JSON.parse(localStorage.getItem("user"));
  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size

  const [kickoutStatus, setkickoutStatus] = useState("");
  const [action, setAction] = useState("");
  const [remarkTL, setRemarkTL] = useState("");
  const [remarkSbu, setRemarkSbu] = useState("");
  const [remarkRegistrar, setRemarkRegistrar] = useState("");
  const [role, setRole] = useState("");
  const [isTL, setIsTL] = useState(false);
  const [isAT, setIsAT] = useState(false);
  const [file1, setFile1] = useState('');
  const [kickOutDocName, setKickOutDocName] = useState(null)
  const [kickOutDoc, setKickOutDoc] = useState("")

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_KickoutInfo_url}/${ids}`,
        API_HEADER
      );
      const { kickout } = response.data;
      const { record } = response.data;
      const { teams } = response.data;

      setRemarkRegistrar(record.kick_registrar_remarks);
      setkickoutStatus(record.kickout_status);

      setRemarkTL(record.tl_remarks);
      setRemarkSbu(record.sbu_remarks);

      let url1 = `${BASE_DOCUMENT}/projects/${kickout.id}/${kickout.kickout_document}`;
      setKickOutDocName(url1);
      setKickOutDoc(kickout.kickout_document);


      const isTeamLeader = record.team.some((item) => item.role_id == 1);
      setIsTL(isTeamLeader);

      const isTeamMember = record.team.some((item) => item.role_id > 1);
      setIsAT(isTeamMember);

      if (designation_id == 8) {
        if (isTeamLeader) {
          setRole("Team Leader");
        } else {
          setRole("Team Member");
        }
      }

    } catch (error) { }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setPercent(percentCompleted);
    }
  };

  const handleRemarksChange = (content) => {
    setRemarkRegistrar(content);
  };

  const handleActionChange = (e) => {
    setAction(e.target.value);
  };

  const navigate = useNavigate();

  const handleSubmitAction = async (e) => {
    e.preventDefault();
    setIsSubmitting(false);

    if (remarkRegistrar == null || remarkRegistrar == '<p><br></p>' || remarkRegistrar == '' || action == '') {
      toast.error('Please upload the status and remarks. ')
    }
    else {
      try {
        setIsSubmitting(true);

        const formDataToSend = new FormData();


        formDataToSend.append("project_id", id);
        formDataToSend.append("kickout_document", file1);
        formDataToSend.append("kick_registrar_remarks", remarkRegistrar);
        formDataToSend.append("project_status", action);

        const response = await axios.post(
          `${Registrar_kickoutAction_url}`,
          formDataToSend,
          CONFIG_Token2
        );
        if (!response.data.status) {
          toast.error(response.data.message);
        } else {
          toast.success("Document Submitted Successfully");
          navigate("/dashboard");
          setRemarkRegistrar("");
          setAction("");

        }
      } catch (error) {
        toast.error(error);
      }
      finally {
        // setLoading(false); // Hide loader
        setIsSubmitting(false);

      }
    }
  };


  return (
    <>
      <LoaderComponent isSubmitting={isSubmitting} percent={percent} />

      <form method="post" encType="multipart/form-data" onSubmit={handleSubmitAction}>
        {kickoutStatus === 0 || kickoutStatus == null ? (
          <div className="col-12 border-0 mx-auto">
            <p className="lightgreen fw-bold my-3 fs-6">Registry Remarks<span style={{ color: "red" }}>*</span></p>
            <hr className="mb-3 lightgreen" />
            <div className="row mt-4">
              <span className="col-10 col-lg-4 col-md-4">
                <input
                  type="radio"
                  id="kickout"
                  name="kickout"
                  value={11}
                  onChange={handleActionChange}
                  className="text-danger"
                />
                <label className="text-danger mx-1" htmlFor="kickout">
                  Kick Out
                </label>
              </span>

              <span className="col-10 col-lg-4 col-md-4">
                <input
                  type="radio"
                  id="kickout"
                  name="kickout"
                  value={12}
                  onChange={handleActionChange}
                  className="text-success"
                />
                <label
                  className="text-success mx-1"
                  htmlFor="issued"
                >
                  Issued/Registered
                </label>
              </span>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <ReactQuill
                  theme="snow"
                  value={remarkRegistrar}
                  name="remarks"
                  dangerouslySetInnerHTML={{ __html: remarkRegistrar }}
                  onChange={handleRemarksChange}
                  style={{

                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>

            <table className="table table-bordered  table-hover mt-4">
              <thead>
                <tr>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    S.No
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Document Name
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">

                    Upload Document
                  </th>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">1</td>

                  <td className="text-center lightgreen p-3">
                    Kick Out Document
                  </td>

                  <FileUploaderComponent file={file1} setFile={setFile1} />
                </tr>

              </tbody>
            </table>

            <div className="container my-4 d-flex justify-content-end pr-0">
              <button
                className="btn px-3 fs-5"
                style={{
                  backgroundColor: "#07b6af",
                  color: "white",
                }}
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        ) : (
          (
            <div className="col-12 border-0 mx-auto">
              <p className="lightgreen fw-bold my-3 fs-6">Registry Remarks<span style={{ color: "red" }}>*</span></p>
              <hr className="mb-3 lightgreen" />


              <div className="row mt-4">
                <div className="col-12">
                  <ReactQuill
                    theme="snow"
                    value={remarkRegistrar}
                    name="remarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: remarkRegistrar }}

                    style={{

                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>

              <table className="table table-bordered  table-hover mt-4">
                <thead>
                  <tr>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      S.No
                    </th>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Document Name
                    </th>

                    <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">

                      Uploaded Document
                    </th>

                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td class="text-center">1</td>

                    <td className="text-center lightgreen p-3">
                      KickOut Document
                      <span style={{ color: "red" }}>*</span>
                    </td>

                    <td>
                      <a href={kickOutDocName} target="_blank">{kickOutDoc}</a>
                    </td>


                  </tr>



                </tbody>
              </table>

            </div>
          )

        )
        }
      </form>
    </>
  );
};

export default RegistrarKickout;
