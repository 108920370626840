import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_HEADER, registrar_rfi_url, get_project_details_url, BASE_DOCUMENT } from "../config";
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import MultipleFileUploader from "../Pages/MultipleFileUploader";
import FileUploaderComponent from "../utlis/FileUploaderComponent";
import LoaderComponent from "../utlis/LoderComponent";



const fileTypes = [
    "JPG",
    "JPEG",
    "PDF",
    "RAR",
    "XLS",
    "XLSX",
    "DOC",
    "DOCX",
    "ZIP",
    "XLSM"
];


const RegistrarRFI = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
    const dynamicMaxSize = my_file_maxSize.max_document_size


    const [registrarRFIDateTo, setRegistrarRFIDateTo] = useState(null)
    const [registrarRFIRemark, setRegistrarRFIRemark] = useState(null)
    const [status, setStatus] = useState('')

    const [fvrName, setFVRName] = useState(null)
    const [fvr, setFvr] = useState("")
    const [pddName, setPDDName] = useState(null);
    const [pdd, setPdd] = useState("");
    const [erName, setERName] = useState(null);
    const [er, setER] = useState("");
    const [verificationDeedName, setVerificationDeedName] = useState(null);
    const [verificationDeed, setVerificationDeed] = useState("");
    const [validationDeedName, setValidationDeedName] = useState(null);
    const [validationDeed, setValidationDeed] = useState("");
    const [registrarDoc, setRegistrarDoc] = useState(null);
    const [fileSizeError, setFileSizeError] = useState(false)
    const [fileSizeRegistrarRFIError, setfileSizeRegistrarRFIError] = useState(false)
    const [rfiReport, setRfiReport] = useState(null);
    const [rfi, setRFI] = useState(null);

    const [newRegistrarDocName, setNewRegistrarDocName] = useState(null)
    const [newRegistrarDoc, setNewRegistrarDoc] = useState('')

    const [newValidationDeedName, setNewValidationDeedName] = useState(null);
    const [newValidationDeed, setNewValidationDeed] = useState('');

    const [newVarificationDeedName, setNewVarificationDeedName] = useState(null);
    const [newVarificationDeed, setNewVarificationDeed] = useState('');

    const [newOtherDocName, setNewOtherDocName] = useState([]);
    const [newOtherDoc, setNewOtherDoc] = useState([]);


    const [file1, setFile1] = useState('');
    const [file2, setFile2] = useState('');
    const [file3, setFile3] = useState('');
    const [file4, setFile4] = useState([]);



    const [isSubmitting, setIsSubmitting] = useState(false);
    const [percent, setPercent] = useState(0);






    const fetchData = async (ids) => {
        try {
            const response = await axios.get(
                `${get_project_details_url}/${ids}`,
                API_HEADER
            );
            const { record } = response.data;

            setStatus(record.status)

            setRegistrarRFIDateTo(record.rfi_registrar_uploading_date)
            setRegistrarRFIRemark(record.rfi_registrar_remarks)


            let url1 = `${BASE_DOCUMENT}/projects/${record.id}/${record.fvr_report}`;
            setFVRName(url1);
            setFvr(record.fvr_report);

            let url2 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_er_doc}`;
            setERName(url2);
            setER(record.tr_er_doc);


            let url3 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_pdd_doc}`;
            setPDDName(url3);
            setPdd(record.tr_pdd_doc);

            let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.verification_deed}`;
            setVerificationDeedName(url4);
            setVerificationDeed(record.verification_deed);


            let url5 = `${BASE_DOCUMENT}/projects/${record.id}/${record.validation_deed}`;
            setValidationDeedName(url5);
            setValidationDeed(record.validation_deed);

            let url6 = `${BASE_DOCUMENT}/projects/${record.id}/${record.rfi_report}`;
            setRfiReport(url6);
            setRFI(record.rfi_report);

            let url7 = `${BASE_DOCUMENT}/projects/${record.id}/${record.verification_deed}`;
            setNewVarificationDeedName(url7);
            setNewVarificationDeed(record.verification_deed);

            let url8 = `${BASE_DOCUMENT}/projects/${record.id}/${record.validation_deed}`;
            setNewValidationDeedName(url8);
            setNewValidationDeed(record.validation_deed);

            let url9 = `${BASE_DOCUMENT}/projects/${record.id}/${record.rfi_report}`;
            setNewRegistrarDocName(url9);
            setNewRegistrarDoc(record.rfi_report);

            let url10 = `${BASE_DOCUMENT}/projects/${record.id}/${record.rfi_registrar_docs}`;
            setNewOtherDocName(url10);
            setNewOtherDoc(record.rfi_registrar_docs);

        } catch (error) { }
    };

    useEffect(() => {
        fetchData(id);
    }, [id]);

    const handleSizeError = (file) => {
        setFileSizeError(true)

    }
    const getCurrentDate = () => {
        const today = new Date();
        let month = today.getMonth() + 1;
        let day = today.getDate();
        const year = today.getFullYear();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return `${year}-${month}-${day}`;
    };

    const handleToDateChange = (e) => {
        const value = e.target.value;
        setRegistrarRFIDateTo(value)
    };

    const handleRegistrarRFIRemark = (content) => {
        setRegistrarRFIRemark(content);
    }

    const CONFIG_Token2 = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setPercent(percentCompleted);
        }
    };

    const handleRegistrarRFISubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(false);

        if ((registrarRFIRemark == null || registrarRFIRemark == '<p><br></p>' || registrarRFIRemark == '') || (file1 == null || file1 == "")) {
            toast.error('Please upload the document and remarks.')
        }
        else {

            try {
                setIsSubmitting(true);

                console.log("newOtherDocName", newOtherDocName)


                // if (!newRegistrarDocName) {
                //     toast.error("Please upload all required documents.");
                //     return;
                // }

                let formData = new FormData();
                formData.append("project_id", id);
                formData.append("rfi_registrar_remarks", registrarRFIRemark);
                formData.append("rfi_registrar_uploading_date", registrarRFIDateTo);
                formData.append("rfi_report", file1);

                formData.append("validation_deed", file2);
                formData.append("verification_deed", file3);
                // formData.append("rfi_registrar_docs", newOtherDocName[0]);
                // for (let i = 0; i < newOtherDocName.length; i++) {
                //     formData.append("rfi_registrar_docs[]", newOtherDocName[i]);
                // }
                for (let i = 0; i < file4.length; i++) {
                    formData.append("rfi_registrar_docs[]", file4[i]);
                }

                const response = await axios.post(
                    registrar_rfi_url,
                    formData,
                    CONFIG_Token2
                );

                if (!response.data.status) {
                    toast.error(response.data.message);
                } else {
                    toast.success("Form Submitted Successfully");
                    navigate("/dashboard");
                }

            } catch (error) {
                console.error("Error occurred while submitting data:", error);
                toast.error("Error occurred while submitting data");
            }
            finally {
                // setLoading(false); 
                setIsSubmitting(false);

            }
        }
    };


    const handleRegistrarRFiChange = (file) => {
        if (file[0].size <= dynamicMaxSize * 1024 * 1024) {
            setfileSizeRegistrarRFIError(false)
        }

        setfileSizeRegistrarRFIError(false)
        setRegistrarDoc(file);
    };

    const handleValidationChange = (file) => {
        setNewValidationDeedName(file);
    };

    const handleVarificationChange = (file) => {
        setNewVarificationDeedName(file);
    };

    const handleRegistrarDocChange = (file) => {
        setNewRegistrarDocName(file);
    };

    const handleNewOtherDocChange = (file) => {
        setFile4(file);
    };

    return (
        <>
            <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
            <form onSubmit={handleRegistrarRFISubmit}>
                <div>
                    <table className="table table-bordered  table-hover">
                        <thead>
                            <tr>
                                <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                                    S.No
                                </th>
                                <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                                    Document Name
                                </th>
                                <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                                    Uploaded Document
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center">1</td>
                                <td className="text-center lightgreen p-3">
                                    FVR Report<span style={{ color: "red" }}>*</span>
                                </td>
                                <td>
                                    <a href={fvrName} target="_blank">{fvr}</a>
                                </td>
                            </tr>

                            <tr>
                                <td class="text-center">2</td>
                                <td className="text-center lightgreen p-3">
                                    ER
                                </td>
                                <td>
                                    <a href={erName} target="_blank">{er}</a>
                                </td>
                            </tr>

                            <tr>
                                <td class="text-center">3</td>
                                <td className="text-center lightgreen p-3">
                                    PDD/MR
                                </td>
                                <td>
                                    <a href={pddName} target="_blank">{pdd}</a>
                                </td>
                            </tr>

                            <tr>
                                <td class="text-center">4</td>
                                <td className="text-center lightgreen p-3">
                                    Verification Deed
                                </td>
                                <td>
                                    <a href={verificationDeedName} target="_blank">{verificationDeed}</a>
                                </td>
                            </tr>

                            <tr>
                                <td class="text-center">5</td>
                                <td className="text-center lightgreen p-3">
                                    Validation Deed
                                </td>
                                <td>
                                    <a href={validationDeedName} target="_blank">{validationDeed}</a>
                                </td>
                            </tr>

                        </tbody>
                    </table>



                    {/* ////for Disable Mode */}
                    {status == 21 ? (



                        <div className="row" style={{ marginTop: '20px' }} >
                            <div className="col-3 mb-3 ">
                                <label htmlFor="visit Date" className="form-label lightgreen fs-6">
                                    RFI/RFR Submission Date<span style={{ color: "red" }}>*</span>
                                </label>
                            </div>
                            <div className="col-4 mb-3 ">
                                <input
                                    type="date"
                                    className={`form-control borderlightgreen`}
                                    id="rfidate"
                                    required
                                    disabled
                                    name="rfidate"
                                    value={registrarRFIDateTo}
                                />
                            </div>
                        </div>

                    ) :
                        <div className="row" style={{ marginTop: '20px' }} >
                            <div className="col-3 mb-3 ">
                                <label htmlFor="visit Date" className="form-label lightgreen fs-6">
                                    RFI/RFR Submission Date<span style={{ color: "red" }}>*</span>
                                </label>
                            </div>
                            <div className="col-4 mb-3 ">
                                <input
                                    type="date"
                                    className={`form-control borderlightgreen`}
                                    id="rfidate"
                                    required={true}
                                    name="rfidate"
                                    onChange={handleToDateChange}
                                    min={getCurrentDate()}
                                />
                            </div>
                        </div>

                    }


                    {status == 21 ? (
                        <div >
                            <label className="form-label lightgreen fs-6">RFI Report </label>
                            <a target="_blank" href={newRegistrarDocName}>
                                {newRegistrarDoc}
                            </a>

                            <label className="form-label lightgreen fs-6">Validation Deed </label>
                            <a target="_blank" href={newValidationDeedName}>
                                {newValidationDeed}
                            </a>

                            <label className="form-label lightgreen fs-6">Varification Deed </label>
                            <a target="_blank" href={newVarificationDeedName}>
                                {newVarificationDeed}
                            </a>

                            <label className="form-label lightgreen fs-6">Other Docs </label>
                            <a target="_blank" href={newOtherDocName}>
                                {newOtherDoc}
                            </a>
                        </div>

                    ) :
                        <>
                            <div className="row my-4" >
                                <div className="col-6"  >
                                    <label className="form-label lightgreen fs-6">RFI Report
                                        <span style={{ color: "red" }}>*</span>
                                    </label>
                                    {/* <FileUploader
                                handleChange={handleRegistrarDocChange}
                                name="rfp_doc"
                                types={fileTypes}
                                multiple="false"
                                maxSize={dynamicMaxSize}
                                onSizeError={handleSizeError}
                                style={{ width: '70%' }}
                            />
                            {fileSizeRegistrarRFIError ? (
                                <span className="text-danger">
                                    File size greater than {dynamicMaxSize} mb is not
                                    allowed
                                </span>
                            ) : (
                                <span>
                                    {newRegistrarDocName && newRegistrarDocName[0].name ?
                                        `File name: ${newRegistrarDocName[0].name}`
                                        :
                                        ''}
                                </span>
                            ) */}

                                    <FileUploaderComponent file={file1} setFile={setFile1} />
                                    {/* } */}
                                </div>

                                <div className="col-6" >
                                    <label className="form-label lightgreen fs-6">Validation Deed

                                    </label>
                                    {/* <FileUploader
                                handleChange={handleValidationChange}
                                name="rfp_doc"
                                types={fileTypes}
                                multiple="false"
                                maxSize={dynamicMaxSize}
                                onSizeError={handleSizeError}
                                style={{ width: '70%' }}
                            />
                            {fileSizeRegistrarRFIError ? (
                                <span className="text-danger">
                                    File size greater than {dynamicMaxSize} mb is not
                                    allowed
                                </span>
                            ) : (
                                <span>
                                    {newValidationDeedName && newValidationDeedName[0].name ?
                                        `File name: ${newValidationDeedName[0].name}`
                                        :
                                        ''}
                                </span>
                            )
                            } */}
                                    <FileUploaderComponent file={file2} setFile={setFile2} />

                                </div>

                            </div>


                            <div className="row">
                                <div className="col-6" >
                                    <label className="form-label lightgreen fs-6">Verification Deed
                                    </label>
                                    {/* <FileUploader
                                handleChange={handleVarificationChange}
                                name="rfp_doc"
                                types={fileTypes}
                                multiple="false"
                                maxSize={dynamicMaxSize}
                                onSizeError={handleSizeError}
                                style={{ width: '70%' }}
                            />
                            {fileSizeRegistrarRFIError ? (
                                <span className="text-danger">
                                    File size greater than {dynamicMaxSize} mb is not
                                    allowed
                                </span>
                            ) : (
                                <span>
                                    {newVarificationDeedName && newVarificationDeedName[0].name ?
                                        `File name: ${newVarificationDeedName[0].name}`
                                        :
                                        ''}
                                </span>
                            )
                            } */}
                                    <FileUploaderComponent file={file3} setFile={setFile3} />

                                </div>

                                <div className="col-6">
                                    <label className="form-label lightgreen fs-6">Other Docs
                                    </label>


                                    <MultipleFileUploader
                                        dynamicMaxSize={dynamicMaxSize}
                                        onFilesChange={handleNewOtherDocChange}
                                    />

                                </div>
                            </div>
                        </>
                    }

                    {status == 21 ? (
                        <div >
                            <p className="lightgreen fw-bold  fs-6 my-4">
                                Registrar Remarks <span style={{ color: "red" }}>*</span>
                            </p>
                            <hr className="mb-3 lightgreen" />
                            <div>
                                <ReactQuill
                                    theme="snow"
                                    value={registrarRFIRemark}
                                    name="remarks"

                                    style={{
                                        height: "20vh",
                                        marginBottom: "2rem",
                                    }}
                                    className="mb-5"
                                />
                            </div>
                        </div>) :

                        <div >
                            <p className="lightgreen fw-bold  fs-6 my-4">
                                Registrar Remarks <span style={{ color: "red" }}>*</span>
                            </p>
                            <hr className="mb-3 lightgreen" />
                            <div>
                                <ReactQuill
                                    theme="snow"
                                    value={registrarRFIRemark}
                                    name="remarks"
                                    onChange={handleRegistrarRFIRemark}
                                    style={{
                                        height: "20vh",
                                        marginBottom: "2rem",
                                    }}
                                    className="mb-5"
                                />
                            </div>
                        </div>}

                    {status == 21 ? '' :

                        <div className="col-12 d-flex justify-content-end gap-3 pr-0">
                            <button
                                type="submit"
                                // onClick={handleRegistrarRFISubmit}
                                className="btn btn-success"
                            >
                                Submit
                            </button>
                        </div>
                    }
                </div>
            </form>
        </>
    )
}

export default RegistrarRFI
