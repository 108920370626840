import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BASE_DOCUMENT, at_trround_url } from "../config";
import { get_project_details_url } from "../config";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import { API_HEADER } from "../config";
import FileUploaderComponent from "../utlis/FileUploaderComponent";
import LoaderComponent from "../utlis/LoderComponent";

const ATTrRound = (props) => {
  const { id } = useParams();
  const { isTL } = props;

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size;

  const [program, setProgram] = useState([]);
  const [action, setAction] = useState("");
  // const [remark, setRemark] = useState("");
  const [remarkTR, setRemarkTR] = useState("");
  const [tlremark, setTLRemark] = useState("");
  const [atremark, setATRemark] = useState("");
  const [remarkClient, setRemarkClient] = useState("");
  const [member, setMember] = useState();
  const [trStatus, setTrStatus] = useState("");
  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const [file3, setFile3] = useState("");
  const [file4, setFile4] = useState("");
  const [file5, setFile5] = useState("");
  const [file6, setFile6] = useState("");
  const [fileDVRSizeError, setFileDVRSizeError] = useState(false);
  const [filePDDSizeError, setFilePDDSizeError] = useState(false);
  const [fileRRSizeError, setFileRRSizeError] = useState(false);
  const [
    filetechnicalreviewsheetSizeError,
    setFiletechnicalreviewsheetSizeError,
  ] = useState(false);
  const [fileCpaSizeError, setFileCpaSizeError] = useState(false);
  const [fileERSizeError, setFileERSizeError] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [pddName, setPDDName] = useState(null);
  const [pdd, setPdd] = useState("");
  const [technicalReviewSheetName, setTechnicalReviewSheetName] =
    useState(null);
  const [technicalReviewSheet, setTechnicalReviewSheet] = useState("");
  const [dvrName, setDVRName] = useState(null);
  const [dvr, setDvr] = useState("");
  const [rrName, setRRName] = useState(null);
  const [rr, setRR] = useState("");

  const [erName, setERName] = useState(null);
  const [er, setER] = useState("");
  const [cpaName, setCpaName] = useState(null);
  const [cpa, setCpa] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [loading, setLoading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_project_details_url}/${ids}`,
        API_HEADER
      );
      const { record } = response.data;
      const { teams } = response.data;
      setTeamList(teams);
      setProgram(record.program);
      setTrStatus(record.tr_status);
      setMember(record.tr_status);
      setRemarkTR(record.dvr_tr_remarks);
      // setRemarkTL(record.dvr_tl_remarks);
      setATRemark(
        record.tr_status == 2 || record.tr_status == 7 || record.tr_status == 5
          ? (record.tr_at_remarks = "")
          : record.tr_at_remarks
      );
      setTLRemark(record.tr_tl_remarks);
      setRemarkClient(record.tr_client_remarks);

      setAction(record.tr_status);

      setStartDate(record.tr_start_date);
      setEndDate(record.tr_end_date);

      let url1 = `${BASE_DOCUMENT}/projects/${record.id}/${record.dvr_report}`;
      setDVRName(url1);
      setDvr(record.dvr_report);

      let url2 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_cpa_doc}`;
      setCpaName(url2);
      setCpa(record.tr_cpa_doc);

      let url3 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_er_doc}`;
      setERName(url3);
      setER(record.tr_er_doc);

      let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_pdd_doc}`;
      setPDDName(url4);
      setPdd(record.tr_pdd_doc);

      let url5 = `${BASE_DOCUMENT}/projects/${record.id}/${record.earthood_report}`;
      setTechnicalReviewSheetName(url5);
      setTechnicalReviewSheet(record.earthood_report);

      let url6 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_irr_doc}`;
      setRRName(url6);
      setRR(record.tr_irr_doc);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setPercent(percentCompleted);
    },
  };

  const handleRemarksChange = (content) => {
    setATRemark(content);
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(false);

    if (atremark == null || atremark == "<p><br></p>" || atremark == "") {
      toast.error("Please Add Some Remarks");
    } else {
      try {
        // setLoading(true); // Show loader
        setIsSubmitting(true);

        const formDataToSend = new FormData();
        formDataToSend.append("project_id", id);
        formDataToSend.append("dvr_report", file1);
        formDataToSend.append("tr_cpa_doc", file2);
        formDataToSend.append("tr_er_doc", file3);
        formDataToSend.append("tr_pdd_doc", file4);
        formDataToSend.append("earthood_report", file5);
        formDataToSend.append("tr_irr_doc", file6);
        formDataToSend.append("tr_status", member);
        formDataToSend.append("tr_at_remarks", atremark);

        const response = await axios.post(
          `${at_trround_url}`,
          formDataToSend,
          CONFIG_Token2
        );

        if (!response.data.status) {
          toast.error(response.data.message);
        } else if (
          fileDVRSizeError ||
          fileCpaSizeError ||
          filePDDSizeError ||
          filetechnicalreviewsheetSizeError ||
          fileRRSizeError ||
          fileERSizeError
        ) {
          toast.error(`File size must below ${dynamicMaxSize}`);
        } else {
          toast.success("Document Submitted Successfully");
          setFile1(null);
          setFile2(null);
          setFile3(null);
          setFile4(null);
          setFile5(null);
          setFile6(null);
          setMember("");
          setATRemark("");
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("Error occurred while submitting data");
      } finally {
        // setLoading(false); // Hide loader
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      {trStatus == 2 || trStatus == 7 || trStatus == 5 ? (
        <>
          <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
          <form
            method="post"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <table className="table table-bordered  table-hover">
              <thead>
                <tr>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    S.No
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Document Name
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Upload Document
                  </th>

                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Uploaded Document
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">1</td>

                  <td className="text-center lightgreen p-3">
                    DVR Report
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </td>

                  <FileUploaderComponent file={file1} setFile={setFile1} />

                  <td>
                    <a href={dvrName} target="_blank">
                      {dvr}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">2</td>

                  <td className="text-center lightgreen p-3">
                    PDD / MR
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </td>

                  <FileUploaderComponent file={file4} setFile={setFile4} />

                  <td>
                    <a href={pddName} target="_blank">
                      {pdd}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">3</td>

                  <td className="text-center lightgreen p-3">
                    ER
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </td>

                  <FileUploaderComponent file={file3} setFile={setFile3} />

                  <td>
                    <a href={erName}>{er}</a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">4</td>

                  <td className="text-center lightgreen p-3">IRR</td>

                  <FileUploaderComponent file={file6} setFile={setFile6} />

                  <td>
                    <a href={rrName}>{rr}</a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">5</td>

                  <td className="text-center lightgreen p-3">CPA</td>

                  <FileUploaderComponent file={file2} setFile={setFile2} />

                  <td>
                    <a href={cpaName}>{cpa}</a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">6</td>

                  <td className="text-center lightgreen p-3">
                    Technical Review Sheet
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <FileUploaderComponent file={file5} setFile={setFile5} />

                  <td>
                    <a href={technicalReviewSheetName} target="_blank">
                      {technicalReviewSheet}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="row">
              <div className="col-5 my-2">
                <label
                  htmlFor="Member"
                  className="form-label lightgreen mb-4 fs-6"
                >
                  Send To
                  <span style={{ color: "red" }}>*</span>
                </label>

                <select
                  id="member"
                  className="form-select borderlightgreen form-select-sm"
                  aria-label="Default select example"
                  name="status"
                  value={member}
                  required
                  onChange={(e) => {
                    setMember(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  <option value={3}>Forward to TL</option>
                  <option value={4}>Reply to Client</option>
                  <option value={6}>Send to TR</option>
                </select>
              </div>
            </div>

            <div className="mb-3">
              <div>
                <p className="lightgreen fw-bold my-3 fs-6">
                  Team Member Remarks<span style={{ color: "red" }}>*</span>
                </p>
                <hr className="mb-3 lightgreen" />
                <div>
                  <ReactQuill
                    theme="snow"
                    value={atremark}
                    name="remarks"
                    dangerouslySetInnerHTML={{ __html: atremark }}
                    onChange={handleRemarksChange}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>

              {tlremark && (
                <div>
                  <p className="lightgreen fw-bold my-3 fs-6">
                    Team Leader Remarks
                  </p>
                  <hr className="mb-3 lightgreen" />
                  <div>
                    <ReactQuill
                      theme="snow"
                      value={tlremark}
                      name="remarks"
                      readOnly={true}
                      dangerouslySetInnerHTML={{ __html: tlremark }}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              )}

              {remarkTR && (
                <div className="mt-3">
                  <p className="lightgreen fw-bold my-3 fs-6">
                    Technical Reviewer Remarks
                  </p>
                  <hr className="mb-3 lightgreen" />
                  <div className="row mt-4">
                    <span>
                      <Alert
                        className="col-12"
                        message="Clarification Required"
                        banner
                      />
                    </span>
                  </div>

                  <div className="row mt-4">
                    <div className="col-12">
                      <ReactQuill
                        theme="snow"
                        value={remarkTR}
                        name="remarks"
                        readOnly={true}
                        dangerouslySetInnerHTML={{ __html: remarkTR }}
                        style={{
                          height: "20vh",
                          marginBottom: "2rem",
                        }}
                        className="mb-5"
                      />
                    </div>
                  </div>
                </div>
              )}

              {remarkClient && (
                <div>
                  <p className="lightgreen fw-bold my-3 fs-6">Client Remarks</p>
                  <hr className="mb-3 lightgreen" />
                  <div>
                    <ReactQuill
                      theme="snow"
                      value={remarkClient}
                      name="remarks"
                      readOnly={true}
                      dangerouslySetInnerHTML={{ __html: remarkClient }}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="container mt-4 d-flex justify-content-end pr-0">
              <button
                className="btn px-3 fs-5"
                style={{
                  backgroundColor: "#07b6af",
                  color: "white",
                }}
                type="submit"
              >
                Submit
              </button>
            </div>

            {loading && <div className="loader"></div>}
          </form>
        </>
      ) : trStatus == 3 || trStatus == 4 || trStatus == 6 || trStatus == 8 ? (
        <form
          method="post"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <table className="table table-bordered  table-hover">
            <thead>
              <tr>
                <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                  S.No
                </th>
                <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                  Document Name
                </th>

                <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                  Uploaded Document
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">1</td>
                <td className="text-center lightgreen p-3">
                  DVR Report
                  {/* <span style={{ color: "red" }}>*</span> */}
                </td>

                <td>
                  <a href={dvrName} target="_blank">
                    {dvr}
                  </a>
                </td>
              </tr>
              <tr>
                <td class="text-center">2</td>

                <td className="text-center lightgreen p-3">
                  PDD / MR
                  {/* <span style={{ color: "red" }}>*</span> */}
                </td>

                <td>
                  <a href={pddName}>{pdd}</a>
                </td>
              </tr>

              <tr>
                <td class="text-center">3</td>

                <td className="text-center lightgreen p-3">
                  ER
                  {/* <span style={{ color: "red" }}>*</span> */}
                </td>

                <td>
                  <a href={erName}>{er}</a>
                </td>
              </tr>

              <tr>
                <td class="text-center">4</td>

                <td className="text-center lightgreen p-3">IRR</td>

                <td>
                  <a href={rrName}>{rr}</a>
                </td>
              </tr>

              <tr>
                <td class="text-center">5</td>

                <td className="text-center lightgreen p-3">CPA</td>

                <td>
                  <a href={cpaName}>{cpa}</a>
                </td>
              </tr>

              <tr>
                <td class="text-center">6</td>

                <td className="text-center lightgreen p-3">
                  Technical Review Sheet<span style={{ color: "red" }}>*</span>
                </td>

                <td>
                  <a href={technicalReviewSheetName}>{technicalReviewSheet}</a>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="row">
            <div className="col-5 my-3">
              <label
                htmlFor="Member"
                className="form-label lightgreen mb-4 fs-6"
              >
                Send To
                <span style={{ color: "red" }}>*</span>
              </label>

              <select
                id="member"
                className="form-select borderlightgreen form-select-sm"
                aria-label="Default select example"
                name="status"
                value={member}
                disabled={true}
                onChange={(e) => {
                  setMember(e.target.value);
                }}
              >
                <option value="">Select</option>
                <option value={3}>Forward to TL</option>
                <option value={6}>Sent to TR</option>
                <option value={4}>Reply to Client</option>
                <option value={8}>TR Approved</option>
              </select>
            </div>
          </div>

          {atremark && (
            <div>
              <p className="lightgreen fw-bold my-3 fs-6">
                Team Member Remarks<span style={{ color: "red" }}>*</span>
              </p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={atremark}
                  name="remarks"
                  readOnly={true}
                  dangerouslySetInnerHTML={{ __html: atremark }}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
          )}

          {tlremark && (
            <div>
              <p className="lightgreen fw-bold my-3 fs-6">
                Team Leader Remarks
              </p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={tlremark}
                  name="remarks"
                  readOnly={true}
                  dangerouslySetInnerHTML={{ __html: tlremark }}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
          )}

          {remarkTR && (
            <div className="mt-3">
              <p className="lightgreen fw-bold my-3 fs-6">
                Technical Reviewer Remarks
              </p>
              <hr className="mb-3 lightgreen" />
              {trStatus === 7 || trStatus === 8 ? (
                <div className="row mt-4">
                  <span>
                    {trStatus === 8 ? (
                      <Alert
                        className="col-12"
                        message="Approved"
                        type="success"
                        showIcon
                      />
                    ) : trStatus === 7 ? (
                      <Alert
                        className="col-12"
                        message="Clarification Required"
                        banner
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              ) : (
                ""
              )}
              <div className="row mt-4">
                <div className="col-12">
                  <ReactQuill
                    theme="snow"
                    value={remarkTR}
                    name="remarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: remarkTR }}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            </div>
          )}

          {remarkClient && (
            <div>
              <p className="lightgreen fw-bold my-3 fs-6">Client Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkClient}
                  name="remarks"
                  readOnly={true}
                  dangerouslySetInnerHTML={{ __html: remarkClient }}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
          )}

          {loading && <div className="loader"></div>}
        </form>
      ) : (
        ""
      )}
    </>
  );
};

export default ATTrRound;
