import React, { useEffect, useState } from 'react';
import axios from "axios";
import { toast } from "react-toastify";
import { API_HEADER, autologin_url, getDashboardData, mail_reminder_url } from '../config';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

const MultiRoleBased = () => {
  const [roleData, setRolesData] = useState([]);

  const navigate = useNavigate();

  const getDashboard = async () => {
    try {
      const result = await axios.get(`${getDashboardData}`, API_HEADER);
      const dashboard = result.data.roles;

      setRolesData(dashboard);

    } catch (error) {
    }
  };


  const loginRoleWise = async (id) => {
    
    try {

        let payload={
            id:id
        }

        const result = await axios.post(autologin_url, payload,API_HEADER);
        
        if(result.status===200 && result.data.status===true){
            
            toast.success('Login Successfully');
            const mail_reminder = await axios.get(`${mail_reminder_url}`);
            localStorage.setItem('mail_reminder', JSON.stringify(mail_reminder.data.data));
            localStorage.setItem("token", result.data.user.token);
            localStorage.setItem("designation_id", Number(result.data.user.designation_id));
            localStorage.setItem("name", result.data.user.name);
            localStorage.setItem('user', JSON.stringify(result.data.user));
            localStorage.setItem("multi_user", result.data.user.multi_user);

            navigate('/dashboard')
            window.location.reload()

        }else{

            toast.error(result.data.error);

        }

        
    } catch (error) {
        toast.error('Invalid User');
    }

  };

  useEffect(() => {
    getDashboard();
  }, []);

  return (
    <>
      <div className="row">

        <h4>Assigned Roles : </h4>
        {roleData && roleData.length > 0 ? (
        roleData.map((item) => (
          <div className="col-sm-3 mt-4 " key={item.id} >
            <div className="card  w-100 card-hover" onClick={() => loginRoleWise(item.id)} style={{cursor:'pointer'}}>
              <div className="card-body d-flex flex-column justify-content-between align-items-center w-100">
                <h5 className="card-title ">{item.description}</h5>
                <div className="mt-auto d-flex justify-content-end">
                 
                </div>
              </div>
            </div>
          </div>
        ))
      ):''
      }
      </div>
    </>
  );
};

export default MultiRoleBased;

