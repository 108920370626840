// import React ,{ useState, useEffect} from "react";
// import  Chart  from "react-apexcharts";
// import { API_HEADER ,contract_signed_monthly,client_contract_signed_graph_url} from "../../../config";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// function ClientContractGraph()
// {
//     const navigate = useNavigate();

//    const [proposalSigned, setProposalSigned]= useState([]);
//    const [proposalSignedValue, setProposalSignedValue]= useState([]);
//    const [createdMonth, setCreatedMonth]= useState([]);
//    const [createdYear, setCreatedYear] = useState([]);
//    const [regionWiseData, setRegionWiseData] = useState([]);

//    useEffect( ()=>{
//        const proposalsigned=[];
//        const proposalsignedvalue=[];
//        const createdmonth=[];
//        const YearCreated = [];

//        const getProposalScopeWise= async()=>{
//        const payload = { "fy": "" };
//        const reqData= await axios.post(`${client_contract_signed_graph_url}`, payload,API_HEADER);
//        const MyprogramwiseData = reqData.data.contract_monthly
       
//        for(let i=0; i< MyprogramwiseData.length; i++)
//        {        
//         proposalsigned.push(((MyprogramwiseData[i].contract_signed_count)));
//         proposalsignedvalue.push(((MyprogramwiseData[i].rfi_registrar_count)));
//         const dateString = MyprogramwiseData[i].month_year_created;
//         const [month, year] = dateString.split('-');
//         const date = new Date(year, parseInt(month) - 1);
//         const formattedMonth = date.toLocaleString('en-US', { month: 'long' });
//         const formattedYear = date.getFullYear();
//         createdmonth.push(formattedMonth);
//         YearCreated.push(formattedYear)
//        }
       
//        setProposalSigned(proposalsigned);
//        setProposalSignedValue(proposalsignedvalue);
//        setCreatedMonth(createdmonth);
//        setCreatedYear(YearCreated)
//        setRegionWiseData(MyprogramwiseData);

//        }

//        getProposalScopeWise();

//    },[]);

//     const handleChartClick = (event, chartContext, { seriesIndex, dataPointIndex, w }) => {
//     const contract_signed_status = w.globals.seriesNames[seriesIndex];

//     const seriesName = w.globals.labels[dataPointIndex];

//     const contract_signed_month = w.globals.labels[dataPointIndex];

//     if (contract_signed_month) {
//         if(contract_signed_status=='Project Delivered'){
//             navigate('/programwiseprojecttable', { state: { title:contract_signed_status,contract_signed_status, contract_signed_month } });
//         }else{
//             navigate('/proposaltable', { state: { title:contract_signed_status,contract_signed_status, contract_signed_month } });
//         }
//     }
// };

//    return(
//     <React.Fragment>
//         <div className="container-fluid">
           
//             <Chart
//             type="bar"
//             width={"100%"}
//             height={400}
//             series={[
//                 {
//                     name:"Contracts Signed",
//                     data: proposalSigned,
//                     color: '#182b55'
//                 },
//                 {
//                     name:"Delivered",
//                     data: proposalSignedValue,
//                     color: '#900c3f'
//                 },
                
//             ]}

//             options={{
//                 // title:{
//                 //     text:"Proposal Month Wise"
//                 // },
//                 chart:{
//                     toolbar: {
//                         show: false,  // This will hide the icons on the top right corner
//                       },
//                     stacked:false,
//                     events: {
//                         dataPointSelection: handleChartClick,
//                     },
//                 },
//                 plotOptions:{
//                     bar:{
//                         horizontal:false,
//                         columnWidth:'40%',
//                         dataLabels : {
//                             total: {
//                                 enabled: true,
//                                 style: {
//                                   fontSize: '11px',
//                                   fontWeight: 900
//                                 },
                                
//                               }
//                           }
//                       }
//                 },
//                 stroke: {
//                     width: 1,
//                 },
//                 xaxis:{
//                     title:{
//                         text: `Month`,
//                         style: {
//                             fontSize: '16px',
//                             fontWeight: 'bold'
//                         }
//                     },
//                     categories : createdMonth
//                 },
//                 yaxis:{
//                     title:{
//                         text:"Project Count",
//                         style:{fontSize:'16px',fontWeight:'bold'}
//                     },
//                     labels:{
//                         style: { fontSize: "15"},

//                     }
//                 },
//                 legend:{
//                     position: 'bottom',
//                 },
//                 dataLabels:{
//                     enabled:false,
//                 },
//                 grid: {
//                     show:true,
//                     xaxis:{
//                         lines:{
//                             show:false
//                         }
//                     },
//                     yaxis:{
//                         lines:{
//                             show:false
//                         }
//                     }

//                 },
//                 grid: {
//                     borderColor: '#f1f1f1',
//                     row: {
//                       colors: ['transparent'], 
//                       opacity: 1,
      
//                     },
//                   },

//             }}

//             />
//         </div>
//     </React.Fragment>
// );
// }
// export default ClientContractGraph;


import React from "react";


function ClientContractGraph({ startDate, endDate }) {
  const grafanaBaseUrl = "https://grafana1.intileotech.xyz:3000/d-solo/eebqpgmaf99mof/client-dashboard";
  const orgId = 1;
  const timezone = "browser";
  // const panelId = 4;
  const panelId = 7;
  const theme = "light";
 


    const role = localStorage.getItem("designation_id");
    console.log("roleee",role)
    const userData = localStorage.getItem("user");
    const userId = JSON.parse(userData);
  
    // Access the 'id' field
    const client_Id = userId.client_id;
    console.log("Extracted ID:", client_Id);


  // Convert start and end date to UNIX timestamps (milliseconds)
  const from = startDate ? startDate.format('YYYY-MM-DD') : '2024-04-01' 
  const to = endDate ? endDate.format('YYYY-MM-DD') : '2025-03-31' 


// const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&from=${from}&to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-user_id=${user_id}`;


const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-client_id=${client_Id}`;


  console.log("from",from,"to",to)

  // Construct Grafana iframe URL dynamically
  // const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&var-from=${from}&var-to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}`;

  return (
    <div className="container-fluid">
      <iframe
        src={grafanaUrl}
        width="100%"
        height="400"
        frameBorder="0"
        title="Program Wise Projects"
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
}

export default ClientContractGraph;






