import { Col, Form, Input, Modal, Row, Select, Table, Tag } from "antd";
import { Tooltip as Tip } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { json, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/bootstrap.css";
import ViewClient from "./ViewClient";
import {
  API_HEADER,
  getAllClients,
  getCountryList,
  get_client_name_url,
  get_regions_url,
  getClientDetails,
  saveClient,
} from "../../config";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import Header from "../../Pages/Header";
import SideNavbar from "../SideNavbar";
import Dashboard from "../../Pages/Dashboard";
import Footer from "../Footer";
const { Option } = Select;

export default function Clients() {
  const [clientform] = Form.useForm();
  const [continent_list, setContinentList] = useState([]);
  const [region, setRegion] = useState([]);
  const [country_list, setCountrysList] = useState([]);
  let [client_id, SetClientId] = useState(null);

  let [modalVisible, SetModalVisible] = useState(false);
  let [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [designation_id, SetDesignation] = useState(0);

  const [formdisable, SetFormDisabled] = useState(false);
  const [formEditView, SetFormEditView] = useState(formdisable);

  const navigate = useNavigate();

  const openClientEdit = async (id, mode) => {
    SetFormDisabled(true)
    navigate(`/viewclient`, {state:{id:id,mode:mode}});
  };

  let [clientData, setClientData] = useState([]);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  let [clientLoad, SetClientLoad] = useState(false);

  const handleRegion = (value) => {
    setRegion(value);
    getCountry(value);
  };

  const clientFormSubmit = (values) => {
    clientform
      .validateFields()
      .then((values) => {
        const requestData = {
          ...values,
          client_id,
          contact_mobile: phone,
          mobile_number: mobile,
        };

        axios
          .post(`${saveClient}`, requestData, API_HEADER)
          .then((result) => {
            if (result.status === 200 && result.data.status === true) {
              if (client_id === null) {
                toast.success("Client Added Successfully!");
              } else {
                toast.success("Client Details Updated Successfully!");
              }

              clientform.resetFields();
              SetModalVisible(false);
              SetClientLoad(true);
              SetClientId(null);
              setPhone(null);
              setMobile(null);
              navigate("/dashboard");
            } else {
              toast.error(result.data.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      })
      .catch((errorInfo) => {
      });
  };

  const getClientTabData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${getAllClients}`, {
        params: {
          page: pagination.current,
          limit: pagination.pageSize,
          country: country ? country : null,
          client_id: client_id ? client_id : null,
          region: region ? region : null,
        },
        ...API_HEADER,
      });
      setClientData(response.data.data);

      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response.data.count,
      }));

      setLoading(false);
      SetClientLoad(false);
    } catch (error) {
    }
  };

  const getContinent = async () => {
    try {
      const result = await axios.get(`${get_regions_url}`);
      setContinentList(result.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getClientTabData();
  }, [clientLoad]);

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    SetClientLoad(true);
    getClientTabData();
  };

  const [country, setCountry] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [clientname, setClientname] = useState([]);

  const getCountry = async (value) => {
    try {
      const result = await axios.get(`${getCountryList}?region_id=${value}`);
      setCountryList(result.data.data);
      setCountrysList(result.data.data);
    } catch (error) {}
  };
  const getRegion = async () => {
    try {
      const result = await axios.get(`${get_regions_url}`);
      setRegionList(result.data.data);
    } catch (error) {}
  };

  const getClientname = async () => {
    try {
      const result = await axios.get(`${get_client_name_url}`);
      setClientname(result.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getClientname();
    getRegion();
  }, []);
  const handleClientNameSearch = (value) => {
    SetClientLoad(true);
    SetClientId(value);
  };

  const handleCountrySearch = (value) => {
    setCountry(value);
    SetClientLoad(true);
  };

  const handleRegionSearch = (value) => {
    setRegion(value);
    setCountry("");
    SetClientLoad(true);
    getCountry(value);
  };

  let [formtitle, setFormTitle] = useState("");

  const openClientAdd = () => {
    navigate('/addclient')
  };

  const handleChange = (value) => {
    const phoneNumber = value.replace(/\D/g, ""); 
    if (phoneNumber.length <= 16) {
      setPhone(value);
      setPhoneError("");
    } else {
      setPhoneError("Phone number must be up to 13 digits.");
    }
  };

  const handleBlur = () => {
    const phoneNumber = phone.replace(/\D/g, ""); 
    if (phoneNumber.length > 16) {
      setPhoneError("Phone number must be up to 13 digits.");
    } else if (!/^\d+$/.test(phoneNumber)) {
      setPhoneError("Phone number must be numeric.");
    } else {
      setPhoneError("");
    }
  };

  const handleMobileChange = (value) => {
    const mobileNumber = value.replace(/\D/g, ""); 
    if (mobileNumber.length <= 16) {
      setMobile(value);
      setMobileError("");
    } else {
      setMobileError("Phone number must be up to 13 digits.");
    }
  };

  const handleMobileBlur = () => {
    const mobileNumber = mobile.replace(/\D/g, ""); 
    if (mobileNumber.length > 16) {
      setMobileError("Phone number must be up to 13 digits.");
    } else if (!/^\d+$/.test(mobileNumber)) {
      setMobileError("Phone number must be numeric.");
    } else {
      setMobileError("");
    }
  };

  useEffect(function () {
    const id = localStorage.getItem("designation_id");
    SetDesignation(parseInt(id));
  }, []);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const columnsClientListing = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (text, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,

      render: (text, record) => {
        return (
          <Tip title={record.name}>
            <span className="textcolorgreen font14px preserve-space text-ellipsis">
              {record.name}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.name > record2.name
          ? 1
          : record1.name === record2.name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Email Id
        </span>
      ),
      width: 140,
      render: (text, record) => {
        return (
          <Tip title={record.email}>
            <span className="textcolorgreen font14px preserve-space text-ellipsis">
              {record.email}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.contact_person > record2.email
          ? 1
          : record1.contact_person === record2.email
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Region
        </span>
      ),
      width: 130,

      render: (text, record) => {
        if (record.region) {
          return (
            <span className="text-capitalize textcolorgreen font14px ">
              {record.region}
            </span>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return record1.region > record2.region
          ? 1
          : record1.region === record2.region
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 80,

      render: (text, record) => {
        return (
      
          <Tip title={record.country}>
            <span className="text-capitalize font14px text-ellipsis">
              {record.country}
            </span>
          </Tip>
        );

      },
      sorter: (record1, record2) => {
        return record1.country > record2.country
          ? 1
          : record1.country === record2.country
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Status
        </span>
      ),
      width: 140,

      render: (text, record) => {
        let color = record.status === 1 ? "green" : "volcano";
        return (
          <Tag className="px-4 py-2 rounded-5 font16px" color={color}>
            {record.status_msg}
          </Tag>
        );
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <a className="">
          {designation_id == 6 ? (
            <>
              <EditOutlined
                style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
                onClick={() => openClientEdit(record.id, 1)}
              />
              <EyeOutlined
                style={{ color: "red", fontSize: "20px" }}
                onClick={() => openClientEdit(record.id, 2)}
              />
            </>
          ) : (
            <EyeOutlined
              style={{ color: "red", fontSize: "20px" }}
              onClick={() => openClientEdit(record.id, 2)}
            />
          )}
        </a>
      ),
    },
  ];

  return (
    <>
      <Header />
      <SideNavbar />
      <div className="content-wrapper bg-white">
        <div className="content">
          <div className="container-fluid textblack">
            <div
              className="row"
              style={{
                justifyContent: "center",
              }}
            >
            
              <div>
                <div className="mx-3 my-4 border-2 border border-light-subtle p-0 rounded-3 mt-5">
                  <div className="row border-0 mb-3">
                    <div className="col-12  ">
                      <div className="d-flex justify-content-between  text-black text-capitalize  font20px fw-bold p-3 rounded-top-3">
                        <p>All Clients</p>
                        {designation_id == 6 ? (
                          <button
                            className="btn border-0  text-white bg_green"
                            style={{
                              fontSize: "16px",
                              backgroundColor: "#52BE80",
                            }}
                            onClick={openClientAdd}
                          >
                            Add Client
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-around">
                    <div className="col-sm-4 col-md-4 col-lg-4">
                      <div className="d-grid mb-3 mx-3">
                        <label className="text-capitalize textcolumntitle font14px fw-bold">
                          Client Name
                        </label>
                        <Select
                           showSearch
                          allowClear
                          placeholder="Select Client Name"
                          filterOption={filterOption}
                          onChange={handleClientNameSearch}
                          style={{ width: "100%" }}
                          className="rounded-2"
                        >
                          <Option value="">Select</Option>
                          {clientname.map((client, index) => (
                            <Option
                              key={index}
                              value={client.id}
                              label={client.name}
                            >
                              {client.name}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>

                    <div className="col-sm-4 col-md-4 col-lg-4">
                      <div className="d-grid mb-3 mx-3">
                        <label className="text-capitalize textcolumntitle font14px fw-bold">
                          Region
                        </label>
                        <Select
                          allowClear
                          placeholder="Select region"
                          onChange={handleRegionSearch}
                          style={{ width: "100%" }}
                          className="rounded-2"
                        >
                          <Option value="">Select</Option>
                          {regionList.map((region, index) => (
                            <Option
                              key={index}
                              value={region.id}
                              label={region.name}
                            >
                              {region.name}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>

                    <div className="col-sm-4 col-md-4 col-lg-4">
                      <div className="d-grid mb-3 mx-3">
                        <label className="text-capitalize textcolumntitle font14px fw-bold">
                          Country
                        </label>
                        <Select
                          allowClear
                          placeholder="Select country"
                          onChange={handleCountrySearch}
                          style={{ width: "100%" }}
                          className="rounded-2"
                        >
                          <Option value="">Select</Option>
                          {countryList.map((country, index) => (
                            <Option
                              key={index}
                              value={country.id}
                              label={country.name}
                            >
                              {country.name}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>

                  <div className="row my-3 mx-3">
                    <Table
                      className="border-1 border ronded-0 border-subtle-light px-0"
                      scroll={{
                        x: 1000,
                      }}
                      columns={columnsClientListing}
                      loading={loading}
                      dataSource={clientData}
                      rowKey="id"
                      pagination={pagination}
                      onChange={handleTableChange}
                      bordered
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
