import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_DOCUMENT, site_visit_completed } from "../config";
import { get_project_details_url } from "../config";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Alert } from "antd";
import {
    API_HEADER,

} from "../config";



const fileTypes = [
    "JPG",
    "JPEG",
    "PDF",
    "RAR",
    "XLS",
    "XLSX",
    "DOC",
    "DOCX",
    "ZIP",
    "XLSM"
];

const ATSiteVisitCompleted = (props) => {


    const { id } = useParams();
    const { isTL } = props;

    const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
    const dynamicMaxSize = my_file_maxSize.max_document_size


    const [remarkTL, setRemarkTL] = useState("")
    const [action, setAction] = useState("");
    const [error, setError] = useState('')
    const [program, setProgram] = useState([]);
    const [projectStatus, setProjectStatus] = useState('')
    const [file4, setFile4] = useState([]);
    const [file5, setFile5] = useState([]);
    const [siteVisitStatus, setSiteVisitStatus] = useState("");
    
    const [fileAttendenceSheetSizeError, setFileAttendenceSheetSizeError] = useState(false);
    const [SiteVisitChecklistSizeError, setSiteVisitChecklistSizeError] = useState(false);
    const [teamList, setTeamList] = useState([])
   
    const [attendenceSheetDocumentName, setAttendenceSheetDocumentName] = useState(null)
    const [attendenceSheetDocument, setAttendenceSheetDocument] = useState("")
    const [siteVisitChecklistName, setSiteVisitChecklistName] = useState(null)
    const [siteVisitChecklist, setSiteVisitChecklist] = useState("")
    const [loading, setLoading] = useState(false);
    const [siteVisitPhotoName, setSiteVisitPhotoName] = useState([]);
    const [siteVisitPhoto, setSiteVisitPhoto] = useState([]);

    const fetchData = async (ids) => {
        try {
            const response = await axios.get(
                `${get_project_details_url}/${ids}`,
                API_HEADER
            );
            const { record } = response.data;
            
            const getUniqueUsers = (teams) => {
                const uniqueUsers = [];
                const userIds = new Set();
            
                teams.forEach((team) => {
                    if (!userIds.has(team.user_id) && team.user_id !== null) {
                        userIds.add(team.user_id);
                        uniqueUsers.push(team);
                    }
                });
            
                return uniqueUsers;
            };
            
            const { teams } = response.data;
            
            const uniqueTeams = getUniqueUsers(teams);
            
            setTeamList(uniqueTeams);
            setProgram(record.program);
            setSiteVisitStatus(record.site_visit_status)
            setProjectStatus(record.project_status)
            setRemarkTL(record.sbu_tl_remarks)

            let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.attendence_sheet}`;
            setAttendenceSheetDocumentName(url4);
            setAttendenceSheetDocument(record.attendence_sheet);

            let url5 = `${BASE_DOCUMENT}/projects/${record.id}/${record.site_check_document}`;
            setSiteVisitChecklistName(url5);
            setSiteVisitChecklist(record.site_check_document);

            let otherDocsUrls = record.site_photographs.split(',').map(doc => `${BASE_DOCUMENT}/projects/${record.id}/${doc.trim()}`);

            console.log("otherDocsUrls", otherDocsUrls)
            let otherDocsNames = record.site_photographs.split(',');
            console.log("otherDocsNames", otherDocsNames)


            setSiteVisitPhotoName(otherDocsUrls);
            setSiteVisitPhoto(otherDocsNames);




        } catch (error) { }
    };

    useEffect(() => {
        fetchData(id);
    }, [id]);

    const combined = siteVisitPhoto?.map((name, index) => ({
        name: name,
        url: siteVisitPhotoName[index]
    }));


    const CONFIG_Token2 = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
        },
    };



    

    const handleFileAttendenceSheetChange = (file4) => {
        if (file4.size <= dynamicMaxSize * 1024 * 1024) {
            setFileAttendenceSheetSizeError(false);
        }

        setFileAttendenceSheetSizeError(false);
        setFile4(file4);
    };
    const handleAttendenceSheetSizeError = (file4) => {
        setFileAttendenceSheetSizeError(true);
    };


    const handleFileSiteVisitChecklistChange = (file5) => {
        if (file5.size <= dynamicMaxSize * 1024 * 1024) {
            setSiteVisitChecklistSizeError(false);
        }

        setSiteVisitChecklistSizeError(false);
        setFile5(file5);
    };
    const handleSiteVisitChecklistSizeError = (file5) => {
        setSiteVisitChecklistSizeError(true);
    };


    const handleRemarksChange = (content) => {
        setRemarkTL(content);
       
    };

   

    const navigate = useNavigate();

    const handleSubmitSiteVisit = async (event) => {
        
        event.preventDefault();
      
        if ((remarkTL == null || remarkTL == '<p><br></p>' || remarkTL == '') || (file4 == null ||  file4 == '') || (file5 ==null ||  file5 == '') ) {
            toast.error("Please upload the document and remarks.");
        }
        else {
        try {
            setLoading(true); // Show loader

            const formDataToSend = new FormData();

           
            formDataToSend.append("project_id", id);

       
            formDataToSend.append("attendence_sheet", file4);
            formDataToSend.append("site_check_document", file5);
            formDataToSend.append("sbu_tl_remarks", remarkTL);



            const response = await axios.post(
                `${site_visit_completed}`,
                formDataToSend,
                CONFIG_Token2
            );

            if (!response.data.status) {
                toast.error(response.data.message);
            } else if (
             
                fileAttendenceSheetSizeError ||
                SiteVisitChecklistSizeError

            ) {
                toast.error(`File size must below ${dynamicMaxSize}MB`);
            }
            else if (error) {
                toast.error("Invalid Dates")
            }
            else {
                toast.success("Document Submitted Successfully");
             
                setFile4(null);
                navigate("/dashboard");
            }
        } catch (error) {
            toast.error("Please fill all the mandatory fields");
        }finally {
            setLoading(false); // Hide loader
          }
        }
        
    };

    return (
        <>
     
                    <form
                        method="post"
                        onSubmit={handleSubmitSiteVisit}
                        encType="multipart/form-data"
                    >
                       

                        <table className="table table-bordered  table-hover">
                            <thead>
                                <tr>
                                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                                        S.No
                                    </th>
                                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                                        Document Name
                                    </th>

                                    <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">

                                        Uploaded Document
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                <tr>
                                    <td class="text-center">1</td>
                                   
                                            <td className="text-center lightgreen p-3">
                                                Attendence Sheet
                                                <span style={{ color: "red" }}>*</span>
                                            </td>
                                          
                                    <td>
                                        <a href={attendenceSheetDocumentName} target="_blank">{attendenceSheetDocument}</a>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-center">2</td>
                                  
                                          
                                                <td className="text-center lightgreen p-3">
                                                    Site Visit Checklist
                                                </td>
                                           
                                    <td>
                                        <a href={siteVisitChecklistName} target="_blank">{siteVisitChecklist}</a>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-center">3</td>


                                    <td className="text-center lightgreen p-3">
                                        Site Visit Photographs
                                    </td>

                                    <td>
                                        {combined.map((item) => (
                                            <div>
                                                <a target="_blank" href={item.url}>
                                                    {item.name}

                                                </a>
                                            </div>
                                        ))}
                                    </td>
                                </tr>

                            </tbody>
                        </table>



                        <div >
                            <div >
                                <p className="lightgreen fw-bold  fs-6">
                                    Team Leader Remarks
                                </p>
                                <hr className="mb-3 lightgreen" />
                                <div>
                                    <ReactQuill
                                        theme="snow"
                                        value={remarkTL}
                                        name="remarks"
                                        readOnly={true}
                                        dangerouslySetInnerHTML={{ __html: remarkTL }}
                                        
                                        style={{

                                            height: "20vh",
                                            marginBottom: "2rem",
                                        }}
                                        className="mb-5"
                                    />
                                </div>
                            </div>
                          
                       
                        </div>

                    </form>
                  
                  
            
        </>
    )
}

export default ATSiteVisitCompleted