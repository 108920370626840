import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import "../../src/App.css";
import FileUploaderComponent from "../utlis/FileUploaderComponent";
import LoaderComponent from "../utlis/LoderComponent";

import {
  get_project_details_url,
  API_HEADER,
  get_assesment_url,
  BASE_DOCUMENT,
  at_desk_review_url,
} from "../config";

const fileTypes = [
  "JPG",
  "JPEG",
  "PDF",
  "RAR",
  "XLS",
  "XLSX",
  "DOC",
  "DOCX",
  "ZIP",
  "XLSM",
];

export default function LODReview() {
  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size;

  const { id } = useParams();
  const navigate = useNavigate();
  const designation_id = localStorage.getItem("designation_id");
  const userdata = JSON.parse(localStorage.getItem("user"));

  const [rowCount, setRowCount] = useState(1);
  const [isTL, setIsTL] = useState(false);
  const [projectid, setProjectId] = useState(null);
  const [teamLeader, setTeamLeader] = useState("");
  const [isAT, setIsAT] = useState(false);
  const [formdisable, setFormDisable] = useState(false);
  const [fileTLSizeError, setFileTLSizeError] = useState(false);
  const [file, setFile] = useState(null);
  const [status, SetStatus] = useState(null);
  const [remark, setRemark] = useState("");
  const [lodStatus, setLodStatus] = useState(null);
  const [clientRemarks, setClientRemarks] = useState("");
  const [atRemarks, setATRemarks] = useState("");
  const [tlRemarks, setTLRemarks] = useState("");

  const [f23, setF23] = useState("");
  const [pddName, setPddName] = useState(null);
  const [pdd, setPdd] = useState("");
  const [erName, setErName] = useState(null);
  const [lod, setlod] = useState("");
  const [lodName, setlodName] = useState(null);
  const [er, setEr] = useState("");
  const [mrName, setMrName] = useState(null);
  const [mr, setMr] = useState("");
  const [dynamicname, setDynamicName] = useState([]);
  const [dynamic, setDynamic] = useState([]);
  const [irrName, setirrName] = useState(null);
  const [irr, setIrr] = useState("");

  const [otherName, setOtherName] = useState(null);
  const [other, setOther] = useState("");
  const [cpaName, setcpaName] = useState(null);
  const [cpa, setCpa] = useState("");
  const [f23name, setF23Name] = useState(null);
  const [member, setMember] = useState();
  const [scope, setScope] = useState("");

  const [formData, setFormData] = useState({ evidence_name: [] });

  const [loading, setLoading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_project_details_url}/${ids}`,
        API_HEADER
      );

      if (response && response.data && response.data.record) {
        const { record } = response.data;
        setProjectId(record.id);
        setLodStatus(record.status);
        setClientRemarks(record.client_remarks);
        setTLRemarks(
          record.status == 6 || record.status == 2 || record.status == 3
            ? (record.lod_tl_remarks = "")
            : record.lod_tl_remarks
        );
        setATRemarks(record.lod_at_remarks);

        setMember(record.status);
        setScope(record.scope_name.sector_name);
        setFile(record.lod_documents);

        const responseteamleader = await axios.get(
          `${get_assesment_url}?program_id=${record.program}`
        );

        setTeamLeader(record.team_leader);
        setFormData({ evidence_name: record.evidences || [] });

        const isTeamLeader = record.team.some((item) => item.role_id == 1);
        setIsTL(isTeamLeader);

        const isTeamMember = record.team.some((item) => item.role_id > 1);
        setIsAT(isTeamMember);

        setRowCount(
          record.evidences
            ? record.evidences.length == 0
              ? 1
              : record.evidences.length
            : 1
        );

        setFormDisable(true);

        let url0 = `${BASE_DOCUMENT}/projects/${record.id}/${record.lod_documents}`;

        setlodName(url0);
        setlod(record.lod_documents);

        let url3 = `${BASE_DOCUMENT}/documents/${
          record.earthood_id.split(" ")[0]
        }/${record.f23_doc}`;

        setF23Name(url3);
        setF23(record.f23_doc);

        let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.pdd_documents}`;

        setPddName(url4);
        setPdd(record.pdd_documents);

        let url5 = `${BASE_DOCUMENT}/projects/${record.id}/${record.er_documents}`;

        setErName(url5);
        setEr(record.er_documents);

        let url6 = `${BASE_DOCUMENT}/projects/${record.id}/${record.mr_documents}`;

        setMrName(url6);
        setMr(record.mr_documents);

        let url7 = `${BASE_DOCUMENT}/projects/${record.id}/${record.irr_documents}`;
        setirrName(url7);
        setIrr(record.irr_documents);

        let url8 = `${BASE_DOCUMENT}/projects/${record.id}/${record.cpa_documents}`;
        setcpaName(url8);
        setCpa(record.cpa_documents);

        let url9 = `${BASE_DOCUMENT}/projects/${record.id}/${record.other_documents}`;
        setOtherName(url9);
        setOther(record.other_documents);

        const dynamicUrl = record.evidences.map(
          (evidence) =>
            `${BASE_DOCUMENT}/projects/${record.id}/${evidence.name}`
        );
        setDynamicName(dynamicUrl);
        const dName = record.evidences.map((evidence) => evidence.name);
        setDynamic(dName);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setPercent(percentCompleted);
    },
  };

  const handleRemarksChange = (content) => {
    setTLRemarks(content);
  };

  const handleSubmitTL = async (e) => {
    e.preventDefault();
    setIsSubmitting(false);

    if (
      tlRemarks == null ||
      tlRemarks == "<p><br></p>" ||
      tlRemarks == "" 
      // ||  file == null
      
    ) {
      toast.error("Please upload the document and remarks. ");
    } else {
      try {
        // setLoading(true); // Show loader
        setIsSubmitting(true);

        const formData = new FormData();
        formData.append("project_id", projectid);
        formData.append("project_status", 2);
        formData.append("status", member);
        formData.append("lod_documents", file);
        formData.append("lod_tl_remarks", tlRemarks);

        const response = await axios.post(
          `${at_desk_review_url}`,
          formData,
          CONFIG_Token2
        );

        if (!response.data.status) {
          toast.error(response.data.message);
        } else if (fileTLSizeError) {
          toast.error(`File size must be below ${dynamicMaxSize}`);
        } else {
          toast.success("Remarks Submitted Successfully");
          setTLRemarks("");

          setFile(null);
          setMember("");
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("Documents and Remarks are mandatory");
      } finally {
        // setLoading(false); // Hide loader
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div>
      <table className="table table-bordered table-hover table-responsive-sm">
        <thead>
          <tr>
            <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
              S.No
            </th>
            <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
              Document Name
            </th>
            <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
              Uploaded Document
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center">1</td>
            <td className="text-center lightgreen p-3">
              PDD Document
              {scope == "VAL" ? <span style={{ color: "red" }}>*</span> : ""}
            </td>
            <td>
              <a target="_blank" href={pddName}>
                {pdd}
              </a>
            </td>
          </tr>
          <tr>
            <td class="text-center">2</td>

            <td className="text-center lightgreen p-3">
              ER Document
              {scope == "VER" || scope == "VAL" ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                ""
              )}
            </td>

            <td>
              <a target="_blank" href={erName}>
                {er}
              </a>
            </td>
          </tr>
          <tr>
            <td class="text-center">3</td>

            <td className="text-center lightgreen p-3">
              MR Document
              {scope == "VER" ? <span style={{ color: "red" }}>*</span> : ""}
            </td>

            <td>
              <a target="_blank" href={mrName}>
                {mr}
              </a>
            </td>
          </tr>

          <tr>
            <td class="text-center">4</td>

            <td className="text-center lightgreen p-3">CPA Document</td>

            <td>
              <a target="_blank" href={cpaName}>
                {cpa}
              </a>
            </td>
          </tr>

          <tr>
            <td class="text-center">5</td>

            <td className="text-center lightgreen p-3">IRR Document</td>

            <td>
              <a target="_blank" href={irrName}>
                {irr}
              </a>
            </td>
          </tr>

          <tr>
            <td class="text-center">6</td>

            <td className="text-center lightgreen p-3">Other Document</td>

            <td>
              <a target="_blank" href={otherName}>
                {other}
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <table className="table table-bordered my-4 table-hover table-responsive-sm">
        <thead>
          <tr>
            <th className="text-center lightgreen fw-bolder fs-6 table-heading-color">
              S.No.
            </th>
            <th className="form-label lightgreen fs-6 text-center table-heading-color">
              Evidence Name
            </th>
            <th className="form-label lightgreen fs-6 text-center table-heading-color">
              Uploaded Documents
            </th>
          </tr>
        </thead>
        <tbody>
          {[...Array(rowCount)].map((_, index) => (
            <tr key={index}>
              <td class="text-center">{index + 1}</td>
              <td>
                <input
                  type="text"
                  name="evidence"
                  className="form-control"
                  disabled={true}
                  placeholder=""
                  required
                  value={
                    formData.evidence_name[index]
                      ? formData.evidence_name[index].remarks
                      : ""
                  }
                />
              </td>

              <td>
                <a target="_blank" href={dynamicname[index]}>
                  {dynamic[index]}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {lodStatus === null || lodStatus === 2 || lodStatus === 6 ? (
        <>
          <LoaderComponent isSubmitting={isSubmitting} percent={percent} />

          <form
            method="post"
            onSubmit={handleSubmitTL}
            encType="multipart/form-data"
          >
            <div>
              <p className="textlightgreen fw-bold mb-3 fs-5">
                Document and Remarks
              </p>
              <hr className="mb-3 lightgreen" />

              <div className="row " style={{ width: "80vw" }}>
                <div className="col-3 my-4">
                  <label
                    htmlFor="Member"
                    className="form-label lightgreen mb-4 fs-6"
                  >
                    Send To
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="member"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="status"
                    value={member}
                    onChange={(e) => {
                      setMember(e.target.value);
                    }}
                    required
                  >
                    <option value="">Select</option>

                    <option value={4}>Reply to Client</option>
                    <option value={5}>Send to Team Members</option>
                    <option value={7}>LOD Closed</option>
                  </select>
                </div>

                <div className="col-4 my-4">
                  <div>
                    {lodStatus === 6 ? (
                      <label
                        className="form-label lightgreen fs-6"
                        htmlFor="remarks"
                      >
                        LOD Document
                      </label>
                    ) : (
                      <label
                        className="form-label lightgreen fs-6"
                        htmlFor="remarks"
                      >
                        LOD Document 
                      </label>
                    )}

                    {lodStatus === 6 ? (
                      <FileUploaderComponent
                        dynamicMaxSize={dynamicMaxSize}
                        file={file}
                        setFile={setFile}
                      />
                    ) : (
                      <FileUploaderComponent
                        dynamicMaxSize={dynamicMaxSize}
                        file={file}
                        setFile={setFile}
                      />
                    )}
                    {/* </div> */}
                  </div>
                </div>
                <div className="col-4 my-4 mt-10">
                  <div style={{ marginTop: "30px" }}>
                    {lodStatus == 6 && (
                      <span>
                        <a target="_blank" href={lodName}>
                          {lod}
                        </a>
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <label className="form-label lightgreen fs-6 mb-2">
                    Team Leader Remarks <span style={{ color: "red" }}>*</span>
                  </label>

                  <ReactQuill
                    theme="snow"
                    value={tlRemarks}
                    name="assessment_remarks"
                    onChange={handleRemarksChange}
                    style={{
                      // width: "68vw",
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>

              {/* {lodStatus == 6 || lodStatus == 2 ? */}
              <div className="row">
                <div className="col-12 mb-3">
                  <label className="form-label lightgreen fs-6 mb-2">
                    Client Remarks{" "}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </label>

                  <ReactQuill
                    theme="snow"
                    value={clientRemarks}
                    name="client_remarks"
                    readOnly={true}
                    style={{
                      // width: "68vw",
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
              {/* :
                    ''
                            } */}

              {atRemarks && (
                <div className="row">
                  <div className="col-12 mb-3">
                    <label className="form-label lightgreen fs-6 mb-2">
                      Team Member Remarks{" "}
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </label>

                    <ReactQuill
                      theme="snow"
                      value={atRemarks}
                      name="at_remarks"
                      readOnly={true}
                      style={{
                        // width: "68vw",
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="container ">
              <div className="row">
                <div className="d-flex justify-content-end my-3 col-12 pr-0">
                  <button
                    className="btn  fs-5"
                    style={{
                      backgroundColor: "#07b6af",
                      color: "white",
                    }}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            {loading && <div className="loader"></div>}
          </form>
        </>
      ) : isTL && lodStatus === 3 ? (
        <>
          <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
          <form
            method="post"
            onSubmit={handleSubmitTL}
            encType="multipart/form-data"
          >
            <div>
              <p className="textlightgreen fw-bold mb-3 fs-5">
                Document and Remarks
              </p>
              <hr className="mb-3 lightgreen" />

              <div className="row " style={{ width: "80vw" }}>
                <div className="col-3 my-4">
                  <label
                    htmlFor="Member"
                    className="form-label lightgreen mb-4 fs-6"
                  >
                    Send To
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="member"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="status"
                    onChange={(e) => {
                      setMember(e.target.value);
                    }}
                    style={{ width: "200px" }}
                    value={member}
                    required
                  >
                    <option value="">Select</option>

                    <option value={4}>Reply to Client</option>
                    <option value={5}>Send to Team Members</option>
                    <option value={7}>LOD Closed</option>
                  </select>
                </div>

                <div className="col-4 my-4 ">
                  <div>
                    {lodStatus === 5 ? (
                      <label
                        className="form-label lightgreen fs-6"
                        htmlFor="remarks"
                      >
                        LOD Document
                      </label>
                    ) : (
                      <label
                        className="form-label lightgreen fs-6"
                        htmlFor="remarks"
                      >
                        LOD Document 
                      </label>
                    )}

                    <div className="borderfileupload  mx-auto">
                      {lodStatus === 3 ? (
                        <FileUploaderComponent
                          dynamicMaxSize={dynamicMaxSize}
                          file={file}
                          setFile={setFile}
                        />
                      ) : (
                        <FileUploaderComponent
                          dynamicMaxSize={dynamicMaxSize}
                          file={file}
                          setFile={setFile}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-4 my-4 mt-10">
                  <div style={{ marginTop: "30px" }}>
                    {lodStatus > 2 && (
                      <span>
                        <a target="_blank" href={lodName}>
                          {lod}
                        </a>
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <label className="form-label lightgreen fs-6 mb-2">
                    Team Leader Remarks <span style={{ color: "red" }}>*</span>
                  </label>

                  <ReactQuill
                    theme="snow"
                    value={tlRemarks}
                    name="assessment_remarks"
                    onChange={handleRemarksChange}
                    style={{
                      // width: "68vw",
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>

              <div className="row mb-4">
                <div>
                  <label className="form-label lightgreen fs-6 mb-2">
                    Client Remarks{" "}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </label>

                  <ReactQuill
                    theme="snow"
                    value={clientRemarks}
                    name="client_remarks"
                    readOnly={true}
                    style={{
                      // width: "68vw",
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>

              {atRemarks && (
                <div className="row">
                  <div className="col-12 mb-3">
                    <label className="form-label lightgreen fs-6 mb-2">
                      Team Member Remarks{" "}
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </label>

                    <ReactQuill
                      theme="snow"
                      value={atRemarks}
                      name="at_remarks"
                      readOnly={true}
                      style={{
                        // width: "68vw",
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="container ">
              <div className="row">
                <div className="d-flex justify-content-end my-3 col-12 pr-0">
                  <button
                    className="btn  fs-5"
                    style={{
                      backgroundColor: "#07b6af",
                      color: "white",
                    }}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            {loading && <div className="loader"></div>}
          </form>
        </>
      ) : (
        <form
          method="post"
          onSubmit={handleSubmitTL}
          encType="multipart/form-data"
        >
          <div>
            <p className="textlightgreen fw-bold  fs-5">Document and Remarks</p>
            <hr className=" lightgreen" />
            <div className="row  " style={{ width: "80vw" }}>
              <div className="col-4">
                <label
                  htmlFor="Member"
                  className="form-label lightgreen mb-3 fs-6"
                >
                  Send To
                  <span style={{ color: "red" }}>*</span>
                </label>

                <select
                  id="member"
                  className="form-select borderlightgreen form-select-sm"
                  aria-label="Default select example"
                  name="status"
                  value={member}
                  disabled
                  // onChange={(e) => {
                  //   setMember(e.target.value);
                  // }}
                >
                  <option value={4}>Reply to Client</option>
                  <option value={5}>Send to Team Members</option>
                  <option value={7}>LOD Closed</option>
                </select>
              </div>

              <div
                className="col-8  "
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <label className="form-label lightgreen fs-6 ">
                  LOD Document
                </label>

                <div>
                  <a target="_blank" href={lodName}>
                    {lod}
                  </a>
                </div>
              </div>
            </div>

            {tlRemarks && (
              <div className="row my-4">
                <div>
                  <label className="form-label lightgreen fs-6 mb-3">
                    Team Leader Remarks <span style={{ color: "red" }}>*</span>
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={tlRemarks}
                    name="remarks"
                    readOnly={true}
                    // dangerouslySetInnerHTML={{ __html: remark }}
                    onChange={handleRemarksChange}
                    style={{
                      // width: "68vw",
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            )}

            <div className="row mt-4">
              <div>
                <label className="form-label lightgreen fs-6 mb-3">
                  Client Remarks
                </label>
                <ReactQuill
                  theme="snow"
                  value={clientRemarks}
                  name="remarks"
                  readOnly={true}
                  style={{
                    // width: "68vw",
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>

            {atRemarks && (
              <div className="row">
                <div>
                  <label className="form-label lightgreen fs-6 mb-2">
                    Team Member Remarks{" "}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={atRemarks}
                    name="at_remarks"
                    readOnly={true}
                    style={{
                      // width: "68vw",
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            )}
          </div>
          {loading && <div className="loader"></div>}
        </form>
      )}
    </div>
  );
}
