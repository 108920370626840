// import React ,{ useState, useEffect} from "react";
// import  Chart  from "react-apexcharts";
// import { API_HEADER,workload_stage_wise_url } from "../../../config";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// function ATTLWorkloadGraph({search,startDate,endDate})
// {
//     const navigate = useNavigate();

//    const [projectUndergoing, setProjectUndergoing]= useState([]);
//    const [projectCompleted, setProjectCompleted]= useState([]);
//    const [tlName, setTLName] = useState([]);
//    const [programWiseData, setProgramWiseData] = useState([]);
//    const [projectRFI, setprojectRFI]= useState([]);
//    const [projectKickout, setprojectKickout]= useState([]);

//    useEffect( ()=>{
//        const projectundergoing=[];
//        const projectcompleted=[];
//        const tlname=[];
//        const projectrfi=[];
//        const projectKickout=[];

//        const getProposalScopeWise= async()=>{
        
//         const payload = { 
//             "fy": "",
//             'start_date': startDate ? startDate.format('YYYY-MM-DD') : null,
//             'end_date': endDate ? endDate.format('YYYY-MM-DD') : null,
//         };

       
//        const reqData= await axios.post(`${workload_stage_wise_url}`, payload,API_HEADER);
//        const MyprogramwiseData = reqData.data.tl_workloads
       
//        for(let i=0; i< MyprogramwiseData.length; i++)
//        {        
//         projectundergoing.push(((MyprogramwiseData[i].project_undergoing)));
//         projectcompleted.push(((MyprogramwiseData[i].project_completed)));
//         tlname.push(((MyprogramwiseData[i].name)));      
//         projectrfi.push(((MyprogramwiseData[i].project_rfi)));
//         projectKickout.push(((MyprogramwiseData[i].project_kickout)));
//        }
       
//        setProjectUndergoing(projectundergoing);
//        setProjectCompleted(projectcompleted);
//        setTLName(tlname);
//        setProgramWiseData(MyprogramwiseData);
//        setprojectRFI(projectrfi);
//        setprojectKickout(projectKickout);
       
//        }

//        getProposalScopeWise();

//    },[search]);

//    const handleChartClick = (event, chartContext, { seriesIndex, dataPointIndex, w }) => {

//     const seriesName = w.globals.seriesNames[seriesIndex];

//     const programName = w.globals.labels[dataPointIndex];

//     const matchedProgram = programWiseData.find((program) => program.name === programName);

//     const team_leader = matchedProgram ? matchedProgram.team_leader : null;
 
//     const graph_type= 'TL Wise';

//     navigate('/programwiseprojecttable', { state: { startDate:startDate ? startDate.format('YYYY-MM-DD') : null,endDate:endDate ? endDate.format('YYYY-MM-DD') : null,graph_type, title:seriesName,workload_status:seriesName, team_leader, programName} });
    
//   };

//    return(
//     <React.Fragment>
//         <div className="container-fluid">
//         <Chart
//           type="bar"
//           width={"100%"}
//           height={400}
//           series={[
//     {
//       name: "Ongoing Projects",
//       data: projectUndergoing,
//       color: '#2776ae'
//     },
//     {
//       name: "RFI Completed",
//       data: projectRFI,
//       color: '#65ddf7'
//     },
//     {
//       name: "Under Kickout",
//       data: projectKickout,
//       color: '#f37324'
//     },
//     {
//       name: "Issued/Registered",
//       data: projectCompleted,
//       color: '#08415C'
//     },
//           ]}
//         options={{
//         chart: {
//       toolbar: {
//         show: false, // Hide toolbar icons
//       },
//       stacked: false,
//       events: {
//         dataPointSelection: handleChartClick,
//       },
//       margin: {
//         top: 20, // Add margin to the top of the chart
//       },
//         },
//         plotOptions: {
//       bar: {
//         horizontal: false,
//         columnWidth: '20%',
//         dataLabels: {
//           position: "top", // Display labels on top of the bars
//           offsetY: -10, // Slightly offset the labels above the bars
//         }
//       }
//         },
//         stroke: {
//           width: 1,
//         },
//         xaxis: {
//       title: {
//         text: "Team Leaders",
//         style: {
//           fontSize: '16px',
//           fontWeight: 'bold'
//         }
//       },
//       categories: tlName
//         },
//         yaxis: {
//       title: {
//         text: "Number of Projects",
//         style: { fontSize: '16px', fontWeight: 'bold' }
//       },
//       max: Math.max(
//         ...projectUndergoing,
//         ...projectRFI,
//         ...projectKickout,
//         ...projectCompleted
//       ) + 5,  // Ensures y-axis maximum is 1 unit greater than the largest value in the data
//         },
//         legend: {
//       position: 'bottom',
//         },
//         dataLabels: {
//       enabled: true,
//       style: {
//         fontSize: '12px',
//         fontWeight: 'bold',
//         colors: ['#304758']
//       },
//       offsetY: -20,  // Slightly adjust label placement
//       formatter: function (val) {
//         return val.toFixed(0); // Show integer values only
//       }
//         },
//         grid: {
//       borderColor: '#f1f1f1',
//       xaxis: {
//         lines: {
//           show: false
//         }
//       },
//       yaxis: {
//         lines: {
//           show: false
//         }
//       }
//         },
//     }}
//     />

//         </div>
//     </React.Fragment>
// );
// }
// export default ATTLWorkloadGraph;


import React from "react";


function ATProgramWiseProjectsGraph({ startDate, endDate }) {
  const grafanaBaseUrl = "https://grafana1.intileotech.xyz:3000/d-solo/febqwyppi7ls0e/assessment-team-dashboard";
  const orgId = 1;
  const timezone = "browser";
  // const panelId = 3;
  const panelId = 10;
  const theme = "light";
 


    const role = localStorage.getItem("designation_id");
    const userData = localStorage.getItem("user");
    const userId = JSON.parse(userData);
  
    // Access the 'id' field
    const user_id = userId.id;
    console.log("Extracted ID:", user_id);


  // Convert start and end date to UNIX timestamps (milliseconds)
  const from = startDate ? startDate.format('YYYY-MM-DD') : '2024-04-01' 
  const to = endDate ? endDate.format('YYYY-MM-DD') : '2025-03-31' 


// const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&from=${from}&to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-user_id=${user_id}`;


const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-user_id=${user_id}`;


 

  // Construct Grafana iframe URL dynamically
  // const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&var-from=${from}&var-to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}`;

  return (
    <div className="container-fluid">
      <iframe
        src={grafanaUrl}
        width="100%"
        height="400"
        frameBorder="0"
        title="Program Wise Projects"
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
}

export default ATProgramWiseProjectsGraph;

