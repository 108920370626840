import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Input, Tooltip as Tip } from "antd";
import { Table, Tag, Select } from "antd";
import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_HEADER, get_all_propoposal_url, get_sectoralscope_url } from "../../config";
import { get_proposal_detail_url, getCountryList, get_client_name_url, get_program_url } from "../../config";
import Header from "../../Pages/Header";
import SideNavbar from "../SideNavbar";
import Footer from "../Footer";
const { Option } = Select;

const AllProjects = () => {
	const [dataSource, setDataSource] = useState([]);
	const [loadData, setloadData] = useState(false);
	const [loader, setLoader] = useState(false);

	const [countryList, setCountryList] = useState([]);
	const [programList, setProgramList] = useState([]);
	const [scopeList, setScopeList] = useState([]);
	const [clientname, setClientname] = useState([]);
	const [client_id, setClientId] = useState([]);
	const [country, setCountry] = useState([]);
	const [scope, setScope] = useState([]);
	const [program, setProgram] = useState([]);
    const [searchValue, setSearchValue] = useState(null);

	const handleSearch = (value) => {
        setSearchValue(value);
        setloadData(true);
    };

	const getCountry = async () => {
		try {
			const result = await axios.get(`${getCountryList}`);
			setCountryList(result.data.data);
		} catch (error) {
		}
	};

	const getProgram = async () => {
		try {
			const result = await axios.get(`${get_program_url}`);
			setProgramList(result.data.data);
		} catch (error) {
		}
	};

	const getScope = async () => {
		try {
			const result = await axios.get(`${get_sectoralscope_url}`);
			setScopeList(result.data.data);
		} catch (error) {
		}
	};

	const getClientname = async () => {
		try {
			const result = await axios.get(`${get_client_name_url}`);
			setClientname(result.data.data);
		} catch (error) {
		}
	};

	const handleClientNameSearch = (value) => {
		setClientId(value);
		setloadData(true);
	};

	const handleCountrySearch = (value) => {
		setCountry(value);
		setloadData(true);
	};

	const handleScopeSearch = (value) => {
		setScope(value);
		setloadData(true);
	};


	const handleProgramSearch = (value) => {
		setProgram(value);
		setloadData(true);
	};

	useEffect(() => {
		getCountry();
		getClientname();
		getScope();
		getProgram()
	}, []);

	const navigate = useNavigate();

	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		total: 0,
	});

	const handleTableChange = (pagination, filters, sorter) => {
		setPagination(pagination);
		setloadData(true);
	};

	const editForm = async (record) => {
		const payload = {
			proposal_id: record.proposal_id,
		};

		const response = await axios.post(
			`${get_proposal_detail_url}`,
			payload,
			API_HEADER,
		);
		const data = response.data.record;

		if (record.status == 0) {
			navigate("/projects", { state: { data } });
		} else {
			navigate('/ptactions', { state: { data } })
		}

	};

	const columns = [
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px  fw-bold">
					S.No
				</span>
			),
			dataIndex: "proposal_id",
			width: 60,
			render: (text, record, index) => {
				const pageIndex = (pagination.current - 1) * pagination.pageSize;
				return pageIndex + index + 1;
			},
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px  fw-bold">
					EID
				</span>
			),
			width: 80,

			render: (text, record) => {
				return (
					<span className="text-capitalize  font14px  ">
						{record.earthood_id}
					</span>
				);
			},
			sorter: (record1, record2) => {
				return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
			},
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px  fw-bold">
					Project Name
				</span>
			),
			width: 160,
			ellipsis: true,
			// dataIndex: "project_name",
			render: (text, record) => {
				return (
				
					<Tip title={record.project_name}>
					<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
					  {record.project_name}
					</span>
				  </Tip>
				);
			},
			sorter: (record1, record2) => {
				return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
			}
		},
		
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px  fw-bold">
					Client Name
				</span>
			),
			width: 120,
			ellipsis: true,

			render: (text, record) => {
				return (
				
					<Tip title={record.client_name}>
					            <span className="text-capitalize font14px text-ellipsis">
								{record.client_name}
							  </span>
				    </Tip>
				);
			},
			sorter: (record1, record2) => {
				return (record1.client_name > record2.client_name) ? 1 : (record1.client_name === record2.client_name) ? 0 : -1
			}
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px  fw-bold">
					Program
				</span>
			),
			width: 80,

			render: (text, record) => {
				return (
					<span className="text-capitalize font14px  ">
						{record.program_id}
					</span>
				);
			},
			sorter: (record1, record2) => {
				return (record1.program > record2.program) ? 1 : (record1.program === record2.program) ? 0 : -1
			}
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px  fw-bold">
					Sales Person
				</span>
			),
			width: 160,
			ellipsis: true,
			render: (text, record) => {
				return (
				
					<Tip title={record.sales_person}>
					<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
					  {record.sales_person}
					</span>
				  </Tip>
				);
			},
			sorter: (record1, record2) => {
				return (record1.sales_person > record2.sales_person) ? 1 : (record1.sales_person === record2.sales_person) ? 0 : -1
			}
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px  fw-bold">
					Sectoral Scope
				</span>
			),
			width: 80,

			render: (text, record) => {
				if (record.sector) {
					return (
						<Tip title={record.sector}>
						<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
						  {record.sector}
						</span>
					  </Tip>
					);
				} else {
					return null
				}
			},
			sorter: (record1, record2) => {
				return (record1.scope > record2.scope) ? 1 : (record1.scope === record2.scope) ? 0 : -1
			}
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px  fw-bold">
					Country
				</span>
			),
			width: 80,

			render: (text, record) => {
				return (
					<span className="text-capitalize font14px ">
						{record.country}
					</span>
				);
			},
			sorter: (record1, record2) => {
				return (record1.country > record2.country) ? 1 : (record1.country === record2.country) ? 0 : -1
			}
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px  fw-bold">
					Status
				</span>
			),
			dataIndex: "status_msg",
			width: 120,
			render: (text, record) => {
				let color = "green";
				let msg = "";
				if (record.status == 0) {
					msg = "Pending";
					color = "orange";
				} else if (record.status == 1) {
					msg = "Proposal Submitted";
				} else if (record.status == 2) {
					msg = "TM Support Raised Clarification";
				}else if (record.status == 3) {
					msg = "TM Support Rejected";
				} else if (record.status == 5) {
					msg = "TM Support Approved";
					color = "volcano";
				} else if (record.status == 4) {
					msg = "Resubmitted";
					color = "green";
				} else if (record.status == 6) {
					msg = "TM Raised Clarification";
				} else if (record.status == 8) {
					msg = "TM Approved";
					color = "blue";
				} else if (record.status == 7) {
					msg = "TM Rejected";
					color = "blue";
				} else if (record.status == 9) {
					msg = "Forward to sales";
				} else if (record.status == 10) {
					msg = "Forward to client";
				}else if (record.status == 11) {
					msg = "Deal Lost";
				}else if (record.status == 12) {
					msg = "Contract Signed";
				}
				else if (record.status == 13) {
					msg = "Client Requested for Revision";
				}
				else if (record.status == 14) {
					msg = "Sales Team sent to PT for Revision";
				} else {
					msg = record.status;
				}

				return (
					<p className="px-lg-4 py-lg-2 rounded-5 text-sm font14px  " style={{color}}>
						{msg}
					</p>
				);
			},
			sorter: (record1, record2) => {
				return (record1.status > record2.status) ? 1 : (record1.status === record2.status) ? 0 : -1
			},
			
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px  fw-bold">
					Action
				</span>
			),
			dataIndex: "",
			key: "x",
			
			width: 70,
			render: (record) => (
				<a className="">

					{record.status === 0 || record.status === 3 || record.status === 5 ?
						(<EditOutlined
							style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
							onClick={() => editForm(record)}
						/>
						)
						:
						(
							<EyeOutlined
								onClick={() => editForm(record)}
								style={{ color: "blue", fontSize: '20px' }}
							/>
						)
					}

				</a>
			),
		},
	];

	const allData = async () => {
		try {
			setLoader(true)
			let payload = {
				status: "",
				page: pagination.current,
				limit: pagination.pageSize,
				country: country ? country : null,
				client_id: client_id ? client_id : null,
				scope: scope ? scope : null,
				program: program ? program : null,
				filter_type:1,
				search:searchValue
			};

			const response = await axios.post(
				`${get_all_propoposal_url}`,
				payload,
				API_HEADER,
			);
			setDataSource(response.data.data);
			setloadData(false);

			setPagination((prevPagination) => ({
				...prevPagination,
				total: response.data.count,
			}));
			setLoader(false)

		} catch (error) {
		}
	};

	useEffect(() => {
		allData();
	}, [loadData]);

	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());
	

	return (
		<>
			<Header />
			<SideNavbar />
			<div className="content-wrapper bg-white">
				<div className="content">
					<div className="container-fluid">
						<div className="row"
							style={{
								justifyContent: "center",
							}}
						>
							

							<div className="col-12">

								<div className="mx-3 my-3 border-2 border border-light-subtle p-0 rounded-3 mt-5 " >
									<div className="row border-0 ">
										<div className="col-12 ">
											<p className="text-black text-capitalize mx-2 font20px fw-bold p-2 rounded-top-3">
												All Proposals
											</p>
										</div>
									</div>

									<div className="row g-3 mx-2">
  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
    <div className="d-grid mb-3">
      <label className="text-capitalize textcolumntitle fw-bold font12px">Client Name</label>
      <Select
	    showSearch
		filterOption={filterOption}
        allowClear
        placeholder="Select Client Name"
        onChange={handleClientNameSearch}
        style={{ width: "100%" }}
        className="rounded-2"
      >
        <Option value="">Select</Option>
        {clientname.map((client, index) => (
          <Option key={index} value={client.id} label={client.name}>
            {client.name}
          </Option>
        ))}
      </Select>
    </div>
  </div>

  <div className="col-12 col-sm-6 col-md-4 col-lg-2">
    <div className="d-grid mb-3">
      <label className="text-capitalize textcolumntitle fw-bold font12px">Country</label>
      <Select
        allowClear
		showSearch
		filterOption={filterOption}
        style={{ width: "100%" }}
        className="rounded-2"
        placeholder="Select country"
        onChange={handleCountrySearch}
      >
        <Option value="">Select</Option>
        {countryList.map((country, index) => (
          <Option key={index} value={country.id} label={country.name}>
            {country.name}
          </Option>
        ))}
      </Select>
    </div>
  </div>

  <div className="col-12 col-sm-6 col-md-4 col-lg-2">
    <div className="d-grid mb-3">
      <label className="text-capitalize textcolumntitle fw-bold font12px">Sectoral Scope</label>
      <Select
        allowClear
		showSearch
		filterOption={filterOption}
        placeholder="Select Scope"
        onChange={handleScopeSearch}
        style={{ width: "100%" }}
        className="rounded-2"
      >
        <Option value="">Select</Option>
        {scopeList.map((scope, index) => (
          <Option key={index} value={scope.id} label={scope.scope}>
            {scope.scope}
          </Option>
        ))}
      </Select>
    </div>
  </div>

  <div className="col-12 col-sm-6 col-md-4 col-lg-2">
    <div className="d-grid mb-3">
      <label className="text-capitalize textcolumntitle fw-bold font12px">Program</label>
      <Select
        allowClear
		showSearch
		filterOption={filterOption}
        placeholder="Select Program"
        onChange={handleProgramSearch}
        style={{ width: "100%" }}
        className="rounded-2"
      >
        <Option value="">Select</Option>
        {programList.map((program, index) => (
          <Option key={index} value={program.id} label={program.program_name}>
            {program.program_name}
          </Option>
        ))}
      </Select>
    </div>
  </div>

  <div className="col-12 col-sm-12 col-md-8 col-lg-3">
    <div className="d-grid mb-3">
      <label className="text-capitalize textcolumntitle fw-bold font12px">Search</label>
      <Input.Search
        allowClear
        placeholder="Search By Project Name/EID"
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
        enterButton
        onSearch={handleSearch}
        style={{ width: "100%" }}
        className="rounded-2 custom-search"
      />
    </div>
  </div>
</div>


										<Table
										    scroll={{ x: 1000, }}
											className="border-1 border ronded-0 border-subtle-light px-0 m-3"
											columns={columns}
											dataSource={dataSource}
											rowKey="proposal_id"
											pagination={pagination}
											onChange={handleTableChange}
											loading={loader}
											bordered
										/>

								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default AllProjects;
