import { Spin, Tabs, Progress, Upload, message, Form as NewForm } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import axios from "axios";
import Swal from "sweetalert2";
import "../App.css";
import { Tooltip } from "antd";
import React, { useState, useEffect, startTransition } from "react";
import { FileUploader } from "react-drag-drop-files";
import { MultiSelect } from "react-multi-select-component";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  get_client_name_url,
  get_scope_pa_poa_url,
  get_meths_url,
} from "../config";
import { get_validator_verifier } from "../config";
import { get_scope_url } from "../config";
import { get_sectoralscope_url } from "../config";
import { get_program_url } from "../config";
import { get_country_url } from "../config";
import { get_assesment_url } from "../config";
import { pt_proposal_team_url, get_local_experts } from "../config";
import {
  pt_proposal_submit_url,
  get_sales_person_url,
  get_contact_person_url,
  get_gis_expert
} from "../config";
import { get_trsbu_url, get_ta_tr_experts } from "../config";
import Header from "./Header";
import PaymentTerms from "../Components/PaymentTerms";
import SideNavbar from "../Components/SideNavbar";
import Footer from "../Components/Footer";
import Select from "react-select";
import ProposalEntity from "../Components/ProposalEntity";
import { DatePicker, ConfigProvider } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import FileUploaderComponent from "../utlis/FileUploaderComponent";
import MultipleFileUploader from "./MultipleFileUploader";

const fileTypes = [
  "JPG",
  "JPEG",
  "PDF",
  "RAR",
  "XLS",
  "XLSX",
  "DOC",
  "DOCX",
  "ZIP",
  "XLSM",
];
const { Dragger } = Upload;
const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const AddProject = () => {
  const navigate = useNavigate();

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size;

  const [projectid, setProjectId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [myscope, setMyScope] = useState([]);
  const [mysectorscope, setMySectorScope] = useState([]);

  const [taTrExpert, setTATRExpert] = useState([]);
  const [validatorVerifier, setvalidatorVerifier] = useState([]);
  const [myvalidatorverifier, setMyvalidatorVerifier] = useState([]);

  const [methExpert, setmethExpert] = useState([]);
  const [mymethexpert, setMymethExpert] = useState([]);

  const [traineeValidator, settraineeValidator] = useState([]);
  const [mytraineevalidator, setMytraineevalidator] = useState([]);

  const [gisExpert, setgisExpert] = useState([]);
  const [mygisexpert, setMygisExpert] = useState([]);

  const [consultant, setConsultant] = useState([]);
  const [myConsultant, setMyConsultant] = useState([]);

  const [mytaexpert, setMyTaexpert] = useState([]);
  const [myexperttr, setMyExpertTr] = useState([]);
  const [trExpert, setTRExpert] = useState([]);

  const [selected, setSelected] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);
  const [program, setProgram] = useState([]);
  const [atlist, setAtList] = useState([]);
  const [trlist, setTRList] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [myprojectName, setMyProjectName] = useState("");
  const [error, setError] = useState("");
  const [earthoodId, setEarthoodId] = useState("");
  const [earthoodIdError, setEarthoodIdError] = useState("");
  const [programId, setProgramId] = useState("");
  const [proposalDesc, setProposalDesc] = useState("");
  const [programIdError, setProgramIdError] = useState("");
  const [monitoringPeriod, setMonitoringPeriod] = useState([]);
  const [monitoringPeriodError, setMonitoringPeriodError] = useState("");
  const [weblink, setWeblink] = useState("");
  const [consultantName, setConsultantName] = useState("");
  const [fees, setFees] = useState("");
  const [feesError, setFeesError] = useState("");
  const [proposalDate, setProposalDate] = useState("");
  const [proposalDateError, setProposalDateError] = useState("");
  const [f20name, setF20Name] = useState("");
  const [f21name, setF21Name] = useState("");
  const [f23name, setF23Name] = useState("");
  const [rfpname, setRFPName] = useState("");
  const [othername, setOtherName] = useState([]);
  const [coiname, setCoiName] = useState([]);

  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileF20SizeError, setFileF20SizeError] = useState(false);
  const [fileF21SizeError, setFileF21SizeError] = useState(false);
  const [fileF23SizeError, setFileF23SizeError] = useState(false);
  const [fileCOISizeError, setFileCOISizeError] = useState(false);
  const [fileOtherSizeError, setFileOtherSizeError] = useState(false);

  const [methOptions, setMethOptions] = useState([]);
  const [localExpert, setLocalExpert] = useState([]);
  const [selectedMeth, setSelectedMeth] = useState(null);
  const [defaultMethName, setDefaultMethName] = useState(null);
  const [methStatus, setMethStatus] = useState(null);
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [defaultClientName, setDefaultClientName] = useState(null);
  const [clientStatus, setClientStatus] = useState(null);
  const [defaultCountryName, setDefaultCountryName] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);
  const [countryStatus, setCountryStatus] = useState(null);
  const [scope_PA_POA, setScope_PA_POA] = useState([]);
  const [salesPerson, setSalesPerson] = useState([]);
  const [clientContactPerson, setClientContactPerson] = useState([]);
  const [selectedclientContactPerson, setselectedclientContactPerson] =
    useState([]);

  const [remarksPTError, setRemarksPTError] = useState("");
  const [remarksPT, setRemarksPT] = useState("");
  const [priority, setPriority] = useState("");
  const [apiData, setAPIData] = useState("");

  const [percent, setPercent] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    project_name: "",
    earthood_id: "",
    client_id: "",
    country: "",
    program: "",
    program_id: "",
    implemented_fees: "",
    created_at: "",
    scope: [],
    sectoral_scope: [],
    scope_pa: "",
    team_leader: "",
    ta_expert: [],
    validator_verifier: [],
    finance_expert: "",
    local_expert: "",
    meth_expert: [],
    meth: "",
    trainee_validator: [],
    technical_reviewer: "",
    expert_tr: [],
    sbu_head: "",
    deadline_date: "",
    sales_id: "",
    gis_expert: [],
    consultant_id: []
  });

  const [rangeForm] = NewForm.useForm();

  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      if (location.state && location.state.data) {
        const { data } = location.state;

        console.log("data", data);
        setAPIData(data);
        setProjectId(data.id);
        setSelectedClient(data.client_id);
        setSelectedCountry(data.country);
        setSelectedMeth(data.meth);
        setMethStatus(data.meth);
        setPriority(data.priority);
        setConsultantName(data.consultant_name);

        if (data?.monitoring_period) {
          setMonitoringPeriod(JSON.parse(data.monitoring_period));
        }

        setProposalDesc(data.proposal_desc);
        setWeblink(data.weblink);

        setFormData({
          project_name: data.project_name,
          earthood_id: data.earthood_id,
          client_id: data.client_id,
          country: data.country,
          program: data.program,
          program_id: data.program_id,
          implemented_fees: data.implemented_fees,
          created_at: data.proposal_date,
          scope: selected,
          scope_pa: data.scope_pa,
          sectoral_scope: selected,
          team_leader: data.team_leader,
          // ta_expert: mytaexpert,
          // validator_verifier: data.validator_verifier,
          finance_expert: data.finance_expert,
          local_expert: data.local_expert,
          meth_expert: data.meth_expert,
          meth: data.meth,
          trainee_validator: data.trainee_validator,
          technical_reviewer: data.technical_reviewer,
          // expert_tr: data.expert_tr,
          sbu_head: data.sbu_head,
          deadline_date: data.deadline_date,
          sales_id: data.sales_id,
          contact_person_id: data.contact_person_id,
          gis_expert: data.gis_expert,
        });

        setClientStatus(data.client_id);
        setCountryStatus(data.country);
        setRemarksPT(data.pt_tmremarks);

        if (data.payment_terms != null && data.payment_terms != "") {
          const parsedRecords = JSON.parse(data.payment_terms);
          setPaymentRecords(parsedRecords);
        }

        if (data.contact_entity != null && data.contact_entity != "") {
          const parsedEntityRecords = JSON.parse(data.contact_entity);
          setProposalEntityRecords(parsedEntityRecords);
        }
      }
    };
    fetchData();
  }, [location]);

  useEffect(() => {
    if (apiData) {
      const scopes = apiData.scope.split(",").map(Number);
      const filteredScopes = myscope.filter((scope) =>
        scopes?.includes(scope.value)
      );
      setSelected(filteredScopes);
      const sectorscopes = apiData.sectoral_scope?.split(",").map(Number);
      const filteredSectorScopes = mysectorscope.filter((sectorscope) =>
        sectorscopes?.includes(sectorscope.value)
      );
      setSelectedSector(filteredSectorScopes);
    }
  }, [apiData, myscope, mysectorscope]);

  const CONFIG_Token = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const CONFIG_Token2 = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setPercent(percentCompleted);
    },
  };

  const handleMyProjectChange = (event) => {
    const { value } = event.target;
    setMyProjectName(value);

    if (value.length < 3 || value.length > 500) {
      setError(
        "Project Name must be in alphabatic characters with 3 to 500 characters"
      );
    } else {
      setError("");
    }
    setFormData({ ...formData, project_name: event.target.value });
  };

  const handleEarthoodIdChange = (event) => {
    const value = event.target.value;
    setEarthoodId(value);
    if (!/^[a-zA-Z0-9._()]{3,40}$/.test(value)) {
      setEarthoodIdError(
        "Earthood Id must be alphanumeric and may contain ., _, and () characters, between 3 to 40 characters"
      );
    } else {
      setEarthoodIdError("");
    }
    setFormData({ ...formData, earthood_id: value });
  };

  const handleProgramIdChange = (event) => {
    const value = event.target.value;
    setProgramId(value);

    if (!/^[a-zA-Z0-9]{3,40}$/.test(value)) {
      setProgramIdError(
        "Earthood Id must be alphanumeric and between 3 to 40 characters"
      );
    } else {
      setProgramIdError("");
    }
    setFormData({ ...formData, program_id: event.target.value });
  };

  const handleFeesChange = (event) => {
    const value = event.target.value;

    setFees(value);

    if (value < 1 || value > 999999999999 || !/^\d+$/.test(value)) {
      setFeesError(
        "Implementation Fees must be a positive integer between 1 and 999,999,999,999."
      );
    } else {
      setFeesError("");
    }
    setFormData({ ...formData, implemented_fees: event.target.value });
  };

  const handleDateChange = (date, dateString) => {
    setFormData((prevData) => ({
      ...prevData,
      created_at: date ? date.format("YYYY-MM-DD") : "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Confirmation?",
      html: "After submission of proposal, the proposal details will not be editable and cannot be changed later <b> except Project Name, Program ID, Scope(pa/poa) SBU Head, Meth, Deadline Date, Monitoring Period and Weblink </b>.<br> Are you sure,  you want to send the proposal with the mentioned details and uploaded documents to Technical Manager Support Team?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (
          remarksPT == null ||
          remarksPT == "<p><br></p>" ||
          remarksPT == ""
        ) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Please Add Some Remarks",
          });
        } else {
          const scopeData = selected.map((value) => value.value);
          const sectorscopeData = selectedSector.map((value) => value.value);
          const TAExpertData = mytaexpert.map((value) => value.value);
          const TRExpertData = myexperttr.map((value) => value.value);
          const ValidatorVerifierData = myvalidatorverifier.map(
            (value) => value.value
          );
          const MathExpertData = mymethexpert.map((value) => value.value);
          const TraineeValidatorData = mytraineevalidator.map(
            (value) => value.value
          );

          const contactperson = selectedclientContactPerson.map(
            (value) => value.value
          );
          const gisexpert = mygisexpert.map((value) => value.value);
          const consultant = myConsultant.map((value) => value.value);

          const payload = {
            ...formData,
            client_id: clientStatus,
            contact_person_id: contactperson,
            country: countryStatus,
            proposal_id: projectid,
            scope: scopeData,
            sectoral_scope: sectorscopeData,
            payment_terms: paymentRecords,
            contact_entity: proposalEntityRecords,
            pt_tmremarks: remarksPT,
            proposal_desc: proposalDesc,
            consultant_id: consultant,
            priority: priority,
            meth: methStatus,
            monitoring_period: monitoringPeriod,
            weblink: weblink,
            ta_expert: TAExpertData,
            expert_tr: TRExpertData,
            validator_verifier: ValidatorVerifierData,
            meth_expert: MathExpertData,
            trainee_validator: TraineeValidatorData,
            gis_expert: gisexpert,
          };

          const total_percent = calculateTotalPercentage();

          if (total_percent <= 100) {
            try {
              setLoading(true);
              const response = await axios.post(
                `${pt_proposal_team_url}`,
                payload,
                CONFIG_Token
              );

              setLoading(false);

              setProjectId(response.data.project_id);
              if (!response.data.status) {
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: response.data.message,
                });
              } else {
                toast.success("Form Saved Successfully");
                setActiveTab("2");
              }
            } catch (error) {
              console.error("Error saving data:", error);
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Error saving data",
              });
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Sum total of all Amounts cannot be greater than Implementation Fee",
            });
          }
        }
      }
    });
  };

  const calculateTotalPercentage = () => {
    return paymentRecords
      .reduce((total, record) => {
        return total + parseFloat(record.percent);
      }, 0)
      .toFixed(2);
  };

  const handleBackToTab1 = () => {
    setActiveTab("1");
  };

  const handleProgram = (event) => {
    const selectedProgram = event.target.value;
    setFormData({ ...formData, program: selectedProgram });
  };
  const handlePriority = (event) => {
    const selectedPriority = event.target.value;
    setPriority(selectedPriority);
  };

  useEffect(() => {
    const fetchClientOptions = async () => {
      try {
        const response = await axios.get(`${get_client_name_url}`);
        const data = response.data;
        setClientOptions(
          data.data.map((item) => ({ value: item.id, label: item.name }))
        );
        const defaultClient = data.data
          .filter((item) => item.id === selectedClient)
          .map((client) => ({ value: client.id, label: client.name }));
        setDefaultClientName(defaultClient[0]);
      } catch (error) {
        console.error("Error fetching client options:", error);
      }
    };

    fetchClientOptions();
  }, [selectedClient]);

  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption);
    setClientStatus(selectedOption.value);
  };

  const handleMethChange = (selectedOption) => {
    setSelectedMeth(selectedOption);
    setMethStatus(selectedOption.value);
  };

  useEffect(() => {
    const fetchMethOptions = async () => {
      try {
        const response = await axios.get(`${get_meths_url}`);
        const data = response.data;

        setMethOptions(
          data.data.map((item) => ({ value: item.id, label: item.name }))
        );
        const defaultMeth = data.data
          .filter((item) => item.id == selectedMeth)
          .map((meth) => ({ value: meth.id, label: meth.name }));
        setDefaultMethName(defaultMeth[0]);
      } catch (error) {
        console.error("Error fetching client options:", error);
      }
    };

    fetchMethOptions();
  }, [selectedMeth]);

  useEffect(() => {
    const fetchDataScope = async () => {
      try {
        const responsescope = await axios.get(`${get_scope_url}`);

        setMyScope(
          responsescope.data.data.map((scope) => ({
            value: scope.id,
            label: scope.sector_name,
          }))
        );
      } catch (error) {}
    };
    fetchDataScope();
  }, []);

  useEffect(() => {
    const fetchSectoralScope = async () => {
      try {
        const responsesectoralscope = await axios.get(
          `${get_sectoralscope_url}`
        );

        setMySectorScope(
          responsesectoralscope.data.data.map((scope) => ({
            value: scope.id,
            label: scope.scope,
          }))
        );
      } catch (error) {}
    };

    fetchSectoralScope();
  }, []);

  useEffect(() => {
    const fetchDataProgram = async () => {
      try {
        const responseprogram = await axios.get(`${get_program_url}`);
        setProgram(responseprogram.data.data);
      } catch (error) {}
    };

    fetchDataProgram();
  }, []);

  useEffect(() => {
    const fetchDataSope_PA_POA = async () => {
      try {
        const responsescope = await axios.get(`${get_scope_pa_poa_url}`);

        setScope_PA_POA(responsescope.data.data);
      } catch (error) {}
    };

    fetchDataSope_PA_POA();
  }, []);

  useEffect(() => {
    const fetchDataSalesPerson = async () => {
      try {
        const responsesalesperson = await axios.get(
          `${get_sales_person_url}?new_team=1`
        );

        setSalesPerson(responsesalesperson.data.data);
      } catch (error) {}
    };

    fetchDataSalesPerson();
  }, []);
console.log("dfdsf",selectedCountry?.value,formData.program)
  useEffect(() => {
    const fetchLocalExpert = async () => {
      try {
        const responselocalexpert = await axios.get(
          `${get_local_experts}?country_id=${selectedCountry?.value}&&program_id=${formData.program}`
        );
        console.log(responselocalexpert,'🏁')

        setLocalExpert(responselocalexpert.data);
      } catch (error) {}
    };

    fetchLocalExpert();
  }, [selectedCountry,formData.program, defaultCountryName]);
  

  // useEffect(() => {
  //   const fetchDataClientContactPerson = async () => {
  //     try {
  //       let payload = {
  //         id: selectedClient,
  //       };
  //       const responseclientcontactperson = await axios.post(
  //         `${get_contact_person_url}`,
  //         payload
  //       );
  //       const formattedClientContactPerson = JSON.parse(
  //         responseclientcontactperson.data.data.contact_person
  //       ).map((person) => ({
  //         value: person.id,
  //         label: person.name,
  //       }));
  //       setClientContactPerson(formattedClientContactPerson);
  //     } catch (error) {}
  //   };
  //   if (selectedClient) {
  //     fetchDataClientContactPerson();
  //   }
  // }, [selectedClient]);


  useEffect(() => {
    const fetchDataClientContactPerson = async () => {
      try {
        let payload = {
          id: selectedClient
        }
       
        const responseclientcontactperson = await axios.post(`${get_contact_person_url}`, payload);
        const formattedClientContactPerson = JSON.parse(responseclientcontactperson.data.data.contact_person).map((person) => ({
          value: person.id,
          label: person.name,
        }));
        
        setClientContactPerson(formattedClientContactPerson);
        
        const contactPersonId = apiData.contact_person_id ? String(apiData.contact_person_id) : '';
        const sectorscopes = contactPersonId.split(",").map(Number);
        const filteredSectorScopes = formattedClientContactPerson.filter((sectorscope) =>
          sectorscopes?.includes(sectorscope.value)
        );
        
        setselectedclientContactPerson(filteredSectorScopes);
        
      } catch (error) { 
        console.log(error)
      }
    };
    if (selectedClient) {
      fetchDataClientContactPerson();
    }
  }, [selectedClient]);
  
  useEffect(() => {
    const fetchDataCountry = async () => {
      try {
        const response = await axios.get(`${get_country_url}`);
        const data = response.data;
        setCountryOptions(
          data.data.map((item) => ({ value: item.id, label: item.name }))
        );
        const defaultCountry = data.data
          .filter((item) => item.id === selectedCountry)
          .map((country) => ({ value: country.id, label: country.name }));
        setDefaultCountryName(defaultCountry[0]);
      } catch (error) {
        console.error("Error fetching client options:", error);
      }
    };

    fetchDataCountry();
  }, [selectedCountry]);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setCountryStatus(selectedOption.value);
  };

  useEffect(() => {
    const fetchDataTechnicalReviewer = async () => {
      try {
        const responseteamleader = await axios.get(
          `${get_assesment_url}?program_id=${formData.program}&new_team=1`
        );
        setAtList(responseteamleader.data.data);
      } catch (error) {}
    };

    fetchDataTechnicalReviewer();
  }, [formData.program]);

  useEffect(() => {
    const fetchDataTATRExpert = async () => {
      try {
        const responsetatrexpert = await axios.get(
          `${get_ta_tr_experts}?program_id=${
            formData.program
          } && sectorscope_id=${selectedSector.map(
            (value) => value.value
          )} && skill_id=7 &new_team=1`
        );
        setTATRExpert(
          responsetatrexpert.data.data.map((taexpert) => ({
            value: taexpert.id,
            label: taexpert.name,
          }))
        );
      } catch (error) {}
    };

    if (formData.program && selectedSector.length > 0) {
      fetchDataTATRExpert();
    }
  }, [formData.program, selectedSector]);

  useEffect(() => {
    const fetchDatavalidatorVerifier = async () => {
      try {
        const responsevalidatorverifier = await axios.get(
          `${get_validator_verifier}?program_id=${
            formData.program
          } && skill_id=2 &new_team=1`
        );
        setvalidatorVerifier(
          responsevalidatorverifier.data.data.map((validator_verifier) => ({
            value: validator_verifier.id,
            label: validator_verifier.name,
          }))
        );
      } catch (error) {}
    };

    fetchDatavalidatorVerifier();
  }, [formData.program]);

  useEffect(() => {
    if (apiData && validatorVerifier.length > 0) {
      const validator = apiData?.validator_verifier?.split(",").map(Number);

      const filteredValidatorExpert = validatorVerifier.filter((expert) =>
        validator?.includes(expert.value)
      );

      setMyvalidatorVerifier(filteredValidatorExpert);
    }
  }, [apiData, validatorVerifier]);

  useEffect(() => {
    const fetchDatamethExpert = async () => {
      try {
        const responsemethexpert = await axios.get(
          `${get_validator_verifier}?program_id=${
            formData.program
          } && skill_id=5`
        );
        setmethExpert(
          responsemethexpert.data.data.map((methexpert) => ({
            value: methexpert.id,
            label: methexpert.name,
          }))
        );
      } catch (error) {}
    };

    fetchDatamethExpert();
  }, [formData.program]);

  useEffect(() => {
    if (apiData && methExpert.length > 0) {
      const meth = apiData?.meth_expert?.split(",").map(Number);

      const filteredMethExpert = methExpert.filter((expert) =>
        meth?.includes(expert.value)
      );

      setMymethExpert(filteredMethExpert);
    }
  }, [apiData, methExpert]);

  useEffect(() => {
    const fetchDatagisExpert = async () => {
      try {
        const responsegisexpert = await axios.get(
          `${get_gis_expert}?skill_id=9`
        );
        setgisExpert(
          responsegisexpert.data.data.map((gisexpert) => ({
            value: gisexpert.id,
            label: gisexpert.name,
          }))
        );
      } catch (error) {}
    };

    fetchDatagisExpert();
  }, []);

  useEffect(() => {
    if (apiData && gisExpert.length > 0) {
      const gisExperts = apiData?.gis_expert?.split(",").map(Number);

      const filteredGISExpert = gisExpert.filter((expert) =>
        gisExperts?.includes(expert.value)
      );

      setMygisExpert(filteredGISExpert);
    }
  }, [apiData, gisExpert]);

  useEffect(() => {
    const fetchDataConsultant = async () => {
      try {
        const responseconsultant = await axios.get(
          `${get_client_name_url}`
        );
        setConsultant(
          responseconsultant.data.data.map((consultant) => ({
            value: consultant.id,
            label: consultant.name,
          }))
        );
      } catch (error) {}
    };

    fetchDataConsultant();
  }, []);

  useEffect(() => {
    if (apiData && consultant.length > 0) {
      const consultantName = apiData?.consultant_id?.split(",").map(Number);

      const filteredConsultant = consultant.filter((expert) =>
        consultantName?.includes(expert.value)
      );

      setMyConsultant(filteredConsultant);
    }
  }, [apiData, consultant]);

  useEffect(() => {
    const fetchDatatraineeValidator = async () => {
      try {
        const responsetraineevalidator = await axios.get(
          `${get_validator_verifier}?program_id=${
            formData.program
          } && skill_id=3`
        );
        settraineeValidator(
          responsetraineevalidator.data.data.map((traineevalidator) => ({
            value: traineevalidator.id,
            label: traineevalidator.name,
          }))
        );
      } catch (error) {}
    };

    fetchDatatraineeValidator();
  }, [formData.program]);

  useEffect(() => {
    if (apiData && traineeValidator.length > 0) {
      const traineevalidator = apiData?.trainee_validator
        ?.split(",")
        .map(Number);

      const filteredTraineeValidator = traineeValidator.filter((expert) =>
        traineevalidator?.includes(expert.value)
      );

      setMytraineevalidator(filteredTraineeValidator);
    }
  }, [apiData, traineeValidator]);

  useEffect(() => {
    if (apiData && taTrExpert.length > 0) {
      const taExpert = apiData.ta_expert?.split(",").map(Number);
      const filteredTaExpert = taTrExpert.filter((taexpert) =>
        taExpert?.includes(taexpert.value)
      );
      setMyTaexpert(filteredTaExpert);
    }
  }, [apiData, taTrExpert]);

  // useEffect(() => {
  //   if (apiData ) {
  //     const validatorVerifier = apiData.validator_verifier?.split(",").map(Number);
  //     const filteredValidatorVerifier = taTrExpert.filter((taexpert) =>
  //       taExpert.includes(taexpert.value)
  //     );
  //     setMyTaexpert(filteredTaExpert);
  //   }
  // }, [apiData, taTrExpert]);

  useEffect(() => {
    const fetchDataTRExpert = async () => {
      try {
        const responsetatrexperts = await axios.get(
          `${get_ta_tr_experts}?program_id=${
            formData.program
          } && sectorscope_id=${selectedSector.map(
            (value) => value.value
          )} && skill_id=8 &new_team=1`
        );

        setTRExpert(
          responsetatrexperts.data.data.map((trexpert) => ({
            value: trexpert.id,
            label: trexpert.name,
          }))
        );
      } catch (error) {}
    };

    if (formData.program && selectedSector.length > 0) {
      fetchDataTRExpert();
    }
  }, [formData.program, selectedSector]);

  useEffect(() => {
    if (apiData && trExpert.length > 0) {
      const trExperts = apiData.expert_tr?.split(",").map(Number);
      const filteredTrExpert = trExpert.filter((taexpert) =>
        trExperts?.includes(taexpert.value)
      );

      setMyExpertTr(filteredTrExpert);
    }
  }, [apiData, trExpert]);

  useEffect(() => {
    if (monitoringPeriod && monitoringPeriod.length > 0) {
      rangeForm.setFieldValue("startEndDate", [
        dayjs(monitoringPeriod[0]),
        dayjs(monitoringPeriod[1]),
      ]);
    } else {
      rangeForm.setFieldValue("startEndDate", [null, null]);
    }
  });

  useEffect(() => {
    const fetchDataTrData = async () => {
      try {
        const responsetrdata = await axios.get(`${get_trsbu_url}?new_team=1`);
        setTRList(responsetrdata.data.data);
      } catch (error) {}
    };

    fetchDataTrData();
  }, []);

  // const handleFileCOIChange = (file) => {
  //   if (file[0].size <= dynamicMaxSize * 1024 * 1024) {
  //     setFileCOISizeError(false);
  //   }

  //   setFileCOISizeError(false);
  //   setCoiName(prevFiles => [...prevFiles, ...Array.from(file)]);
  // };

  const handleFileCOIChange = (file) => {
    console.log("9999", file);
    setCoiName(file);
  };

  const handleFileOtherChange = (file) => {
    setOtherName(file);
  };

  const handleRemarksPTChange = (e) => {
    setRemarksPT(e.target.value);
  };

  const handleProposalDescChange = (event) => {
    const value = event.target.value;
    setProposalDesc(value);
  };

  const handleMonitoringPeriodChange = (dates) => {
    setMonitoringPeriod(dates);
  };

  const handleConsultantNameChange = (event) => {
    const value = event.target.value;
    setConsultantName(value);
  };

  const handleWeblinkChange = (event) => {
    const value = event.target.value;
    setWeblink(value);
  };

  const handleSubmitFiles = async (e) => {
    e.preventDefault();
    setIsSubmitting(false);
    if (
      f20name == null ||
      f20name == "" ||
      f21name == null ||
      f21name == "" ||
      f23name == null ||
      f23name == "" ||
      coiname == null ||
      coiname == ""
    ) {
      toast.error("Please upload the documents ");
    } else {
      Swal.fire({
        title: "Confirmation?",
        text: "Are you sure you want to send the proposal with the mentioned details and uploaded documents to Technical Manager Support?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // setLoading(true);
            setIsSubmitting(true);

            const formData = new FormData();

            formData.append("f20_doc", f20name);
            formData.append("f21_doc", f21name);
            formData.append("f23_doc", f23name);
            // coiname.forEach(fileList => formData.append("coi_doc[]", fileList));

            for (let i = 0; i < coiname.length; i++) {
              formData.append("coi_doc[]", coiname[i]);
            }
            if (othername != null) {
              // othername.forEach(fileList => formData.append("other_doc[]", fileList));
              for (let i = 0; i < othername.length; i++) {
                formData.append("other_doc[]", othername[i]);
              }
            }
            if (rfpname != null) {
              formData.append("rfp_doc", rfpname);
            }
            formData.append("proposal_id", projectid);

            const response = await axios.post(
              `${pt_proposal_submit_url}`,
              formData,
              CONFIG_Token2
            );

            // setLoading(false);

            if (!response.data.status) {
              toast.error("Please Upload Mandatory Documents");
            } else {
              toast.success("Form Submitted Successfully");
              navigate("/dashboard");
            }
          } catch (error) {
            toast.error("Documents file size exceed the limit");
            // setLoading(false);
            console.error("Error uploading file:", error);
          } finally {
            setIsSubmitting(false); // Reset submitting state after completion
          }
        }
      });
    }
  };

  const [paymentRecords, setPaymentRecords] = useState([
    { description: "", stage: "", percent: "", amount: "" },
  ]);

  const addPayment = () => {
    setPaymentRecords([
      ...paymentRecords,
      { description: "", stage: "", percent: "", amount: "" },
    ]);
  };

  const handlePaymentChange = (index, event) => {
    const { name, value } = event.target;
    const newPayments = paymentRecords.map((payment, idx) => {
      if (idx === index) {
        let updatedPayment = { ...payment, [name]: value };

        if (name === "percent") {
          const baseValue = parseFloat(formData.implemented_fees);
          if (!isNaN(baseValue)) {
            const newAmount = (baseValue * parseFloat(value)) / 100;
            updatedPayment.amount = newAmount.toFixed(2);
          } else {
          }
        } else if (name === "amount") {
          const baseValue = parseFloat(formData.implemented_fees);
          if (!isNaN(baseValue)) {
            const amount = parseFloat(value);
            const per = (amount * 100) / baseValue;
            updatedPayment.percent = per.toFixed(2);
          } else {
          }
        }

        return updatedPayment;
      }
      return payment;
    });

    setPaymentRecords(newPayments);
  };

  const deletePayment = (index) => {
    const newPayments = paymentRecords.filter((_, idx) => idx !== index);
    setPaymentRecords(newPayments);
  };

  const [proposalEntityRecords, setProposalEntityRecords] = useState([
    { name: "", entity: "", relationship: "" },
  ]);

  const addProposalEntity = () => {
    setProposalEntityRecords([
      ...proposalEntityRecords,
      { name: "", entity: "", relationship: "" },
    ]);
  };

  const handleProposalEntityChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRecords = proposalEntityRecords.map((record, idx) =>
      idx === index ? { ...record, [name]: value } : record
    );
    setProposalEntityRecords(updatedRecords);
  };

  const deleteProposalEntity = (index) => {
    const newProposalEntity = proposalEntityRecords.filter(
      (_, idx) => idx !== index
    );
    setProposalEntityRecords(newProposalEntity);
  };

  const handleDeadlineDateChange = (date, dateString) => {
    setFormData((prevData) => ({
      ...prevData,
      deadline_date: date ? date.format("YYYY-MM-DD") : "",
    }));
  };

  return (
    <>
      <Header />
      <SideNavbar />
      <div className="content-wrapper bg-white">
        <div className="content">
          <div className="container-fluid bg-white">
            <div className="row">
              <div className="col-12">
                <h4 className="textcolorblue fw-bolder p-3 text-capitalize my-2 mx-4">
                  {formData.earthood_id != "" ? (
                    <>
                      <div className="row">
                        <div className="col-lg-6 col-12 fs-4">
                          {formData.earthood_id} - EARTHOOD
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <p className="fs-4">Add Proposal</p>
                    </>
                  )}
                </h4>
                <Tabs
                  defaultActiveKey="1"
                  activeKey={activeTab}
                  className="mx-5"
                  indicator={{ Backgroundcolor: "#07B6AF" }}
                >
                  <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0 px-2 py-2 text-center tab_size ${
                          activeTab == 1 ? "box_sh" : ""
                        }`}
                      >
                        <p>Proposal Details</p>
                      </div>
                    }
                    key="1"
                  >
                    <Spin spinning={loading}>
                      <form onSubmit={handleSubmit} method="POST">
                        <div className="col-12 border-0 py-2 mx-auto">
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="projectname"
                                className="form-label lightgreen fs-6"
                              >
                                Project Name
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Tooltip
                                title={formData.project_name}
                                placement="top"
                              >
                                <input
                                  type="text"
                                  className={`form-control borderlightgreen ${
                                    error ? "is-invalid" : ""
                                  }`}
                                  id="project_name"
                                  placeholder="Project Name"
                                  required
                                  name="project_name"
                                  value={formData.project_name}
                                  onChange={handleMyProjectChange}
                                />
                              </Tooltip>
                              {error && (
                                <div className="error text-danger">{error}</div>
                              )}
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="eid"
                                className="form-label lightgreen fs-6"
                              >
                                Earthood Id
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control borderlightgreen ${
                                  earthoodIdError ? "is-invalid" : ""
                                }`}
                                id="earthood_id"
                                placeholder="Earthood Id"
                                name="earthood_id"
                                required
                                value={formData.earthood_id}
                                onChange={handleEarthoodIdChange}
                              />
                              {earthoodIdError && (
                                <div className="invalid-feedback text-danger">
                                  {earthoodIdError}
                                </div>
                              )}
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="clientname"
                                className="form-label lightgreen fs-6"
                              >
                                Client Name
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <Select
                                id="client_id"
                                name="client_id"
                                value={defaultClientName}
                                onChange={handleClientChange}
                                options={clientOptions}
                                placeholder="Select"
                                required
                                isSearchable={true}
                              />
                            </div>
                          </div>
                          {}
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <div>
                                <label
                                  htmlFor="contact person"
                                  className="form-label lightgreen fs-6"
                                >
                                  Client Contact Person
                                  <span style={{ color: "red" }}>*</span>
                                </label>

                                <MultiSelect
                                  // className={}
                                  options={clientContactPerson}
                                  value={selectedclientContactPerson}
                                  onChange={setselectedclientContactPerson}
                                  labelledBy="Select"
                                />
                                {/* 
                                <select
                                  id="contact_person"
                                  className="form-select borderlightgreen form-select-sm"
                                  aria-label="Default select example"
                                  name="contact_person"
                                  required
                                  value={formData.contact_person_id}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      contact_person_id: e.target.value,
                                    })
                                  }
                                >
                                  <option value={""}>Select</option>
                                  {clientContactPerson && clientContactPerson
                                    .map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                </select>
                                 */}
                              </div>
                            </div>


                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="consultant"
                                className="form-label lightgreen fs-6"
                              >
                                Consultant Name
                              </label>

                              <MultiSelect
                                options={consultant}
                                value={myConsultant}
                                onChange={setMyConsultant}
                                labelledBy="Select"
                              />
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Program"
                                className="form-label lightgreen fs-6"
                              >
                                Program<span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="program"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="program"
                                required
                                value={formData.program}
                                onChange={handleProgram}
                              >
                                <option value={""}>Select</option>
                                {program.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.program_name}
                                  </option>
                                ))}
                              </select>
                            </div>




                          </div>

                          <div className="row">

                          <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="clientname"
                                className="form-label lightgreen fs-6"
                              >
                                Project Country
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <Select
                                id="country"
                                name="country"
                                value={defaultCountryName}
                                onChange={handleCountryChange}
                                options={countryOptions}
                                placeholder="Select"
                                required
                                isSearchable={true}
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Scope(PO/POA)"
                                className="form-label lightgreen fs-6"
                              >
                                Scope(PA/POA)
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                id="scope_pa"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="scope_pa"
                                required
                                value={formData.scope_pa}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    scope_pa: e.target.value,
                                  })
                                }
                              >
                                <option value={""}>Select</option>
                                {scope_PA_POA.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.scope}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Program Id"
                                className="form-label lightgreen fs-6"
                              >
                                Program Id
                              </label>
                              <input
                                type="text"
                                id="program_id"
                                placeholder="Program Id"
                                name="program_id"
                                className={`form-control borderlightgreen ${
                                  programIdError ? "is-invalid" : ""
                                }`}
                                value={formData.program_id}
                                onChange={handleProgramIdChange}
                              />
                              {programIdError && (
                                <div className="invalid-feedback text-danger">
                                  {programIdError}
                                </div>
                              )}
                            </div>

                          </div>

                          <div className="row">

                          <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Implementation Fees"
                                className="form-label lightgreen fs-6"
                              >
                                Implementation Fees
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="number"
                                className={`form-control borderlightgreen ${
                                  feesError ? "is-invalid" : ""
                                }`}
                                id="implemented_fees"
                                placeholder="Implementation Fees"
                                required
                                name="implemented_fees"
                                value={formData.implemented_fees}
                                onChange={handleFeesChange}
                              />
                              {feesError && (
                                <div className="invalid-feedback text-danger">
                                  {feesError}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <div>
                                <label
                                  htmlFor="Sectoral Scope"
                                  className="form-label lightgreen fs-6"
                                >
                                  SBU Head
                                  <span style={{ color: "red" }}>*</span>
                                </label>

                                <select
                                  id="sbu_head"
                                  className="form-select borderlightgreen form-select-sm"
                                  aria-label="Default select example"
                                  name="sbu_head"
                                  required
                                  value={formData.sbu_head}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      sbu_head: e.target.value,
                                    })
                                  }
                                >
                                  <option value={""}>Select</option>
                                  {trlist
                                    .filter(
                                      (option) => option.designation_id == 9
                                    )
                                    .map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Scope"
                                className="form-label lightgreen fs-6"
                              >
                                Sectoral Scope
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <MultiSelect
                                // className={}
                                options={mysectorscope}
                                value={selectedSector}
                                onChange={setSelectedSector}
                                labelledBy="Select"
                              />
                            </div>


                          </div>

                          <div className="row ">

                          <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Scope"
                                className="form-label lightgreen fs-6"
                              >
                                Scope<span style={{ color: "red" }}>*</span>
                              </label>
                              <MultiSelect
                                options={myscope}
                                value={selected}
                                onChange={setSelected}
                                labelledBy="Select"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <div>
                                <label
                                  htmlFor="Sales person"
                                  className="form-label lightgreen fs-6"
                                >
                                  Sales Person
                                  <span style={{ color: "red" }}>*</span>
                                </label>

                                <select
                                  id="sales_person"
                                  className="form-select borderlightgreen form-select-sm"
                                  aria-label="Default select example"
                                  name="sales_person"
                                  required
                                  value={formData.sales_id}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      sales_id: e.target.value,
                                    })
                                  }
                                >
                                  <option value={""}>Select</option>
                                  {salesPerson.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="priority"
                                className="form-label lightgreen fs-6"
                              >
                                Priority
                              </label>

                              <select
                                id="priority"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="priority"
                                value={priority}
                                onChange={handlePriority}
                              >
                                <option value={""}>Select</option>

                                <option value="low">Low</option>
                                <option value="medium">Medium</option>
                                <option value="high">High</option>
                              </select>
                            </div>


                          </div>

                          <div className="row">

                          <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Meth"
                                className="form-label lightgreen fs-6"
                              >
                                Meth
                              </label>

                              <Select
                                id="meth_id"
                                name="meth"
                                value={defaultMethName}
                                onChange={handleMethChange}
                                options={methOptions}
                                placeholder="Select"
                                isSearchable={true}
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Proposal Date"
                                className="form-label lightgreen fs-6"
                              >
                                Proposal Date
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <DatePicker
                                id="proposaldate"
                                className={`form-control borderlightgreen custom-disabled-date-picker `}
                                style={{ color: "black" }}
                                required
                                format="DD/MM/YYYY"
                                value={
                                  formData.created_at
                                    ? dayjs(formData.created_at)
                                    : null
                                }
                                onChange={handleDateChange}
                                disabledDate={(current) =>
                                  current && current > moment().endOf("day")
                                }
                              />
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="deadline Date"
                                className="form-label lightgreen fs-6"
                              >
                                Deadline Date
                              </label>

                              <DatePicker
                                id="deadlinedate"
                                className={`form-control borderlightgreen custom-disabled-date-picker`}
                                style={{ color: "black" }}
                                format="DD/MM/YYYY"
                                value={
                                  formData.deadline_date
                                    ? dayjs(formData.deadline_date)
                                    : null
                                }
                                onChange={handleDeadlineDateChange}
                                disabledDate={(current) =>
                                  current && current < moment().startOf("day")
                                }
                              />
                            </div>


                          </div>

                          <div className="row">
                            {/* <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Consultant Name"
                                className="form-label lightgreen fs-6"
                              >
                                Consultant Name
                              </label>
                              <input
                                type="text"
                                placeholder="Consultant Name"
                                className="form-control"
                                id="consultant_name"
                                value={consultantName}
                                onChange={handleConsultantNameChange}
                              />
                            </div> */}

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Monitoring Period"
                                className="form-label lightgreen fs-6"
                              >
                                Monitoring Period
                              </label>

                              <NewForm form={rangeForm}>
                                <NewForm.Item name="startEndDate">
                                  <RangePicker
                                    style={{ width: "100%" }}
                                    format={"DD/MM/YYYY"}
                                    onChange={handleMonitoringPeriodChange}
                                    // onClear={rangeForm.setFieldValue("startEndDate", [null, null])}
                                  />
                                </NewForm.Item>
                              </NewForm>

                              {proposalDateError && (
                                <div className="invalid-feedback">
                                  {proposalDateError}
                                </div>
                              )}
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Proposal desc"
                                className="form-label lightgreen fs-6"
                              >
                                Proposal Description
                              </label>
                              <textarea
                                placeholder="Proposal Description"
                                className="form-control"
                                style={{ width: "100%" }}
                                id="proposal_desc"
                                name="proposal_desc"
                                rows={3}
                                value={proposalDesc}
                                onChange={handleProposalDescChange}
                              />
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="weblink"
                                className="form-label lightgreen fs-6"
                              >
                                Weblink
                              </label>

                              <textarea
                                placeholder="Weblink"
                                className="form-control"
                                style={{ width: "100%" }}
                                id="weblink"
                                rows={3}
                                value={weblink}
                                onChange={handleWeblinkChange}
                              />
                            </div>
                          </div>

                          <p className="textlightgreen font-weight-600 my-2 fs-5">
                            Assessment Team
                          </p>
                          <hr className="mb-2 lightgreen" />
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="teamleader"
                                className="form-label lightgreen fs-6"
                              >
                                Team Leader
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="team_leader"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="team_leader"
                                required
                                value={formData.team_leader}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    team_leader: e.target.value,
                                  })
                                }
                              >
                                <option value={""}>Select</option>
                                {atlist
                                  .filter((option) => option.skill_id == 1)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="taexpert"
                                className="form-label lightgreen fs-6"
                              >
                                TA Expert
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <MultiSelect
                                options={taTrExpert}
                                value={mytaexpert}
                                onChange={setMyTaexpert}
                                labelledBy="Select"
                              />
                            </div>

                            {/* <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="teamleader"
                                className="form-label lightgreen fs-6"
                              >
                                Local Expert
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="local_expert"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="local_expert"
                                value={formData.local_expert}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    local_expert: e.target.value,
                                  })
                                }
                              >
                                <option value={""}>To be added later</option>
                                {atlist
                                  .filter(
                                    (option) =>
                                      option.skill_id == 6 &&
                                      option.country_id == countryStatus
                                  )
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div> */}

                             <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="teamleader"
                                className="form-label lightgreen fs-6"
                              >
                                Local Expert
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="local_expert"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="local_expert"
                                value={formData.local_expert}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    local_expert: e.target.value,
                                  })
                                }
                              >
                                <option value={""}>To be added later</option>
                                {localExpert?.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                         
                                  
                              </select>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="financeexpert"
                                className="form-label lightgreen fs-6"
                              >
                                Finance Expert
                              </label>

                              <select
                                id="finance_expert"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="finance_expert"
                                value={formData.finance_expert}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    finance_expert: e.target.value,
                                  })
                                }
                              >
                                <option value={""}>Select</option>
                                {atlist
                                  .filter((option) => option.skill_id == 4)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="validator"
                                className="form-label lightgreen fs-6"
                              >
                                Validator / Verifier
                              </label>

                              <MultiSelect
                                options={validatorVerifier}
                                value={myvalidatorverifier}
                                onChange={setMyvalidatorVerifier}
                                labelledBy="Select"
                              />
                            </div>

                            {/* <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="validator"
                                className="form-label lightgreen fs-6"
                              >
                                Validator / Verifier
                              </label>

                              <select
                                id="validator_verifier"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="validator_verifier"
                                value={formData.validator_verifier}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    validator_verifier: e.target.value,
                                  })
                                }
                              >
                                <option value={""}>Select</option>
                                {atlist
                                  .filter((option) => option.skill_id == 2)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div> */}

                            {/* <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="methexpert"
                                className="form-label lightgreen fs-6"
                              >
                                Meth Expert
                              </label>

                              <select
                                id="meth_expert"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="meth_expert"
                                value={formData.meth_expert}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    meth_expert: e.target.value,
                                  })
                                }
                              >
                                <option value={""}>Select</option>
                                {atlist
                                  .filter((option) => option.skill_id == 5)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div> */}

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="methexpert"
                                className="form-label lightgreen fs-6"
                              >
                                Meth Expert
                              </label>

                              <MultiSelect
                                options={methExpert}
                                value={mymethexpert}
                                onChange={setMymethExpert}
                                labelledBy="Select"
                              />
                            </div>
                          </div>
                          <div className="row">
                            
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="trainee_validator"
                                className="form-label lightgreen fs-6"
                              >
                                Trainee Validator / Verifier
                              </label>

                              <MultiSelect
                                options={traineeValidator}
                                value={mytraineevalidator}
                                onChange={setMytraineevalidator}
                                labelledBy="Select"
                              />
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="technicalreviewer"
                                className="form-label lightgreen fs-6"
                              >
                                Technical Reviewer
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="technical_reviewer"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="technical_reviewer"
                                required
                                value={formData.technical_reviewer}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    technical_reviewer: e.target.value,
                                  })
                                }
                              >
                                <option value={""}>Select</option>
                                {trlist
                                  .filter(
                                    (option) => option.designation_id == 7
                                  )
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="technicalreviewer"
                                className="form-label lightgreen fs-6"
                              >
                                Expert to Technical Reviewer
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <MultiSelect
                                options={trExpert}
                                value={myexperttr}
                                onChange={setMyExpertTr}
                                labelledBy="Select"
                              />
                              {/* <select
                                id="expert_tr"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="expert_tr"
                                required
                                value={formData.expert_tr}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    expert_tr: e.target.value,
                                  })
                                }
                              >
                                <option value={""}>Select</option>

                                {trExpert
                                  .filter(
                                    (option) => option.skill_id == 8 
                                  )
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select> */}
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="gis_expert"
                                className="form-label lightgreen fs-6"
                              >
                                GIS Expert
                              </label>

                              <MultiSelect
                                options={gisExpert}
                                value={mygisexpert}
                                onChange={setMygisExpert}
                                labelledBy="Select"
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <p className="textlightgreen font-weight-600  my-3 fs-5">
                                Proposal Entity
                              </p>
                              <hr className="mb-4 lightgreen" />
                            </div>
                            <ProposalEntity
                              proposalEntityRecords={proposalEntityRecords}
                              formdisabled={false}
                              addProposalEntity={addProposalEntity}
                              handleProposalEntityChange={
                                handleProposalEntityChange
                              }
                              deleteProposalEntity={deleteProposalEntity}
                            />
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <p className="textlightgreen font-weight-600  my-3 fs-5">
                                Payment Terms
                              </p>
                              <hr className="mb-4 lightgreen" />
                            </div>
                            <PaymentTerms
                              paymentRecords={paymentRecords}
                              formdisabled={false}
                              addPayment={addPayment}
                              handlePaymentChange={handlePaymentChange}
                              deletePayment={deletePayment}
                            />
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <div class="mt-1 mb-3">
                                <label
                                  className="textlightgreen my-3 fs-5"
                                  style={{ fontWeight: "600" }}
                                >
                                  PT Remarks
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <textarea
                                  placeholder="Add Remarks"
                                  className="form-control"
                                  style={{ width: "100%" }}
                                  id="exampleFormControlTextarea1"
                                  rows={3}
                                  value={remarksPT}
                                  onChange={handleRemarksPTChange}
                                ></textarea>
                                {remarksPTError && (
                                  <div className="invalid-feedback text-danger">
                                    {remarksPTError}
                                  </div>
                                )}
                              </div>

                              <div className="col-12 d-flex justify-content-end pr-0">
                                <button
                                  type="submit"
                                  className="btn btn-success mt-3"
                                >
                                  Save & Next
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </Spin>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0 px-2 py-2 text-center tab_size text-wrap ${
                          activeTab == 2 ? "box_sh" : ""
                        }`}
                      >
                        <p>Attachments</p>
                      </div>
                    }
                    key="2"
                  >
                    {/* <Spin spinning={loading}> */}
                    <div className="container">
                      <div className="row">
                        <div className="col-12 border-0 py-3 mx-auto">
                          {isSubmitting && (
                            <div className="overlay">
                              <Progress type="circle" percent={percent} />
                            </div>
                          )}
                          <form
                            onSubmit={handleSubmitFiles}
                            method="POST"
                            encType="multipart/form-data"
                          >
                            {/* <b>Note:</b>To add multiple documents, upload documents in Supportive Docs e.g. PDD, MR, ER, IRR etc. as zipped files. */}

                            <table className="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th className="text-center lightgreen p-3 fw-bolder fs-6">
                                    Document Name{" "}
                                  </th>
                                  <th className="text-center lightgreen p-3 fw-bolder fs-6">
                                    Upload File
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="text-center lightgreen p-1">
                                    Request For Proposal Recieved From Client
                                    (F20)
                                  </td>
                                  {/* <td className="p-1">
                                      <FileUploader
                                        handleChange={handleFileRFPChange}
                                        name="rfp_doc"
                                        types={fileTypes}
                                        multiple="false"
                                        maxSize={dynamicMaxSize}
                                        onSizeError={handleSizeError}
                                      />

                                      {fileSizeError ? (
                                        <span className="text-danger">
                                          File size greater than {dynamicMaxSize} mb is not
                                          allowed
                                        </span>
                                      ) : (
                                        <span>
                                          {rfpname &&
                                            `File name: ${rfpname[0].name}`}
                                        </span>
                                      )}
                                    </td> */}

                                  <FileUploaderComponent
                                    file={rfpname}
                                    setFile={setRFPName}
                                  />
                                </tr>
                                <tr>
                                  <td className="text-center lightgreen p-1">
                                    Request For Proposal Reviewed (F20)
                                    <span style={{ color: "red" }}>*</span>
                                  </td>
                                  {/* <td className="p-1">
                                      <FileUploader
                                        handleChange={handleFileF20Change}
                                        name="f20_doc"
                                        types={fileTypes}
                                        multiple="false"
                                        maxSize={dynamicMaxSize}
                                        onSizeError={handleF20SizeError}
                                      />

                                      {fileF20SizeError ? (
                                        <span className="text-danger">
                                          File size greater than {dynamicMaxSize} mb is not
                                          allowed
                                        </span>
                                      ) : (
                                        <span>
                                          {f20name &&
                                            `File name: ${f20name[0].name}`}
                                        </span>
                                      )}
                                    </td> */}

                                  <FileUploaderComponent
                                    file={f20name}
                                    setFile={setF20Name}
                                  />
                                </tr>
                                <tr>
                                  <td className="text-center lightgreen p-1">
                                    Contract Review & Team Selection (F21)
                                    <span style={{ color: "red" }}>*</span>
                                  </td>

                                  {/* <td className="p-1">
                                      <FileUploader
                                        handleChange={handleFileF21Change}
                                        name="f21_doc"
                                        types={fileTypes}
                                        multiple="false"
                                        maxSize={dynamicMaxSize}
                                        onSizeError={handleF21SizeError}
                                      />

                                      {fileF21SizeError ? (
                                        <span className="text-danger">
                                          File size greater than {dynamicMaxSize} mb is not
                                          allowed
                                        </span>
                                      ) : (
                                        <span>
                                          {f21name &&
                                            `File name: ${f21name[0].name}`}
                                        </span>
                                      )}
                                    </td> */}

                                  <FileUploaderComponent
                                    file={f21name}
                                    setFile={setF21Name}
                                  />
                                </tr>
                                <tr>
                                  <td className="text-center lightgreen p-1">
                                    Service Agreement (F23)
                                    <span style={{ color: "red" }}>*</span>
                                  </td>

                                  {/* <td className="p-1">
                                      <FileUploader
                                        handleChange={handleFileF23Change}
                                        name="f23_doc"
                                        types={fileTypes}
                                        multiple="false"
                                        maxSize={dynamicMaxSize}
                                        onSizeError={handleF23SizeError}
                                      />

                                      {fileF23SizeError ? (
                                        <span className="text-danger">
                                          File size greater than {dynamicMaxSize} mb is not
                                          allowed
                                        </span>
                                      ) : (
                                        <span>
                                          {f23name &&
                                            `File name: ${f23name[0].name}`}
                                        </span>
                                      )}
                                    </td> */}
                                  <FileUploaderComponent
                                    file={f23name}
                                    setFile={setF23Name}
                                  />
                                </tr>
                                <tr>
                                  <td className="text-center lightgreen p-1">
                                    COI Document (F22)
                                    <span style={{ color: "red" }}>*</span>
                                  </td>

                                  {/* <td className="p-1">
                                      <FileUploader
                                        handleChange={handleFileCOIChange}
                                        name="coi_doc"
                                        types={fileTypes}
                                        multiple="true"
                                        maxSize={dynamicMaxSize}
                                        onSizeError={handleCOISizeError}
                                      />

                                      {fileCOISizeError ? (
                                        <span className="text-danger">
                                          File size greater than {dynamicMaxSize} mb is not
                                          allowed
                                        </span>
                                      ) : (
                                        <span >
                                          {coiname.map((coi) => <div><span style={{ marginRight: '20px' }}>&nbsp;{<DeleteTwoTone twoToneColor="#eb2f96" onClick={() => handleCOIDelete(coi)} />}</span>{`File name: ${coi?.name}`}</div>)}
                                        </span>
                                      )}

                                    </td> */}

                                  <MultipleFileUploader
                                    onFilesChange={handleFileCOIChange}
                                  />
                                </tr>

                                <tr>
                                  <td className="text-center lightgreen p-1">
                                    Supportive Documents
                                  </td>

                                  {/* <td className="p-1">
                                      <FileUploader
                                        handleChange={handleFileOtherChange}
                                        name="other_doc"
                                        types={fileTypes}
                                        multiple={true}
                                        maxSize={dynamicMaxSize}
                                        onSizeError={handleOtherSizeError}
                                      />

                                      {fileOtherSizeError ? (
                                        <span className="text-danger">
                                          File size greater than {dynamicMaxSize} mb is not
                                          allowed
                                        </span>
                                      ) : (
                                        <span>
                                          {othername.map((othername) => <div><span style={{ marginRight: '20px' }}>&nbsp;{<DeleteTwoTone twoToneColor="#eb2f96" onClick={() => handleSupportiveDelete(othername)} />}</span>{`File name: ${othername?.name}`}</div>)}

                                        </span>
                                      )}
                                    </td> */}

                                  <MultipleFileUploader
                                    onFilesChange={handleFileOtherChange}
                                  />
                                </tr>
                              </tbody>
                            </table>

                            <div className="col-12 d-flex justify-content-end gap-3 pr-0">
                              <button
                                onClick={handleBackToTab1}
                                className="btn btn-success mt-5"
                                type="button"
                              >
                                Previous
                              </button>
                              <button
                                type="submit"
                                className="btn btn-success mt-5"
                              >
                                Submit
                              </button>
                            </div>
                            {/* {<Progress percent={percent} />} */}
                          </form>
                        </div>
                      </div>
                    </div>
                    {/* </Spin> */}
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddProject;
