// import React ,{ useState, useEffect} from "react";
// import  Chart  from "react-apexcharts";
// import { API_HEADER, commonproject_graphs_url } from "../../config";
// import axios from "axios";

// function DealLostchart()
// {
//    const [dealLostReason, setDealLostReason]= useState([]);
//    const [dealLost, setDealLost]= useState([]);

//    useEffect( ()=>{
//        const lostDealReason=[];
//        const lostDeal=[];

//        const getlostDealdata= async()=>{
        
//        const reqData= await axios.get(`${commonproject_graphs_url}`, API_HEADER);
    
//        const mydeallost = reqData.data.deal_lost_reason_wise;
       
//        for(let i=0; i< mydeallost.length; i++)
//        {
//         lostDealReason.push(mydeallost[i].deal_lost_reason);
//         lostDeal.push((mydeallost[i].deal_lost));
//        }
//        setDealLostReason(lostDealReason);
//        setDealLost(lostDeal);
//        }

//     getlostDealdata();

//    },[]);

//     return(
//         <React.Fragment>
//             <div className="container-fluid">
               
//                 <Chart 
//                 type="pie"
//                 width={"100%"}
//                 height={300}

//                 series={ dealLost }                

//                 options={{
                       
//                        noData:{text:"Empty Data"},                        
                      
//                       labels: dealLostReason,      
//                       colors:'#78938a'  ,
//                       legend:{
//                         position: 'bottom'
//                     },          

//                  }}
//                 >
//                 </Chart>
//             </div>
//         </React.Fragment>
//     );
// }
// export default DealLostchart;

import React from "react";


function DealLostchart({ startDate, endDate }) {
  const grafanaBaseUrl = "https://grafana1.intileotech.xyz:3000/d-solo/cebnhxngazxtsd/sales-dashboard";
  const orgId = 1;
  const timezone = "browser";
  // const panelId = 4;
  const panelId = 22;
  const theme = "light";
 


    const role = localStorage.getItem("designation_id");
    const userData = localStorage.getItem("user");
    const userId = JSON.parse(userData);
  
    // Access the 'id' field
    const user_id = userId.id;
    console.log("Extracted ID:", user_id);


  // Convert start and end date to UNIX timestamps (milliseconds)
  const from = startDate ? startDate.format('YYYY-MM-DD') : '2024-04-01' 
  const to = endDate ? endDate.format('YYYY-MM-DD') : '2025-03-31' 
  
  
  // const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&from=${from}&to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-user_id=${user_id}`;
  
  
  const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-user_id=${user_id}`;


  console.log("from",from,"to",to)

  // Construct Grafana iframe URL dynamically
  // const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&var-from=${from}&var-to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}`;

  return (
    <div className="container-fluid">
      <iframe
        src={grafanaUrl}
        width="100%"
        height="400"
        frameBorder="0"
        title="Program Wise Projects"
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
}

export default DealLostchart;



