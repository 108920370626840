import {
  Input,
  Table,
  Tabs,
  DatePicker,
  Button,
  Select,
  Tooltip as Tip,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  faFileSignature,
  faFileArrowDown,
  faFileCircleCheck,
  faFileCircleQuestion,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
  get_proposal_detail_url,
  getCountryList,
  get_client_name_url,
  get_scope_url,
  get_program_url,
  at_tl_data_url,
  API_HEADER,
  getDashboardData,
  getAllProposals,
  team_change_listing_url,
} from "../../config";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LegendTable from "../LegendTable";
import ProjectNameWithDelay from "../ProjectNameWithDelay";
const { Option } = Select;

export default function TMSDash() {
  const mail_data = JSON.parse(localStorage.getItem("mail_reminder"));
  const [searchValue, setSearchValue] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [clientname, setClientname] = useState([]);

  const [scope, setScope] = useState(null);
  const [scopeList, setScopeList] = useState([]);
  const [programname, setProgramname] = useState([]);
  const [program, setProgram] = useState(null);

  const [spinloader, setspinloader] = useState(true);

  const editFormForDeskReview = async (id) => {
    navigate(`/atdeskreview/${id}`);
  };

  const getCountry = async () => {
    try {
      const result = await axios.get(`${getCountryList}`);
      setCountryList(result.data.data);
    } catch (error) {}
  };

  const getClientname = async () => {
    try {
      const result = await axios.get(`${get_client_name_url}`);
      setClientname(result.data.data);
    } catch (error) {}
  };

  const getProgramname = async () => {
    try {
      const result = await axios.get(`${get_program_url}`);
      setProgramname(result.data.data);
    } catch (error) {}
  };

  const getScope = async () => {
    try {
      const result = await axios.get(`${get_scope_url}`);
      setScopeList(result.data.data);
    } catch (error) {}
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    getCountry();
    getClientname();
    getProgramname();
    getScope();
  }, []);

  const dateFormat = "DD/MM/YYYY";
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [country, setCountry] = useState(null);
  const [client_id, setClient_id] = useState(null);

  const handleFromDateChange = (date) => {
    setFromDate(date);
    Setloader(true);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    Setloader(true);
  };

  const disabledFutureDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleSearchByDateRange = (value) => {
    const currentDate = moment();
    if (fromDate && fromDate.isAfter(currentDate)) {
      toast.error("From date cannot be a future date");
    } else if (toDate && toDate.isAfter(currentDate)) {
      toast.error("To date cannot be a future date");
    } else if (fromDate && toDate && fromDate.isAfter(toDate)) {
      toast.error("From date cannot be greater than to date");
    } else if (!fromDate && !toDate && !country && !client_id) {
      toast.error("Invalid Search");
    } else {
      Setloader(true);
    }
  };

  const navigate = useNavigate();
  const [proposal_received_pt, setProposal_received_pt] = useState(0);
  const [proposal_sent_clarify, setProposal_sent_clarify] = useState(0);
  const [proposal_sent_tm, setProposal_sent_tm] = useState(0);
  const [proposal_revertedby_tm, setProposal_revertedby_tm] = useState(0);
  const [proposal_sentto_sales, setProposal_sentto_sales] = useState(0);
  const [remoteSiteVisit, setRemoteSiteVisit] = useState(0);
  const [teamChangePermission, setTeamChangePermission] = useState(0);

  const [search, setSearch] = useState(null);

  const handleSearch = (value) => {
    setSearch(value);
    Setloader(true);
  };

  let [loader, Setloader] = useState(false);
  let [projectLoader, SetProjectLoader] = useState(false);

  const [activeKey, setActiveKey] = useState("1");
  const [statuskey, setStatus] = useState(1);
  const [projectStatus, setProjectStatus] = useState("");
  const [alldata, setAlldata] = useState([]);
  const [allProjectData, setAllProjectData] = useState([]);
  const [siteVisitStatus, setSiteVisitStatus] = useState("");
  const [teamChangedata, setTeamChangedata] = useState([]);
  const [teamChangeStatus, setTeamChangeStatus] = useState("");
  let [teamChangeLoader, SetTeamChangeLoader] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const getDashData = async () => {
    try {
      const result = await axios.get(`${getDashboardData}`, API_HEADER);
      const dashboard = result.data.dashboard;
      setProposal_received_pt(dashboard.status1);
      setProposal_sent_clarify(dashboard.status2);
      setProposal_sent_tm(dashboard.status5);
      setProposal_revertedby_tm(dashboard.status6);
      setProposal_sentto_sales(dashboard.status9);
      setRemoteSiteVisit(dashboard.remote_site_visit);
      setTeamChangePermission(dashboard.team_change);
      setspinloader(false);
    } catch (error) {}
  };

  const handleTmsActions = async (record) => {
    const payload = {
      proposal_id: record.proposal_id,
    };
    const response = await axios.post(
      `${get_proposal_detail_url}`,
      payload,
      API_HEADER
    );
    const data = response.data.record;
    console.log("rrrrrrrrrrrrr:", data);
    navigate("/tmsactions", { state: { data } });
  };

  const handleTeamChange = async (id, teamId) => {
    navigate(`/teamchangemodule/${id}`, { state: { teamId } });
  };

  const allData = async () => {
    try {
      let payload = {
        tms_status: statuskey,
        page: pagination.current,
        limit: pagination.pageSize,
        fromDate: fromDate ? fromDate.format("YYYY-MM-DD") : null,
        toDate: toDate ? toDate.format("YYYY-MM-DD") : null,
        country: country ? country : null,
        client_id: client_id ? client_id : null,
        search: search ? search : null,
      };
      const response = await axios.post(
        `${getAllProposals}`,
        payload,
        API_HEADER
      );

      setAlldata(response.data.data);

      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response.data.count,
      }));

      Setloader(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const allDataProject = async () => {
    try {
      let payload = {
        project_status: projectStatus,
        page: pagination.current,
        limit: pagination.pageSize,
        fromDate: fromDate ? fromDate.format("YYYY-MM-DD") : null,
        toDate: toDate ? toDate.format("YYYY-MM-DD") : null,
        country: country ? country : null,
        client_id: client_id ? client_id : null,
        program: program ? program : null,
        scope: scope ? scope : null,
        search: search ? search : null,
      };
      const response = await axios.post(
        `${at_tl_data_url}`,
        payload,
        API_HEADER
      );

      setAllProjectData(response.data.records.data);

      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response.data.records.total,
      }));

      SetProjectLoader(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const allTeamChangeData = async () => {
    try {
      let payload = {
        page: pagination.current,
        limit: pagination.pageSize,
        search: search ? search : null,
        team_change_approved_status: teamChangeStatus,
      };
      const response = await axios.post(
        `${team_change_listing_url}`,
        payload,
        API_HEADER
      );
      setTeamChangedata(response.data.records.data);

      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response.data.records.total,
      }));

      SetTeamChangeLoader(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleTeamSearch = (value) => {
    setSearch(value);
    SetTeamChangeLoader(true);
  };

  const handleTeamTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    SetTeamChangeLoader(true);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    Setloader(true);
  };

  const handleTabChange = (key) => {
    setFromDate("");
    setToDate("");
    setCountry("");
    setClient_id("");

    setActiveKey(key);

    setPagination((prevPagination) => ({
      ...prevPagination,
      current: 1,
    }));

    if (key == 1) {
      Setloader(true);
      setStatus(1);
    } else if (key == 2) {
      Setloader(true);
      setStatus(2);
    } else if (key == 3) {
      Setloader(true);
      setStatus(5);
    } else if (key == 4) {
      Setloader(true);
      setStatus(6);
    } else if (key == 5) {
      Setloader(true);
      setStatus(9);
    } else if (key == 6) {
      SetProjectLoader(true);
      setProjectStatus(3);
    } else if (key == 7) {
      SetTeamChangeLoader(true);
    }
  };

  useEffect(() => {
    if (activeKey == 6) {
      allDataProject();
    }
  }, [projectLoader]);

  useEffect(() => {
    if (activeKey == 7) {
      allTeamChangeData();
    }
  }, [teamChangeLoader, teamChangeStatus]);

  useEffect(() => {
    if (
      activeKey == 1 ||
      activeKey == 2 ||
      activeKey == 3 ||
      activeKey == 4 ||
      activeKey == 5
    ) {
      allData();
    }
  }, [loader]);

  useEffect(() => {
    getDashData();
  }, []);

  const handleClientNameSearch = (value) => {
    setClient_id(value);
    Setloader(true);
  };

  const handleCountrySearch = (value) => {
    setCountry(value);
    Setloader(true);
  };

  const handleProgramNameSearch = (value) => {
    setProgram(value);
    Setloader(true);
  };

  const handleScopeSearch = (value) => {
    setScope(value);
    Setloader(true);
  };

  const handleTeamChangeStatus = (e) => {
    setTeamChangeStatus(e.target.value);
  };

  const columnProposalReceivedPT = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Proposal Recd. Date
        </span>
      ),
      width: 130,
      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.created_at.slice(0, 10)}
          // </span>
          <Tip title={record.created_at.slice(0, 10)}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.created_at.slice(0, 10)}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.created_at.slice(0, 10) > record2.created_at.slice(0, 10)
          ? 1
          : record1.created_at.slice(0, 10) === record2.created_at.slice(0, 10)
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: 140,
      fixed: "left",
      // dataIndex: "earthood_id",
      render: (text, record) => {
        return (
          // <span className="font14px ">
          //   {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      // sorter: (record1, record2) => {
      //   return record1.earthood_id - record2.earthood_id
      // },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 120,
      render: (text, record) => {
        const proposalReceivedDateParts = record.created_at
          .split(" ")[0]
          .split("-");
        const proposalReceivedDate = new Date(
          `${proposalReceivedDateParts[2]}-${proposalReceivedDateParts[1]}-${proposalReceivedDateParts[0]}`
        );

        // Calculate the difference between today's date and the proposal received date
        const today = new Date();
        const differenceInMilliseconds = today - proposalReceivedDate;
        const differenceInDays = Math.floor(
          differenceInMilliseconds / (1000 * 60 * 60 * 24)
        );

        let projectNameStyle = { color: "green" };
        let delayDays = "";
        let redDot = false;

        if (
          differenceInDays == mail_data.mail_days_warning ||
          (differenceInDays > mail_data.mail_days_warning &&
            differenceInDays <= mail_data.mail_days_danger)
        ) {
          redDot = true;
        } else if (differenceInDays > mail_data.mail_days_danger) {
          projectNameStyle = { color: "red" };
          // delayDays = differenceInDays - mail_data.mail_days_danger;
          delayDays = differenceInDays;
          redDot = false;
        }

        return (
          <span className="text-capitalize font14px" style={projectNameStyle}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {redDot ? (
                <span>
                  <FontAwesomeIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ color: "red", marginRight: "5px" }}
                  />
                </span>
              ) : (
                ""
              )}
              <Tip title={record.project_name}>
                <span
                  className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis"
                  style={projectNameStyle}
                >
                  {record.project_name}
                </span>
              </Tip>
            </div>
            <div>{delayDays ? ` (${delayDays} days)` : ""}</div>
          </span>
        );
      },
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,
      render: (text, record) => {
        return (
          <Tip title={record.client_name}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.client_name}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.client_name > record2.client_name
          ? 1
          : record1.client_name === record2.client_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Sectoral Sector
        </span>
      ),
      width: 130,
      render: (text, record) => {
        if (record.sector) {
          return (
            <Tip title={record.sector}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.sector}
              </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return record1.sector > record2.sector
          ? 1
          : record1.sector === record2.sector
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 80,
      render: (text, record) => {
        if (record.country) {
          return (
            // <span className="text-capitalize textcolorgreen font14px">
            //   {record.country}
            // </span>
            <Tip title={record.country}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.country}
              </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return record1.country > record2.country
          ? 1
          : record1.country === record2.country
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 80,
      render: (record) => (
        <a className="">
          <EditOutlined
            onClick={() => handleTmsActions(record)}
            style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
          />
        </a>
      ),
    },
  ];

  const columnSentToSalesProposal = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 70,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Proposal Recd. Date
        </span>
      ),

      width: 130,
      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.pt_submit_date.slice(0, 10)}
          // </span>
          <Tip title={record.pt_submit_date.slice(0, 10)}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.pt_submit_date.slice(0, 10)}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.pt_submit_date.slice(0, 10) >
          record2.pt_submit_date.slice(0, 10)
          ? 1
          : record1.pt_submit_date.slice(0, 10) ===
            record2.pt_submit_date.slice(0, 10)
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action taken Date
        </span>
      ),
      width: 130,
      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.tms_action_date.slice(0, 10)}
          // </span>
          <Tip title={record.tms_action_date.slice(0, 10)}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.tms_action_date.slice(0, 10)}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.tm_action_date.slice(0, 10) >
          record2.tm_action_date.slice(0, 10)
          ? 1
          : record1.tm_action_date.slice(0, 10) ===
            record2.tm_action_date.slice(0, 10)
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: 160,
      // dataIndex: "earthood_id",
      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 130,
      render: (text, record) => {
        const proposalReceivedDateParts = record.created_at
          .split(" ")[0]
          .split("-");
        const proposalReceivedDate = new Date(
          `${proposalReceivedDateParts[2]}-${proposalReceivedDateParts[1]}-${proposalReceivedDateParts[0]}`
        );

        // Calculate the difference between today's date and the proposal received date
        const today = new Date();
        const differenceInMilliseconds = today - proposalReceivedDate;
        const differenceInDays = Math.floor(
          differenceInMilliseconds / (1000 * 60 * 60 * 24)
        );

        let projectNameStyle = { color: "green" };
        let delayDays = "";
        let redDot = false;

        if (
          differenceInDays == mail_data.mail_days_warning ||
          (differenceInDays > mail_data.mail_days_warning &&
            differenceInDays <= mail_data.mail_days_danger)
        ) {
          redDot = true;
        } else if (differenceInDays > mail_data.mail_days_danger) {
          projectNameStyle = { color: "red" };
          // delayDays = differenceInDays - mail_data.mail_days_danger;
          delayDays = differenceInDays;
          redDot = false;
        }

        return (
          <span className="text-capitalize font14px" style={projectNameStyle}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {redDot ? (
                <span>
                  <FontAwesomeIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ color: "red", marginRight: "5px" }}
                  />
                </span>
              ) : (
                ""
              )}
              <Tip title={record.project_name}>
                <span
                  className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis"
                  style={projectNameStyle}
                >
                  {record.project_name}
                </span>
              </Tip>
            </div>
            <div>{delayDays ? ` (${delayDays} days)` : ""}</div>
          </span>
        );
      },
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,
      render: (text, record) => {
        return (
          <Tip title={record.client_name}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.client_name}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.client_name > record2.client_name
          ? 1
          : record1.client_name === record2.client_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Sectoral Scope
        </span>
      ),
      width: 120,
      render: (text, record) => {
        if (record.sector) {
          return (
            <Tip title={record.sector}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.sector}
              </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return record1.sector > record2.sector
          ? 1
          : record1.sector === record2.sector
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 90,
      render: (text, record) => {
        if (record.country) {
          return (
            <Tip title={record.country}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.country}
              </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return record1.country > record2.country
          ? 1
          : record1.country === record2.country
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 80,
      render: (record) => (
        <a className="">
          <EyeOutlined
            onClick={() => handleTmsActions(record)}
            style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
          />
        </a>
      ),
    },
  ];

  const columnApprovedProposal = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 70,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Proposal Recd. Date
        </span>
      ),

      width: 120,
      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.pt_submit_date.slice(0, 10)}
          // </span>
          <Tip title={record.pt_submit_date.slice(0, 10)}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.pt_submit_date.slice(0, 10)}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.pt_submit_date.slice(0, 10) >
          record2.pt_submit_date.slice(0, 10)
          ? 1
          : record1.pt_submit_date.slice(0, 10) ===
            record2.pt_submit_date.slice(0, 10)
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action taken Date
        </span>
      ),
      width: 120,
      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.tms_action_date.slice(0, 10)}
          // </span>
          <Tip title={record.tms_action_date.slice(0, 10)}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.tms_action_date.slice(0, 10)}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.tm_action_date.slice(0, 10) >
          record2.tm_action_date.slice(0, 10)
          ? 1
          : record1.tm_action_date.slice(0, 10) ===
            record2.tm_action_date.slice(0, 10)
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: 150,
      // dataIndex: "earthood_id",
      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 130,
      render: (text, record) => {
        const proposalReceivedDateParts = record.created_at
          .split(" ")[0]
          .split("-");
        const proposalReceivedDate = new Date(
          `${proposalReceivedDateParts[2]}-${proposalReceivedDateParts[1]}-${proposalReceivedDateParts[0]}`
        );

        const today = new Date();
        const differenceInMilliseconds = today - proposalReceivedDate;
        const differenceInDays = Math.floor(
          differenceInMilliseconds / (1000 * 60 * 60 * 24)
        );

        let projectNameStyle = { color: "green" };
        let delayDays = "";
        let redDot = false;

        if (
          differenceInDays == mail_data.mail_days_warning ||
          (differenceInDays > mail_data.mail_days_warning &&
            differenceInDays <= mail_data.mail_days_danger)
        ) {
          redDot = true;
        } else if (differenceInDays > mail_data.mail_days_danger) {
          projectNameStyle = { color: "red" };
          // delayDays = differenceInDays - mail_data.mail_days_danger;
          delayDays = differenceInDays;
          redDot = false;
        }

        return (
          <span className="text-capitalize font14px" style={projectNameStyle}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {redDot ? (
                <span>
                  <FontAwesomeIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ color: "red", marginRight: "5px" }}
                  />
                </span>
              ) : (
                ""
              )}
              <Tip title={record.project_name}>
                <span
                  className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis"
                  style={projectNameStyle}
                >
                  {record.project_name}
                </span>
              </Tip>
            </div>
            <div>{delayDays ? ` (${delayDays} days)` : ""}</div>
          </span>
        );
      },
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,
      render: (text, record) => {
        return (
          <Tip title={record.client_name}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.client_name}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.client_name > record2.client_name
          ? 1
          : record1.client_name === record2.client_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Sectoral Scope
        </span>
      ),
      width: 100,
      render: (text, record) => {
        if (record.sector) {
          return (
            <Tip title={record.sector}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.sector}
              </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return record1.sector > record2.sector
          ? 1
          : record1.sector === record2.sector
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 90,
      render: (text, record) => {
        if (record.country) {
          return (
            <Tip title={record.country}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.country}
              </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return record1.country > record2.country
          ? 1
          : record1.country === record2.country
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 80,
      render: (record) => (
        <a className="">
          <EyeOutlined
            onClick={() => handleTmsActions(record)}
            style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
          />
        </a>
      ),
    },
  ];

  const columnRemoteSiteVisit = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: 140,

      render: (text, record) => {
        return (
          // <span className="font14px">
          //     {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
      // defaultSortOrder: 'ascend',
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,

      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.client_name?.name > record2.client_name?.name
          ? 1
          : record1.client_name?.name === record2.client_name?.name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 150,

      render: (text, record) => (
        // <span className="text-capitalize font14px textcolor">{record.project_name}</span>
        <ProjectNameWithDelay record={record} />
      ),
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Scope
        </span>
      ),
      width: 90,

      render: (text, record) => (
        // <span className="text-capitalize font14px textcolorgreen font14px">{record.scope_name?.scope}</span>
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.scope_name?.scope > record2.scope_name?.scope
          ? 1
          : record1.scope_name?.scope === record2.scope_name?.scope
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 80,

      render: (text, record) => (
        // <span className="text-capitalize font14px textcolorgreen font14px ">{record.program_name?.description}</span>
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.program_name?.description >
          record2.program_name?.description
          ? 1
          : record1.program_name?.description ===
            record2.program_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 80,

      render: (text, record) => (
        // <span className="text-capitalize font14px">{record.country_name?.description}</span>
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.country_name?.description >
          record2.country_name?.description
          ? 1
          : record1.country_name?.description ===
            record2.country_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Site Visit Status
        </span>
      ),
      width: 150,
      render: (text, record) => {
        let color = "black";
        let msg = "";
        if (record.site_visit_status == 0 || record.site_visit_status == null) {
          msg = "Not Scheduled";
          color = "blue";
        } else if (record.site_visit_status == 1) {
          msg = "Team Member Sent to TL";
        } else if (record.site_visit_status == 2) {
          msg = "TL Sent to Team Member";
        } else if (record.site_visit_status == 3) {
          msg = "Action Pending";
        } else if (record.site_visit_status == 4) {
          msg = "TM Support Raised Clarification";
        } else if (record.site_visit_status == 5) {
          msg = "TM Support Approved";
        } else if (record.site_visit_status == 6) {
          msg = "TM Raised Clarification";
        } else if (record.site_visit_status == 7) {
          msg = "TM Approved";
        } else if (record.site_visit_status == 8) {
          msg = "Team Member Sent to TL";
        } else if (record.site_visit_status == 9) {
          msg = "TL Sent to Team Member";
        } else if (record.site_visit_status == 10) {
          msg = "Action Pending";
        } else if (record.site_visit_status == 11) {
          msg = "SBU Head Raised Clarification";
        } else if (record.site_visit_status == 12) {
          msg = "Site Visit Approved";
        } else {
          msg = record.site_visit_status;
        }

        return (
          // <p className="px-lg-4 py-lg-2 rounded-5 text-sm" style={{ color }}>
          //     {msg}
          // </p>
          <Tip title={msg}>
            <p
              className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis"
              style={{ color }}
            >
              {msg}
            </p>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.site_visit_status > record2.site_visit_status
          ? 1
          : record1.site_visit_status === record2.site_visit_status
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 80,
      render: (record) => (
        <a>
          {record.site_visit_status === 3 || record.site_visit_status == 6 ? (
            <EditOutlined
              onClick={() => editFormForDeskReview(record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            />
          ) : (
            <EyeOutlined
              onClick={() => editFormForDeskReview(record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            />
          )}
        </a>
      ),
    },
  ];

  const ColumnTeamChange = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 80,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: 150,

      render: (text, record) => {
        return (
          // <span className="font14px">
          //     {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
            <span className="text-capitalize font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
      // defaultSortOrder: 'ascend',
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 120,

      render: (text, record) => (
        <Tip title={record.project_name}>
          <span className="text-capitalize font14px preserve-space text-ellipsis">
            {record.project_name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Team Change Status
        </span>
      ),
      width: 150,
      render: (text, record) => {
        let color = "black";
        let msg = "";

        if (record.team_change_status == 0) {
          msg = "Not Started";
        } else if (record.team_change_status == 1) {
          msg = "SBU Initiate the team Change";
        } else if (record.team_change_status == 2) {
          msg = "TL sent to team members";
        } else if (record.team_change_status == 3) {
          msg = "Team members sent to TL";
        } else if (record.team_change_status == 4) {
          msg = "Action Pending";
        } else if (record.team_change_status == 5) {
          msg = "TM support raised the clarification";
        } else if (record.team_change_status == 6) {
          msg = "TM support approved";
        } else if (record.team_change_status == 7) {
          msg = "TM raised the clarification";
        } else if (record.team_change_status == 8) {
          msg = "TM approved";
        }

        return (
          <Tip title={msg}>
            <span
              className="text-capitalize font14px preserve-space text-ellipsis"
              style={{ color }}
            >
              {msg}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.status > record2.status
          ? 1
          : record1.status === record2.status
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 80,
      render: (record) => (
        <a>
          {record.team_change_status === 4 ||
          record.team_change_status === 7 ? (
            <EditOutlined
              onClick={() => handleTeamChange(record.project_id, record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            />
          ) : (
            <EyeOutlined
              onClick={() => handleTeamChange(record.project_id, record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            />
          )}
        </a>
      ),
    },
  ];

  return (
    <>
      <Spin spinning={spinloader}>
        <div className="container-fluid bg-white">
          <div className="row">
            <div className="col-12">
              <Tabs
                defaultActiveKey="1"
                centered
                activeKey={activeKey}
                onChange={handleTabChange}
              >
                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tabTMS ${
                        activeKey == 1 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap ">
                        Pending for review
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {proposal_received_pt}
                        </p>
                        <p>
                          <FontAwesomeIcon
                            icon={faFileArrowDown}
                            size="xl"
                            className="iconcolor"
                          />
                        </p>
                      </div>
                    </div>
                  }
                  key="1"
                >
                  <div className="container-fluid mt-4">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className=" bg-white border-0 rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                Proposal Pending for review
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-2 col-lg-2">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  Client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-2 col-lg-2">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>

                            <div className="col-sm-4 col-md-3 col-lg-2">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3 col-lg-2">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>

                            <div className="col-sm-4 col-md-1 col-lg-2">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-4 col-lg-2 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                                enterButton
                                onSearch={handleSearch}
                              />
                            </div>
                          </div>
                        </div>
                        <Table
                          className="col-12 border-1 border ronded-0 border-subtle-light px-0"
                          scroll={{
                            x: 1000,
                          }}
                          columns={columnProposalReceivedPT}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tabTMSTMS ${
                        activeKey == 2 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap ">
                        Sent for clarification to PT
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {proposal_sent_clarify}
                        </p>
                        <p>
                          <FontAwesomeIcon
                            icon={faFileCircleQuestion}
                            size="xl"
                            className="iconcolor"
                          />
                        </p>
                      </div>
                    </div>
                  }
                  key="2"
                >
                  <div className="container-fluid mt-4">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                Proposal Sent For Clarification to PT
                              </p>
                            </div>
                          </div>
                          <div className="row p-2 gx-3 align-items-center">
                            <div className="col-sm-4 col-md-2 col-lg-2">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  Client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">
                                    <Select></Select>
                                  </Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-2 col-lg-2">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3 col-lg-2">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3 col-lg-2">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>

                            <div className="col-sm-4 col-md-1 col-lg-2">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-4 col-lg-2 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                onSearch={handleSearch}
                                style={{ width: "100%" }}
                                className="custom-search rounded-2"
                              />{" "}
                            </div>
                          </div>
                        </div>
                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          className=" border-1 border ronded-0 border-subtle-light px-0"
                          columns={columnApprovedProposal}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tabTMS ${
                        activeKey == 3 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap ">
                        Proposal Sent to TM
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {proposal_sent_tm}
                        </p>
                        <p>
                          <FontAwesomeIcon
                            icon={faFileCircleCheck}
                            size="xl"
                            className="iconcolor"
                          />
                        </p>
                      </div>
                    </div>
                  }
                  key="3"
                >
                  <div className="container-fluid mt-4">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                Proposal Sent to TM
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-2 col-lg-2">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  Client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-2 col-lg-2">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3 col-lg-2">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3 col-lg-2">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle   fw-bold font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>

                            <div className="col-sm-4 col-md-1 col-lg-2">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-4 col-lg-2 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                style={{ width: "100%" }}
                                onSearch={handleSearch}
                                className="custom-search rounded-2"
                              />
                            </div>
                          </div>
                        </div>
                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          className=" border-1 border ronded-0 border-subtle-light px-0"
                          columns={columnApprovedProposal}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tabTMS ${
                        activeKey == 4 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap ">
                        Proposal Reverted By TM
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {proposal_revertedby_tm}
                        </p>
                        <p>
                          <FontAwesomeIcon
                            icon={faFileCircleCheck}
                            size="xl"
                            className="iconcolor"
                          />
                        </p>
                      </div>
                    </div>
                  }
                  key="4"
                >
                  <div className="container-fluid mt-4">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                Proposal Reverted by TM
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-2 col-lg-2">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  Client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-2 col-lg-2">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3 col-lg-2">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3 col-lg-2">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle   fw-bold font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>

                            <div className="col-sm-4 col-md-1 col-lg-2">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-4 col-lg-2 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                style={{ width: "100%" }}
                                onSearch={handleSearch}
                                className="custom-search rounded-2"
                              />
                            </div>
                          </div>
                        </div>
                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          className=" border-1 border ronded-0 border-subtle-light px-0"
                          columns={columnProposalReceivedPT}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tabTMS ${
                        activeKey == 5 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap ">
                        Submitted to Sales
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {proposal_sentto_sales}
                        </p>
                        <p>
                          <FontAwesomeIcon
                            icon={faFileCircleCheck}
                            size="xl"
                            className="iconcolor"
                          />
                        </p>
                      </div>
                    </div>
                  }
                  key="5"
                >
                  <div className="container-fluid mt-4">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                Submitted to Sales
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-2 col-lg-2">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  Client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-2 col-lg-2">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3 col-lg-2">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3 col-lg-2">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle   fw-bold font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>

                            <div className="col-sm-4 col-md-1 col-lg-2">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-4 col-lg-2 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                style={{ width: "100%" }}
                                onSearch={handleSearch}
                                className="custom-search rounded-2"
                              />
                            </div>
                          </div>
                        </div>
                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          className=" border-1 border ronded-0 border-subtle-light px-0"
                          columns={columnSentToSalesProposal}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 6 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        Remote Site Visit
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {remoteSiteVisit}
                        </p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </div>
                    </div>
                  }
                  key="6"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                Remote Site Visit
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="d-grid mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Scope{" "}
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select scope"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleScopeSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>

                                  {scopeList.map((scope, index) => (
                                    <Option
                                      key={index}
                                      value={scope.id}
                                      label={scope.sector_name}
                                    >
                                      {scope.sector_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  program Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select program name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleProgramNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {programname.map((program, index) => (
                                    <Option
                                      key={index}
                                      value={program.id}
                                      label={program.program_name}
                                    >
                                      {program.program_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-3 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                onSearch={handleSearch}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                              />
                            </div>
                          </div>
                        </div>

                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={columnRemoteSiteVisit}
                          loading={loader}
                          dataSource={allProjectData}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 7 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        Team Change
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {teamChangePermission}
                        </p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </div>
                    </div>
                  }
                  key="7"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                Team Change
                              </p>
                            </div>
                          </div>

                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-4 col-lg-3 mb-3">
                              <label htmlFor="teamStatus">
                                Team change status
                              </label>
                              <select
                                id="teamStatus"
                                className="form-select"
                                value={teamChangeStatus}
                                onChange={handleTeamChangeStatus}
                              >
                                <option value="">Select</option>
                                <option value="0">Pending</option>
                                <option value="1">Approved</option>
                              </select>
                            </div>

                            <div className="col-sm-4 col-md-4 col-lg-3 mb-3 ml-auto">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                                enterButton
                                onSearch={handleTeamSearch}
                              />
                            </div>
                          </div>
                        </div>

                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={ColumnTeamChange}
                          loading={teamChangeLoader}
                          dataSource={teamChangedata}
                          rowKey="id"
                          pagination={pagination}
                          onChange={handleTeamTableChange}
                          bordered
                        />
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
}
