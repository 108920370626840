// import ExcelJS from 'exceljs';
// import { saveAs } from 'file-saver';
// import ReactDOMServer from 'react-dom/server';

// const pagination = {
//   current: 1,
//   pageSize: 10
// };

// const exportToExcel = (data, columns) => {
//   const workbook = new ExcelJS.Workbook();
//   const worksheet = workbook.addWorksheet('Sheet1');

//   worksheet.addRow(columns.map(col => extractHeaderTitle(col)));

//   data.forEach((row, rowIndex) => {
//     worksheet.addRow(columns.map(col => {
//       if (col.dataIndex === 'id') {
//         const pageIndex = (pagination.current - 1) * pagination.pageSize;
//         return pageIndex + rowIndex + 1;
//       }

//       const cellValue = col.render ? col.render(row[col.dataIndex], row) : row[col.dataIndex];
//       return extractCellValue(cellValue);
//     }));
//   });

//   const headerRow = worksheet.getRow(1);
//   headerRow.eachCell({ includeEmpty: true }, (cell) => {
//     cell.fill = {
//       type: 'pattern',
//       pattern: 'solid',
//       fgColor: { argb: 'FFFF00' } 
//     };
//     cell.font = {
//       bold: true
//     };
//   });

//   columns.forEach((col, index) => {
//     worksheet.getColumn(index + 1).width = 20; 
//   });

//   workbook.xlsx.writeBuffer().then((buffer) => {
//     saveAs(new Blob([buffer]), 'table-data.xlsx');
//   });
// };

// const extractHeaderTitle = (col) => {
//   if (typeof col.title === 'string') {
//     return col.title;
//   } else if (col.title && col.title.props && col.title.props.children) {
//     return col.title.props.children;
//   }
//   return '';
// };

// const extractCellValue = (cellValue) => {
//   if (typeof cellValue === 'string' || typeof cellValue === 'number') {
//     return cellValue;
//   } else if (cellValue && typeof cellValue === 'object') {
//     return ReactDOMServer.renderToStaticMarkup(cellValue).replace(/<[^>]+>/g, ''); // Remove HTML tags
//   }
//   return ''; 
// };

// export default exportToExcel;


import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import ReactDOMServer from 'react-dom/server';

const pagination = {
  current: 1,
  pageSize: 10
};

const exportToExcel = (data, columns) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');

  worksheet.addRow(columns.map(col => extractHeaderTitle(col)));

  data.forEach((row, rowIndex) => {
    worksheet.addRow(columns.map(col => {
      if (col.dataIndex === 'id') {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + rowIndex + 1;
      }

      const cellValue = col.render ? col.render(row[col.dataIndex], row) : row[col.dataIndex];
      return extractCellValue(cellValue);
    }));
  });

  const headerRow = worksheet.getRow(1);
  headerRow.eachCell({ includeEmpty: true }, (cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' } 
    };
    cell.font = {
      bold: true
    };
  });

  columns.forEach((col, index) => {
    worksheet.getColumn(index + 1).width = 20; 
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer]), 'table-data.xlsx');
  });
};

const extractHeaderTitle = (col) => {
  if (typeof col.title === 'string') {
    return col.title;
  } else if (col.title && col.title.props && col.title.props.children) {
    return col.title.props.children;
  }
  return '';
};

const decodeHtmlEntities = (str) => {
  const tempElement = document.createElement('textarea');
  tempElement.innerHTML = str;
  return tempElement.value;
};

const extractCellValue = (cellValue) => {
  if (typeof cellValue === 'string') {
    return decodeHtmlEntities(cellValue); // Decode HTML entities
  } else if (typeof cellValue === 'number') {
    return cellValue;
  } else if (cellValue && typeof cellValue === 'object') {
    const staticMarkup = ReactDOMServer.renderToStaticMarkup(cellValue);
    const plainText = staticMarkup.replace(/<[^>]+>/g, ''); // Remove HTML tags
    return decodeHtmlEntities(plainText); // Decode any remaining entities
  }
  return '';
};


export default exportToExcel;
