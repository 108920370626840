import { Input, Table, Tabs, DatePicker, Button, Select } from "antd";
import { Tooltip as Tip } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCircleQuestion,
  faFileCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
  API_HEADER,
  getDashboardData,
  at_tl_data_url,
  getCountryList,
  get_client_name_url,
  get_scope_url,
  get_program_url,
  team_change_listing_url,
} from "../../config";
import axios from "axios";
import { toast } from "react-toastify";
import {
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  InfoOutlined,
  ZoomInOutlined,
} from "@ant-design/icons";
import LegendTable from "../LegendTable";
import "../../App.css";
import ProjectNameWithDelay from "../ProjectNameWithDelay";
import { Spin } from "antd";

const { Option } = Select;

const AT_TLDash = () => {
  const navigate = useNavigate();
  const { ids } = useParams();
  const [searchValue, setSearchValue] = useState("");
  const [signedContract, setSignedContract] = useState(0);
  const [underLODReview, setUnderLODReview] = useState(0);
  const [underDeskReview, setUnderDeskReview] = useState(0);
  const [siteVisitCompleted, setSiteVisitCompleted] = useState(0);
  const [siteVisitfindings, setSiteVisitFindings] = useState(0);
  const [underDvrReview, setUnderDvrReview] = useState(0);
  const [underTrReview, setUnderTrReview] = useState(0);
  const [CCReview, setCCReview] = useState(0);
  const [myCCReview, setMyCCReview] = useState(0);
  const [myRFIReview, setRFIReview] = useState(0);
  const [myRFICompleted, setRFICompleted] = useState(0);
  const [underKickout, setUnderKickout] = useState(0);
  const [myKickoutCCReview, setMyKickoutCCReview] = useState(0);
  const [issuedRegisters, setIssuedRegisters] = useState(0);
  const [teamChangeCount, setTeamChangeCount] = useState(0);
  const [totalProject, setTotalProject] = useState(0);
  const [clientPendingDoc, setclientPendingDoc] = useState(0);
  const [earthoodPendingDoc, setearthoodPendingDoc] = useState(0);
  let [loader, Setloader] = useState(false);
  const [type, setType] = useState(1);
  const [statuskey, setStatus] = useState(1);
  const [alldata, setAlldata] = useState([]);
  const [dvrStatus, setdvrStatus] = useState("");
  const [kickoutStatus, setKickoutStatus] = useState("");
  const [activeKey, setActiveKey] = useState("1");
  const [teamChangedata, setTeamChangedata] = useState([]);
  let [teamChangeLoader, SetTeamChangeLoader] = useState(false);
  const [teamChangeStatus, setTeamChangeStatus] = useState("");

  let [dashLoader, setdashLoader] = useState(true);

  const editFormForKickOut = async (id) => {
    navigate(`/kickoutmodule/${id}`);
  };

  const handleTeamChange = async (id, teamId) => {
    navigate(`/teamchangemodule/${id}`, { state: { teamId } });
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const getDashData = async () => {
    try {
      const result = await axios.get(`${getDashboardData}`, API_HEADER);
      const dashboard = result.data.dashboard;

      setSignedContract(dashboard.status1);
      setUnderLODReview(dashboard.status2);
      setUnderDeskReview(dashboard.status3);
      setSiteVisitCompleted(dashboard.status4);
      setSiteVisitFindings(dashboard.status5);
      setUnderDvrReview(dashboard.status6);
      setUnderTrReview(dashboard.status7);
      setCCReview(dashboard.status8);
      setRFIReview(dashboard.status9);
      setRFICompleted(dashboard.status10);
      setMyCCReview(result.data.underCCReview);
      setUnderKickout(dashboard.status11);
      setMyKickoutCCReview(result.data.kickoutCC);
      setIssuedRegisters(dashboard.status12);
      setTeamChangeCount(dashboard.team_change);
      setTotalProject(dashboard.total_project);
      setclientPendingDoc(dashboard.pending_at_client);
      setearthoodPendingDoc(dashboard.pending_at_earthood);

      setdashLoader(false);
    } catch (error) {}
  };

  useEffect(() => {
    getDashData();
  }, []);

  const allData = async () => {
    try {
      let payload = {
        project_status: statuskey,
        page: pagination.current,
        limit: pagination.pageSize,
        fromDate: fromDate ? fromDate.format("YYYY-MM-DD") : null,
        toDate: toDate ? toDate.format("YYYY-MM-DD") : null,
        country: country ? country : null,
        client_id: client_id ? client_id : null,
        program: program ? program : null,
        scope: scope ? scope : null,
        search: search ? search : null,
        type: type,
        dvr_status: dvrStatus,
        kickout_status: kickoutStatus,
      };
      const response = await axios.post(
        `${at_tl_data_url}`,
        payload,
        API_HEADER
      );

      setAlldata(response.data.records.data);

      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response.data.records.total,
      }));

      Setloader(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const allTeamChangeData = async () => {
    try {
      let payload = {
        search: search ? search : null,
        team_change_approved_status: teamChangeStatus,
      };
      const response = await axios.post(
        `${team_change_listing_url}`,
        payload,
        API_HEADER
      );
      setTeamChangedata(response.data.records.data);

      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response.data.records.total,
      }));

      SetTeamChangeLoader(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleTabChange = (key) => {
    setFromDate(null);
    setToDate(null);
    setCountry(null);
    setClient_id(null);
    setProgram(null);
    setScope(null);
    setSearch(null);

    setActiveKey(key);

    setType(1);

    setPagination((prevPagination) => ({
      ...prevPagination,
      current: 1,
    }));
    setdvrStatus("");

    if (key == 1) {
      setStatus(1);
      setKickoutStatus("");
      Setloader(true);
    } else if (key == 2) {
      setStatus(2);
      setKickoutStatus("");
      Setloader(true);
    } else if (key == 3) {
      setStatus(3);
      setKickoutStatus("");
      Setloader(true);
    } else if (key == 4) {
      setStatus(4);
      setKickoutStatus("");
      Setloader(true);
    } else if (key == 5) {
      setStatus(5);
      setKickoutStatus("");
      Setloader(true);
    } else if (key == 6) {
      setStatus(6);
      setKickoutStatus("");
      Setloader(true);
    } else if (key == 7) {
      setStatus(7);
      setKickoutStatus("");
      Setloader(true);
    } else if (key == 8) {
      setStatus(8);
      setType(1);
      setKickoutStatus("");
      Setloader(true);
    } else if (key == 9) {
      setStatus(8);
      setType(2);
      setKickoutStatus("");
      Setloader(true);
    } else if (key == 10) {
      setStatus(9);
      setKickoutStatus("");
      Setloader(true);
    } else if (key == 11) {
      setStatus(10);
      setKickoutStatus("");
      Setloader(true);
    } else if (key == 12) {
      setStatus(11);
      setKickoutStatus("");
      Setloader(true);
    } else if (key == 13) {
      setStatus(12);
      setKickoutStatus("");
      Setloader(true);
    } else if (key == 14) {
      setStatus(11);
      setKickoutStatus(14);
      setType(2);
      Setloader(true);
    } else if (key == 15) {
      SetTeamChangeLoader(true);
    }
  };

  useEffect(() => {
    if (activeKey != 15) {
      allData();
    }
  }, [loader]);

  useEffect(() => {
    if (activeKey == 15) {
      allTeamChangeData();
    }
  }, [teamChangeLoader, teamChangeStatus]);

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    Setloader(true);
  };

  const dateFormat = "DD/MM/YYYY";
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [country, setCountry] = useState(null);
  const [client_id, setClient_id] = useState(null);
  const [program, setProgram] = useState(null);
  const [search, setSearch] = useState(null);

  const handleSearch = (value) => {
    setSearch(value);
    Setloader(true);
  };

  const editFormForDeskReview = async (id) => {
    navigate(`/atdeskreview/${id}`);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
    Setloader(true);
  };
  const disabledFutureDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    Setloader(true);
  };

  const handleSearchByDateRange = (value) => {
    const currentDate = moment();
    if (fromDate && fromDate.isAfter(currentDate)) {
      toast.error("From date cannot be a future date");
    } else if (toDate && toDate.isAfter(currentDate)) {
      toast.error("To date cannot be a future date");
    } else if (fromDate && toDate && fromDate.isAfter(toDate)) {
      toast.error("From date cannot be greater than to date");
    } else {
      Setloader(true);
    }
  };
  const handleClientNameSearch = (value) => {
    setClient_id(value);
    Setloader(true);
  };
  const handleProgramNameSearch = (value) => {
    setProgram(value);
    Setloader(true);
  };
  const handleCountrySearch = (value) => {
    setCountry(value);
    Setloader(true);
  };
  const handleScopeSearch = (value) => {
    setScope(value);
    Setloader(true);
  };

  const handleSearchAll = (value) => {
    setSearch(value);
    Setloader(true);
  };

  const [scope, setScope] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [scopeList, setScopeList] = useState([]);
  const [clientname, setClientname] = useState([]);
  const [programname, setProgramname] = useState([]);

  const getCountry = async () => {
    try {
      const result = await axios.get(`${getCountryList}`);
      setCountryList(result.data.data);
    } catch (error) {
      toast.error("Error fetching country list");
    }
  };
  const getClientname = async () => {
    try {
      const result = await axios.get(`${get_client_name_url}`);
      setClientname(result.data.data);
    } catch (error) {
      toast.error("Error fetching Clientname list");
    }
  };
  const getProgramname = async () => {
    try {
      const result = await axios.get(`${get_program_url}`);
      setProgramname(result.data.data);
    } catch (error) {
      toast.error("Error fetching Clientname list");
    }
  };
  const getScope = async () => {
    try {
      const result = await axios.get(`${get_scope_url}`);
      setScopeList(result.data.data);
    } catch (error) {
      toast.error("Error fetching Scope list");
    }
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    getCountry();
    getClientname();
    getProgramname();
    getScope();
  }, []);

  const handletotalprojects = () => {
    navigate("/totalprojectsat");
  };

  const handleClientEndPending = () => {
    navigate("/totalprojectsat", { state: { id: 1 } });
  };

  const handleEarthoodEndPending = () => {
    navigate("/totalprojectsat", { state: { id: 2 } });
  };

  const handleTeamChangeStatus = (e) => {
    setTeamChangeStatus(e.target.value);
  };

  const columnSignedContract = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold ">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: "17%",
      render: (text, record) => {
        return (
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Contract Date
        </span>
      ),
      width: 120,
      render: (text, record) => (
        <span className="text-capitalize font14px">
          {record.created_at.slice(8, 10)}-{record.created_at.slice(5, 7)}-
          {record.created_at.slice(0, 4)}
        </span>
      ),
      sorter: (record1, record2) => {
        return record1.created_at.slice(0, 10) > record2.created_at.slice(0, 10)
          ? 1
          : record1.created_at.slice(0, 10) === record2.created_at.slice(0, 10)
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 150,
      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.client_name?.name > record2.client_name?.name
          ? 1
          : record1.client_name?.name === record2.client_name?.name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 180,
      render: (text, record) => <ProjectNameWithDelay record={record} />,
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Scope
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.scope_name?.scope > record2.scope_name?.scope
          ? 1
          : record1.scope_name?.scope === record2.scope_name?.scope
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.program_name?.description >
          record2.program_name?.description
          ? 1
          : record1.program_name?.description ===
            record2.program_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.country_name?.description >
          record2.country_name?.description
          ? 1
          : record1.country_name?.description ===
            record2.country_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 80,
      render: (text, record) => (
        <a className="">
          <EyeOutlined
            onClick={() => editFormForDeskReview(record.id)}
            style={{ marginRight: "8px", color: "blue", fontSize: "16px" }}
          />
        </a>
      ),
    },
  ];

  const columnUnderLODReview = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 70,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: "17%",
      render: (text, record) => {
        return (
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Contract Date
        </span>
      ),
      width: 130,
      render: (text, record) => (
        <span className="text-capitalize font14px">
          {record.created_at.slice(8, 10)}-{record.created_at.slice(5, 7)}-
          {record.created_at.slice(0, 4)}
        </span>
      ),
      sorter: (record1, record2) => {
        return record1.created_at.slice(0, 10) > record2.created_at.slice(0, 10)
          ? 1
          : record1.created_at.slice(0, 10) === record2.created_at.slice(0, 10)
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,

      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.client_name?.name > record2.client_name?.name
          ? 1
          : record1.client_name?.name === record2.client_name?.name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 160,

      render: (text, record) => <ProjectNameWithDelay record={record} />,

      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Scope
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.scope_name?.scope > record2.scope_name?.scope
          ? 1
          : record1.scope_name?.scope === record2.scope_name?.scope
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.program_name?.description >
          record2.program_name?.description
          ? 1
          : record1.program_name?.description ===
            record2.program_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.country_name?.description >
          record2.country_name?.description
          ? 1
          : record1.country_name?.description ===
            record2.country_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Status
        </span>
      ),
      width: 120,
      render: (text, record) => {
        let color = "red";
        let msg = "";
        if (record.status == 1) {
          msg = "Contract Signed";
          color = "orange";
        } else if (record.status == 2) {
          msg = "Documents received from Client";
          color = "black";
        } else if (record.status == 3) {
          msg = "TL Approval Pending";
          color = "#153448";
        } else if (record.status == 4) {
          msg = "Forwarded to Client";
          color = "green";
        } else if (record.status == 5) {
          msg = "Send to Team Member";
          color = "blue";
        } else if (record.status == 6) {
          msg = "Client Resubmitted Document";
          color = "#153448";
        } else if (record.status == 7) {
          msg = "Finding Pending";
        } else {
          msg = record.status;
        }

        return (
          <Tip title={msg}>
            <span
              className="text-capitalize font14px preserve-space text-ellipsis"
              style={{ color }}
            >
              {msg}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.status > record2.status
          ? 1
          : record1.status === record2.status
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 80,
      render: (text, record) => (
        <a className="">
          {record.status === 4 ? (
            <EyeOutlined
              onClick={() => editFormForDeskReview(record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            />
          ) : (
            <EditOutlined
              onClick={() => editFormForDeskReview(record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            />
          )}
        </a>
      ),
    },
  ];

  const columnUnderDeskReview = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 70,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: "17%",

      render: (text, record) => {
        return (
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
      // defaultSortOrder: 'ascend',
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,

      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.client_name?.name > record2.client_name?.name
          ? 1
          : record1.client_name?.name === record2.client_name?.name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 130,

      render: (text, record) => <ProjectNameWithDelay record={record} />,
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Scope
        </span>
      ),
      width: 80,

      render: (text, record) => (
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.scope_name?.scope > record2.scope_name?.scope
          ? 1
          : record1.scope_name?.scope === record2.scope_name?.scope
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.program_name?.description >
          record2.program_name?.description
          ? 1
          : record1.program_name?.description ===
            record2.program_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.country_name?.description >
          record2.country_name?.description
          ? 1
          : record1.country_name?.description ===
            record2.country_name?.description
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Finding Status
        </span>
      ),
      width: 140,
      render: (text, record) => {
        let color = "red";
        let msg = "";
        if (record.finding_status == 0 || record.finding_status == null) {
          msg = "Not Started";
          color = "red";
        } else if (record.finding_status == 1) {
          msg = "Submitted to TL";
          color = "orange";
        } else if (record.finding_status == 2) {
          msg = "Team Leader Sent to Team Member";
          color = "black";
        } else if (record.finding_status == 3) {
          msg = "Team Leader Sent to Team Member";
          color = "black";
        } else if (record.finding_status == 4) {
          msg = "Forwarded to client";
          color = "black";
        } else if (record.finding_status == 5) {
          msg = "Client Responded";
          color = "green";
        } else if (record.finding_status == 6) {
          msg = "Finding Closed";
          color = "brown";
        } else {
          msg = record.finding_status;
        }

        return (
          <Tip title={msg}>
            <span
              className="text-capitalize font14px preserve-space text-ellipsis"
              style={{ color }}
            >
              {msg}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.finding_status > record2.finding_status
          ? 1
          : record1.finding_status === record2.finding_status
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Site Visit Status
        </span>
      ),
      width: 140,
      render: (text, record) => {
        let color = "black";
        let msg = "";
        if (record.site_visit_status == 0 || record.site_visit_status == null) {
          msg = "Not Scheduled";
          color = "blue";
        } else if (record.site_visit_status == 1) {
          msg = "Team Member Sent to TL";
        } else if (record.site_visit_status == 2) {
          msg = "TL Sent to Team Member";
        } else if (record.site_visit_status == 3) {
          msg = "Sent to TM Support";
        } else if (record.site_visit_status == 4) {
          msg = "TM Support Raised Clarification";
        } else if (record.site_visit_status == 5) {
          msg = "TM Support Approved";
        } else if (record.site_visit_status == 6) {
          msg = "TM Raised Clarification";
        } else if (record.site_visit_status == 7) {
          msg = "TM Approved";
        } else if (record.site_visit_status == 8) {
          msg = "Team Member Sent to TL";
        } else if (record.site_visit_status == 9) {
          msg = "TL Sent to Team Member";
        } else if (record.site_visit_status == 10) {
          msg = "Sent to SBU Head";
        } else if (record.site_visit_status == 11) {
          msg = "SBU Head Raised Clarification";
        } else if (record.site_visit_status == 12) {
          msg = "Site Visit Approved";
        } else {
          msg = record.site_visit_status;
        }

        return (
          <Tip title={msg}>
            <span
              className="text-capitalize font14px preserve-space text-ellipsis"
              style={{ color }}
            >
              {msg}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.site_visit_status > record2.site_visit_status
          ? 1
          : record1.site_visit_status === record2.site_visit_status
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 90,
      render: (record) => (
        <a>
          <EditOutlined
            style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            onClick={() => editFormForDeskReview(record.id)}
          />
        </a>
      ),
    },
  ];

  const columnSiteVisitCompleted = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: "17%",

      render: (text, record) => {
        return (
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
      // defaultSortOrder: 'ascend',
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 180,

      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.client_name?.name > record2.client_name?.name
          ? 1
          : record1.client_name?.name === record2.client_name?.name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 200,

      render: (text, record) => <ProjectNameWithDelay record={record} />,
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Scope
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.scope_name?.scope > record2.scope_name?.scope
          ? 1
          : record1.scope_name?.scope === record2.scope_name?.scope
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.program_name?.description >
          record2.program_name?.description
          ? 1
          : record1.program_name?.description ===
            record2.program_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 90,

      render: (text, record) => (
        // <span className="text-capitalize font14px">{record.country_name?.description}</span>
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.country_name?.description >
          record2.country_name?.description
          ? 1
          : record1.country_name?.description ===
            record2.country_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 80,
      render: (record) => (
        <a>
          <EditOutlined
            style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            onClick={() => editFormForDeskReview(record.id)}
          />
        </a>
      ),
    },
  ];

  const columnSiteVisitFindings = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: "17%",

      render: (text, record) => {
        return (
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 140,

      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.client_name?.name > record2.client_name?.name
          ? 1
          : record1.client_name?.name === record2.client_name?.name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 180,

      render: (text, record) => <ProjectNameWithDelay record={record} />,
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Scope
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.scope_name?.scope > record2.scope_name?.scope
          ? 1
          : record1.scope_name?.scope === record2.scope_name?.scope
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 80,

      render: (text, record) => (
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.program_name?.description >
          record2.program_name?.description
          ? 1
          : record1.program_name?.description ===
            record2.program_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 80,

      render: (text, record) => (
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.country_name?.description >
          record2.country_name?.description
          ? 1
          : record1.country_name?.description ===
            record2.country_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Site Finding Status
        </span>
      ),
      width: 160,
      render: (text, record) => {
        let color = "red";
        let msg = "";
        if (
          record.site_finding_status == 0 ||
          record.site_finding_status == null
        ) {
          msg = "Not Started";
          color = "red";
        } else if (record.site_finding_status == 1) {
          msg = "Submitted to TL";
          color = "orange";
        } else if (record.site_finding_status == 2) {
          msg = "TL reverted to Team";
          color = "black";
        } else if (record.site_finding_status == 3) {
          msg = "TL reverted to Team";
          color = "black";
        } else if (record.site_finding_status == 4) {
          msg = "Forwarded to client";
          color = "black";
        } else if (record.site_finding_status == 5) {
          msg = "Client Responded";
          color = "green";
        } else if (record.site_finding_status == 6) {
          msg = "Site Finding Closed";
          color = "brown";
        } else {
          msg = record.site_finding_status;
        }

        return (
          <Tip title={msg}>
            <span
              className="text-capitalize  font14px preserve-space text-ellipsis"
              style={{ color }}
            >
              {msg}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.site_finding_status > record2.site_finding_status
          ? 1
          : record1.site_finding_status === record2.site_finding_status
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 80,
      render: (record) => (
        <a>
          {record.status === 4 ? (
            <EyeOutlined
              onClick={() => editFormForDeskReview(record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            />
          ) : (
            <EditOutlined
              onClick={() => editFormForDeskReview(record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            />
          )}
        </a>
      ),
    },
  ];

  const columnDvrReview = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: "17%",

      render: (text, record) => {
        return (
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,

      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.client_name?.name > record2.client_name?.name
          ? 1
          : record1.client_name?.name === record2.client_name?.name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 160,

      render: (text, record) => <ProjectNameWithDelay record={record} />,
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Scope
        </span>
      ),
      width: 90,

      render: (text, record) => (
        // <span className="text-capitalize font14px textcolorgreen font14px">{record.scope_name?.scope}</span>
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.scope_name?.scope > record2.scope_name?.scope
          ? 1
          : record1.scope_name?.scope === record2.scope_name?.scope
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 80,

      render: (text, record) => (
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.program_name?.description >
          record2.program_name?.description
          ? 1
          : record1.program_name?.description ===
            record2.program_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 80,

      render: (text, record) => (
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.country_name?.description >
          record2.country_name?.description
          ? 1
          : record1.country_name?.description ===
            record2.country_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Status
        </span>
      ),
      width: 120,
      render: (text, record) => {
        let color = "red";
        let msg = "";
        if (record.dvr_status == 0) {
          msg = "Not Started";
          color = "red";
        } else if (record.dvr_status == 1) {
          msg = "TL sent To Team";
          color = "orange";
        } else if (record.dvr_status == 2) {
          msg = "Team sent to TL";
          color = "black";
        } else if (record.dvr_status == 3) {
          msg = "Sent to TR";
          color = "blue";
        } else {
          msg = record.dvr_status;
        }

        return (
          <Tip title={msg}>
            <span
              className="text-capitalize font14px preserve-space text-ellipsis"
              style={{ color }}
            >
              {msg}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.dvr_status > record2.dvr_status
          ? 1
          : record1.dvr_status === record2.dvr_status
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 80,
      render: (record) => (
        <a>
          {record.dvr_status == 0 ||
          record.dvr_status == 2 ||
          record.dvr_status == 3 ||
          record.dvr_status == 1 ? (
            <EditOutlined
              onClick={() => editFormForDeskReview(record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            />
          ) : (
            <EyeOutlined
              onClick={() => editFormForDeskReview(record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            />
          )}
        </a>
      ),
    },
  ];

  const columnTrReview = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: "17%",

      render: (text, record) => {
        return (
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
      // defaultSortOrder: 'ascend',
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,

      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.client_name?.name > record2.client_name?.name
          ? 1
          : record1.client_name?.name === record2.client_name?.name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 160,

      render: (text, record) => <ProjectNameWithDelay record={record} />,
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Scope
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.scope_name?.scope > record2.scope_name?.scope
          ? 1
          : record1.scope_name?.scope === record2.scope_name?.scope
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 80,

      render: (text, record) => (
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.program_name?.description >
          record2.program_name?.description
          ? 1
          : record1.program_name?.description ===
            record2.program_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.country_name?.description >
          record2.country_name?.description
          ? 1
          : record1.country_name?.description ===
            record2.country_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Status
        </span>
      ),
      width: 140,
      render: (text, record) => {
        let color = "red";
        let msg = "";
        if (record.tr_status == 1) {
          msg = "Sent to TR";
          color = "brown";
        } else if (record.tr_status == 2) {
          msg = "Sent To Team Member";
          color = "orange";
        } else if (record.tr_status == 3) {
          msg = "Team sent to TL";
          color = "black";
        } else if (record.tr_status == 4) {
          msg = "Forwarded to Client";
          color = "blue";
        } else if (record.tr_status == 5) {
          msg = "Client Resubmitted";
          color = "brown";
        } else if (record.tr_status == 6) {
          msg = "Sent to TR";
          color = "black";
        } else if (record.tr_status == 7) {
          msg = "TR raised clarification";
          color = "black";
        } else if (record.tr_status == 8) {
          msg = "TR approved";
          color = "green";
        } else {
          msg = record.tr_status;
        }

        return (
          <Tip title={msg}>
            <span
              className="text-capitalize font14px preserve-space text-ellipsis"
              style={{ color }}
            >
              {msg}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.tr_status > record2.tr_status
          ? 1
          : record1.tr_status === record2.tr_status
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 90,
      render: (record) => (
        <a>
          {record.tr_status === 3 ||
          record.tr_status === 5 ||
          record.tr_status == 7 ||
          record.tr_status == 2 ? (
            <EditOutlined
              onClick={() => editFormForDeskReview(record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            />
          ) : (
            <EyeOutlined
              onClick={() => editFormForDeskReview(record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            />
          )}
        </a>
      ),
    },
  ];

  const columnCCReview = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: "17%",

      render: (text, record) => {
        return (
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,

      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.client_name?.name > record2.client_name?.name
          ? 1
          : record1.client_name?.name === record2.client_name?.name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 160,

      render: (text, record) => <ProjectNameWithDelay record={record} />,
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Scope
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.scope_name?.scope > record2.scope_name?.scope
          ? 1
          : record1.scope_name?.scope === record2.scope_name?.scope
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 80,

      render: (text, record) => (
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.program_name?.description >
          record2.program_name?.description
          ? 1
          : record1.program_name?.description ===
            record2.program_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 80,

      render: (text, record) => (
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.country_name?.description >
          record2.country_name?.description
          ? 1
          : record1.country_name?.description ===
            record2.country_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Status
        </span>
      ),
      width: 150,
      render: (text, record) => {
        let color = "red";
        let msg = "";
        if (record.cc_status == 0) {
          msg = "Not Started";
          // color = "red";
          color = "#153448";
        } else if (record.cc_status == 1) {
          msg = "Allocated the CC";
          color = "orange";
        } else if (record.cc_status == 2) {
          msg = "CC raised clarification";
          color = "blue";
        } else if (record.cc_status == 3) {
          msg = "TL sent to team";
          color = "black";
        } else if (record.cc_status == 4) {
          msg = "Sent to client";
          color = "purple";
        } else if (record.cc_status == 5) {
          msg = "Client resubmitted";
          color = "purple";
        } else if (record.cc_status == 6) {
          msg = "Member sent to TL";
          color = "brown";
        } else if (record.cc_status == 7) {
          msg = "Sent to CC";
          color = "green";
        } else if (record.cc_status == 8) {
          msg = "Sent to TR";
          color = "green";
        } else if (record.cc_status == 9) {
          msg = "TR raised clarification ";
          color = "green";
        } else if (record.cc_status == 10) {
          msg = "TR approved";
          color = "green";
        } else if (record.cc_status == 11) {
          msg = "CC approved";
          color = "green";
        } else {
          msg = record.cc_status;
        }

        return (
          <Tip title={msg}>
            <span
              className="text-capitalize font14px preserve-space text-ellipsis"
              style={{ color }}
            >
              {msg}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.cc_status > record2.cc_status
          ? 1
          : record1.cc_status === record2.cc_status
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 80,
      render: (record) => (
        <a>
          {record.cc_status === 2 ||
          record.cc_status === 5 ||
          record.cc_status == 6 ||
          record.cc_status == 9 ||
          record.cc_status == 10 ||
          record.cc_status == 0 ? (
            <EditOutlined
              onClick={() => editFormForDeskReview(record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            />
          ) : (
            <EyeOutlined
              onClick={() => editFormForDeskReview(record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            />
          )}
        </a>
      ),
    },
  ];

  const MyCCReview = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: "17%",

      render: (text, record) => {
        return (
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,

      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.client_name?.name > record2.client_name?.name
          ? 1
          : record1.client_name?.name === record2.client_name?.name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 160,
      render: (text, record) => <ProjectNameWithDelay record={record} />,
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Scope
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.scope_name?.scope > record2.scope_name?.scope
          ? 1
          : record1.scope_name?.scope === record2.scope_name?.scope
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.program_name?.description >
          record2.program_name?.description
          ? 1
          : record1.program_name?.description ===
            record2.program_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 80,

      render: (text, record) => (
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.country_name?.description >
          record2.country_name?.description
          ? 1
          : record1.country_name?.description ===
            record2.country_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Status
        </span>
      ),
      width: 150,
      render: (text, record) => {
        let color = "red";
        let msg = "";
        if (record.cc_status == 0) {
          msg = "Not Started";
          color = "#153448";
        } else if (record.cc_status == 1) {
          msg = "Action Pending";
          color = "orange";
        } else if (record.cc_status == 2) {
          msg = "CC raised clarification";
          color = "blue";
        } else if (record.cc_status == 3) {
          msg = "TL sent to team";
          color = "black";
        } else if (record.cc_status == 4) {
          msg = "Sent to client";
          color = "purple";
        } else if (record.cc_status == 5) {
          msg = "Client resubmitted";
          color = "purple";
        } else if (record.cc_status == 6) {
          msg = "Team Member sent to TL";
          color = "brown";
        } else if (record.cc_status == 7) {
          msg = "Sent to CC";
          color = "green";
        } else if (record.cc_status == 8) {
          msg = "Sent to TR";
          color = "green";
        } else if (record.cc_status == 9) {
          msg = "TR raised clarification ";
          color = "green";
        } else if (record.cc_status == 10) {
          msg = "TR approved";
          color = "green";
        } else if (record.cc_status == 11) {
          msg = "CC approved";
          color = "green";
        } else {
          msg = record.cc_status;
        }

        return (
          <Tip title={msg}>
            <span
              className="text-capitalize font14px preserve-space text-ellipsis"
              style={{ color }}
            >
              {msg}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.cc_status > record2.cc_status
          ? 1
          : record1.cc_status === record2.cc_status
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 80,
      render: (record) => (
        <a>
          {record.cc_status === 1 || record.cc_status === 7 ? (
            <EditOutlined
              onClick={() => editFormForDeskReview(record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            />
          ) : (
            <EyeOutlined
              onClick={() => editFormForDeskReview(record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            />
          )}
        </a>
      ),
    },
  ];

  const RFI = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: "17%",

      render: (text, record) => {
        return (
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
      // defaultSortOrder: 'ascend',
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,

      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.client_name?.name > record2.client_name?.name
          ? 1
          : record1.client_name?.name === record2.client_name?.name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 150,

      render: (text, record) => (
        // <span className="text-capitalize font14px textcolor">{record.project_name}</span>
        <ProjectNameWithDelay record={record} />
      ),
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Scope
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.scope_name?.scope > record2.scope_name?.scope
          ? 1
          : record1.scope_name?.scope === record2.scope_name?.scope
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 80,

      render: (text, record) => (
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.program_name?.description >
          record2.program_name?.description
          ? 1
          : record1.program_name?.description ===
            record2.program_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 80,

      render: (text, record) => (
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.country_name?.description >
          record2.country_name?.description
          ? 1
          : record1.country_name?.description ===
            record2.country_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Status
        </span>
      ),
      width: 150,
      render: (text, record) => {
        let color = "red";
        let msg = "";
        if (record.status == 13) {
          msg = "Not Started";
          color = "red";
        } else if (record.status == 14) {
          msg = "Team submitted FVR to TL";
          color = "orange";
        } else if (record.status == 15) {
          msg = "TL send to Team";
          color = "blue";
        } else if (record.status == 16) {
          msg = "TL submitted FVR to TM";
          color = "orange";
        } else if (record.status == 17) {
          msg = "Tech Mgr raised RFI Clarification";
          color = "blue";
        } else if (record.status == 18) {
          msg = "TM approved RFI";
          color = "green";
        } else if (record.status == 19) {
          msg = "MD raised clarification";
          color = "purple";
        } else if (record.status == 20) {
          msg = "MD Approved";
          color = "green";
        } else {
          msg = record.status;
        }

        return (
          <Tip title={msg}>
            <span
              className="text-capitalize font14px preserve-space text-ellipsis"
              style={{ color }}
            >
              {msg}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.status > record2.status
          ? 1
          : record1.status === record2.status
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 90,
      render: (record) => (
        <a>
          {record.status === 15 ||
          record.status === 17 ||
          record.status === 19 ||
          record.status === 13 ||
          record.status === 14 ? (
            <EditOutlined
              onClick={() => editFormForDeskReview(record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            />
          ) : (
            <EyeOutlined
              onClick={() => editFormForDeskReview(record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            />
          )}
        </a>
      ),
    },
  ];

  const RFICompleted = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: "17%",

      render: (text, record) => {
        return (
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,

      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.client_name?.name > record2.client_name?.name
          ? 1
          : record1.client_name?.name === record2.client_name?.name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 150,

      render: (text, record) => <ProjectNameWithDelay record={record} />,
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Scope
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.scope_name?.scope > record2.scope_name?.scope
          ? 1
          : record1.scope_name?.scope === record2.scope_name?.scope
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 80,

      render: (text, record) => (
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.program_name?.description >
          record2.program_name?.description
          ? 1
          : record1.program_name?.description ===
            record2.program_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 80,

      render: (text, record) => (
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.country_name?.description >
          record2.country_name?.description
          ? 1
          : record1.country_name?.description ===
            record2.country_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Status
        </span>
      ),
      width: 150,
      render: (text, record) => {
        let color = "red";
        let msg = "";

        if (record.status == 20) {
          msg = "MD approved RFI";
          color = "black";
        } else if (record.status == 21) {
          msg = "Registrar approved and RFI completed";
          color = "green";
        } else {
          msg = record.status;
        }

        return (
          <Tip title={msg}>
            <span
              className="text-capitalize font14px preserve-space text-ellipsis"
              style={{ color }}
            >
              {msg}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.status > record2.status
          ? 1
          : record1.status === record2.status
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 90,
      render: (record) => (
        <>
          <a>
            <EyeInvisibleOutlined
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
              onClick={() => editFormForDeskReview(record.id)}
            />
          </a>
          <a>
            <EditOutlined
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
              onClick={() => editFormForKickOut(record.id)}
            />
          </a>
        </>
      ),
    },
  ];

  const UnderKickout = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: "17%",

      render: (text, record) => {
        return (
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,

      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.client_name?.name > record2.client_name?.name
          ? 1
          : record1.client_name?.name === record2.client_name?.name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 150,
      render: (text, record) => <ProjectNameWithDelay record={record} />,
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Scope
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.scope_name?.scope > record2.scope_name?.scope
          ? 1
          : record1.scope_name?.scope === record2.scope_name?.scope
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 80,

      render: (text, record) => (
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.program_name?.description >
          record2.program_name?.description
          ? 1
          : record1.program_name?.description ===
            record2.program_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 80,
      render: (text, record) => (
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.country_name?.description >
          record2.country_name?.description
          ? 1
          : record1.country_name?.description ===
            record2.country_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Status
        </span>
      ),
      width: 150,
      render: (text, record) => {
        let color = "red";
        let msg = "";
        if (record.kickout_status == 1) {
          msg = "kickout Received";
          color = "black";
        } else if (
          record.kickout_status == null ||
          record.kickout_status == 0
        ) {
          msg = "Kick out not Started";
        } else if (record.kickout_status == 2) {
          msg = "Team forwarded to TL";
        } else if (record.kickout_status == 3) {
          msg = "TL sent to team members";
        } else if (record.kickout_status == 4) {
          msg = "Sent to Client";
        } else if (record.kickout_status == 5) {
          msg = "Client Resubmitted";
        } else if (record.kickout_status == 6) {
          msg = "Sent to TR";
        } else if (record.kickout_status == 7) {
          msg = "TR raised clarification in TR Round";
        } else if (record.kickout_status == 8) {
          msg = "TL Sent to team member in TR Round";
        } else if (record.kickout_status == 9) {
          msg = "Team Member forward to TL in TR Round";
        } else if (record.kickout_status == 10) {
          msg = "Sent to Client in TR Round";
        } else if (record.kickout_status == 11) {
          msg = "Client Resubmitted in TR Round";
        } else if (record.kickout_status == 12) {
          msg = "Resubmitted to TR";
        } else if (record.kickout_status == 13) {
          msg = "TR approved";
        } else if (record.kickout_status == 14) {
          msg = "Allocated CC Reviewer";
        } else if (record.kickout_status == 15) {
          msg = "CC raised clarification";
        } else if (record.kickout_status == 16) {
          msg = "Team Member sent to TL in CC Round";
        } else if (record.kickout_status == 17) {
          msg = "TL forwarded to team members in CC Round";
        } else if (record.kickout_status == 18) {
          msg = "Forwarded to client in CC Round";
        } else if (record.kickout_status == 19) {
          msg = "Client resubmitted in CC Round";
        } else if (record.kickout_status == 20) {
          msg = "Forwarded to TR";
        } else if (record.kickout_status == 21) {
          msg = "TR raised clarification in CC Round";
        } else if (record.kickout_status == 22) {
          msg = "TR approved cc round";
        } else if (record.kickout_status == 23) {
          msg = "Team to CC Reviewer";
        } else if (record.kickout_status == 24) {
          msg = "CC Approved";
        } else if (record.kickout_status == 25) {
          msg = "Team Member sent to TL in kickout RFI";
        } else if (record.kickout_status == 26) {
          msg = "TL sent to Team Member in kickout RFI";
        } else if (record.kickout_status == 27) {
          msg = "Sent to TM in Kickout RFI";
        } else if (record.kickout_status == 28) {
          msg = "TM raised clarification in kickout RFI";
        } else if (record.kickout_status == 29) {
          msg = "TM approved Kickout RFI";
        } else if (record.kickout_status == 30) {
          msg = "MD raised clarification in kickout RFI";
        } else if (record.kickout_status == 31) {
          msg = "MD Approved";
        } else if (record.kickout_status == 32) {
          msg = "RFI Completed";
        }

        return (
          <Tip title={msg}>
            <span
              className="text-capitalize font14px preserve-space text-ellipsis"
              style={{ color }}
            >
              {msg}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.status > record2.status
          ? 1
          : record1.status === record2.status
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 80,
      render: (record) => (
        <a>
          <EyeInvisibleOutlined
            style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            onClick={() => editFormForDeskReview(record.id)}
          />

          {record.kickout_status == 1 ||
          record.kickout_status == 2 ||
          record.kickout_status == 3 ||
          record.kickout_status == 5 ||
          record.kickout_status == 7 ||
          record.kickout_status == 8 ||
          record.kickout_status == 9 ||
          record.kickout_status == 11 ||
          record.kickout_status == 12 ||
          record.kickout_status == 13 ||
          record.kickout_status == 15 ||
          (record.kickout_status == 16) | (record.kickout_status == 17) ||
          record.kickout_status == 19 ||
          record.kickout_status == 21 ||
          record.kickout_status == 22 ||
          record.kickout_status == 24 ||
          record.kickout_status == 25 ||
          record.kickout_status == 26 ||
          record.kickout_status == 28 ||
          record.kickout_status == 30 ||
          record.kickout_status == 32 ? (
            <EditOutlined
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
              onClick={() => editFormForKickOut(record.id)}
            />
          ) : (
            <EyeOutlined
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
              onClick={() => editFormForKickOut(record.id)}
            />
          )}
        </a>
      ),
    },
  ];

  const MyKickoutCCReview = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: "17%",

      render: (text, record) => {
        return (
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,

      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.client_name?.name > record2.client_name?.name
          ? 1
          : record1.client_name?.name === record2.client_name?.name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 150,

      render: (text, record) => <ProjectNameWithDelay record={record} />,
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Scope
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.scope_name?.scope > record2.scope_name?.scope
          ? 1
          : record1.scope_name?.scope === record2.scope_name?.scope
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 80,

      render: (text, record) => (
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.program_name?.description >
          record2.program_name?.description
          ? 1
          : record1.program_name?.description ===
            record2.program_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 80,

      render: (text, record) => (
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.country_name?.description >
          record2.country_name?.description
          ? 1
          : record1.country_name?.description ===
            record2.country_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Status
        </span>
      ),
      width: 150,
      render: (text, record) => {
        let color = "red";
        let msg = "";

        if (record.kickout_status == 14) {
          msg = "Allocated CC Reviewer";
        } else if (record.kickout_status == 15) {
          msg = "CC raised clarification";
        } else if (record.kickout_status == 16) {
          msg = "Team Member sent to TL in CC Round";
        } else if (record.kickout_status == 17) {
          msg = "TL forwarded to team members in CC Round";
        } else if (record.kickout_status == 18) {
          msg = "Forwarded to client in CC Round";
        } else if (record.kickout_status == 19) {
          msg = "Client resubmitted in CC Round";
        } else if (record.kickout_status == 20) {
          msg = "Forwarded to TR";
        } else if (record.kickout_status == 21) {
          msg = "TR raised clarification in CC Round";
        } else if (record.kickout_status == 22) {
          msg = "TR approved cc round";
        } else if (record.kickout_status == 23) {
          msg = "TL to CC Reviewer";
        } else if (record.kickout_status == 24) {
          msg = "CC Approved";
        }

        return (
          <Tip title={msg}>
            <span
              className="text-capitalize font14px preserve-space text-ellipsis"
              style={{ color }}
            >
              {msg}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.status > record2.status
          ? 1
          : record1.status === record2.status
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 90,
      render: (record) => (
        <a>
          {record.kickout_status == 14 || record.kickout_status == 23 ? (
            <EditOutlined
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
              onClick={() => editFormForKickOut(record.id)}
            />
          ) : (
            <EyeOutlined
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
              onClick={() => editFormForKickOut(record.id)}
            />
          )}
        </a>
      ),
    },
  ];

  const IssuedRegisters = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: "17%",

      render: (text, record) => {
        return (
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,
      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.client_name?.name > record2.client_name?.name
          ? 1
          : record1.client_name?.name === record2.client_name?.name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 160,
      render: (text, record) => <ProjectNameWithDelay record={record} />,
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Scope
        </span>
      ),
      width: 90,

      render: (text, record) => (
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.scope_name?.scope > record2.scope_name?.scope
          ? 1
          : record1.scope_name?.scope === record2.scope_name?.scope
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 80,

      render: (text, record) => (
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.program_name?.description >
          record2.program_name?.description
          ? 1
          : record1.program_name?.description ===
            record2.program_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 80,

      render: (text, record) => (
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.country_name?.description >
          record2.country_name?.description
          ? 1
          : record1.country_name?.description ===
            record2.country_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 90,
      render: (record) => (
        <a>
          <EyeOutlined
            style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            onClick={() => editFormForDeskReview(record.id)}
          />
        </a>
      ),
    },
  ];

  const ColumnTeamChange = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: "17%",

      render: (text, record) => {
        return (
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 160,

      render: (text, record) => (
        <span className="text-capitalize font14px textcolor">
          {record.project_name}
        </span>
      ),
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Team Change Status
        </span>
      ),
      width: 150,
      render: (text, record) => {
        let color = "red";
        let msg = "";

        if (record.team_change_status == 0) {
          msg = "Not Started";
        } else if (record.team_change_status == 1) {
          msg = "SBU Initiate the team Change";
        } else if (record.team_change_status == 2) {
          msg = "TL sent to team members";
        } else if (record.team_change_status == 3) {
          msg = "Team members sent to TL";
        } else if (record.team_change_status == 4) {
          msg = "Sent to TM support";
        } else if (record.team_change_status == 5) {
          msg = "TM support raised the clarification";
        } else if (record.team_change_status == 6) {
          msg = "TM support approved";
        } else if (record.team_change_status == 7) {
          msg = "TM raised the clarification";
        } else if (record.team_change_status == 8) {
          msg = "TM approved";
        }

        return (
          <Tip title={msg}>
            <span
              className="text-capitalize font14px preserve-space text-ellipsis"
              style={{ color }}
            >
              {msg}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.status > record2.status
          ? 1
          : record1.status === record2.status
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 90,
      render: (record) => (
        <a>
          {record.team_change_status === 4 ||
          record.team_change_status === 6 ||
          record.team_change_status === 7 ||
          record.team_change_status === 8 ? (
            <EyeOutlined
              onClick={() => handleTeamChange(record.project_id, record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            />
          ) : (
            <EditOutlined
              onClick={() => handleTeamChange(record.project_id, record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
            />
          )}
        </a>
      ),
    },
  ];

  return (
    <>
      <Spin spinning={dashLoader}>
        <div>
          <div style={{ marginLeft: "0.5rem" }}>
            <span onClick={handletotalprojects}>
              Total Projects:{" "}
              <b style={{ cursor: "pointer", color: "blue" }}>
                {totalProject}{" "}
              </b>{" "}
            </span>
            <br />
            <span>
              Projects pending at Client's end:{" "}
              <b
                onClick={handleClientEndPending}
                style={{ cursor: "pointer", color: "blue" }}
              >
                {clientPendingDoc}
              </b>
            </span>
            <br />
            <span>
              Projects pending at Earthood's end:{" "}
              <b
                onClick={handleEarthoodEndPending}
                style={{ cursor: "pointer", color: "blue" }}
              >
                {earthoodPendingDoc}
              </b>
            </span>
            <br />
          </div>
        </div>
        <div className="container-fluid bg-white">
          <div className="row">
            <div className="col-12">
              <Tabs
                defaultActiveKey="1"
                centered
                activeKey={activeKey}
                onChange={handleTabChange}
                className="at_tl_dash_tab_align"
              >
                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 1 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        Signed Contract
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {signedContract}
                        </p>
                        <p>
                          <FontAwesomeIcon
                            icon={faFileCircleQuestion}
                            size="xl"
                            className="iconcolor"
                          />
                        </p>
                      </div>
                    </div>
                  }
                  key="1"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                Signed Contract
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px ">
                                  client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="d-grid mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Scope{" "}
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select scope"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleScopeSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>

                                  {scopeList.map((scope, index) => (
                                    <Option
                                      key={index}
                                      value={scope.id}
                                      label={scope.sector_name}
                                    >
                                      {scope.sector_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  program Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select program name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleProgramNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {programname.map((program, index) => (
                                    <Option
                                      key={index}
                                      value={program.id}
                                      label={program.program_name}
                                    >
                                      {program.program_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-3 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                onSearch={handleSearch}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                              />
                            </div>
                          </div>
                        </div>
                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={columnSignedContract}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 2 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        LoD Review
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {underLODReview}
                        </p>
                        <p>
                          <FontAwesomeIcon
                            icon={faFileCircleQuestion}
                            size="xl"
                            className="iconcolor"
                          />
                        </p>
                      </div>
                    </div>
                  }
                  key="2"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                LoD Review
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="d-grid mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Scope
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select scope"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleScopeSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>

                                  {scopeList.map((scope, index) => (
                                    <Option
                                      key={index}
                                      value={scope.id}
                                      label={scope.sector_name}
                                    >
                                      {scope.sector_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  program Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select program name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleProgramNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {programname.map((program, index) => (
                                    <Option
                                      key={index}
                                      value={program.id}
                                      label={program.program_name}
                                    >
                                      {program.program_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-3 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                onSearch={handleSearch}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                                // buttonStyle={{ backgroundColor: '#fff' }} // Change color here
                              />{" "}
                            </div>
                          </div>
                        </div>
                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={columnUnderLODReview}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 3 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        Desk Review
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {underDeskReview}
                        </p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </div>
                    </div>
                  }
                  key="3"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                Desk Review
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="d-grid mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Scope{" "}
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select scope"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleScopeSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>

                                  {scopeList.map((scope, index) => (
                                    <Option
                                      key={index}
                                      value={scope.id}
                                      label={scope.sector_name}
                                    >
                                      {scope.sector_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  program Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select program name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleProgramNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {programname.map((program, index) => (
                                    <Option
                                      key={index}
                                      value={program.id}
                                      label={program.program_name}
                                    >
                                      {program.program_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-3 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                onSearch={handleSearch}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                              />
                            </div>
                          </div>
                        </div>

                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={columnUnderDeskReview}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 4 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        Site Visit Completed
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {siteVisitCompleted}
                        </p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </div>
                    </div>
                  }
                  key="4"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                Site Visit Completed
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="d-grid mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Scope{" "}
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select scope"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleScopeSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>

                                  {scopeList.map((scope, index) => (
                                    <Option
                                      key={index}
                                      value={scope.id}
                                      label={scope.sector_name}
                                    >
                                      {scope.sector_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  program Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select program name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleProgramNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {programname.map((program, index) => (
                                    <Option
                                      key={index}
                                      value={program.id}
                                      label={program.program_name}
                                    >
                                      {program.program_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-3 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                onSearch={handleSearch}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                                // buttonStyle={{ backgroundColor: '#fff' }} // Change color here
                              />{" "}
                            </div>
                          </div>
                        </div>

                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={columnSiteVisitCompleted}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 5 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        Site Visit Findings
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {siteVisitfindings}
                        </p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </div>
                    </div>
                  }
                  key="5"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                Site Visit Findings
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="d-grid mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Scope{" "}
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select scope"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleScopeSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>

                                  {scopeList.map((scope, index) => (
                                    <Option
                                      key={index}
                                      value={scope.id}
                                      label={scope.sector_name}
                                    >
                                      {scope.sector_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  program Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select program name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleProgramNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {programname.map((program, index) => (
                                    <Option
                                      key={index}
                                      value={program.id}
                                      label={program.program_name}
                                    >
                                      {program.program_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-3 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                onSearch={handleSearch}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                                // buttonStyle={{ backgroundColor: '#fff' }} // Change color here
                              />{" "}
                            </div>
                          </div>
                        </div>

                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={columnSiteVisitFindings}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 6 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        DVR Stage
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {underDvrReview}
                        </p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </div>
                    </div>
                  }
                  key="6"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                DVR Stage
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="d-grid mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Scope{" "}
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select scope"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleScopeSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>

                                  {scopeList.map((scope, index) => (
                                    <Option
                                      key={index}
                                      value={scope.id}
                                      label={scope.sector_name}
                                    >
                                      {scope.sector_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  program Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select program name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleProgramNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {programname.map((program, index) => (
                                    <Option
                                      key={index}
                                      value={program.id}
                                      label={program.program_name}
                                    >
                                      {program.program_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px ">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-3 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                onSearch={handleSearch}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                                // buttonStyle={{ backgroundColor: '#fff' }} // Change color here
                              />{" "}
                            </div>
                          </div>
                        </div>

                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={columnDvrReview}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 7 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        Tech. Review
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {underTrReview}
                        </p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </div>
                    </div>
                  }
                  key="7"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                Tech. Review
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="d-grid mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Scope{" "}
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select scope"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleScopeSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>

                                  {scopeList.map((scope, index) => (
                                    <Option
                                      key={index}
                                      value={scope.id}
                                      label={scope.sector_name}
                                    >
                                      {scope.sector_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  program Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select program name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleProgramNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {programname.map((program, index) => (
                                    <Option
                                      key={index}
                                      value={program.id}
                                      label={program.program_name}
                                    >
                                      {program.program_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-3 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                onSearch={handleSearch}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                                // buttonStyle={{ backgroundColor: '#fff' }} // Change color here
                              />{" "}
                            </div>
                          </div>
                        </div>

                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={columnTrReview}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 8 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        Consistency Check
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">{CCReview}</p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </div>
                    </div>
                  }
                  key="8"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                Consistency Check
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="d-grid mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Scope{" "}
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select scope"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleScopeSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>

                                  {scopeList.map((scope, index) => (
                                    <Option
                                      key={index}
                                      value={scope.id}
                                      label={scope.sector_name}
                                    >
                                      {scope.sector_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  program Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select program name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleProgramNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {programname.map((program, index) => (
                                    <Option
                                      key={index}
                                      value={program.id}
                                      label={program.program_name}
                                    >
                                      {program.program_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-3 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                onSearch={handleSearch}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                                // buttonStyle={{ backgroundColor: '#fff' }} // Change color here
                              />
                            </div>
                          </div>
                        </div>

                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={columnCCReview}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 9 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        As CC Reviewer
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">{myCCReview}</p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </div>
                    </div>
                  }
                  key="9"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                As CC Reviewer
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="d-grid mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Scope{" "}
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select scope"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleScopeSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>

                                  {scopeList.map((scope, index) => (
                                    <Option
                                      key={index}
                                      value={scope.id}
                                      label={scope.sector_name}
                                    >
                                      {scope.sector_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  program Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select program name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleProgramNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {programname.map((program, index) => (
                                    <Option
                                      key={index}
                                      value={program.id}
                                      label={program.program_name}
                                    >
                                      {program.program_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-3 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                onSearch={handleSearch}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                                // buttonStyle={{ backgroundColor: '#fff' }} // Change color here
                              />
                            </div>
                          </div>
                        </div>

                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={MyCCReview}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 10 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        RFI / RFR
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">{myRFIReview}</p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </div>
                    </div>
                  }
                  key="10"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                RFI / RFR
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="d-grid mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Scope{" "}
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select scope"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleScopeSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>

                                  {scopeList.map((scope, index) => (
                                    <Option
                                      key={index}
                                      value={scope.id}
                                      label={scope.sector_name}
                                    >
                                      {scope.sector_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  program Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select program name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleProgramNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {programname.map((program, index) => (
                                    <Option
                                      key={index}
                                      value={program.id}
                                      label={program.program_name}
                                    >
                                      {program.program_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-3 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                onSearch={handleSearch}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                                // buttonStyle={{ backgroundColor: '#fff' }} // Change color here
                              />
                            </div>
                          </div>
                        </div>

                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={RFI}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 11 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        RFI /RFR Completed
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {myRFICompleted}
                        </p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </div>
                    </div>
                  }
                  key="11"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                RFI / RFR Completed
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="d-grid mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Scope{" "}
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select scope"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleScopeSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>

                                  {scopeList.map((scope, index) => (
                                    <Option
                                      key={index}
                                      value={scope.id}
                                      label={scope.sector_name}
                                    >
                                      {scope.sector_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  program Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select program name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleProgramNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {programname.map((program, index) => (
                                    <Option
                                      key={index}
                                      value={program.id}
                                      label={program.program_name}
                                    >
                                      {program.program_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-3 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                onSearch={handleSearch}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                                // buttonStyle={{ backgroundColor: '#fff' }} // Change color here
                              />
                            </div>
                          </div>
                        </div>

                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={RFICompleted}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 12 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        Kickout
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {underKickout}
                        </p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </div>
                    </div>
                  }
                  key="12"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                Kickout
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="d-grid mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Scope{" "}
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select scope"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleScopeSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>

                                  {scopeList.map((scope, index) => (
                                    <Option
                                      key={index}
                                      value={scope.id}
                                      label={scope.sector_name}
                                    >
                                      {scope.sector_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  program Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select program name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleProgramNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {programname.map((program, index) => (
                                    <Option
                                      key={index}
                                      value={program.id}
                                      label={program.program_name}
                                    >
                                      {program.program_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-3 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                onSearch={handleSearch}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                              />
                            </div>
                          </div>
                        </div>

                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={UnderKickout}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 14 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        As Kickout CC Reviewer
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {myKickoutCCReview}
                        </p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </div>
                    </div>
                  }
                  key="14"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                As Kickout CC Reviewer
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="d-grid mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Scope{" "}
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select scope"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleScopeSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>

                                  {scopeList.map((scope, index) => (
                                    <Option
                                      key={index}
                                      value={scope.id}
                                      label={scope.sector_name}
                                    >
                                      {scope.sector_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  program Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select program name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleProgramNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {programname.map((program, index) => (
                                    <Option
                                      key={index}
                                      value={program.id}
                                      label={program.program_name}
                                    >
                                      {program.program_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-3 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                onSearch={handleSearch}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                                // buttonStyle={{ backgroundColor: '#fff' }} // Change color here
                              />
                            </div>
                          </div>
                        </div>

                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={MyKickoutCCReview}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 13 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        Issued/Registered
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {issuedRegisters}
                        </p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </div>
                    </div>
                  }
                  key="13"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                Issued/Registered
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="d-grid mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Scope{" "}
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select scope"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleScopeSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>

                                  {scopeList.map((scope, index) => (
                                    <Option
                                      key={index}
                                      value={scope.id}
                                      label={scope.sector_name}
                                    >
                                      {scope.sector_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  program Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select program name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleProgramNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {programname.map((program, index) => (
                                    <Option
                                      key={index}
                                      value={program.id}
                                      label={program.program_name}
                                    >
                                      {program.program_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle fw-bold font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-3 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                onSearch={handleSearch}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                                // buttonStyle={{ backgroundColor: '#fff' }} // Change color here
                              />
                            </div>
                          </div>
                        </div>

                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={IssuedRegisters}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 15 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        Team Change
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {teamChangeCount}
                        </p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </div>
                    </div>
                  }
                  key="15"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0 p-2">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium  rounded-top-3">
                                Team Change
                              </p>
                            </div>

                            <div className="col-sm-4 col-md-4 col-lg-3 mb-3">
                              <label htmlFor="teamStatus">
                                Team change status
                              </label>
                              <select
                                id="teamStatus"
                                className="form-select"
                                value={teamChangeStatus}
                                onChange={handleTeamChangeStatus}
                              >
                                <option value="">Select</option>
                                <option value="0">Pending</option>
                                <option value="1">Approved</option>
                              </select>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2"></div>
                        </div>

                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={ColumnTeamChange}
                          loading={teamChangeLoader}
                          dataSource={teamChangedata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default AT_TLDash;
