// import React, { useState, useEffect } from "react";
// import Chart from "react-apexcharts";
// import {
//   kickout_monthly_wise,
//   API_HEADER,
//   kickout_monthly_wise_tr,
// } from "../../../config";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// function KickoutMontlyWiseGraphTR() {
//   const [kickoutMonthly, setKickoutMonthly] = useState([]);
//   const [createdMonth, setCreatedMonth] = useState([]);
//   const [createdYear, setCreatedYear] = useState([]);
//   const [monthlyData, setMonthlyData] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const kickoutmonthly = [];
//     const createdmonth = [];
//     const createdyear = [];

//     const fetchData = async () => {
//       try {
//         const payload = { fy: "", type: 1 };
//         const reqData = await axios.get(
//           `${kickout_monthly_wise_tr}?type=${1}`,
//           API_HEADER
//         );
//         const mysignedcontract = reqData.data.records[0].monthly_data;
//         console.log("mysignedcontract", mysignedcontract);

//         for (let i = 0; i < mysignedcontract.length; i++) {
//           // kickoutmonthly.push(mysignedcontract[i].count);
//           kickoutmonthly.push(parseInt(mysignedcontract[i].count) || 0);

//           const dateString = mysignedcontract[i].month_year;
//           const [month, year] = dateString.split("-");
//           const date = new Date(year, parseInt(month) - 1);
//           const formattedMonth = date.toLocaleString("en-US", {
//             month: "long",
//           });
//           const formattedYear = date.getFullYear();

//           createdmonth.push(formattedMonth);
//           createdyear.push(formattedYear);
//         }

//         setKickoutMonthly(kickoutmonthly);
//         setCreatedMonth(createdmonth);
//         setCreatedYear(createdyear);
//         setMonthlyData(mysignedcontract); // Save the entire data for later use
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   const handleChartClick = (
//     event,
//     chartContext,
//     { seriesIndex, dataPointIndex, w }
//   ) => {
//     const selectedMonthData = monthlyData[dataPointIndex];

//     const title = "Kickout Monthly";

//     if (selectedMonthData) {
//       navigate("/programwiseprojecttable", {
//         state: { title, selectedMonthData },
//       });
//     }
//   };

//   return (
//     <div className="container-fluid">
//       <Chart
//         type="line"
//         width={"100%"}
//         height={400}
//         series={[{ name: "Kickout", data: kickoutMonthly, color: "#c28b1d" }]}
//         options={{
//           chart: {
//             toolbar: {
//               show: false, // This will hide the icons on the top right corner
//             },
//             events: {
//               dataPointSelection: handleChartClick,
//             },
//           },
//           dropShadow: {
//             enabled: true,
//             color: "#000",
//             top: 18,
//             left: 7,
//             blur: 10,
//             opacity: 0.2,
//           },
//           style: {
//             width: "10%",
//           },
//           stroke: {
//             curve: "smooth",
//           },
//           markers: {
//             size: 1,
//           },
//           zoom: {
//             enabled: false,
//           },
//           toolbar: {
//             show: false,
//           },
//           grid: {
//             borderColor: "#e7e7e7",
//             row: {
//               colors: ["transparent"], // takes an array which will be repeated on columns
//               opacity: 1,
//             },
//           },
//           dataLabels: {
//             enabled: true,
//           },
//           xaxis: {
//             title: {
//               text: `Month`,
//               style: { fontSize: "16px", fontWeight: "bold" },
//             },
//             categories: createdMonth,
//           },
//           yaxis: {
//             title: {
//               text: "Number of kickout",
//               style: { fontSize: "16px", fontWeight: "bold" },
//             },
//           },
//         }}
//       />
//     </div>
//   );
// }

// export default KickoutMontlyWiseGraphTR;



import React from "react";


function KickoutMontlyWiseGraphTR({ startDate, endDate }) {
  const grafanaBaseUrl = "https://grafana1.intileotech.xyz:3000/d-solo/aea99hs3003y8b/md-dashboard";
  const orgId = 1;
  const timezone = "browser";
  // const panelId = 35;
  const panelId = 52;
  const theme = "light";
 


    const role = localStorage.getItem("designation_id");
    const userData = localStorage.getItem("user");
    const userId = JSON.parse(userData);
    const type = 1
  
    // Access the 'id' field
    const user_id = userId.id;
    console.log("Extracted ID:", user_id);


const from = startDate ? startDate.format('YYYY-MM-DD') : '2024-04-01' 
const to = endDate ? endDate.format('YYYY-MM-DD') : '2025-03-31'

// const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&from=${from}&to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-user_id=${user_id}`;

const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-user_id=${user_id}`;

 

  // Construct Grafana iframe URL dynamically
  // const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&var-from=${from}&var-to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}`;

  return (
    <div className="container-fluid">
      <iframe
        src={grafanaUrl}
        width="100%"
        height="400"
        frameBorder="0"
        title="Program Wise Projects"
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
}

export default KickoutMontlyWiseGraphTR;