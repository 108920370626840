import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useLocation, useNavigate } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import SummaryChain from "../Components/SummaryChain";
import ActivityChain from "../Components/ActivityChain";
import { Form, Tabs, Upload, Progress, message, Input, Alert } from "antd";
import { Form as NewForm } from "antd";
import { Tooltip } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import {
  API_HEADER,
  BASE_DOCUMENT,
  get_client_name_url,
  sales_update_proposal_url,
  get_meths_url,
  get_contact_person_url,
  get_local_experts
} from "../config";
import { get_scope_url } from "../config";
import { get_sectoralscope_url } from "../config";
import { get_program_url } from "../config";
import { get_country_url } from "../config";
import { get_validator_verifier } from "../config";
import { get_assesment_url } from "../config";
import { toast } from "react-toastify";
import {
  get_trsbu_url,
  get_scope_pa_poa_url,
  get_ta_tr_experts,
  get_sales_person_url,
  get_gis_expert,
  get_tm_url,
} from "../config";
import Swal from "sweetalert2";
import {
  pt_tm_proposalaction_url,
  get_pt_forwardToSales_url,
  get_sales_action_url,
  pt_proposal_submit_url,
  pt_proposal_team_url,
} from "../config";
import Header from "./Header";
import SideNavbar from "../Components/SideNavbar";
import Footer from "../Components/Footer";
import PaymentTerms from "../Components/PaymentTerms";
import Select from "react-select";
import DealLost from "../Components/DealLost";
import ProposalEntity from "../Components/ProposalEntity";
import { DatePicker, ConfigProvider } from "antd";

import moment from "moment";
import dayjs from "dayjs";
import "../App.css";

const fileTypes = [
  "JPG",
  "JPEG",
  "PDF",
  "RAR",
  "XLS",
  "XLSX",
  "DOC",
  "DOCX",
  "ZIP",
  "XLSM",
];

const { Dragger } = Upload;
const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";
const PtActions = () => {
  const { Option } = Select;

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size;

  const navigate = useNavigate();

  const designation_id = localStorage.getItem("designation_id");
  const [projectid, setProjectId] = useState(null);
  const [clientName, setClientName] = useState([]);
  const [sectoralScope, setSectoralScope] = useState([]);
  const [loading, setLoading] = useState(true);
  const [myscope, setMyScope] = useState([]);
  const [mysectorscope, setMySectorScope] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);
  const [mytechnicalreviewer, setMyTechnicalReviewer] = useState([]);
  const [selectedtaexpert, setSelectedTAExpert] = useState([]);
  const [selectedtechnicalreviewer, setSelectedTechnicalReviewer] = useState(
    []
  );
  const [priority, setPriority] = useState("");
  const [meth, setMeth] = useState([]);

  const [proposalDesc, setProposalDesc] = useState("");
  const [programIdError, setProgramIdError] = useState("");
  const [proposalDescError, setProposalDescError] = useState("");
  const [monitoringPeriod, setMonitoringPeriod] = useState([]);
  const [weblink, setWeblink] = useState("");
  const [monitoringPeriodError, setMonitoringPeriodError] = useState("");
  const [consultantName, setConsultantName] = useState("");

  const [consultantNameError, setConsultantNameError] = useState("");

  const [program, setProgram] = useState([]);
  const [scope_PA_POA, setScope_PA_POA] = useState([]);
  const [country, setCountry] = useState([]);
  const [atlist, setAtList] = useState([]);
  const [f20name, setF20Name] = useState(null);
  const [f21name, setF21Name] = useState(null);
  const [f23name, setF23Name] = useState(null);
  const [coiname, setCoiName] = useState([]);
  const [rfpname, setRFPName] = useState(null);
  const [selected, setSelected] = useState([]);
  const [trlist, setTRList] = useState([]);
  const [localExpert, setLocalExpert] = useState([]);
  

  const [othername, setOtherName] = useState([]);
  const [formEdit, setformEdit] = useState(true);
  const [tmForm, setTMForm] = useState(true);
  const [tmButton, setTmButton] = useState(false);
  const [tm_form_input, setTMInput] = useState(true);

  const [action, setAction] = useState("");
  const [remarks, setRemarks] = useState("");
  const [remarksError, setRemarksError] = useState("");
  const [ptremarks, setPtRemarks] = useState("");
  const [stremarks, setStRemarks] = useState("");
  const [contractremarks, setContractRemarks] = useState("");
  const [projectstatus, setProjectstatus] = useState("");
  const [file, setFile] = useState(null);
  const [signedFileName, setSignedFileName] = useState("");

  const [activeTab, setActiveTab] = useState("1");

  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileF20SizeError, setFileF20SizeError] = useState(false);
  const [fileF21SizeError, setFileF21SizeError] = useState(false);
  const [fileF23SizeError, setFileF23SizeError] = useState(false);
  const [fileCOISizeError, setFileCOISizeError] = useState(false);
  const [fileOtherSizeError, setFileOtherSizeError] = useState(false);
  const [fileContractSizeError, setFileContractSizeError] = useState(false);
  const [rpf, setRpf] = useState("");
  const [f20, setF20] = useState("");
  const [f21, setF21] = useState("");
  const [f23, setF23] = useState("");
  const [coi, setCoi] = useState("");
  const [other, setOther] = useState("");
  const [signed, setSigned] = useState("");

  const [methOptions, setMethOptions] = useState([]);
  const [selectedMeth, setSelectedMeth] = useState(null);
  const [defaultMethName, setDefaultMethName] = useState(null);
  const [methStatus, setMethStatus] = useState(null);
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [defaultClientName, setDefaultClientName] = useState({});
  const [clientStatus, setClientStatus] = useState(null);
  const [defaultCountryName, setDefaultCountryName] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);
  const [countryStatus, setCountryStatus] = useState(null);

  const [remarksPTError, setRemarksPTError] = useState("");
  const [remarksPT, setRemarksPT] = useState("");
  const [tmsremarks, setTmsRemarks] = useState("");

  const [salesPerson, setSalesPerson] = useState([]);
  const [taTrExpert, setTATRExpert] = useState([]);
  const [mytaexpert, setMyTaexpert] = useState([]);
  const [myexperttr, setMyExpertTr] = useState([]);
  const [trExpert, setTRExpert] = useState([]);
  const [apiData, setAPIData] = useState("");
  const [clientContactPerson, setClientContactPerson] = useState([]);
  const [selectedclientContactPerson, setselectedclientContactPerson] =
    useState([]);

  const [validatorVerifier, setvalidatorVerifier] = useState([]);
  const [myvalidatorverifier, setMyvalidatorVerifier] = useState([]);

  const [methExpert, setmethExpert] = useState([]);
  const [mymethexpert, setMymethExpert] = useState([]);

  const [traineeValidator, settraineeValidator] = useState([]);
  const [mytraineevalidator, setMytraineevalidator] = useState([]);

  const [gisExpert, setgisExpert] = useState([]);
  const [mygisexpert, setMygisExpert] = useState([]);

  const [percent, setPercent] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [consultant, setConsultant] = useState([]);
  const [myConsultant, setMyConsultant] = useState([]);

  const [tm, setTM] = useState([]);
  const [tmName, setTMName] = useState("");

  const [formData, setFormData] = useState({
    project_name: "",
    earthood_id: "",
    client_id: "",
    country: "",
    program: "",
    program_id: "",
    implemented_fees: "",
    created_at: "",
    scope: [],
    scope_pa: "",
    sectoral_scope: [],
    team_leader: "",
    ta_expert: [],
    validator_verifier: [],
    finance_expert: "",
    local_expert: "",
    meth_expert: [],
    meth: "",
    trainee_validator: [],
    technical_reviewer: "",
    expert_tr: [],
    sbu_head: "",
    deadline_date: "",
    sales_id: "",
    contact_person_id: "",
    gis_expert: [],
    consultant_id: [],
  });

  const location = useLocation();
  const [rangeForm] = NewForm.useForm();

  useEffect(() => {
    const fetchData = async () => {
      if (location.state && location.state.data) {
        const { data } = location.state;
        setAPIData(data);

        setProjectId(data.id);
        setSelectedClient(data.client_id);
        setSelectedCountry(data.country);
        setPriority(data.priority);
        setProposalDesc(data.proposal_desc);
        setConsultantName(data.consultant_name);
        setMethStatus(data.meth);
        setTMName(data.tm_id);

        if (data?.monitoring_period) {
          setMonitoringPeriod(JSON.parse(data.monitoring_period));
        } // setMeth(data.meth)
        setWeblink(data.weblink);
        setSelectedMeth(data.meth);

        try {
          const responseteamleader = await axios.get(
            `${get_assesment_url}?program_id=${data.program}`
          );
          setAtList(responseteamleader.data.data);

          if (data.status > 1) {
            // setAction(data.tm_action);
            setRemarks(data.status == 5 ? "" : data.tm_remarks);
            setPtRemarks(data.pt_remarks);
            setTmsRemarks(data.tms_remarks);
            setStRemarks(data.sales_remarks);
            setContractRemarks(data.signed_contract_remarks);
            setFile(data.signed_contract);
          }

          setRemarksPT(data.pt_tmremarks);

          setProjectstatus(data.status);
          setClientStatus(data.client_id);
          setCountryStatus(data.country);

          const status = data.status;

          if (designation_id == 4) {
            if (status == 6) {
              setTMForm(false);
              setformEdit(false);
            }

            if (status == 8) {
              setformEdit(false);
            }
          } else if (designation_id == 3) {
            if (status == 5) {
              setTmButton(true);
              setTMInput(false);
            }
            if (status == 8) {
              setTmButton(false);
              setTMInput(true);
            }
          }

          setFormData({
            project_name: data.project_name,
            earthood_id: data.earthood_id,
            client_id: data.client_id,
            country: data.country,
            program: data.program,
            program_id: data.program_id,
            implemented_fees: data.implemented_fees,
            created_at: data.proposal_date,
            scope: selected,
            sectoral_scope: selected,
            scope_pa: data.scope_pa,
            team_leader: data.team_leader,
            // ta_expert: data.ta_expert,
            validator_verifier: data.validator_verifier,
            finance_expert: data.finance_expert,
            local_expert: data.local_expert,
            meth_expert: data.meth_expert,
            meth: data.meth,
            trainee_validator: data.trainee_validator,
            technical_reviewer: data.technical_reviewer,
            sbu_head: data.sbu_head,
            deadline_date: data.deadline_date,
            sales_id: data.sales_id,
            contact_person_id: data.contact_person_id,
            // consultant_id:  data.consultant_id
          });

          if (data.payment_terms != null && data.payment_terms != "") {
            const parsedRecords = JSON.parse(data.payment_terms);
            setPaymentRecords(parsedRecords);
          }

          if (data.contact_entity != null && data.contact_entity != "") {
            const parsedEntityRecords = JSON.parse(data.contact_entity);
            setProposalEntityRecords(parsedEntityRecords);
          }

          setRFPName(
            `${BASE_DOCUMENT}/documents/${data.earthood_id}/${data.rfp_doc}`
          );
          setRpf(data.rfp_doc);

          setF20Name(
            `${BASE_DOCUMENT}/documents/${data.earthood_id}/${data.f20_doc}`
          );
          setF20(data.f20_doc);

          setF21Name(
            `${BASE_DOCUMENT}/documents/${data.earthood_id}/${data.f21_doc}`
          );
          setF21(data.f21_doc);

          setF23Name(
            `${BASE_DOCUMENT}/documents/${data.earthood_id}/${data.f23_doc}`
          );
          setF23(data.f23_doc);

          const accumulatedFiles = data.coi_doc
            .split(",")
            .reduce((acc, item) => {
              const filename = `${BASE_DOCUMENT}/documents/${data.earthood_id}/${item}`;
              return {
                ...acc,
                [item]: { url: filename },
              };
            }, {});

          setCoiName(accumulatedFiles);
          setCoi(Object.keys(accumulatedFiles));

          const accumulatedOtherFiles = data.other_doc
            .split(",")
            .reduce((acc, item) => {
              const filename = `${BASE_DOCUMENT}/documents/${data.earthood_id}/${item}`;
              return {
                ...acc,
                [item]: { url: filename },
              };
            }, {});

          setOtherName(accumulatedOtherFiles);
          setOther(Object.keys(accumulatedOtherFiles));

          setSignedFileName(
            `${BASE_DOCUMENT}/documents/${data.earthood_id}/${data.signed_contract}`
          );
          setSigned(data.signed_contract);
        } catch (error) {}
      }
    };

    fetchData();
  }, [location]);

  useEffect(() => {
    if (apiData) {
      const scopes = apiData.scope.split(",").map(Number);
      const filteredScopes = myscope.filter((scope) =>
        scopes?.includes(scope.value)
      );
      setSelected(filteredScopes);

      const sectorscopes = apiData.sectoral_scope?.split(",").map(Number);
      const filteredSectorScopes = mysectorscope.filter((sectorscope) =>
        sectorscopes?.includes(sectorscope.value)
      );
      setSelectedSector(filteredSectorScopes);
    }
  }, [apiData, myscope, mysectorscope]);

  const CONFIG_Token = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setPercent(percentCompleted);
    },
  };
  const handlePtRemarksChange = (content) => {
    setPtRemarks(content);
  };
  const handleStRemarksChange = (content) => {
    setStRemarks(content);
  };
  const handleContractRemarksChange = (content) => {
    setContractRemarks(content);
  };
  const handleActionChange = (e) => {
    setAction(e.target.value);
  };

  const handleRemarksChange = (e) => {
    const value = e.target.value;

    setRemarks(value);
  };

  const [proposalEntityRecords, setProposalEntityRecords] = useState([
    { name: "", entity: "", relationship: "" },
  ]);

  const addProposalEntity = () => {
    setProposalEntityRecords([
      ...proposalEntityRecords,
      { name: "", entity: "", relationship: "" },
    ]);
  };

  const handleProposalEntityChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRecords = proposalEntityRecords.map((record, idx) =>
      idx === index ? { ...record, [name]: value } : record
    );
    setProposalEntityRecords(updatedRecords);
  };

  const deleteProposalEntity = (index) => {
    const newProposalEntity = proposalEntityRecords.filter(
      (_, idx) => idx !== index
    );
    setProposalEntityRecords(newProposalEntity);
  };

  const [paymentRecords, setPaymentRecords] = useState([
    { description: "", stage: "", percent: "", amount: "" },
  ]);

  const addPayment = () => {
    setPaymentRecords([
      ...paymentRecords,
      { description: "", stage: "", percent: "", amount: "" },
    ]);
  };

  const calculateTotalPercentage = () => {
    return paymentRecords
      .reduce((total, record) => {
        return total + parseFloat(record.percent);
      }, 0)
      .toFixed(2);
  };

  const handlePaymentChange = (index, event) => {
    const { name, value } = event.target;
    const newPayments = paymentRecords.map((Payment, idx) => {
      if (idx === index) {
        let updatedPayment = { ...Payment, [name]: value };

        if (name === "percent") {
          const baseValue = formData.implemented_fees;
          const newAmount = (baseValue * value) / 100;
          updatedPayment.amount = newAmount.toFixed(2);
        } else if (name === "amount") {
          const baseValue = formData.implemented_fees;
          const amount = value;
          const per = (amount * 100) / baseValue;
          updatedPayment.percent = per.toFixed(2);
        }

        return updatedPayment;
      }
      return Payment;
    });

    setPaymentRecords(newPayments);
  };

  const deletePayment = (index) => {
    const newPayments = paymentRecords.filter((_, idx) => idx !== index);
    setPaymentRecords(newPayments);
  };

  const handleSubmitAction = async (e) => {
    e.preventDefault();

    if (
      remarks == null ||
      remarks == "<p><br></p>" ||
      remarks == "" ||
      action == ""
    ) {
      toast.error("Please Add Some Remarks or Status Field");
    } else {
      try {
        Swal.fire({
          title: "Confirmation?",
          text: "Are you sure, you want to submit the proposal with the selected Action?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              let payload = {
                proposal_id: projectid,
                status: action,
                tm_remarks: remarks,
              };
              const response = await axios.post(
                `${pt_tm_proposalaction_url}`,
                payload,
                API_HEADER
              );
              if (response.status === 200) {
                setRemarks("");
                setAction("");
                toast.success("Remarks added successfully");
                navigate("/dashboard");
              } else {
                toast.error(response.data.message);
              }
            } catch (error) {
              toast.error("Please Upload Mandatory Documents");
              setLoading(false);
            }
          }
        });
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleSubmitFiles = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const formData = new FormData();
      formData.append("f20_doc", f20name[0]);
      formData.append("f21_doc", f21name[0]);
      formData.append("f23_doc", f23name[0]);
      formData.append("rfp_doc", rfpname[0]);
      formData.append("coi_doc", coiname[0]);

      if (othername != null) {
        formData.append("other_doc", othername[0]);
      }

      formData.append("proposal_id", projectid);

      const response = await axios.post(
        `${pt_proposal_submit_url}`,
        formData,
        CONFIG_Token2
      );

      if (!response.data.status) {
        toast.error("Files Do not uploaded");
      } else if (
        fileSizeError ||
        fileF20SizeError ||
        fileCOISizeError ||
        fileF21SizeError ||
        fileF23SizeError ||
        fileOtherSizeError
      ) {
        toast.error("Files size can not exceed {dynamicMaxSize} mb");
      } else {
        toast.success("Files Uploaded Successfully");
        navigate("/dashboard");
      }
    } catch (error) {
      toast.error("Please Upload Mandatory Documents");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleMyProjectChange = (event) => {
    setFormData({ ...formData, project_name: event.target.value });
  };

  const handleEarthoodIdChange = (event) => {
    setFormData({ ...formData, earthood_id: event.target.value });
  };

  const handleProgramIdChange = (event) => {
    setFormData({ ...formData, program_id: event.target.value });
  };

  const handleFeesChange = (event) => {
    setFormData({ ...formData, implemented_fees: event.target.value });
  };

  const handleDateChange = (date, dateString) => {
    setFormData((prevData) => ({
      ...prevData,
      created_at: date ? date.format("YYYY-MM-DD") : "",
    }));
  };

  const handleApproved = () => {
    navigate("/dashboard");
  };

  const handleProgram = (event) => {
    const selectedProgram = event.target.value;
    setFormData({ ...formData, program: selectedProgram });
  };

  useEffect(() => {
    const fetchDataSalesPerson = async () => {
      try {
        const responsesalesperson = await axios.get(`${get_sales_person_url}`);

        setSalesPerson(responsesalesperson.data.data);
      } catch (error) {}
    };

    fetchDataSalesPerson();
  }, []);

  const handleMethChange = (selectedOption) => {
    setSelectedMeth(selectedOption);
    setMethStatus(selectedOption.value);
  };

  useEffect(() => {
    const fetchMethOptions = async () => {
      try {
        const response = await axios.get(`${get_meths_url}`);
        const data = response.data;
        setMethOptions(
          data.data.map((item) => ({ value: item.id, label: item.name }))
        );
        const defaultMeth = data.data
          .filter((item) => item.id == selectedMeth)
          .map((meth) => ({ value: meth.id, label: meth.name }));
        setDefaultMethName(defaultMeth[0]);
      } catch (error) {}
    };

    fetchMethOptions();
  }, [selectedMeth]);

  useEffect(() => {
    const fetchClientOptions = async () => {
      try {
        const response = await axios.get(`${get_client_name_url}`);
        const data = response.data;
        setClientOptions(
          data.data.map((item) => ({ value: item.id, label: item.name }))
        );
        const defaultClient = data.data
          .filter((item) => item.id === selectedClient)
          .map((client) => ({ value: client.id, label: client.name }));
        setDefaultClientName(defaultClient[0]);
      } catch (error) {}
    };

    fetchClientOptions();
  }, [selectedClient]);

  useEffect(() => {
    const fetchDatavalidatorVerifier = async () => {
      try {
        const responsevalidatorverifier = await axios.get(
          `${get_validator_verifier}?program_id=${formData.program} && skill_id=2 &new_team=1`
        );
        setvalidatorVerifier(
          responsevalidatorverifier.data.data.map((validator_verifier) => ({
            value: validator_verifier.id,
            label: validator_verifier.name,
          }))
        );
      } catch (error) {}
    };

    fetchDatavalidatorVerifier();
  }, [formData.program]);

  useEffect(() => {
    if (apiData && validatorVerifier.length > 0) {
      const validator = apiData?.validator_verifier?.split(",").map(Number);

      const filteredValidatorExpert = validatorVerifier.filter((expert) =>
        validator?.includes(expert.value)
      );

      setMyvalidatorVerifier(filteredValidatorExpert);
    }
  }, [apiData, validatorVerifier]);

  useEffect(() => {
    const fetchDatamethExpert = async () => {
      try {
        const responsemethexpert = await axios.get(
          `${get_validator_verifier}?program_id=${formData.program} && skill_id=5`
        );
        setmethExpert(
          responsemethexpert.data.data.map((methexpert) => ({
            value: methexpert.id,
            label: methexpert.name,
          }))
        );
      } catch (error) {}
    };

    fetchDatamethExpert();
  }, [formData.program]);

  useEffect(() => {
    if (apiData && methExpert.length > 0) {
      const meth = apiData?.meth_expert?.split(",").map(Number);

      const filteredMethExpert = methExpert.filter((expert) =>
        meth?.includes(expert.value)
      );

      setMymethExpert(filteredMethExpert);
    }
  }, [apiData, methExpert]);

  useEffect(() => {
    if (apiData && traineeValidator.length > 0) {
      const traineevalidator = apiData?.trainee_validator
        ?.split(",")
        .map(Number);

      const filteredTraineeValidator = traineeValidator.filter((expert) =>
        traineevalidator?.includes(expert.value)
      );

      setMytraineevalidator(filteredTraineeValidator);
    }
  }, [apiData, traineeValidator]);

  useEffect(() => {
    const fetchDatatraineeValidator = async () => {
      try {
        const responsetraineevalidator = await axios.get(
          `${get_validator_verifier}?program_id=${formData.program} && skill_id=3`
        );
        settraineeValidator(
          responsetraineevalidator.data.data.map((traineevalidator) => ({
            value: traineevalidator.id,
            label: traineevalidator.name,
          }))
        );
      } catch (error) {}
    };

    fetchDatatraineeValidator();
  }, [formData.program]);

  useEffect(() => {
    if (apiData && gisExpert.length > 0) {
      const gisExperts = apiData?.gis_expert?.split(",").map(Number);

      const filteredGISExpert = gisExpert.filter((expert) =>
        gisExperts?.includes(expert.value)
      );

      setMygisExpert(filteredGISExpert);
    }
  }, [apiData, gisExpert]);

  useEffect(() => {
    const fetchDatagisExpert = async () => {
      try {
        const responsegisexpert = await axios.get(
          `${get_gis_expert}?skill_id=9`
        );
        setgisExpert(
          responsegisexpert.data.data.map((gisexpert) => ({
            value: gisexpert.id,
            label: gisexpert.name,
          }))
        );
      } catch (error) {}
    };

    fetchDatagisExpert();
  }, [apiData]);

  useEffect(() => {
    const fetchDataConsultant = async () => {
      try {
        const responseconsultant = await axios.get(`${get_client_name_url}`);
        setConsultant(
          responseconsultant.data.data.map((consultant) => ({
            value: consultant.id,
            label: consultant.name,
          }))
        );
      } catch (error) {}
    };

    fetchDataConsultant();
  }, []);

  useEffect(() => {
    if (apiData && consultant.length > 0) {
      const consultantName = apiData?.consultant_id?.split(",").map(Number);

      const filteredConsultant = consultant.filter((expert) =>
        consultantName?.includes(expert.value)
      );

      setMyConsultant(filteredConsultant);
    }
  }, [apiData, consultant]);

  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption.value);
    setClientStatus(selectedOption.value);
  };

  useEffect(() => {
    const fetchDataCountry = async () => {
      try {
        const response = await axios.get(`${get_country_url}`);
        const data = response.data;
        setCountryOptions(
          data.data.map((item) => ({ value: item.id, label: item.name }))
        );
        const defaultCountry = data.data
          .filter((item) => item.id === selectedCountry)
          .map((country) => ({ value: country.id, label: country.name }));
        setDefaultCountryName(defaultCountry[0]);
      } catch (error) {}
    };

    fetchDataCountry();
  }, [selectedCountry]);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setCountryStatus(selectedOption.value);
  };

  useEffect(() => {
    const fetchDataScope = async () => {
      try {
        const responsescope = await axios.get(`${get_scope_url}`);

        setMyScope(
          responsescope.data.data.map((scope) => ({
            value: scope.id,
            label: scope.sector_name,
          }))
        );
      } catch (error) {}
      setLoading(false);
    };

    fetchDataScope();
  }, []);

  useEffect(() => {
    const fetchSectoralScope = async () => {
      try {
        const responsesectoralscope = await axios.get(
          `${get_sectoralscope_url}`
        );

        setMySectorScope(
          responsesectoralscope.data.data.map((scope) => ({
            value: scope.id,
            label: scope.scope,
          }))
        );
      } catch (error) {}
    };

    fetchSectoralScope();
  }, []);

  useEffect(() => {
    const fetchDataProgram = async () => {
      try {
        const responseprogram = await axios.get(`${get_program_url}`);
        setProgram(responseprogram.data.data);
      } catch (error) {}
      setLoading(false);
    };

    fetchDataProgram();
  }, []);

  useEffect(() => {
    const fetchDataTechnicalReviewer = async () => {
      try {
        const responseteamleader = await axios.get(
          `${get_assesment_url}?program_id=${formData.program}`
        );
        setAtList(responseteamleader.data.data);
      } catch (error) {}
      setLoading(false);
    };

    fetchDataTechnicalReviewer();
  }, [formData.program]);

  //   useEffect(() => {
  //     const fetchDataClientContactPerson = async () => {
  //       try {
  //         let payload={
  //           id:selectedClient
  //         }

  //         const responseclientcontactperson = await axios.post(`${get_contact_person_url}`, payload);
  //         const formattedClientContactPerson = JSON.parse(responseclientcontactperson.data.data.contact_person).map((person) => ({
  //           value: person.id,
  //           label: person.name,
  //         }));
  //         setClientContactPerson(formattedClientContactPerson);

  //         const sectorscopes = apiData.contact_person_id?.split(",").map(Number);
  //         const filteredSectorScopes = formattedClientContactPerson.filter((sectorscope) =>
  //           sectorscopes.includes(sectorscope.value)
  //         );

  //         setselectedclientContactPerson(filteredSectorScopes);

  //       } catch (error) { }
  //     };
  // if(selectedClient){
  //     fetchDataClientContactPerson();
  // }
  //   }, [selectedClient]);

  useEffect(() => {
    const fetchDataClientContactPerson = async () => {
      try {
        let payload = {
          id: selectedClient,
        };

        const responseclientcontactperson = await axios.post(
          `${get_contact_person_url}`,
          payload
        );
        const formattedClientContactPerson = JSON.parse(
          responseclientcontactperson.data.data.contact_person
        ).map((person) => ({
          value: person.id,
          label: person.name,
        }));

        console.log(
          "formattedClientContactPerson",
          formattedClientContactPerson
        );
        setClientContactPerson(formattedClientContactPerson);

        const clientcontactperson =
          apiData.contact_person_id?.split(",").map(Number) || [];
        console.log("clientcontactperson", clientcontactperson);
        // const filteredClientContact = formattedClientContactPerson.filter((clientcontact) =>
        //   clientcontactperson?.(clientcontact.value)
        // );

        const filteredClientContact = formattedClientContactPerson.filter(
          (clientcontact) => clientcontactperson?.includes(clientcontact.value)
        );

        console.log("responseclientcontactperson😊", filteredClientContact);

        setselectedclientContactPerson(filteredClientContact);
      } catch (error) {}
    };
    if (selectedClient) {
      fetchDataClientContactPerson();
    }
  }, [selectedClient, apiData]);
  useEffect(() => {
    const fetchDataTrData = async () => {
      try {
        const responsetrdata = await axios.get(`${get_trsbu_url}`);
        setTRList(responsetrdata.data.data);
      } catch (error) {}
    };

    fetchDataTrData();
  }, []);

  useEffect(() => {
    const fetchDataTATRExpert = async () => {
      try {
        const responsetatrexpert = await axios.get(
          `${get_ta_tr_experts}?program_id=${
            formData.program
          } && sectorscope_id=${selectedSector.map(
            (value) => value.value
          )} && skill_id=7`
        );
        setTATRExpert(
          responsetatrexpert.data.data.map((taexpert) => ({
            value: taexpert.id,
            label: taexpert.name,
          }))
        );
      } catch (error) {}
    };

    if (formData.program && selectedSector.length > 0) {
      fetchDataTATRExpert();
    }
  }, [formData.program, selectedSector]);

  useEffect(() => {
    if (apiData && taTrExpert.length > 0) {
      const taExpert = apiData.ta_expert?.split(",").map(Number);
      const filteredTaExpert = taTrExpert.filter((taexpert) =>
        taExpert?.includes(taexpert.value)
      );
      setMyTaexpert(filteredTaExpert);
    }
  }, [apiData, taTrExpert]);

  useEffect(() => {
    const fetchDataTRExpert = async () => {
      try {
        const responsetatrexperts = await axios.get(
          `${get_ta_tr_experts}?program_id=${
            formData.program
          } && sectorscope_id=${selectedSector.map(
            (value) => value.value
          )} && skill_id=8`
        );

        setTRExpert(
          responsetatrexperts.data.data.map((trexpert) => ({
            value: trexpert.id,
            label: trexpert.name,
          }))
        );
      } catch (error) {}
    };

    if (formData.program && selectedSector.length > 0) {
      fetchDataTRExpert();
    }
  }, [formData.program, selectedSector]);

  useEffect(() => {
    if (apiData && trExpert.length > 0) {
      const trExperts = apiData.expert_tr?.split(",").map(Number);
      const filteredTrExpert = trExpert.filter((taexpert) =>
        trExperts?.includes(taexpert.value)
      );
      setMyExpertTr(filteredTrExpert);
    }
  }, [apiData, trExpert]);

  useEffect(() => {
    if (monitoringPeriod && monitoringPeriod.length > 0) {
      rangeForm.setFieldValue("startEndDate", [
        dayjs(monitoringPeriod[0]),
        dayjs(monitoringPeriod[1]),
      ]);
    } else {
      rangeForm.setFieldValue("startEndDate", [null, null]);
    }
  });

  useEffect(() => {
    const fetchDataSope_PA_POA = async () => {
      try {
        const responsescope = await axios.get(`${get_scope_pa_poa_url}`);

        setScope_PA_POA(responsescope.data.data);
      } catch (error) {}
    };

    fetchDataSope_PA_POA();
  }, []);

  useEffect(() => {
    const fetchDataTM = async () => {
      try {
        const responsetechnicalmanager = await axios.get(`${get_tm_url}`);
        setTM(responsetechnicalmanager.data.data);
      } catch (error) {}
      setLoading(false);
    };

    fetchDataTM();
  }, []);

  useEffect(() => {
    const fetchLocalExpert = async () => {
      try {
        const responselocalexpert = await axios.get(
          `${get_local_experts}?country_id=${selectedCountry}&&program_id=${formData.program}`
        );
        console.log(responselocalexpert,'🏁')

        setLocalExpert(responselocalexpert.data);
      } catch (error) {}
    };

    fetchLocalExpert();
  }, [selectedCountry,formData.program,formData]);

  const handleTM = (e) => {
    setTMName(e.target.value);
  };
  console.log("tm:", tmName);

  const handleRemarksTmsChange = (e) => {
    const value = e.target.value;
    setTmsRemarks(value);
  };
  const handlePriority = (event) => {
    const selectedPriority = event.target.value;
    setPriority(selectedPriority);
  };

  const handleFileChange = (file) => {
    if (file.size <= 5 * 1024 * 1024) {
      setFileContractSizeError(false);
    }

    setFileContractSizeError(false);
    setFile(file);
  };

  const handleContractFileError = (file) => {
    setFileContractSizeError(true);
  };

  const handleFileF20Change = (file) => {
    if (file[0].size <= dynamicMaxSize * 1024 * 1024) {
      setFileF20SizeError(false);
    }

    setFileF20SizeError(false);
    setF20Name(file);
  };

  const handleFileRFPChange = (file) => {
    if (file[0].size <= dynamicMaxSize * 1024 * 1024) {
      setFileSizeError(false);
    }

    setFileSizeError(false);
    setRFPName(file);
  };

  const handleFileF21Change = (file) => {
    if (file[0].size <= dynamicMaxSize * 1024 * 1024) {
      setFileF21SizeError(false);
    }

    setFileF21SizeError(false);
    setF21Name(file);
  };

  const handleFileF23Change = (file) => {
    if (file[0].size <= dynamicMaxSize * 1024 * 1024) {
      setFileF23SizeError(false);
    }

    setFileF23SizeError(false);
    setF23Name(file);
  };

  const handleFileCOIChange = (file) => {
    if (file[0].size <= dynamicMaxSize * 1024 * 1024) {
      setFileCOISizeError(false);
    }

    setFileCOISizeError(false);
    setCoiName(file);
  };

  const handleFileOtherChange = (file) => {
    if (file[0].size <= dynamicMaxSize * 1024 * 1024) {
      setFileOtherSizeError(false);
    }

    setFileOtherSizeError(false);
    setOtherName(file);
  };

  const handleSizeError = (file) => {
    setFileSizeError(true);
  };
  const handleF20SizeError = (file) => {
    setFileF20SizeError(true);
  };

  const handleF21SizeError = (file) => {
    setFileF21SizeError(true);
  };

  const handleF23SizeError = (file) => {
    setFileF23SizeError(true);
  };

  const handleCOISizeError = (file) => {
    setFileCOISizeError(true);
  };
  const handleOtherSizeError = (file) => {
    setFileOtherSizeError(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const scopeData = selected.map((value) => value.value);
    const sectorscopeData = selectedSector.map((value) => value.value);
    const TAExpertData = mytaexpert.map((value) => value.value);
    const TRExpertData = myexperttr.map((value) => value.value);
    const ValidatorVerifierData = myvalidatorverifier.map(
      (value) => value.value
    );
    const MathExpertData = mymethexpert.map((value) => value.value);
    const TraineeValidatorData = mytraineevalidator.map((value) => value.value);
    const gisexpert = mygisexpert.map((value) => value.value);
    const consultant = myConsultant.map((value) => value.value);

    const payload = {
      ...formData,
      client_id: clientStatus,
      country: countryStatus,
      meth: methStatus,
      proposal_id: projectid,
      scope: scopeData,
      sectoral_scope: sectorscopeData,
      ta_expert: TAExpertData,
      expert_tr: TRExpertData,
      validator_verifier: ValidatorVerifierData,
      meth_expert: MathExpertData,
      consultant_id: consultant,
      trainee_validator: TraineeValidatorData,
      gis_expert: gisexpert,
      payment_terms: paymentRecords,
      contact_entity: proposalEntityRecords,
      pt_tmremarks: remarksPT,
      priority: priority,
      consultant_name: consultantName,
      proposal_desc: proposalDesc,
      monitoring_period: monitoringPeriod,
      weblink: weblink,
    };

    const total_percent = calculateTotalPercentage();

    if (total_percent <= 100) {
      try {
        setLoading(true);
        let response;
        if (designation_id == 5) {
          response = await axios.post(
            `${sales_update_proposal_url}`,
            payload,
            CONFIG_Token
          );
        } else {
          response = await axios.post(
            `${pt_proposal_team_url}`,
            payload,
            CONFIG_Token
          );
        }

        setLoading(false);

        setProjectId(response.data.project_id);
        if (!response.data.status) {
          toast.error(response.data.message);
        } else {
          toast.success("Form Submitted Successfully");
          setActiveTab("2");
        }
      } catch (error) {}
    } else {
      toast.error("Please enter valid payment terms");
    }
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handleProposalDescChange = (event) => {
    const value = event.target.value;
    setProposalDesc(value);
  };

  const handleMonitoringPeriodChange = (dates) => {
    setMonitoringPeriod(dates);
  };
  const disabledDate = (current) => {
    return current && current < dayjs().endOf("day");
  };

  const handleWeblinkChange = (event) => {
    const value = event.target.value;
    setWeblink(value);
  };

  const handleConsultantNameChange = (event) => {
    const value = event.target.value;
    setConsultantName(value);
  };

  const handleDeadlineDateChange = (date, dateString) => {
    setFormData((prevData) => ({
      ...prevData,
      deadline_date: date ? date.format("YYYY-MM-DD") : "",
    }));
  };

  return (
    <>
      <Header />
      <SideNavbar />
      <div className="content-wrapper bg-white">
        <div className="content">
          <div className="container-fluid bg-white">
            <div className="row pb-5 ">
              <div className="col-12">
                <h4 className="textcolorblue fw-bolder py-2 text-capitalize mt-4">
                  {formData.earthood_id != "" ? (
                    <>
                      <div className="row mx-5">
                        <div className="col-lg-6 col-12 fs-4">
                          {formData.earthood_id} - EARTHOOD
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* <p className="textcolorblue fw-bolder text-capitalize">Add Proposal</p> */}
                      <p className="fs-4"></p>
                    </>
                  )}
                </h4>
                <Tabs
                  defaultActiveKey="1"
                  className="mx-5"
                  // centered
                  activeKey={activeTab}
                  onChange={handleTabChange}
                  indicator={{ Backgroundcolor: "#07B6AF" }}
                  // size="large"
                >
                  <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0 px-2 py-2 text-center tab_size  ${
                          activeTab == 1 ? "box_sh" : ""
                        }`}
                      >
                        <p>Proposal Details</p>
                      </div>
                    }
                    key="1"
                  >
                    <form onSubmit={handleSubmit} method="post">
                      <fieldset disabled={formEdit}>
                        <div className="col-12 border-0 py-2 mx-auto">
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="projectname"
                                className="form-label lightgreen fs-6"
                              >
                                Project Name
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Tooltip
                                title={formData.project_name}
                                placement="top"
                              >
                                <input
                                  type="text"
                                  className="form-control borderlightgreen"
                                  id="project_name"
                                  placeholder="Project Name"
                                  required
                                  name="project_name"
                                  value={formData.project_name}
                                  onChange={handleMyProjectChange}
                                />
                              </Tooltip>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="eid"
                                className="form-label lightgreen fs-6"
                              >
                                Earthood Id
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control borderlightgreen"
                                id="earthood_id"
                                placeholder="Earthood Id"
                                name="earthood_id"
                                required
                                value={formData.earthood_id}
                                onChange={handleEarthoodIdChange}
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="clientname"
                                className="form-label lightgreen fs-6"
                              >
                                Client Name
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              {/* <div > */}

                              <Select
                                id="client_id"
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: state.isDisabled
                                      ? "#e9ecef"
                                      : "",
                                  }),
                                }}
                                name="client_id"
                                value={defaultClientName}
                                onChange={handleClientChange}
                                options={clientOptions}
                                placeholder="Select"
                                isSearchable={true}
                                // disabled={formEdit}
                                isDisabled={formEdit}

                                // disabled={true}
                                // style={{ width: '80%' }}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <div>
                                <label
                                  htmlFor="contact person"
                                  className="form-label lightgreen fs-6"
                                >
                                  Client Contact Person
                                  <span style={{ color: "red" }}>*</span>
                                </label>

                                {/* <select
                                  id="contact_person"
                                  className="form-select borderlightgreen form-select-sm"
                                  aria-label="Default select example"
                                  name="contact_person"
                                  required
                                  value={formData.contact_person_id}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      contact_person_id: e.target.value,
                                    })
                                  }
                                >
                                  <option value={""}>Select</option>
                                  {clientContactPerson && clientContactPerson
                                    .map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                </select> */}

                                <MultiSelect
                                  options={clientContactPerson}
                                  value={selectedclientContactPerson}
                                  onChange={setselectedclientContactPerson}
                                  labelledBy="Select"
                                  className={formEdit ? "multi" : ""}
                                />
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="consultant"
                                className="form-label lightgreen fs-6"
                              >
                                Consultant Name
                              </label>

                              <MultiSelect
                                options={consultant}
                                value={myConsultant}
                                onChange={setMyConsultant}
                                labelledBy="Select"
                                className={formEdit ? "multi" : ""}
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Program"
                                className="form-label lightgreen fs-6"
                              >
                                Program<span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="program"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="program"
                                value={formData.program}
                                onChange={handleProgram}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {program.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.program_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="clientname"
                                className="form-label lightgreen fs-6"
                              >
                                Project Country
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Select
                                id="country"
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: state.isDisabled
                                      ? "#e9ecef"
                                      : "",
                                  }),
                                }}
                                name="country"
                                value={defaultCountryName}
                                onChange={handleCountryChange}
                                options={countryOptions}
                                placeholder="Select"
                                isSearchable={true}
                                // disabled={formEdit}
                                isDisabled={formEdit}
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Scope(PO/POA)"
                                className="form-label lightgreen fs-6"
                              >
                                Scope(PA/POA)
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                id="scope_pa"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="scope_pa"
                                value={formData.scope_pa}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    scope_pa: e.target.value,
                                  })
                                }
                              >
                                <option value={""}>Select</option>
                                {scope_PA_POA.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.scope}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Program Id"
                                className="form-label lightgreen fs-6"
                              >
                                Program Id
                              </label>
                              <input
                                type="text"
                                id="program_id"
                                placeholder="Program Id"
                                name="program_id"
                                className="form-control borderlightgreen"
                                value={formData.program_id}
                                onChange={handleProgramIdChange}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Implementation Fees"
                                className="form-label lightgreen fs-6"
                              >
                                Implementation Fees
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control borderlightgreen"
                                id="implemented_fees"
                                placeholder="Implementation Fees"
                                required
                                name="implemented_fees"
                                value={formData.implemented_fees}
                                onChange={handleFeesChange}
                              />
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <div>
                                <label
                                  htmlFor="SBU Head"
                                  className="form-label lightgreen fs-6"
                                >
                                  SBU Head
                                  <span style={{ color: "red" }}>*</span>
                                </label>

                                <select
                                  id="sbu_head"
                                  className="form-select borderlightgreen form-select-sm"
                                  aria-label="Default select example"
                                  name="sbu_head"
                                  required
                                  value={formData.sbu_head}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      sbu_head: e.target.value,
                                    })
                                  }
                                >
                                  <option selected value={""}>
                                    Select
                                  </option>
                                  {trlist
                                    .filter(
                                      (option) => option.designation_id == 9
                                    )
                                    .map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Scope"
                                className="form-label lightgreen fs-6"
                              >
                                Sectoral Scope
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <MultiSelect
                                options={mysectorscope}
                                value={selectedSector}
                                onChange={setSelectedSector}
                                labelledBy="Select"
                                className={formEdit ? "multi" : ""}
                              />
                            </div>
                          </div>

                          <div className="row ">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Scope"
                                className="form-label lightgreen fs-6"
                              >
                                Scope<span style={{ color: "red" }}>*</span>
                              </label>

                              <MultiSelect
                                options={myscope}
                                value={selected}
                                onChange={setSelected}
                                labelledBy="Select"
                                className={formEdit ? "multi" : ""}
                              />
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <div>
                                <label
                                  htmlFor="Sales person"
                                  className="form-label lightgreen fs-6"
                                >
                                  Sales Person
                                  <span style={{ color: "red" }}>*</span>
                                </label>

                                <select
                                  id="sales_person"
                                  className="form-select borderlightgreen form-select-sm"
                                  aria-label="Default select example"
                                  name="sales_person"
                                  required
                                  value={formData.sales_id}
                                  // onChange={(e) =>
                                  //   setFormData({
                                  //     ...formData,
                                  //     sales_id: e.target.value,
                                  //   })
                                  // }
                                >
                                  <option value={""}>Select</option>
                                  {salesPerson.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="priority"
                                className="form-label lightgreen fs-6"
                              >
                                Priority
                              </label>

                              <select
                                id="priority"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="priority"
                                value={priority}
                                onChange={handlePriority}
                              >
                                <option value={""}>Select</option>
                                <option value="low">Low</option>
                                <option value="medium">Medium</option>
                                <option value="high">High</option>
                              </select>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Meth"
                                className="form-label lightgreen fs-6"
                              >
                                Meth
                              </label>
                              {/* <select
                                id="meth"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="meth"
                                value={formData.meth}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    meth: e.target.value,
                                  })
                                }
                              >
                                <option value={""}>Select</option>
                                {meth.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select> */}

                              <Select
                                id="meth_id"
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: state.isDisabled
                                      ? "#e9ecef"
                                      : "",
                                  }),
                                }}
                                // className="form-select borderlightgreen form-select-sm"
                                // aria-label="Default select example"
                                name="meth"
                                value={defaultMethName}
                                onChange={handleMethChange}
                                options={methOptions}
                                placeholder="Select"
                                isSearchable={true}
                                isDisabled={formEdit}
                              />
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Proposal Date"
                                className="form-label lightgreen fs-6"
                              >
                                Proposal Date
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <DatePicker
                                id="proposaldate"
                                className={`form-control borderlightgreen custom-disabled-date-picker `}
                                style={{ color: "black" }}
                                disabled={formEdit}
                                // required
                                format="DD/MM/YYYY"
                                value={
                                  formData.created_at
                                    ? dayjs(formData.created_at)
                                    : null
                                }
                                // onChange={handleDateChange}
                                // disabledDate={(current) => current && current > moment().endOf('day')}
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="deadline Date"
                                className="form-label lightgreen fs-6"
                              >
                                Deadline Date
                              </label>

                              <DatePicker
                                id="deadlinedate"
                                className={`form-control borderlightgreen custom-disabled-date-picker`}
                                style={{ color: "black" }}
                                format="DD/MM/YYYY"
                                disabled={formEdit}
                                value={
                                  formData.deadline_date
                                    ? dayjs(formData.deadline_date)
                                    : null
                                }
                                // onChange={handleDeadlineDateChange}
                                // disabledDate={(current) => current && current < moment().startOf('day')}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Monitoring Period"
                                className="form-label lightgreen fs-6"
                              >
                                Monitoring Period
                              </label>
                              {/* <input
                                type="date"
                                className={`form-control borderlightgreen ${
                                  proposalDateError ? "is-invalid" : ""
                                }`}
                                id="monitoring_period"
                                required
                                // name="created_at"
                                // value={formData.created_at}
                                value={monitoringPeriod}

                                // onChange={handleDateChange}
                                onChange={handleMonitoringPeriodChange}

                                max={new Date().toISOString().split("T")[0]} // Set max attribute to today's date
                              /> */}
                              <NewForm form={rangeForm}>
                                <NewForm.Item name="startEndDate">
                                  <RangePicker
                                    className={` custom-disabled-date-picker `}
                                    style={{ width: "100%" }}
                                    // name='startEndDate'
                                    format={"DD/MM/YYYY"}
                                    // disabledDate={disabledDate}
                                    disabled={formEdit}
                                    onChange={handleMonitoringPeriodChange}

                                    // value={monitoringPeriod}
                                  />
                                </NewForm.Item>
                              </NewForm>

                              {/* {proposalDateError && (
                                <div className="invalid-feedback">
                                  {proposalDateError}
                                </div>
                              )} */}
                            </div>
                            {/* <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Consultant Name"
                                className="form-label lightgreen fs-6"
                              >
                                Consultant Name
                              </label>
                              <input
                                type="text"
                                placeholder="Add Consultant Name"
                                className="form-control"
                                style={{ width: "100%" }}
                                id="consultant_name"

                                //  disabled={tm_form_input}
                                value={consultantName}
                                onChange={handleConsultantNameChange}
                              />
                              
                            </div> */}
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Proposal desc"
                                className="form-label lightgreen fs-6"
                              >
                                Proposal Description
                              </label>
                              <textarea
                                placeholder="Add Proposal Description"
                                className="form-control"
                                style={{ width: "100%" }}
                                id="proposal_desc"
                                name="proposal_desc"
                                rows={3}
                                // disabled={tm_form_input}
                                value={proposalDesc}
                                onChange={handleProposalDescChange}
                              />
                            </div>

                            {/* <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Monitoring Period"
                                className="form-label lightgreen fs-6"
                              >
                                Monitoring Period
                              </label>
                              <textarea
                                placeholder="Add Monitoring Period"
                                className="form-control"
                                style={{ width: "100%" }}
                                id="monitoring_period"
                                rows={3}
                                // disabled={tm_form_input}
                                value={monitoringPeriod}
                                onChange={handleMonitoringPeriodChange}
                              />
                              
                            </div> */}

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="weblink"
                                className="form-label lightgreen fs-6"
                              >
                                Weblink
                              </label>
                              <textarea
                                placeholder="Add Weblink"
                                className="form-control"
                                style={{ width: "100%" }}
                                id="weblink"
                                rows={3}
                                // disabled={tm_form_input}
                                value={weblink}
                                onChange={handleWeblinkChange}
                              />
                            </div>
                          </div>

                          <p className="textlightgreen font-weight-600 mt-2 mb-2 fs-5">
                            Assessment Team
                          </p>
                          <hr className="mb-2 lightgreen" />
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="teamleader"
                                className="form-label lightgreen fs-6"
                              >
                                Team Leader
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="team_leader"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="team_leader"
                                required
                                value={formData.team_leader}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    team_leader: e.target.value,
                                  })
                                }
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {atlist
                                  .filter((option) => option.skill_id == 1)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="taexpert"
                                className="form-label lightgreen fs-6"
                              >
                                TA Expert
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <MultiSelect
                                options={taTrExpert}
                                value={mytaexpert}
                                onChange={setMyTaexpert}
                                labelledBy="Select"
                                className={formEdit ? "multi" : ""}
                              />

                              {/* <select
                                id="ta_expert"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="ta_expert"
                                required
                                value={formData.ta_expert}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    ta_expert: e.target.value,
                                  })
                                }
                              >
                                <option selected value={""}>
                                  Select
                                </option> */}
                              {/* {atlist
                                  .filter((option) => option.skill_id == 7 && (selectedSector.map((sector) => sector.value)).includes(option.scope_id))
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option> */}
                              {/* <option value={""}>Select</option>
                                {taTrExpert
                                  .filter(
                                    (option) =>
                                      option.skill_id == 7 
                                        // .includes(option.scope_id)
                                  )
                                  .map((option) => (
                        
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select> */}
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="teamleader"
                                className="form-label lightgreen fs-6"
                              >
                                Local Expert
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="local_expert"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="local_expert"
                                value={formData.local_expert}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    local_expert: e.target.value,
                                  })
                                }
                              >
                                <option selected value={""}>
                                  To be added later
                                </option>
                                {localExpert
                                  // .filter((option) => option.skill_id == 6)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="financeexpert"
                                className="form-label lightgreen fs-6"
                              >
                                Finance Expert
                              </label>

                              <select
                                id="finance_expert"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="finance_expert"
                                value={formData.finance_expert}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    finance_expert: e.target.value,
                                  })
                                }
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {atlist
                                  .filter((option) => option.skill_id == 4)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="validator"
                                className="form-label lightgreen fs-6"
                              >
                                Validator / Verifier
                              </label>

                              <MultiSelect
                                options={validatorVerifier}
                                value={myvalidatorverifier}
                                onChange={setMyvalidatorVerifier}
                                labelledBy="Select"
                                className={formEdit ? "multi" : ""}
                              />
                            </div>

                            {/* <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label htmlFor="validator" className="form-label lightgreen fs-6">
                                Validator/Verifier
                              </label>

                              <select
                                id="validator_verifier"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="validator_verifier"
                                value={formData.validator_verifier}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    validator_verifier: e.target.value,
                                  })
                                }
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {atlist
                                  .filter((option) => option.skill_id == 2)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>

                            </div> */}
                            {/* <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Meth"
                                className="form-label lightgreen fs-6"
                              >
                                Meth Expert
                                
                              </label>
                              <select
                                id="meth"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="meth"
                                value={formData.meth_expert}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    meth_expert: e.target.value,
                                  })
                                }
                              >
                                <option value={""}>Select</option>
                                {atlist.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div> */}

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="methexpert"
                                className="form-label lightgreen fs-6"
                              >
                                Meth Expert
                              </label>
                              <MultiSelect
                                options={methExpert}
                                value={mymethexpert}
                                onChange={setMymethExpert}
                                labelledBy="Select"
                                className={formEdit ? "multi" : ""}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="taexpert"
                                className="form-label lightgreen fs-6"
                              >
                                Trainee Validator/Verifier
                              </label>

                              <MultiSelect
                                options={traineeValidator}
                                value={mytraineevalidator}
                                onChange={setMytraineevalidator}
                                labelledBy="Select"
                                className={formEdit ? "multi" : ""}
                              />
                            </div>

                            {/* <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label htmlFor="taexpert" className="form-label lightgreen fs-6">
                                Trainee Validator/Verifier
                              </label>

                              <select
                                id="trainee_validator"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="trainee_validator"
                                value={formData.trainee_validator}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    trainee_validator: e.target.value,
                                  })
                                }
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {atlist
                                  .filter((option) => option.skill_id == 3)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>

                            </div> */}
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="technicalreviewer"
                                className="form-label lightgreen fs-6"
                              >
                                Technical Reviewer
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="technical_reviewer"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="technical_reviewer"
                                required
                                value={formData.technical_reviewer}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    technical_reviewer: e.target.value,
                                  })
                                }
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {trlist
                                  .filter(
                                    (option) => option.designation_id == 7
                                  )
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="technicalreviewer"
                                className="form-label lightgreen fs-6"
                              >
                                Expert to Technical Reviewer
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <MultiSelect
                                options={trExpert}
                                value={myexperttr}
                                onChange={setMyExpertTr}
                                labelledBy="Select"
                                className={formEdit ? "multi" : ""}
                              />
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="gis_expert"
                                className="form-label lightgreen fs-6"
                              >
                                GIS Expert
                              </label>

                              <MultiSelect
                                options={gisExpert}
                                value={mygisexpert}
                                onChange={setMygisExpert}
                                labelledBy="Select"
                                className={formEdit ? "multi" : ""}
                              />
                            </div>

                            {/* <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label htmlFor="financeexpert" className="form-label lightgreen fs-6">
                                Technical Manager<span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="finance_expert"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="finance_expert"
                                value={tmName}
                                onChange={handleTM}
                                disabled={designation_id == 6} 
                                required
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {tm?.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>

                            </div> */}
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <p className="textlightgreen font-weight-600 my-3 fs-5">
                                Proposal Entity
                              </p>
                              <hr className="mb-4 lightgreen" />
                            </div>
                            <ProposalEntity
                              proposalEntityRecords={proposalEntityRecords}
                              formdisabled={formEdit}
                              addProposalEntity={addProposalEntity}
                              handleProposalEntityChange={
                                handleProposalEntityChange
                              }
                              deleteProposalEntity={deleteProposalEntity}
                            />
                          </div>

                          <div className="row">
                            <p className="textlightgreen font-weight-600 mt-2 mb-2 fs-5">
                              Payment Terms
                            </p>

                            <PaymentTerms
                              paymentRecords={paymentRecords}
                              formdisabled={formEdit}
                              addPayment={addPayment}
                              handlePaymentChange={handlePaymentChange}
                              deletePayment={deletePayment}
                            />

                            {designation_id != 5 ? (
                              <div class="mt-1 mb-2">
                                <label
                                  className="textlightgreen  my-2 fs-5"
                                  style={{ fontWeight: "600" }}
                                >
                                  TM Support Remarks
                                </label>
                                <textarea
                                  placeholder="Add Remarks"
                                  className="form-control"
                                  style={{ width: "100%" }}
                                  id="exampleFormControlTextarea1"
                                  rows={3}
                                  value={tmsremarks}
                                ></textarea>
                                {remarksPTError && (
                                  <div className="invalid-feedback text-danger">
                                    {remarksPTError}
                                  </div>
                                )}
                              </div>
                            ) : (
                              ""
                            )}

                            {!formEdit ? (
                              <div className="col-12 d-flex justify-content-end">
                                <button
                                  type="submit"
                                  className="btn btn-success mt-3"
                                >
                                  Save & Next
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </fieldset>
                    </form>

                    <div className="col-12 border-0 mx-auto">
                      {tmForm && designation_id != 5 ? (
                        <>
                          <p className="textlightgreen font-weight-600 my-2 fs-5">
                            TM Action
                            <span style={{ color: "red" }}>*</span>
                          </p>
                          <hr className="mb-2 lightgreen" />
                          <div>
                            {tm_form_input ? (
                              <span className="mx-4">
                                {projectstatus === 9 ? (
                                  <Alert
                                    className="col-12"
                                    message="Approved"
                                    type="success"
                                    showIcon
                                  />
                                ) : projectstatus === 6 ? (
                                  <Alert
                                    className="col-12"
                                    message="Clarification Required"
                                    banner
                                  />
                                ) : projectstatus === 7 ? (
                                  <Alert
                                    className="col-12"
                                    message="Rejected"
                                    type="error"
                                    showIcon
                                  />
                                ) : (
                                  ""
                                )}
                              </span>
                            ) : (
                              <>
                                <div className="row">
                                  <span className="col-12 col-lg-4 col-md-3">
                                    <input
                                      type="radio"
                                      id="approve"
                                      name="tm_action"
                                      value={9}
                                      disabled={tm_form_input}
                                      onChange={handleActionChange}
                                      required
                                      className=" text-success"
                                    />
                                    <label
                                      className="text-success mx-1"
                                      htmlFor="approve"
                                    >
                                      Approved
                                    </label>
                                  </span>

                                  <span className="col-12 col-lg-4 col-md-3">
                                    <input
                                      type="radio"
                                      id="clarification_required"
                                      name="tm_action"
                                      value={6}
                                      disabled={tm_form_input}
                                      onChange={handleActionChange}
                                      className="text-primary"
                                    />
                                    <label
                                      className="text-primary mx-1"
                                      htmlFor="clarification_required"
                                    >
                                      Clarification Required
                                    </label>
                                  </span>

                                  <span className="col-12 col-lg-4 col-md-3">
                                    <input
                                      type="radio"
                                      id="rejected"
                                      name="tm_action"
                                      value={7}
                                      disabled={tm_form_input}
                                      onChange={handleActionChange}
                                      className="text-danger"
                                    />
                                    <label
                                      className="text-danger mx-1"
                                      htmlFor="rejected"
                                    >
                                      Rejected
                                    </label>
                                  </span>
                                </div>
                              </>
                            )}
                          </div>
                          <div class="mt-1 mb-3" style={{ display: "flex" }}>
                            <textarea
                              placeholder="Add Remarks"
                              className="form-control"
                              style={{ width: "100%" }}
                              id="exampleFormControlTextarea1"
                              rows={3}
                              required
                              disabled={tm_form_input}
                              value={remarks}
                              onChange={handleRemarksChange}
                            ></textarea>
                            {remarksError && (
                              <div className="invalid-feedback text-danger">
                                {remarksError}
                              </div>
                            )}
                          </div>

                          {tmButton ? (
                            <div className="d-flex justify-content-end">
                              <button
                                className="btn btn-success mt-3 "
                                onClick={handleSubmitAction}
                              >
                                Submit
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </Tabs.TabPane>

                  <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0 px-2 py-2 text-center tab_size ${
                          activeTab == 2 ? "box_sh" : ""
                        }`}
                      >
                        <p>Attachments</p>
                      </div>
                    }
                    key="2"
                  >
                    <div className="col-12 border-0 py-4 mx-auto">
                      {isSubmitting && (
                        <div className="overlay">
                          <Progress type="circle" percent={percent} />
                        </div>
                      )}

                      <form
                        onSubmit={handleSubmitFiles}
                        method="POST"
                        encType="multipart/form-data"
                      >
                        <table className="table table-bordered table-hover table-responsive-sm">
                          <thead>
                            <tr>
                              <th className="text-center lightgreen p-3 fw-bolder fs-6">
                                Document Name{" "}
                              </th>
                              {!formEdit &&
                              (designation_id == 6 || designation_id == 5) ? (
                                <th className="text-center lightgreen p-3 fw-bolder fs-6">
                                  Select File
                                </th>
                              ) : (
                                ""
                              )}
                              <th className="text-center lightgreen p-3 fw-bolder fs-6">
                                Uploaded File
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                Request For Proposal Recieved From Client (F20){" "}
                              </td>

                              {!formEdit &&
                              (designation_id == 6 || designation_id == 5) ? (
                                <td>
                                  <FileUploader
                                    handleChange={handleFileRFPChange}
                                    name="rfp_doc"
                                    types={fileTypes}
                                    multiple="false"
                                    maxSize={dynamicMaxSize}
                                    onSizeError={handleSizeError}
                                  />
                                  {fileSizeError ? (
                                    <span className="text-danger">
                                      File size greater than {dynamicMaxSize} mb
                                      is not allowed
                                    </span>
                                  ) : (
                                    <span>
                                      {rfpname[0].name != undefined ? (
                                        <>
                                          {" "}
                                          {rfpname &&
                                            `File name: ${rfpname[0].name}`}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  )}
                                </td>
                              ) : (
                                ""
                              )}

                              <td>
                                <a target="_blank" href={rfpname}>
                                  {rpf}
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Request For Proposal Reviewed (F20)
                                <span style={{ color: "red" }}>*</span>
                              </td>

                              {!formEdit &&
                              (designation_id == 6 || designation_id == 5) ? (
                                <td>
                                  <FileUploader
                                    handleChange={handleFileF20Change}
                                    name="f20_doc"
                                    types={fileTypes}
                                    multiple="false"
                                    maxSize={dynamicMaxSize}
                                    onSizeError={handleF20SizeError}
                                  />
                                  {fileF20SizeError ? (
                                    <span className="text-danger">
                                      File size greater than {dynamicMaxSize} mb
                                      is not allowed
                                    </span>
                                  ) : (
                                    <span>
                                      {f20name[0].name != undefined ? (
                                        <>
                                          {" "}
                                          {f20name &&
                                            `File name: ${f20name[0].name}`}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  )}
                                </td>
                              ) : (
                                ""
                              )}

                              <td>
                                <a target="_blank" href={f20name}>
                                  {f20}
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Contract Review & Team Selection (F21)
                                <span style={{ color: "red" }}>*</span>
                              </td>

                              {!formEdit &&
                              (designation_id == 6 || designation_id == 5) ? (
                                <td>
                                  <FileUploader
                                    handleChange={handleFileF21Change}
                                    name="f21_doc"
                                    types={fileTypes}
                                    multiple="false"
                                    maxSize={dynamicMaxSize}
                                    onSizeError={handleF21SizeError}
                                  />
                                  {fileF21SizeError ? (
                                    <span className="text-danger">
                                      File size greater than {dynamicMaxSize} mb
                                      is not allowed
                                    </span>
                                  ) : (
                                    <span>
                                      {f21name[0].name != undefined ? (
                                        <>
                                          {" "}
                                          {f21name &&
                                            `File name: ${f21name[0].name}`}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  )}
                                </td>
                              ) : (
                                ""
                              )}

                              <td>
                                <a target="_blank" href={f21name}>
                                  {f21}
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Service Agreement (F23)
                                <span style={{ color: "red" }}>*</span>
                              </td>

                              {!formEdit &&
                              (designation_id == 6 || designation_id == 5) ? (
                                <td>
                                  <FileUploader
                                    handleChange={handleFileF23Change}
                                    name="f23_doc"
                                    types={fileTypes}
                                    multiple="false"
                                    maxSize={dynamicMaxSize}
                                    onSizeError={handleF23SizeError}
                                  />
                                  {fileF23SizeError ? (
                                    <span className="text-danger">
                                      File size greater than {dynamicMaxSize} mb
                                      is not allowed
                                    </span>
                                  ) : (
                                    <span>
                                      {f23name[0].name != undefined ? (
                                        <>
                                          {" "}
                                          {f23name &&
                                            `File name: ${f23name[0].name}`}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  )}
                                </td>
                              ) : (
                                ""
                              )}

                              <td>
                                <a target="_blank" href={f23name}>
                                  {f23}
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                COI Document (F22)
                                <span style={{ color: "red" }}>*</span>
                              </td>

                              {!formEdit &&
                              (designation_id == 6 || designation_id == 5) ? (
                                <td>
                                  <FileUploader
                                    handleChange={handleFileCOIChange}
                                    name="coi_doc"
                                    types={fileTypes}
                                    multiple={true}
                                    maxSize={dynamicMaxSize}
                                    onSizeError={handleCOISizeError}
                                  />
                                  {fileCOISizeError ? (
                                    <span className="text-danger">
                                      File size greater than {dynamicMaxSize} mb
                                      is not allowed
                                    </span>
                                  ) : (
                                    <span>
                                      {coiname[0].name != undefined ? (
                                        <>
                                          {" "}
                                          {coiname &&
                                            `File name: ${coiname[0].name}`}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  )}
                                </td>
                              ) : (
                                ""
                              )}

                              <td>
                                {Object.keys(coiname).map((fileName) => (
                                  <div key={fileName}>
                                    <a
                                      target="_blank"
                                      href={coiname[fileName].url}
                                    >
                                      {fileName}
                                    </a>
                                  </div>
                                ))}
                              </td>
                            </tr>

                            <tr>
                              <td>Supportive Documents</td>

                              {!formEdit &&
                              (designation_id == 6 || designation_id == 5) ? (
                                <td>
                                  <FileUploader
                                    handleChange={handleFileOtherChange}
                                    name="other_doc"
                                    types={fileTypes}
                                    multiple={true}
                                    maxSize={dynamicMaxSize}
                                    onSizeError={handleOtherSizeError}
                                  />
                                  {fileOtherSizeError ? (
                                    <span className="text-danger">
                                      File size greater than {dynamicMaxSize} mb
                                      is not allowed
                                    </span>
                                  ) : (
                                    <span>
                                      {othername[0].name != undefined ? (
                                        <>
                                          {" "}
                                          {othername &&
                                            `File name: ${othername[0].name}`}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  )}
                                </td>
                              ) : (
                                ""
                              )}
                              <td>
                                {Object.keys(othername).map((fileName) => (
                                  <div key={fileName}>
                                    <a
                                      target="_blank"
                                      href={othername[fileName].url}
                                    >
                                      {fileName}
                                    </a>
                                  </div>
                                ))}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        {!formEdit &&
                        (designation_id == 6 || designation_id == 5) ? (
                          <div className="col-12 d-flex justify-content-end gap-3">
                            <button
                              type="submit"
                              className="btn btn-success mt-5"
                            >
                              Submit
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </form>
                    </div>
                  </Tabs.TabPane>
                  {projectstatus > 8 && designation_id == 5 ? (
                    <>
                      <Tabs.TabPane
                        tab={
                          <div
                            className={` textlightgreen rounded-0 px-2 py-2 text-center tab_size ${
                              activeTab == 3 ? "box_sh" : ""
                            }`}
                          >
                            <p>Forward to Client</p>
                          </div>
                        }
                        key="3"
                      >
                        <div className="col-8 border-0 bg-white p-0">
                          {projectstatus >= 10 ? (
                            <>
                              <div
                                class="mt-3 mb-3 d-grid"
                                style={{ display: "flex" }}
                              >
                                <label className="mb-3 textcolor fs-6">
                                  Sales Team Remarks{" "}
                                </label>
                                <ReactQuill
                                  theme="snow"
                                  value={stremarks}
                                  style={{ width: "50vw" }}
                                  modules={{ toolbar: false }}
                                  readOnly={true}
                                  dangerouslySetInnerHTML={{
                                    __html: stremarks,
                                  }}
                                />
                              </div>
                            </>
                          ) : projectstatus == 11 ? (
                            <>
                              <div
                                class="mt-3 mb-3 d-grid"
                                style={{ display: "flex" }}
                              >
                                <label className="mb-3 textcolor fs-6">
                                  Sales Team Remarks{" "}
                                </label>
                                <ReactQuill
                                  theme="snow"
                                  value={stremarks}
                                  onChange={handlePtRemarksChange}
                                  modules={{ toolbar: false }}
                                  style={{ width: "50vw" }}
                                  readOnly={true}
                                  dangerouslySetInnerHTML={{
                                    __html: stremarks,
                                  }}
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Tabs.TabPane>
                    </>
                  ) : (
                    <></>
                  )}

                  <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0 px-2 py-2 text-center tab_size  ${
                          activeTab == 4 ? "box_sh" : ""
                        }`}
                      >
                        <p>Summary</p>
                      </div>
                    }
                    key="4"
                  >
                    <div className="col-12 border-0 bg-white  ">
                      <SummaryChain proposalId={projectid} />
                    </div>
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default PtActions;
