import React, { useState, useEffect } from "react";
import "../../src/App.css";
import { useNavigate } from "react-router-dom";
import ProgramWiseProjects from "../Components/Graphs/projectDashboardGraphs/ProgramWiseProject";
import ProjectStageWise from "../Components/Graphs/projectDashboardGraphs/ProjectStageWise";
import SBUWorkloadGraph from "../Components/Graphs/projectDashboardGraphs/SBUWorkloadGraph";
import TLWorkloadGraph from "../Components/Graphs/projectDashboardGraphs/TLWorkloadGraph";
import ValidatorWorkloadGraph from "../Components/Graphs/projectDashboardGraphs/ValidatorWorkloadGraph";
import TraineeWorkloadGraph from "../Components/Graphs/projectDashboardGraphs/TraineeWorkloadGraph";
import RegionWiseProjectGraph from "../Components/Graphs/projectDashboardGraphs/RegionWiseProjectGraph";
import ContractSignedMonthlyGraph from "../Components/Graphs/projectDashboardGraphs/ContractSignedMonthlyGraph";
import KickoutMontlyWiseGraph from "../Components/Graphs/projectDashboardGraphs/KickoutMonthlyWiseGraph";
import ProjectMovingDateWise from "../Components/Graphs/projectDashboardGraphs/ProjectMovingDateWise";
import { DatePicker, Spin } from "antd";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ProjectStageBar from "../Components/Graphs/projectDashboardGraphs/ProjectStageBar";
import RFIMonthly from "../Components/Graphs/projectDashboardGraphs/RFIMonthly";
import KickoutRFI from "../Components/Graphs/projectDashboardGraphs/KickoutRFI";
import {
  API_HEADER,
  getStatsData,
  project_wise_project_url,
  get_total_data_url,
} from "../config";
import axios from "axios";
import KickoutMontlyWiseGraphTR from "../Components/Graphs/projectDashboardGraphs/KickoutMonthlyWiseGraphTR";
import KickoutMontlyWiseGraphCC from "../Components/Graphs/projectDashboardGraphs/KickoutMonthlyGraphCC";

const ProjectDashboard = () => {
  const dateFormat = "DD/MM/YYYY";

  const [totalProject, setTotalProject] = useState(0);
  const [counter, setCounter] = useState({});

  useEffect(() => {
    const getProposalScopeWise = async () => {
      const payload = { fy: "" };
      const reqData = await axios.post(`${getStatsData}`, payload, API_HEADER);
      setTotalProject(reqData.data.total_count);
    };

    getProposalScopeWise();
  }, []);

  useEffect(() => {
    const getCounter = async () => {
      const payload = {
        fy: "",
        // 'start_date': startDate ? startDate.format('YYYY-MM-DD') : null,
        // 'end_date': endDate ? endDate.format('YYYY-MM-DD') : null,
      };
      // const reqData = await axios.post(`${get_total_data_url}`,payload, API_HEADER);
      // const MyprogramwiseData = reqData.data.totals;
      // setCounter(MyprogramwiseData)

      const reqData = await axios.post(
        `${project_wise_project_url}`,
        payload,
        API_HEADER
      );
      const MyprogramwiseData = reqData.data.totals;
      setCounter(MyprogramwiseData);
    };

    getCounter();
  }, []);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/dashboard");
  };
  const [search, setSearch] = useState(false);

  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [loader, setLoader] = useState(false);

  const handleSearchByColumn = (value) => {
    if (startDate && endDate) {
      if (startDate.isAfter(endDate)) {
        alert("Start Date cannot be after End Date.");
        setendDate(null);
        setstartDate(null);
        return;
      }
    }
    setSearch(!search);
  };

  const resetFilters = () => {
    setendDate(null);
    setstartDate(null);
    setSearch(!search);
  };

  const handleDownload = async () => {
    setLoader(true);

    const pdf = new jsPDF();
    const sections = document.querySelectorAll(".page-break"); // Use class to target sections

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const canvas = await html2canvas(section);
      const imgData = canvas.toDataURL("image/png");

      if (i > 0) {
        pdf.addPage();
      }

      pdf.addImage(imgData, "PNG", 0, 0, 210, 297); // Adjust dimensions as needed
    }

    pdf.save("document.pdf");
    setLoader(false);
  };

  const navigateToAllProjects = async () => {
    navigate("/totalprojectsat");
  };

  return (
    <>
      <Spin spinning={loader}>
        <div className="content dashboardcolor">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 my-4">
                <div className="my-3 mx-4 d-flex align-items-center justify-content-between">
                  <h3 className="font-weight-bold">Project Dashboard</h3>

                  <div className="d-flex">
                    <button
                      className="mx-2 btn btn-light"
                      onClick={navigateToAllProjects}
                    >
                      Total Project : {totalProject}
                    </button>

                    <button
                      type="button"
                      className="mx-2 btn btn-outline-success"
                      onClick={handleClick}
                    >
                      Home
                    </button>

                    {/* <button className="mx-2 btn btn-primary" onClick={handleDownload}>Download</button> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mx-4">
              <div className="col-sm-3 col-md-2">
            <div className="mb-3">
               <label className="text-capitalize textcolumntitle  fw-bold font12px">
                   Start Date
               </label>
                 <DatePicker
                      onChange={(date, dateString) => setstartDate(date)}
                      placeholder="From Date"
                      style={{ width: "100%" }}
                      className="rounded-2"
                      format={dateFormat}
                      showTime={false}
                      value={startDate}
                      disabledDate={(current) => current && current > moment().endOf('day')}
                 />
            </div>
            
            </div>

              <div className="col-sm-3 col-md-2">
              <div className="mb-3">
                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                  End Date
                </label>
              <DatePicker
                   onChange={(date, dateString) => setendDate(date)}
                   placeholder="To Date"
                   style={{ width: "100%" }}
                   className="rounded-2"
                   format={dateFormat}
                   showTime={false}
                   value={endDate}
                   disabledDate={(current) => current && current > moment().endOf('day')}
              />
              </div>
              
              </div>

              <div className="col-sm-3 col-md-2 mt-4">

                        <button
                          className="btn btn-success btn-md rounded-2 me-2"
                          onClick={handleSearchByColumn}
                        >
                          Search
                        </button>

                        <button
                          className="btn btn-danger btn-md rounded-2"
                          onClick={resetFilters}
                        >
                          Reset
                        </button>

              </div>

              {/* <div className="col-sm-3 col-md-6 mt-4 justify-content-end"> */}
              <div className="col mt-4 justify-content-end">
                <h4 style={{ float: "right" }}>
                  <b>FY 2024-2025</b>
                </h4>
              </div>
            </div>

            <div className="page-break">
              <div className="d-flex  justify-content-around my-4">
                <div
                  className="border-1 border border-light-subtle p-3 rounded-3 text-bold"
                  style={{ backgroundColor: "#E7F8F7" }}
                >
                  Total Contract Signed: {counter?.total_project_notstarted}
                </div>
                <div
                  className="border-1 border border-light-subtle p-3 rounded-3 text-bold"
                  style={{ backgroundColor: "#E7F8F7" }}
                >
                  Total Ongoing Projects: {counter?.total_project_ongoing}
                </div>
                <div
                  className="border-1 border border-light-subtle p-3 rounded-3 text-bold"
                  style={{ backgroundColor: "#E7F8F7" }}
                >
                  Total Submitted: {counter?.total_project_completed}
                </div>
                <div
                  className="border-1 border border-light-subtle p-3 rounded-3 text-bold"
                  style={{ backgroundColor: "#E7F8F7" }}
                >
                  Total Kickout: {counter?.total_project_kickout}
                </div>
              </div>
              <div className="row mx-4 graph">
                <div className="col-12 col-md-12 col-lg-12 col-sm-12  my-2 ">
                  <div class="mycard_dash">
                    <div class="mycard-content text-center">
                      <h4>Program Wise Projects</h4>
                      <ProgramWiseProjects
                        search={search}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mx-4 graph">
                {/* <div className="col-12 col-md-12 col-lg-12 col-sm-12 my-4 ">
                  <div class="mycard_dash">
                    <div class="mycard-content text-center">
                      <h4>Projects in Different Stages</h4>
                      <ProjectStageWise
                        search={search}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </div>
                  </div>
                </div> */}


                               <div className="col-12 col-md-12 col-lg-12  col-sm-12 my-2 graph">
                  <div class="mycard_dash">
                    <div class="mycard-content text-center">
                      <h4>Project Stages</h4>
                      <ProjectStageBar 
                      search={search}
                      startDate={startDate}
                      endDate={endDate}/>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mx-4 graph">
                <div className="col-12 col-md-12 col-lg-12 col-sm-12 my-2 ">
                  <div class="mycard_dash">
                    <div class="mycard-content text-center">
                      <h4>SBU Head WorkLoad</h4>
                      <SBUWorkloadGraph
                        search={search}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-12 col-lg-12  col-sm-12 my-2 graph">
                  <div class="mycard_dash">
                    <div class="mycard-content text-center">
                      <h4>Team Leader WorkLoad</h4>
                      <TLWorkloadGraph
                        search={search}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mx-4">
                <div className="col-12 col-md-12 col-lg-12 my-2 graph">
                  <div class="mycard_dash">
                    <div class="mycard-content text-center">
                      <h4>Validator/Verifier Workload</h4>
                      <ValidatorWorkloadGraph
                        search={search}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12 my-2 graph">
                  <div class="mycard_dash">
                    <div class="mycard-content text-center">
                      <h4>Trainee Workload</h4>
                      <TraineeWorkloadGraph
                        search={search}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="page-break">
              <div className="row mx-4">
                <div className="col-12 col-md-12 col-lg-12 col-sm-12 my-2 graph">
                  <div class="mycard_dash">
                    <div class="mycard-content text-center">
                      <h4>Region Wise Projects</h4>
                      <RegionWiseProjectGraph
                        search={search}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mx-4">
                <div className="col-8 col-md-12 col-lg-8  col-sm-12 my-2 graph">
                  <div class="mycard_dash">
                    <div class="mycard-content text-center">
                      <h4>Kickout Month Wise</h4>
                      <KickoutMontlyWiseGraph
                        search={search}
                        startDate={startDate}
                        endDate={endDate}/>
                    </div>
                  </div>
                </div>

                <div className="col-4 col-md-12 col-lg-4  col-sm-12 my-2 graph">
                  <div class="mycard_dash">
                    <div class="mycard-content text-center">
                      <h4>Project Delayed</h4>
                      <ProjectMovingDateWise 
                      search={search}
                      startDate={startDate}
                      endDate={endDate}/>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mx-4">
                <div className="col-12 col-md-12 col-lg-12  col-sm-12 my-2 graph">
                  <div class="mycard_dash">
                    <div class="mycard-content text-center">
                      <h4>Contracts Signed vs Delivered</h4>
                      <ContractSignedMonthlyGraph 
                       search={search}
                       startDate={startDate}
                       endDate={endDate}/>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mx-4">
                {/* <div className="col-12 col-md-12 col-lg-12  col-sm-12 my-2 graph">
                  <div class="mycard_dash">
                    <div class="mycard-content text-center">
                      <h4>Project Stages</h4>
                      <ProjectStageBar />
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="row mx-4">
                <div className="col-12 col-md-12 col-lg-12  col-sm-12 my-2 graph">
                  <div class="mycard_dash">
                    <div class="mycard-content text-center">
                      <h4>RFI Initial Submission</h4>
                      <RFIMonthly 
                      search={search}
                      startDate={startDate}
                      endDate={endDate}/>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mx-4">
                <div className="col-12 col-md-12 col-lg-12  col-sm-12 my-2 graph">
                  <div class="mycard_dash">
                    <div class="mycard-content text-center">
                      <h4>RFI Resubmission</h4>
                      <KickoutRFI 
                      search={search}
                      startDate={startDate}
                      endDate={endDate}/>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mx-4">
                <div className="col-6 col-md-12 col-lg-6  col-sm-12 my-2 graph">
                  <div class="mycard_dash">
                    <div class="mycard-content text-center">
                      <h4>Month Wise TR</h4>
                      <KickoutMontlyWiseGraphTR 
                      search={search}
                      startDate={startDate}
                      endDate={endDate}/>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-12 col-lg-6  col-sm-12 my-2 graph">
                  <div class="mycard_dash">
                    <div class="mycard-content text-center">
                      <h4>Month Wise CC</h4>
                      <KickoutMontlyWiseGraphCC 
                      search={search}
                      startDate={startDate}
                      endDate={endDate}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default ProjectDashboard;
