import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BASE_DOCUMENT, tl_rfi_kickout_url, get_tm_url } from "../../../config";
import { get_KickoutInfo_url } from "../../../config";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import { API_HEADER } from "../../../config";
import FileUploaderComponent from "../../../utlis/FileUploaderComponent";
import LoaderComponent from "../../../utlis/LoderComponent";

const ATKickoutRFIRound = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size;

  const [remark, setRemark] = useState("");
  const [remarkTLRFI, setRemarkTLRFI] = useState("");
  const [remarkATRFI, setRemarkATRFI] = useState("");

  const [tmRfiStatus, setTmRfiStatus] = useState("");

  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const [file3, setFile3] = useState("");
  const [file4, setFile4] = useState("");
  const [file5, setFile5] = useState("");
  const [file6, setFile6] = useState("");
  const [file7, setFile7] = useState("");

  const [fvrName, setFVRName] = useState(null);
  const [fvr, setFvr] = useState("");
  const [pddName, setPDDName] = useState(null);
  const [pdd, setPdd] = useState("");
  const [erName, setERName] = useState(null);
  const [er, setER] = useState("");
  const [cpaName, setCpaName] = useState(null);
  const [cpa, setCpa] = useState("");
  const [rrName, setRRName] = useState(null);
  const [rr, setRR] = useState("");
  const [auditFindingName, setAuditFindingName] = useState(null);
  const [auditFinding, setAuditFinding] = useState("");
  const [otherName, setOtherName] = useState(null);
  const [other, setOther] = useState("");

  const [fileFVRSizeError, setFileFVRSizeError] = useState(false);
  const [filePDDSizeError, setFilePDDSizeError] = useState(false);
  const [fileERSizeError, setFileERSizeError] = useState(false);
  const [fileRRSizeError, setFileRRSizeError] = useState(false);
  const [fileCpaSizeError, setFileCpaSizeError] = useState(false);
  const [fileAuditSizeError, setFileAuditSizeError] = useState(false);
  const [fileOtherSizeError, setFileOtherSizeError] = useState(false);

  const [status, setStatus] = useState("");

  const [remarkTMRFI, setRemarkTMRFI] = useState("");
  const [remarkMD, setRemarkMD] = useState("");

  const [registrarRFIDateTo, setRegistrarRFIDateTo] = useState(null);
  const [registrarRFIRemark, setRegistrarRFIRemark] = useState(null);

  const [loading, setLoading] = useState(false);
  const [member, setMember] = useState();

  const [otherDocName, setOtherDocName] = useState(null);
  const [otherDoc, setOtherDoc] = useState([]);

  const [kickoutStatus, setkickoutStatus] = useState("");
  const [kickoutId, setKickoutId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);

  const [tm, setTM] = useState([]);
  const [tmName, setTMName] = useState("");

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_KickoutInfo_url}/${ids}`,
        API_HEADER
      );
      const { record } = response.data;
      const { kickout } = response.data;

      setTMName(record.rfi_tm_id);

      setKickoutId(kickout.id);
      setkickoutStatus(record.kickout_status);

      setRemarkTLRFI(kickout.rfi_tl_remarks);
      // setRemarkATRFI(kickout.rfi_at_remarks)
      setRemarkATRFI(
        kickout.kickout_status == 24 ||
          kickout.kickout_status == 26 ||
          kickout.kickout_status == 28 ||
          kickout.kickout_status == 30
          ? (kickout.rfi_at_remarks = "")
          : kickout.rfi_at_remarks
      );

      setRemarkTMRFI(kickout.rfi_tmremarks);
      setRemarkMD(kickout.rfi_mdremarks);
      setTmRfiStatus(kickout.rfi_tmaction);
      setRegistrarRFIDateTo(kickout.rfi_registrar_uploading_date);
      setRegistrarRFIRemark(kickout.rfi_registrar_remarks);
      setMember(kickout.kickout_status);

      let url1 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_fvr_report}`;
      setFVRName(url1);
      setFvr(kickout.rfi_fvr_report);

      let url2 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_err_document}`;
      setERName(url2);
      setER(kickout.rfi_err_document);

      let url3 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_pdd_document}`;
      setPDDName(url3);
      setPdd(kickout.rfi_pdd_document);

      let url4 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_cpa_document}`;
      setCpaName(url4);
      setCpa(kickout.rfi_cpa_document);

      let url5 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_irr_document}`;
      setRRName(url5);
      setRR(kickout.rfi_irr_document);

      let url6 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_audit_finding}`;
      setAuditFindingName(url6);
      setAuditFinding(kickout.rfi_audit_finding);

      let url7 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_other_docs}`;
      setOtherName(url7);
      setOther(kickout.rfi_other_docs);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  useEffect(() => {
    const fetchDataTM = async () => {
      try {
        const responsetechnicalmanager = await axios.get(`${get_tm_url}`);
        setTM(responsetechnicalmanager.data.data);
      } catch (error) {}
      // setLoading(false);
    };

    fetchDataTM();
  }, []);

  const handleTM = (e) => {
    setTMName(e.target.value);
  };

  const combined = otherDoc?.map((name, index) => ({
    name: name,
    url: otherDocName[index],
  }));

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setPercent(percentCompleted);
    },
  };

  const handleRemarksChange = (content) => {
    setRemarkATRFI(content);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(false);

    if (
      remarkATRFI == null ||
      remarkATRFI == "<p><br></p>" ||
      remarkATRFI == ""
    ) {
      toast.error("Please Add Some Remarks");
    } else {
      try {
        // setLoading(true); // Show loader
        setIsSubmitting(true);

        const formDataToSend = new FormData();
        formDataToSend.append("project_id", id);
        formDataToSend.append("rfi_fvr_report", file1);
        formDataToSend.append("rfi_cpa_document", file5);
        formDataToSend.append("rfi_err_document", file2);
        formDataToSend.append("rfi_pdd_document", file3);
        formDataToSend.append("rfi_audit_finding", file6);
        formDataToSend.append("rfi_irr_document", file4);
        formDataToSend.append("rfi_other_docs[]", file7);
        formDataToSend.append("rfi_at_remarks", remarkATRFI);
        formDataToSend.append("kickout_status", member);
        formDataToSend.append("kickout_id", kickoutId);
        formDataToSend.append("tm_id", tmName);

        const response = await axios.post(
          `${tl_rfi_kickout_url}`,
          formDataToSend,
          CONFIG_Token2
        );

        if (!response.data.status) {
          toast.error(response.data.message);
        } else if (fileFVRSizeError) {
          toast.error(`File size must below ${dynamicMaxSize}MB`);
        } else {
          toast.success("Document Submitted Successfully");
          setFile1(null);

          setRemarkTLRFI("");
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("Error occurred while submitting data");
      } finally {
        // setLoading(false); // Hide loader
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      {kickoutStatus == 24 ||
      kickoutStatus == 26 ||
      kickoutStatus === 28 ||
      kickoutStatus === 30 ? (
        <>
          <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
          <form
            method="post"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <table className="table table-bordered  table-hover">
              <thead>
                <tr>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    S.No
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Document Name
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                    Upload Document
                  </th>

                  <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                    Uploaded Document
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">1</td>

                  <td className="text-center lightgreen p-3">
                    FVR Report
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <FileUploaderComponent file={file1} setFile={setFile1} />

                  <td>
                    <a href={fvrName} target="_blank">
                      {fvr}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">2</td>

                  <td className="text-center lightgreen p-3">
                    ER
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <FileUploaderComponent file={file2} setFile={setFile2} />

                  <td>
                    <a href={erName} target="_blank">
                      {er}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">3</td>

                  <td className="text-center lightgreen p-3">
                    PDD/MR
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <FileUploaderComponent file={file3} setFile={setFile3} />

                  <td>
                    <a href={pddName} target="_blank">
                      {pdd}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">4</td>

                  <td className="text-center lightgreen p-3">IRR</td>

                  <FileUploaderComponent file={file4} setFile={setFile4} />

                  <td>
                    <a href={rrName}>{rr}</a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">5</td>

                  <td className="text-center lightgreen p-3">CPA</td>

                  <FileUploaderComponent file={file5} setFile={setFile5} />

                  <td>
                    <a href={cpaName}>{cpa}</a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">6</td>

                  <td className="text-center lightgreen p-3">
                    Audit Finding
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <FileUploaderComponent file={file6} setFile={setFile6} />

                  <td>
                    <a href={auditFindingName} target="_blank">
                      {auditFinding}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">7</td>

                  <td className="text-center lightgreen p-3">Other Document</td>

                  <FileUploaderComponent file={file7} setFile={setFile7} />

                  <td>
                    <a target="_blank" href={otherName}>
                      {other}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="d-flex">
              <div className="col-4 my-4">
                <label
                  htmlFor="Member"
                  className="form-label lightgreen mb-4 fs-6"
                >
                  Send To
                  <span style={{ color: "red" }}>*</span>
                </label>

                <select
                  id="member"
                  className="form-select borderlightgreen form-select-sm"
                  aria-label="Default select example"
                  name="status"
                  value={member}
                  required
                  onChange={(e) => {
                    setMember(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  <option value={25}>Forward to TL</option>
                  <option value={27}>Forward to TM</option>
                </select>
              </div>

              {member == 27 && (
                <div className="col-lg-4 col-md-4 col-12 mb-3 my-4">
                  <label
                    htmlFor="financeexpert"
                    className="form-label lightgreen fs-6 mb-4"
                  >
                    Technical Manager<span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="finance_expert"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="finance_expert"
                    value={tmName}
                    onChange={handleTM}
                    // disabled={designation_id == 6}
                    required
                  >
                    <option selected value={""}>
                      Select
                    </option>
                    {tm?.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>

            <div style={{ marginTop: "50px" }}>
              <p className="lightgreen fw-bold  fs-6">
                Team Member Remarks<span style={{ color: "red" }}>*</span>
              </p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkATRFI}
                  name="remarks"
                  dangerouslySetInnerHTML={{ __html: remarkATRFI }}
                  onChange={handleRemarksChange}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>

            {remarkTLRFI && (
              <div style={{ marginTop: "50px" }}>
                <p className="lightgreen fw-bold  fs-6">Team Leader Remarks</p>
                <hr className="mb-3 lightgreen" />
                <div>
                  <ReactQuill
                    theme="snow"
                    value={remarkTLRFI}
                    name="remarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: remarkTLRFI }}
                    // onChange={handleRemarksChange}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            )}

            {remarkTMRFI && (
              <div className="mt-3">
                <p className="lightgreen fw-bold my-3 fs-6">TM Remarks</p>
                <hr className="mb-3 lightgreen" />
                {/* <div className="row mt-4">
                                    {kickoutStatus === 28 ? (
                                        <span>
                                            <Alert className="col-12" message="Clarification Required" banner />
                                        </span>
                                    ) : null}
                                </div> */}
                <div className="row mt-4">
                  <div className="col-12">
                    <ReactQuill
                      theme="snow"
                      value={remarkTMRFI}
                      name="remarks"
                      readOnly={true}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              </div>
            )}

            {remarkMD && (
              <div className="mt-3">
                <p className="lightgreen fw-bold my-3 fs-6">MD Remarks</p>
                <hr className="mb-3 lightgreen" />
                {/* <div className="row mt-4">
                                    {status === 30 ? (
                                        <span>
                                            <Alert className="col-12" message="Clarification Required" banner />
                                        </span>
                                    ) : null}
                                </div> */}
                <div className="row mt-4">
                  <div>
                    <ReactQuill
                      theme="snow"
                      value={remarkMD}
                      name="remarks"
                      readOnly={true}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="container mt-4 d-flex justify-content-end pr-0">
              <button
                className="btn  my-4 fs-5"
                style={{
                  backgroundColor: "#07b6af",
                  color: "white",
                }}
                type="submit"
              >
                Submit
              </button>
            </div>
            {loading && <div className="loader"></div>}
          </form>
        </>
      ) : (
        <form
          method="post"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <table className="table table-bordered  table-hover">
            <thead>
              <tr>
                <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                  S.No
                </th>
                <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                  Document Name
                </th>

                <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                  Uploaded Document
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">1</td>

                <td className="text-center lightgreen p-3">
                  FVR Report
                  <span style={{ color: "red" }}>*</span>
                </td>

                <td>
                  <a href={fvrName} target="_blank">
                    {fvr}
                  </a>
                </td>
              </tr>

              <tr>
                <td class="text-center">2</td>

                <td className="text-center lightgreen p-3">
                  ER
                  <span style={{ color: "red" }}>*</span>
                </td>

                <td>
                  <a href={erName} target="_blank">
                    {er}
                  </a>
                </td>
              </tr>

              <tr>
                <td class="text-center">3</td>

                <td className="text-center lightgreen p-3">
                  PDD/MR
                  <span style={{ color: "red" }}>*</span>
                </td>

                <td>
                  <a href={pddName} target="_blank">
                    {pdd}
                  </a>
                </td>
              </tr>

              <tr>
                <td class="text-center">4</td>

                <td className="text-center lightgreen p-3">IRR</td>

                <td>
                  <a href={rrName}>{rr}</a>
                </td>
              </tr>

              <tr>
                <td class="text-center">5</td>

                <td className="text-center lightgreen p-3">CPA</td>

                <td>
                  <a href={cpaName}>{cpa}</a>
                </td>
              </tr>

              <tr>
                <td class="text-center">6</td>

                <td className="text-center lightgreen p-3">
                  Audit Finding
                  <span style={{ color: "red" }}>*</span>
                </td>

                <td>
                  <a href={auditFindingName} target="_blank">
                    {auditFinding}
                  </a>
                </td>
              </tr>

              <tr>
                <td class="text-center">7</td>

                <td className="text-center lightgreen p-3">Other Document</td>

                <td>
                  <a target="_blank" href={otherName}>
                    {other}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="d-flex">
            <div className="col-4 my-4">
              <label
                htmlFor="Member"
                className="form-label lightgreen mb-4 fs-6"
              >
                Send To
                <span style={{ color: "red" }}>*</span>
              </label>

              <select
                id="member"
                className="form-select borderlightgreen form-select-sm"
                aria-label="Default select example"
                name="status"
                value={member}
                disabled
                readOnly={true}
                // onChange={(e) => {
                //   setMember(e.target.value);
                // }}
              >
                <option value="">Select</option>
                <option value={25}>Forward to TL</option>
                <option value={26}>Send to Team</option>
                <option value={27}>Forward to TM</option>
                <option value={29}>TM Send to MD</option>
                <option value={31}>MD Send to Registrar </option>
                <option value={32}>RFI Complete</option>
              </select>
</div>
              {member == 27 && (
                <div className="col-lg-4 col-md-4 col-12 mb-3 my-4">
                  <label
                    htmlFor="financeexpert"
                    className="form-label lightgreen fs-6 mb-4"
                  >
                    Technical Manager<span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="finance_expert"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="finance_expert"
                    value={tmName}
                    // onChange={handleTM}
                    disabled
                    required
                  >
                    <option selected value={""}>
                      Select
                    </option>
                    {tm?.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>

          {kickoutStatus == 32 ? (
            <div className="row" style={{ marginTop: "40px" }}>
              <div className="col-3 mb-3 ">
                <label
                  htmlFor="visit Date"
                  className="form-label lightgreen fs-6"
                >
                  RFI Submission Date<span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div className="col-4 mb-3 ">
                <input
                  type="date"
                  className={`form-control borderlightgreen`}
                  id="rfidate"
                  required
                  disabled
                  name="rfidate"
                  value={registrarRFIDateTo}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          <div style={{ marginTop: "50px" }}>
            <p className="lightgreen fw-bold  fs-6">
              Team Member Remarks<span style={{ color: "red" }}>*</span>
            </p>
            <hr className="mb-3 lightgreen" />
            <div>
              <ReactQuill
                theme="snow"
                value={remarkATRFI}
                name="remarks"
                readOnly={true}
                dangerouslySetInnerHTML={{ __html: remarkATRFI }}
                // onChange={handleRemarksChange}
                style={{
                  height: "20vh",
                  marginBottom: "2rem",
                }}
                className="mb-5"
              />
            </div>
          </div>

          {remarkTLRFI && (
            <div style={{ marginTop: "50px" }}>
              <p className="lightgreen fw-bold  fs-6">Team Leader Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkTLRFI}
                  name="remarks"
                  readOnly={true}
                  dangerouslySetInnerHTML={{ __html: remarkTLRFI }}
                  // onChange={handleRemarksChange}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
          )}

          {remarkTMRFI && (
            <div className="mt-3">
              <p className="lightgreen fw-bold my-3 fs-6">TM Remarks</p>
              <hr className="mb-3 lightgreen" />
              {/* <div className="row mt-4">
                                    {status === 28 ? (
                                        <span>
                                            <Alert className="col-12" message="Clarification Required" banner />
                                        </span>
                                    ) : null}
                                </div> */}
              <div className="row mt-4">
                <div className="col-12">
                  <ReactQuill
                    theme="snow"
                    value={remarkTMRFI}
                    name="remarks"
                    readOnly={true}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            </div>
          )}

          {remarkMD && (
            <div className="mt-3">
              <p className="lightgreen fw-bold my-3 fs-6">MD Remarks</p>
              <hr className="mb-3 lightgreen" />
              {/* <div className="row mt-4">
                                    {status === 30 ? (
                                        <span>
                                            <Alert className="col-12" message="Clarification Required" banner />
                                        </span>
                                    ) : null}
                                </div> */}
              <div className="row mt-4">
                <div>
                  <ReactQuill
                    theme="snow"
                    value={remarkMD}
                    name="remarks"
                    readOnly={true}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            </div>
          )}

        </form>
      )}
    </>
  );
};

export default ATKickoutRFIRound;
