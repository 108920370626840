import React, { useState } from "react";
import Image from "../assets/25_earth.png";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { feedback_url } from "../config";

const FeedbackForm = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    // reasons: '', // For checkboxes
    // priceComparison: "", // For radio buttons
    // technicalExpertise: "", // For technical expertise rating
    // otherReason: "", // For other reason text input
    // additionalComments: "", // For additional comments

    verificationServices: "",
    communication: "",
    effectively: "",
    additionalComments: "", // For additional comments
    // reasons: "", // For checkboxes
    feedbackComments: "",
    earthoodServices: "",
    technicalExpertise: "", // For technical expertise rating
    main_reason: ''
  });

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;

    setFormData((prevData) => {
      if (id === "otherReason") {
        return {
          ...prevData,
          otherReason: checked ? prevData.otherReason : "",
          reasons: checked
            ? [...prevData.reasons.split(",").filter(Boolean), id].join(",")
            : prevData.reasons
                .split(",")
                .filter((reason) => reason !== id)
                .join(","),
        };
      }

      const reasonsArray = checked
        ? [...prevData.reasons.split(",").filter(Boolean), id]
        : prevData.reasons.split(",").filter((reason) => reason !== id);

      return { ...prevData, reasons: reasonsArray.join(",") };
    });
  };

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleClearForm = () => {
    setFormData({
      // reasons: '',
      // priceComparison: "",
      // technicalExpertise: "",
      // otherReason: "",
      // additionalComments: "",

      verificationServices: "",
      communication: "",
      effectively: "",
      additionalComments: "",
      // reasons: "",
      feedbackComments: "",
      earthoodServices: "",
      technicalExpertise: "", // For technical expertise rating
      main_reason: ''
    });

    // Clear checkboxes and radio buttons manually
    document
      .querySelectorAll("input[type='checkbox'], input[type='radio']")
      .forEach((input) => {
        input.checked = false;
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const payload = {
        token,
        // main_reason: formData.reasons,
        // price: formData.priceComparison,
        // technical_expertise: formData.technicalExpertise,
        // otherReason: formData.otherReason,
        // suggestion: formData.additionalComments,

        verificationServices: formData.verificationServices,
        communication: formData.communication,
        effectively: formData.communication,
        suggestion: formData.additionalComments,
        // main_reason: formData.reasons,
        feedbackComments: formData.feedbackComments,
        earthoodServices: formData.earthoodServices,
        technical_expertise: formData.technicalExpertise,
        main_reason: formData.main_reason,
      };

      const response = await axios.post(feedback_url, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("Response:", response.data.status);
      if (response.data.status) {
        navigate("/feedbackthanks");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to submit feedback. Please try again.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        className="d-flex flex-column align-items-center p-3 justify-content-center border-2 rounded"
        style={{ backgroundColor: "#e0f7fa" }}
      >
        {/* Logo Section */}
        {/* <div className="border d-flex justify-content-center align-items-center border-2 w-50 rounded"> */}
        <img src={Image} alt="Logo" className=" h-10 rounded w-50" />
        {/* </div> */}

        {/* Title and User Info */}
        <div className=" mt-2 p-2 w-50">
          <h3 className=" ml-0 font-weight-bold">
            Client Feedback Survey: Earthood
          </h3>
        </div>

        {/* Verification Services */}
        <div className="card  p-4 w-50 shadow-sm">
          <h5>
            How would you rate the quality of our validation and/or verification
            services?
          </h5>
          {["Excellent", "Good", "Fair", "Poor"].map((option, index) => (
            <div className="form-check my-2" key={index}>
              <input
                className="form-check-input"
                type="radio"
                id={`verification-${option}`}
                name="verificationServices"
                value={option}
                onChange={handleRadioChange}
              />
              <label className="form-check-label" htmlFor={`verification-${option}`}>{option}</label>
            </div>
          ))}
        </div>

        {/* Communication */}
        <div className="card  p-4 w-50 shadow-sm">
          <h5>How satisfied are you with the communication from our team?</h5>
          {[
            "Very Satisfied",
            "Satisfied",
            "Neutral",
            "Dissatisfied",
            "Very Dissatisfied",
          ].map((option, index) => (
            <div className="form-check my-2" key={index}>
              <input
                className="form-check-input"
                type="radio"
                id={`communication-${option}`}
                name="communication"
                value={option}
                onChange={handleRadioChange}
              />
              <label className="form-check-label" htmlFor={`communication-${option}`}>{option}</label>
            </div>
          ))}
        </div>

        {/* Effectively */}
        <div className="card  p-4 w-50 shadow-sm">
          <h5>
            How effectively do we respond to your issues or concerns during the
            process of validation and/or verification?
          </h5>
          {[
            "Very Effectively",
            "Effectively",
            "Somewhat Effectively",
            "Not Effectively",
          ].map((option, index) => (
            <div className="form-check my-2" key={index}>
              <input
                className="form-check-input"
                type="radio"
                id={`effectively-${option}`}
                name="effectively"
                value={option}
                onChange={handleRadioChange}
              />
              <label className="form-check-label" htmlFor={`effectively-${option}`}>{option}</label>
            </div>
          ))}
        </div>

        {/* Additional Comments */}
        <div className="card p-4 w-50 shadow-sm">
          <h5>What aspect of our service would you like to see improved?</h5>
          <textarea
            type="text"
            id="additionalComments"
            className="form-control"
            placeholder="Your Answer"
            value={formData.additionalComments} // Bind the value here
            onChange={handleInputChange}
          />
        </div>

        {/* Checkbox Group */}
        <div className="card  p-4 w-50 shadow-sm">
          <h5>
            Which of the following characteristics of our validation and
            verification services do you find most valuable? Select all that
            apply
          </h5>
          {[
            "Accuracy",
            "Transparency",
            "Timeliness",
            "Comprehensiveness",
            "Ease of Communication",
            "Customized Solutions",
          ].map((reason, index) => (
            <div className="form-check my-1" key={index}>
              <input
                className="form-check-input my-1"
                type="checkbox"
                id={`reason${index + 1}`}
                // onChange={handleCheckboxChange}

                name="main_reason"
                value={reason}
                onChange={handleRadioChange}
              />
              <label
                className="form-check-label"
                htmlFor={`reason${index + 1}`}

                // htmlFor={`main_reason`}
              >
                {reason}
              </label>
            </div>
          ))}
        </div>

        {/* Feedback Comments */}
        <div className="card p-4 w-50 shadow-sm">
          <h5>
            Please share any specific feedback or experiences you've had with
            our team that stood out to you.
          </h5>
          <textarea
            type="text"
            id="feedbackComments"
            className="form-control"
            placeholder="Your Answer"
            value={formData.feedbackComments} // Bind the value here
            onChange={handleInputChange}
          />
        </div>

        {/* <div className="card p-4 w-50 shadow-sm">
  <h5>
    Please share any specific feedback or experiences you've had with our team that stood out to you.
  </h5>
  <textarea
    id="feedbackComments"
    className="form-control border-0 border-bottom"
    placeholder="Your Answer"
    value={formData.feedbackComments} // Bind the value here
    onChange={handleInputChange}
    style={{ borderRadius: "0", outline: "none", boxShadow: "none" }}
  />
</div> */}

        {/* Verification Services */}
        <div className="card  p-4 w-50 shadow-sm">
          <h5>Overall, how satisfied are you with our services at Earthood?</h5>
          {[
            "Very Satisfied",
            "Satisfied",
            "Neutral",
            "Dissatisfied",
            "Very Dissatisfied",
          ].map((option, index) => (
            <div className="form-check my-2" key={index}>
              <input
                className="form-check-input"
                type="radio"
                id={`earthoodServices-${option}`}
                name="earthoodServices"
                value={option}
                onChange={handleRadioChange}
              />
              <label className="form-check-label" htmlFor={`earthoodServices-${option}`}>{option}</label>
            </div>
          ))}
        </div>

        {/* Technical Expertise */}
        {/* <div className="card  p-4 w-50 shadow-sm">
          <h5>
            How would you rate the technical expertise demonstrated in our
            proposal?
          </h5>
          <div className="d-flex justify-content-between align-items-center my-4">
            <div className="my-4">Not at all likely</div>
            {[1, 2, 3, 4, 5,6,7,8,9,10].map((rating) => (
              <div className="" key={rating}>
                <div className="">{rating}</div>
                <div>
                  <input
                    type="radio"
                    name="technicalExpertise"
                    value={rating}
                    onChange={handleRadioChange}
                  />
                </div>
              </div>
            ))}
            <div className="my-4">Extremely likely</div>
          </div>
        </div> */}

        <div className="card p-4 w-50 shadow-sm">
          <h5>
            How would you rate the technical expertise demonstrated in our
            proposal?
          </h5>

          {/* Ratings Container */}
          <div className="row text-center my-4">
            <div className="col-12 col-md-3 text-md-left my-2 ">
              Not at all likely
            </div>

            <div className="col-12 col-md-6 d-flex justify-content-between flex-wrap">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((rating) => (
                <div key={rating} className="text-center mx-1">
                  <div className="fw-bold">{rating}</div>
                  <input
                    type="radio"
                    name="technicalExpertise"
                    value={rating}
                    onChange={handleRadioChange}
                  />
                </div>
              ))}
            </div>

            <div className="col-12 col-md-3 text-md-right my-2">
              Extremely likely
            </div>
          </div>
        </div>

        <div className="d-flex mt-2 justify-content-around w-50 ">
          <div className="flex justify-content-start">
            <button className="btn btn-primary" type="submit">
              Submit
            </button>
          </div>
          <div>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={handleClearForm}
            >
              Clear form
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FeedbackForm;
