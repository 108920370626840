import React, { useEffect, useState } from 'react';
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import Header from '../../../../Pages/Header';
import Footer from '../../../Footer';
import SideNavbar from '../../../SideNavbar';
import { Table, Tooltip as Tip } from "antd";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { getAllProjectsDashboard, API_HEADER,getAllKickoutDashboard } from "../../../../config";
import { useParams,useNavigate } from "react-router-dom";
import ProjectStatusSchema from '../../../../Schema/ProjectStatusSchema';

const KickoutWiseProjectTable = () => {
    const navigate = useNavigate();

    const pathSegments = window.location.pathname.split("/").slice(2); // Extracting dynamic parts

    // const formattedSegments = pathSegments.map(segment =>
    //     segment
    //         .split("_") // Split by underscore
    //         .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
    //         .join(" ") // Join words with a space
    // );

    const formattedSegments = pathSegments.map(segment =>
        decodeURIComponent(segment) // Decode URL encoding (e.g., %20 → space)
            .split("_") // Split by underscore
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
            .join(" ") // Join words with a space
    );
        
    console.log("formatText",formattedSegments)

    let title = formattedSegments[1];
    let seriesName = formattedSegments[1]; 
    let project_stage = formattedSegments[1];
    const program_id = Number(formattedSegments[3])
    const programName = formattedSegments[2]
    let workload_status = formattedSegments[1]
    const sbu_head = Number(formattedSegments[2])
    const team_leader = Number(formattedSegments[2])
    const validator_verifier = Number(formattedSegments[2])
    const trainee_validator = Number(formattedSegments[2])
    const region_id = Number(formattedSegments[3])
    const region_status = formattedSegments[1]
    const updated_period = formattedSegments[1]
    const graph_name = formattedSegments[0];
    const contract_signed_month = formattedSegments[1];
    const contract_signed_status = formattedSegments[2];

    const segmentFrom = formattedSegments[4]; // "Var-from=2024-04-01"
    const startDate = segmentFrom?.split('=')[1]; // "2024-04-01"
    console.log(startDate,'😂');

    const segmentTo = formattedSegments[5]; // "Var-from=2024-04-01"
    const endDate = segmentTo?.split('=')[1]; // "2024-04-01"
    console.log(endDate,'😊');

    console.log("startDate",startDate)

    console.log("graph_name",graph_name)

    if(formattedSegments[1] == 'Issued Registered'){
        title = 'Issued Registered';
        seriesName = 'Issued/Registered'; 
       project_stage = 'Issued/Registered';
       workload_status = 'Issued/Registered'
    }

    if(formattedSegments[1] == 'Rfi Completed'){
        title = 'RFI Completed';
        seriesName = 'RFI Completed'; 
       project_stage = 'RFI Completed';
       workload_status = 'RFI Completed'
    }

    
 
console.log("new",title,seriesName,program_id,programName)
    const location = useLocation();
    // const {
    //     // workload_status,
    //     // seriesName,
    //     // program_id,
    //     // programName,
    //     // sbu_head,
    //     // team_leader,
    //     // trainee_validator,
    //     // validator_verifier,
    //     // project_stage,
    //     // updated_period,
    //     // region_status,
    //     // region_id,
    //     // contract_signed_month,
    //     // contract_signed_status,
    //     // title,
    //     // startDate,
    //     // endDate,
    //     // graph_type
    // } = location.state ?? {};
    
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    useEffect(() => {
        const fetchData = async () => {

            const payload = {
                // program: program_id,
                // project_stage:project_stage,
                // program_name:programName
                page: pagination.current,
                limit: pagination.pageSize,
                // program_status: seriesName,
                // sbu_head: sbu_head,
                // team_leader: team_leader,
                // trainee_validator: trainee_validator,
                // validator_verifier: validator_verifier,
                // complete_status: seriesName,                
                // updated_period:updated_period, 
                // region_status:region_status,
                // region_id:region_id,
                contract_signed_status:contract_signed_status,
                contract_signed_month:contract_signed_month,
                // workload_status:workload_status,
                startDate:startDate,
                endDate:endDate,
                // graph_type:graph_type,
                graph_name:graph_name
                
            };
            try {
                console.log("API_HEADER",API_HEADER)
                console.log("payload",payload)
                const response = await axios.post(`${getAllKickoutDashboard}`, payload, API_HEADER);
                console.log("popopo",response.data.records.data)
                setDataSource(response.data.records.data);
                setPagination((prevPagination) => ({
                    ...prevPagination,
                    total: response.data.total,
                }));
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        fetchData();
    }, [seriesName, program_id, sbu_head, team_leader, trainee_validator, validator_verifier, pagination.current, pagination.pageSize,project_stage,updated_period,region_id]);

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
        setLoading(true);
    };

    const viewDetails = async (id) => {
        navigate(`/atdeskreview/${id}`);
    };

    const columns = [
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    S.No
                </span>
            ),
            dataIndex: "id",
            fixed: "left",
            width: 70,
            key: "id",
            render: (id, record, index) => {
                const pageIndex = (pagination.current - 1) * pagination.pageSize;
                return pageIndex + index + 1;
            },
        },
        {
            title: "EID",
            dataIndex: "earthood_id",
            key: "earthood_id",
            width: 90,
            render: (record) => (
                <Tip title={record}>
                    <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                        {record}
                    </span>
                </Tip>
            ),
         },
        {
            title: "Project Name",
            dataIndex: "project_name",
            key: "project_name",
            width: 150,
            render: (record) => (
                <Tip title={record}>
                    <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                        {record}
                    </span>
                </Tip>
            ),
        },
        // {
        //     title: "Program",
        //     dataIndex: "program_name",
        //     key: "program_name",
        //     width: 90,
        //     render: (record) => (
        //         <Tip title={record.description}>
        //             <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
        //                 {record.description}
        //             </span>
        //         </Tip>
        //     ),
        // },
        {
            title: "Program",
            dataIndex: "program_name",
            key: "program_name",
            width: 90,
            render: (record) => (
                <Tip title={record?.program_name}>
                    <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                        {record?.program_name == null ? record : record?.program_name}
                    </span>
                </Tip>
            ),
        },
        // {
        //     title: "Scope",
        //     dataIndex: "scope_name",
        //     key: "scope_name",
        //     width: 90,
        //     render: (record) => (
        //         <Tip title={record.scope}>
        //             <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
        //                 {record.scope}
        //             </span>
        //         </Tip>
        //     ),
        // },
        //         {
        //     title: "Scope",
        //     dataIndex: "scope",
        //     key: "scope",
        //     width: 90,
        //     render: (record) => (
        //         <Tip title={record}>
        //             <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
        //                 {record}
        //             </span>
        //         </Tip>
        //     ),
        // },
        // {
        //     title: (
        //         <span className="text-capitalize textcolumntitle font14px fw-bold">
        //             Status
        //         </span>
        //     ),
        //     width: 130,
        //     render: (record) => (
        //         <ProjectStatusSchema status={record.project_status} />
        //     ),
        // },
               {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Status
                </span>
            ),
            width: 130,
            render: (record) => (
                <ProjectStatusSchema status={record.project_status} />
            ),
        },
        {
            title: "Action",
            key: "action",
            width: 80,
            render: (record) => (
                <a>
                    <EyeOutlined
                        onClick={() => viewDetails(record.id)}
                        style={{ color: "blue", fontSize: '20px' }}
                    />
                </a>
            ),
        },
    ];
    console.log("rttrtr",dataSource)
    return (
        <>
            <Header />
            <SideNavbar/>
            <div className="content-wrapper bg-white">
                <div className="content">
                    <div className="container-fluid">
                        <div className="row" style={{ justifyContent: "center" }}>
                            <div className="">
                                <div className="mx-3 my-3 border-2 border border-light-subtle p-0 rounded-3 mt-5">
                                    <div className="row border-0">
                                        <div className="col-12">
                                            <p className="text-black text-capitalize font20px fw-bold px-3 pt-3 rounded-top-3">
                                                {title}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='row d-flex justify-content-around'></div>
                                    <div className="row my-2 mx-3">
                                        <Table
                                            className="border-1 border rounded-0 border-subtle-light px-0"
                                            scroll={{ x: 1000 }}
                                            columns={columns}
                                            dataSource={dataSource}
                                            rowKey="id"
                                            loading={loading}
                                            pagination={pagination}
                                            onChange={handleTableChange}
                                            bordered
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default KickoutWiseProjectTable;
