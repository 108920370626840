// import React, { useState, useEffect } from "react";
// import Chart from "react-apexcharts";
// import { API_HEADER, client_project_moving_wise_graph_url } from "../../../config";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// function ClientProjectMovingDateWiseGraph() {
//   const navigate = useNavigate();

//   const [updatedPeriod, setUpdatedPeriod] = useState([]);
//   const [projectCount, setProjectCount] = useState([]);
//   const [programWiseData, setProgramWiseData] = useState([]);

//   useEffect(() => {
//     const updatedperiod = [];
//     const projectcount = [];

//     const getProposalScopeWise = async () => {
//       const payload = { "fy": "" };
//       const reqData = await axios.post(`${client_project_moving_wise_graph_url}`, payload, API_HEADER);

//       const MyprogramwiseData = Object.values(reqData.data.data);
     
//       const order = ['0-10 days', '11-20 days', '21-30 days', 'older'];

//       MyprogramwiseData.sort((a, b) => order.indexOf(a.updated_period) - order.indexOf(b.updated_period));

//       for (let key in MyprogramwiseData) {
//         if (MyprogramwiseData.hasOwnProperty(key)) {
//           updatedperiod.push(MyprogramwiseData[key].updated_period);
//           projectcount.push(MyprogramwiseData[key].project_count);
//         }
//       }

//       setUpdatedPeriod(updatedperiod);
//       setProjectCount(projectcount);
//       setProgramWiseData(MyprogramwiseData);
//     };

//     getProposalScopeWise();
//   }, []);

//   const handleChartClick = (event, chartContext, { seriesIndex, dataPointIndex, w }) => {
//     const seriesName = w.globals.seriesNames[seriesIndex];
//     const programName = w.globals.labels[dataPointIndex];

//     const matchedProgram = Object.values(programWiseData).find((program) => program.updated_period === programName);

//     const updated_period = matchedProgram ? matchedProgram.updated_period : null;

//     if (updated_period) {
//       navigate('/programwiseprojecttable', { state: { title:updated_period, updated_period, seriesName: programName } });
//     }
//   };

//   return (
//     <React.Fragment>
//       <div className="container-fluid">
//         <Chart
//           type="bar"
//           width={"100%"}
//           height={400}
//           series={[
//             {
//               name: "Project Count",
//               data: projectCount,
//               color: '#D84315'
//             },
//           ]}
//           options={{
//             chart: {
//               toolbar: {
//                 show: false,  // This will hide the icons on the top right corner
//               },
//               stacked: true,
//               events: {
//                 dataPointSelection: handleChartClick,
//               },
//             },
//             plotOptions: {
//               bar: {
//                 horizontal: false,
//                 columnWidth: '40%',
//                 dataLabels: {
//                   total: {
//                     enabled: true,
//                     style: {
//                       fontSize: '11px',
//                       fontWeight: 900
//                     },
//                   }
//                 }
//               }
//             },
//             stroke: {
//               width: 1,
//             },
//             xaxis: {
//               title: {
//                 text: "Project Not moving forward",
//                 style: {
//                   fontSize: '16px',
//                   fontWeight: 'bold'
//                 }
//               },
//               categories: updatedPeriod
//             },
//             yaxis: {
//               title: {
//                 text: "Number of Projects",
//                 style: { fontSize: '16px', fontWeight: 'bold' }
//               },
//               labels: {
//                 style: { fontSize: "15" },
//               }
//             },
//             legend: {
//               position: 'bottom',
//             },
//             dataLabels: {
//               enabled: false,
//             },
//             grid: {
//               show: true,
//               xaxis: {
//                 lines: {
//                   show: false
//                 }
//               },
//               yaxis: {
//                 lines: {
//                   show: false
//                 }
//               },
//               borderColor: '#f1f1f1',
//               row: {
//                 colors: ['transparent'],
//                 opacity: 1,
//               },
//             },
//           }}
//         />
//       </div>
//     </React.Fragment>
//   );
// }

// export default ClientProjectMovingDateWiseGraph;

import React from "react";


function ClientProjectMovingDateWiseGraph({ startDate, endDate }) {
  const grafanaBaseUrl = "https://grafana1.intileotech.xyz:3000/d-solo/eebqpgmaf99mof/client-dashboard";
  const orgId = 1;
  const timezone = "browser";
  // const panelId = 3;
  const panelId = 8;
  const theme = "light";
 


    const role = localStorage.getItem("designation_id");
    console.log("roleee",role)
    const userData = localStorage.getItem("user");
    const userId = JSON.parse(userData);
  
    // Access the 'id' field
    const client_Id = userId.client_id;
    console.log("Extracted ID:", client_Id);


  // Convert start and end date to UNIX timestamps (milliseconds)
  const from = startDate ? startDate.format('YYYY-MM-DD') : '2024-04-01' 
  const to = endDate ? endDate.format('YYYY-MM-DD') : '2025-03-31' 


// const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&from=${from}&to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-user_id=${user_id}`;


const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-client_id=${client_Id}`;


  console.log("from",from,"to",to)

  // Construct Grafana iframe URL dynamically
  // const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&var-from=${from}&var-to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}`;

  return (
    <div className="container-fluid">
      <iframe
        src={grafanaUrl}
        width="100%"
        height="400"
        frameBorder="0"
        title="Program Wise Projects"
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
}

export default ClientProjectMovingDateWiseGraph;






